import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';

import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {AventiFile} from 'src/app/models/File';
import {GlobalService} from 'src/app/services/global.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';


import piexif from 'piexifjs';
import heic2any from 'heic2any';

// function sleep(milliseconds) {
//   var start = new Date().getTime();
//   for (var i = 0; i < 1e7; i++) {
//     if (new Date().getTime() - start > milliseconds) {
//       break;
//     }
//   }
// }

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.css'],
})
export class UploadButtonComponent {
  private ngUnsubscribe = new Subject();

  // @Input() dbRefFileCol = 'test';
  // @Input() dbRefDoc = 'test';
  // @Input() storageFolder = 'test';
  @Input() dbPath = '';
  @Input() text: string;
  @Input() icon = 'add_a_photo';
  @Input() color = undefined;
  @Input() raised = true;
  @Input() isDeviationSign = false;
  @Input() button = true;
  @Input() size = '85px';
  loading: boolean;

  isOnline: boolean;

  // @Input() showWhenUploaded = false;

  doc: AngularFirestoreDocument<any>;
  docObs: Observable<any>;
  fileID: string[];

  // Main task
  // task: AngularFireUploadTask;

  // Progress monitoring
  progress: {
    percentage: Observable<number>;
    snapshot: Observable<any>;
  }[] = [];

  // downloadurl URL
  downloadurl: Observable<string>;
  uploadDone = true;
  // loading: boolean;

  // State for dropzone CSS toggling
  isHovering: boolean;

  mobileQuery: MediaQueryList;

  exifdata: any;
  file: any[] = [];

  filedata = 'data'; // {
  //   lat: 0,
  //   lon: 0,
  // };

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private globalservice: GlobalService,
    private sharedservice: SharedService,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit() {
    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onBlurMethod() {
    console.log('Blurred');
  }

  uploadButtonClicked() {
    console.log('Clicked');
    // this.loading = true;
    // document.getElementById('fileNameInput').click();
  }


  async convertIfHeic(file: File): Promise<File> {
    console.log('Check if file is heic', file);
    if (/\.hei(c|f)+$/.test(file.name.toLowerCase())) {
      console.log('convert heic...');
      const blob = await heic2any({blob: file, toType: 'image/jpeg', quality: 1}) as Blob;
      const fileName = file.name.replace(/\.[^/.]+$/, '.jpg');
      return this.blobToFile(blob, fileName);
    }
    return file;
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    const options: FilePropertyBag = {type: theBlob.type};
    return new File([theBlob], fileName, options);
  }

  // private blobToFile = (theBlob: Blob, fileName: string): File => {
  //   const b: any = theBlob;
  //
  //   // A Blob() is almost a File() - it's just missing the two properties below which we will add
  //   b.lastModified = new Date();
  //   b.name = fileName;
  //
  //   // Cast to a File() type
  //   return theBlob as File;
  // }

  async startUpload(eventFileList: FileList, fileNameInput) {
    // Clear observable to show progress in html
    this.loading = true;
    this.uploadDone = false;
    this.downloadurl = null;
    this.progress = [];

    let uploadIndex = 0;

    // The File objects
    let files = Array.from(eventFileList);

    // Clear the input
    fileNameInput.value = null;

    if (files.length > 0) {
      for (const f of files) {
        let fLocal: any = f;

        console.log(fLocal);
        if (/image\/hei(c|f)/.test(fLocal.type)) {
          // CONVERT HEIC TO JPG
          fLocal = await this.convertIfHeic(fLocal);
          console.log(fLocal);
        }
        let exif = null;
        let exifstring: string = null;

        const reader = new FileReader();
        reader.readAsDataURL(fLocal);
        reader.onload = async () => {
          const exifRaw = await piexif.load(reader.result);
          console.log(exif);
          console.log(exifRaw);

          let make: string = null;
          let model: string = null;
          let dateTime: string = null;
          let dateTimeOriginal: string = null;
          let exifVersion: string = null;

          let pixelXDimension: string = null;
          let pixelYDimension: string = null;

          let altitudeRational: string = null;
          let altitudeDecimal: number = null;
          // let altitudeRef: string = null;
          // let altitudeString: string = null;

          let gpsHPositioningError: string = null;
          let latitude: string = null;
          let latitudeRef: string = null;
          let longitude: string = null;
          let longitudeRef: string = null;
          let latitudeMultiplier: number = null;
          let decimalLatitude: number = null;
          let longitudeMultiplier: number = null;
          let decimalLongitude: number = null;

          for (const exifdata of Object.entries(exifRaw)) {
            switch (exifdata[0]) {
              case '0th':
                // make = exifdata['0th'][piexif.ImageIFD.Make];
                make = exifdata[1][piexif.ImageIFD.Make] || null;
                model = exifdata[1][piexif.ImageIFD.Model] || null;
                dateTime = exifdata[1][piexif.ImageIFD.DateTime] || null;
                break;

              case 'Exif':
                dateTimeOriginal =
                  exifdata[1][piexif.ExifIFD.DateTimeOriginal] || null;
                exifVersion = exifdata[1][piexif.ExifIFD.ExifVersion] || null;
                pixelXDimension =
                  exifdata[1][piexif.ExifIFD.PixelXDimension] || null;
                pixelYDimension =
                  exifdata[1][piexif.ExifIFD.PixelYDimension] || null;
                break;

              case 'GPS':
                altitudeRational =
                  exifdata[1][piexif.GPSIFD.GPSAltitude] || null;
                if (altitudeRational) {
                  altitudeDecimal = this.rationalToDecimal(altitudeRational);
                }
                gpsHPositioningError =
                  exifdata[1][piexif.GPSIFD.GPSHPositioningError] || null;
                latitude = exifdata[1][piexif.GPSIFD.GPSLatitude] || null;
                latitudeRef =
                  exifdata[1][piexif.GPSIFD.GPSLatitudeRef] || null;
                longitude = exifdata[1][piexif.GPSIFD.GPSLongitude] || null;
                longitudeRef =
                  exifdata[1][piexif.GPSIFD.GPSLongitudeRef] || null;
                latitudeMultiplier = latitudeRef === 'N' ? 1 : -1;
                if (latitude) {
                  decimalLatitude =
                    latitudeMultiplier *
                    piexif.GPSHelper.dmsRationalToDeg(latitude);
                }
                longitudeMultiplier = longitudeRef === 'E' ? 1 : -1;
                if (longitude) {
                  decimalLongitude =
                    longitudeMultiplier *
                    piexif.GPSHelper.dmsRationalToDeg(longitude);
                }
                break;
            }
          }
          // });
          exif = {
            Make: make || null,
            Model: model || null,
            // LensMake: output.LensMake || null,
            // LensModel: output.LensModel || null,
            // CreateDate: output.CreateDate || null,
            DateTime: dateTime || null,
            DateTimeOriginal: dateTimeOriginal || null,
            ExifImageWidth: pixelXDimension || null,
            ExifImageHeight: pixelYDimension || null,
            latitude: decimalLatitude || null,
            // latitude: latitude || null,
            latitudeRef: latitudeRef || null,
            longitude: decimalLongitude || null,
            // longitude: longitude || null,
            longitudeRef: longitudeRef || null,
            // GPSAltitude: gpsAltitude || null,
            altitudeDecimal: altitudeDecimal || null,
            // altitudeString: altitudeString || null,
            // Orientation: output.Orientation || null,
            // ModifyDate: output.ModifyDate || null,
            // HostComputer: output.HostComputer || null,
            ExifVersion: exifVersion || null,
            // GPSSpeed: output.GPSSpeed || null,
            GPSHPositioningError: gpsHPositioningError || null,
            // GPSDateStamp: output.GPSDateStamp || null,
          };
          console.log(exif);
          exifstring = JSON.stringify(exif || null);
        };

        console.log(exif);
        this.filedata = JSON.stringify(exif);

        const file: AventiFile = fLocal;
        file.exif = exif;

        file.timestamp = firebase.firestore.Timestamp.now();

        // The storage path
        const time = new Date().getTime();
        const filename = time + '_' + file.name;
        const path = `${this.dbPath}/${filename}`;

        let storagename200: string = null;
        let storagename500: string = null;
        let thumbnailpath: string = null;
        if (file.type.indexOf('image') > -1) {
          storagename200 =
            filename.substring(0, filename.lastIndexOf('.')) +
            '_200x200' +
            filename.substring(filename.lastIndexOf('.'), filename.length);
          storagename500 =
            filename.substring(0, filename.lastIndexOf('.')) +
            '_500x500' +
            filename.substring(filename.lastIndexOf('.'), filename.length);
          thumbnailpath = `${this.dbPath}/thumbs/`;
        }

        const newfileID = this.afs.createId();
        const firestorePath = this.dbPath + '/files/' + newfileID;

        // Totally optional metadata
        const customMetadata = {
          app: 'APG',
          fileid: newfileID,
          lastModified: f.lastModified.toString(),
          size: f.size.toString(),
          exifstring,
          ...exif,
          // Make: exif.Make || null,
          // Model: exif.Model || null,
          // LensModel: exif.LensModel || null,
          // CreateDate: exif.CreateDate || null,
          // ExifImageWidth: exif.ExifImageWidth || null,
          // ExifImageHeight: exif.ExifImageHeight || null,
          // latitude: exif.latitude || null,
          // longitude: exif.longitude || null,
          // GPSAltitude: exif.GPSAltitude || null,
          // Orientation: output.Orientation || null,
          // ModifyDate: output.ModifyDate || null,
          // HostComputer: output.HostComputer || null,
          // ExifVersion: output.ExifVersion || null,
          // GPSSpeed: output.GPSSpeed || null,
          // GPSHPositioningError: output.GPSHPositioningError || null,
          // GPSDateStamp: output.GPSDateStamp || null
        };

        // The main tasks
        const ref = this.storage.ref(path);
        const task: AngularFireUploadTask = this.storage.upload(path, file, {
          customMetadata,
        });

        let projectid = null;
        if (this.dbPath.split('/').length > 0) {
          projectid = this.dbPath.split('/')[1];
        }
        let isReceiptform = false;
        if (this.dbPath.split('/')[2] === 'receiptforms') {
          isReceiptform = true;
        }
        let objectid = null;
        let checklistid = null;
        let checklistitemid = null;
        console.log(this.dbPath.split('/'));
        if (isReceiptform) {
          if (this.dbPath.split('/').length > 2) {
            checklistid = this.dbPath.split('/')[3];
          }
          if (this.dbPath.split('/').length > 4) {
            checklistitemid = this.dbPath.split('/')[5];
          }
        } else {
          if (this.dbPath.split('/').length > 2) {
            objectid = this.dbPath.split('/')[3];
          }
          if (this.dbPath.split('/').length > 4) {
            checklistid = this.dbPath.split('/')[5];
          }
          if (this.dbPath.split('/').length > 6) {
            checklistitemid = this.dbPath.split('/')[7];
          }
        }

        // Progress monitoring
        this.progress.push({
          percentage: task.percentageChanges(),
          snapshot: task.snapshotChanges(),
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              if (ref.child(path)) {
                ref.getMetadata().subscribe((meta) => {
                  const sizeB = meta.size;
                  const sizeMB = Math.ceil(meta.size / 1000000);
                  console.log(meta);
                  ref
                    .getDownloadURL()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((url) => {
                      console.log('...waiting for file...', url);
                      const downloadurlstring = url;
                      this.sharedservice.user
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((u) => {
                          const user = u[0];
                          this.afs
                            .doc(firestorePath)
                            .set({
                              id: newfileID,
                              projectid,
                              objectid,
                              checklistid,
                              checklistitemid,
                              path: firestorePath,
                              storagepath: this.dbPath,
                              storagename: filename,
                              downloadurl: downloadurlstring,
                              thumbnailpath,
                              storagename200,
                              storagename500,
                              timestamp: file.timestamp,
                              name: file.name,
                              type: file.type,
                              sizeB,
                              sizeMB,
                              sizestring: this.globalservice.formatBytes(
                                sizeB,
                                1
                              ),
                              editedBy:
                                firebase.firestore.FieldValue.arrayUnion({
                                  user,
                                  timestamp:
                                    firebase.firestore.Timestamp.now(),
                                }),
                              lastupdated: firebase.firestore.Timestamp.now(),
                              isdeviationsign: this.isDeviationSign,
                              exif: exif || null,
                              exifstring,
                            })
                            .then(() => {
                              console.log('Uploaded to Firestore');
                            })
                            .catch((err) => {
                              console.log(
                                'Error uploading to Firestore: ',
                                err
                              );
                            });
                        });
                      uploadIndex++;
                      if (uploadIndex === files.length) {
                        this.uploadDone = true;
                        this.loading = false;
                        uploadIndex = 0;
                        eventFileList = null;
                        fileNameInput.value = null;
                        files = null;
                      }
                    });
                });
              }
            })
          )
          .subscribe();
      }
    } else {
      this.uploadDone = true;
      this.loading = false;
      uploadIndex = 0;
    }
  }

  // Given a Piexifjs object, this function displays its Exif tags
  // in a human-readable format
  debugExif(exif) {
    for (const ifd in exif) {
      if (ifd === 'thumbnail') {
        const thumbnailData = exif[ifd] === null ? 'null' : exif[ifd];
        console.log(`- thumbnail: ${thumbnailData}`);
      } else {
        console.log(`- ${ifd}`);
        for (const tag in exif[ifd]) {
          if (exif[ifd].hasOwnProperty(tag)) {
            console.log(
              `    - ${piexif.TAGS[ifd][tag].name}: ${exif[ifd][tag]}`
            );
          }
        }
      }
    }
  }
  // Given a numerator/denominator pair expressed as a 2-element array,
  // return it as a single numeric value
  rationalToDecimal(rationalValue) {
    return rationalValue[0] / rationalValue[1];
  }

  // Given the altitude and altitudeRef values from Exif,
  // return a string expressing these values in terms of
  // meters above or below sea level
  formatAltitude(altitude, altitudeRef) {
    let altitudeRefText = '(above or below sea level not specified)';
    if (altitudeRef === 0) {
      altitudeRefText = 'above sea level';
    } else if (altitudeRef === 1) {
      altitudeRefText = 'below sea level';
    }
    return `${altitude} meters ${altitudeRefText}`;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
