import {Component} from '@angular/core';
import {fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {map, mapTo} from 'rxjs/operators';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  selector: 'app-offline-detection',
  templateUrl: './offline-detection.component.html',
  styleUrls: ['./offline-detection.component.css'],
})
export class OfflineDetectionComponent {
  online$: Observable<boolean>;
  first = true;
  alert = '';
  networkStatus = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(sharedservice: SharedService) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.online$.subscribe((state) => {
      if (!this.first) {
        if (!state) {
          // snackbadservice.openSnackBar(
          //   'Offline... Endringer synkroniserer når du er online igjen',
          //   ''
          // );
        } else {
          // snackbadservice.openSnackBar('Online!', '');
        }
      } else {
        this.first = false;
      }
      if (!state) {
        sharedservice.online.next(false);
      } else {
        sharedservice.online.next(true);
      }
    });
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        // console.log('status', status);
        this.networkStatus = status;
      });
  }
}
