import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import firebase from 'firebase/compat/app';

import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmComponent } from '../components/common/bottom-sheet/confirm/confirm.component';
import { AventiFile } from '../models/File';
import { GlobalService } from './global.service';
import { IdService } from './id.service';
import { SharedService } from './shared.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  deleteReadyFirestore = false;
  deleteReadyStorage = false;
  deleteReadyStorage200 = false;
  deleteReadyStorage500 = false;
  deleteReadyFirestoreRef = false;

  constructor(
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private bottomSheet: MatBottomSheet,
    private snackbarservice: SnackbarService,
    private sharedservice: SharedService,
    private globalservice: GlobalService,
    private idservice: IdService
  ) {}

  updateid(file: AventiFile) {
    const docRef = this.afs.doc(file.path);
    const docid = docRef.ref.id;
    if (docid !== file.id) {
      console.log(
        'File id does not match id stored on document. Updating document with new id',
        file.id,
        docid
      );
      docRef.update({
        id: docRef.ref.id,
      });
    }
  }

  filesize(file: AventiFile): Observable<string> {
    const subject = new Subject<string>();
    if (!file.sizeB || !file.sizeMB || !file.sizestring) {
      const fileRef = this.afStorage.ref(
        file.storagepath + '/' + file.storagename
      );
      const metadata = fileRef.getMetadata();
      metadata.pipe(take(1)).subscribe((meta) => {
        // console.log(meta);
        file.sizeB = meta.size;
        const sizestring = this.globalservice.formatBytes(file.sizeB, 1);
        file.sizeMB = Math.ceil(meta.size / 1000000);
        this.afs
          .doc(file.path)
          .update({
            sizeB: file.sizeB,
            sizeMB: file.sizeMB,
            sizestring,
          })
          .then(() => {
            console.log('Updated sizes');
          })
          .catch((err) => {
            console.log(err);
          });
        subject.next(sizestring);
      });
    } else {
      subject.next(file.sizestring || null);
    }
    return subject.asObservable();
  }

  getExif(file): Promise<any> {
    return new Promise<any>(async (resolve) => {
      resolve(file);

      // // only GPS
      // let {latitude, longitude} = await exifr.gps(file);
      // window.alert(latitude + ', ' + longitude);

      // console.log(latitude, longitude);
      // resolve({latitude, longitude});

      //   // console.log(file);
      //   const tags = await ExifReader.load(file);
      //   // console.log(tags);
      //   const imageDate = tags['DateTimeOriginal']?.description;
      //   // console.log(imageDate);
      //   // const unprocessedTagValue = tags['DateTimeOriginal'].value;
      //   resolve(imageDate || firebase.firestore.Timestamp.now());

      // EXIF.getData(result, function () {
      //   // const make = EXIF.getTag(this, 'Make');
      //   // const model = EXIF.getTag(this, 'Model');
      //   const all = EXIF.getAllTags(this);
      //   // const makeAndModel = document.getElementById('makeAndModel');
      //   console.log(all);
      //   resolve(all);
      // });
    });
  }

  filetimestamp(file: AventiFile): Observable<any> {
    const subject = new Subject<string>();
    if (!file.timestamporiginal) {
      const fileRef = this.afStorage.ref(
        file.storagepath + '/' + file.storagename
      );
      const metadata = fileRef.getMetadata();
      metadata.pipe(take(1)).subscribe((meta) => {
        console.log(meta);
        const timestamporiginal = meta.timeCreated;
        //   this.afs
        //     .doc(file.path)
        //     .update({
        //       timestamporiginal,
        //     })
        //     .then(() => {
        //       console.log('Updated timestamporiginal');
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        subject.next(timestamporiginal);
      });
    } else {
      subject.next(file.sizestring || null);
    }
    return subject.asObservable();
  }

  getFile(fileid: string, collectionname: string, path: string) {
    // let path = `projects/${projectid}/objects/${objectid}/checklists/${checklistid}`;
    // if(checklistitemid) { path += `/checklistitems/${checklistitemid}`; }
    path += `/${collectionname}/${fileid}`;
    return this.afs.doc(path).valueChanges();
  }

  getFiles(path: string) {
    path += '/files';
    // console.log(path);
    // const projectid = this.idservice.projectId();
    // const checklistid = this.idservice.checklistId();
    return this.afs
      .collection(path, (ref) => ref.orderBy('timestamp'))
      .valueChanges({ idField: 'id' });
    // .get()
    // .snapshotChanges()
    // .pipe(
    //   // take(1),
    //   map((actions) => {
    //     return actions.map((a) => {
    //       const data = a.payload.doc.data() as File;
    //       data.id = a.payload.doc.id;
    //       data.path = a.payload.doc.ref.path;
    //       return { ...data };
    //     });
    //   })
    // )
  }

  getAllFilesInProject() {
    const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('files', (ref) =>
        ref
          // .orderBy('name')
          .where('projectid', '==', projectid)
      )
      .valueChanges();
  }

  updateFileComment(file: AventiFile, comment: string) {
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        this.afs.doc(file.path).update({
          comment,
          editedBy: firebase.firestore.FieldValue.arrayUnion({
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
          lastupdated: firebase.firestore.Timestamp.now(),
        });
      }
    });
  }

  deleteFile(file: AventiFile, force?: boolean) {
    if (force) {
      this.deleteFileExecuter(file);
    } else {
      const sheet = this.bottomSheet.open(ConfirmComponent, {
        backdropClass: 'blur-dialog-backdrop',
        data: {
          title: 'Er du sikker?',
          text: 'Slette fil?',
          yes: {
            name: 'Ja',
            icon: 'delete',
          },
          no: {
            name: 'Nei',
            icon: 'close',
          },
        },
      });
      sheet.afterDismissed().subscribe((confirm) => {
        if (confirm) {
          this.deleteFileExecuter(file);
        }
      });
    }
  }

  private deleteFileExecuter(file: AventiFile) {
    this.sharedservice.isLoading.next([true, 'Sletter fil', [file.name]]);
    console.log(
      'File to be deleted:',
      file.storagepath + '/' + file.storagename
    );
    const patharr = file.path.split('/');
    patharr.pop();
    patharr.pop();
    const checklistpath = patharr.join('/');
    // this.afs
    //   .doc(checklistpath)
    //   .update({
    //     generatingPDFstatusIcon: 'delete',
    //     generatingPDFstatusText: 'Sletter...',
    //     generatingPDFchecklist: true,
    //     generatingPDFstatus: 'Sletter'
    //   });
    // try {
    // Delete the file
    this.afStorage.storage
      .ref(file.storagepath + '/' + file.storagename)
      .delete()
      .then(() => {
        console.log('File successfully deleted!');
        this.deleteReadyStorage = true;
        this.checkIfDeleteIsFinished();
      })
      .catch((error) => {
        this.snackbarservice.openSnackBar('Error deleting file: ' + error, '');
        console.error('Error deleting file: ', error);
        this.deleteReadyStorage = true;
        this.checkIfDeleteIsFinished();
      });
    // Delete from Firestore
    this.afs
      .doc(checklistpath)
      .update({
        pdfexports: firebase.firestore.FieldValue.arrayRemove(file.id),
      })
      .then(() => {
        this.deleteReadyFirestoreRef = true;
        this.checkIfDeleteIsFinished();
        // console.log(pdf.checklistid, 'updated')
      })
      .catch((error) => {
        this.snackbarservice.openSnackBar(
          'Error deleting reference to pdf: ' + error,
          ''
        );
        console.error('Error deleting reference to pdf: ', error);
        this.deleteReadyFirestoreRef = true;
        this.checkIfDeleteIsFinished();
      });
    this.afs
      .doc(file.path)
      .delete()
      .then(() => {
        this.globalservice.updateParentParent(file.path);
        this.snackbarservice.openSnackBar('Fil slettet! ' + file.name, '');
        this.sharedservice.isLoading.next([false, '', ['']]);
        console.log('Document successfully deleted!');
        this.deleteReadyFirestore = true;
        this.checkIfDeleteIsFinished();
      })
      .catch((error) => {
        this.snackbarservice.openSnackBar(
          'Error removing document: ' + error,
          ''
        );
        console.error('Error removing document: ', error);
        this.deleteReadyFirestore = true;
        this.checkIfDeleteIsFinished();
      });
    if (file.storagename200) {
      this.afStorage.storage
        .ref(file.storagepath + '/thumbs/' + file.storagename200)
        .delete()
        .then(() => {
          console.log('Thumbnail 200 successfully deleted!');
          this.deleteReadyStorage200 = true;
          this.checkIfDeleteIsFinished();
        })
        .catch((error) => {
          this.snackbarservice.openSnackBar(
            'Error deleting thumbnail 200: ' + error,
            ''
          );
          console.error('Error deleting thumbnail: ', error);
          this.deleteReadyStorage200 = true;
          this.checkIfDeleteIsFinished();
        });
    }
    if (file.storagename500) {
      this.afStorage.storage
        .ref(file.storagepath + '/thumbs/' + file.storagename500)
        .delete()
        .then(() => {
          console.log('Thumbnail 500 successfully deleted!');
          this.deleteReadyStorage500 = true;
          this.checkIfDeleteIsFinished();
        })
        .catch((error) => {
          this.snackbarservice.openSnackBar(
            'Error deleting thumbnail 500: ' + error,
            ''
          );
          console.error('Error deleting thumbnail: ', error);
          this.deleteReadyStorage500 = true;
          this.checkIfDeleteIsFinished();
        });
    }
    // } catch (error) {
    //   // Uh-oh, an error occurred!
    //   this.snackbarservice.openSnackBar(
    //     'Uh-oh, an error occurred when trying to delete your file!',
    //     ''
    //   );
    //   console.error(
    //     'Uh-oh, an error occurred when trying to delete your file!',
    //     error
    //   );
    //   this.sharedservice.isLoading.next([false, '', ['']]);
    // }
  }

  checkIfDeleteIsFinished() {
    if (
      this.deleteReadyFirestore &&
      this.deleteReadyStorage &&
      this.deleteReadyStorage200 &&
      this.deleteReadyStorage500 &&
      this.deleteReadyFirestoreRef
    ) {
      this.sharedservice.isLoading.next([false, '', ['']]);
    }
  }

  // resizeImageAndStoreAsBase64(file: AventiFile) {
  //   const fileextention = file.name.substring(
  //     file.name.lastIndexOf('.'),
  //     file.name.length
  //   );
  //   const afs = this.afs;
  //   const filetypesImage = ['.jpg', '.jpeg', '.png'];
  //   if (
  //     !file.base64 &&
  //     filetypesImage.indexOf(fileextention.toLowerCase()) > -1
  //   ) {
  //     async function imageToDataUri(img, width, height) {
  //       return new Promise<string>(async function (resolve, reject) {
  //         // create an off-screen canvas
  //         var canvas = document.createElement('canvas'),
  //           ctx = canvas.getContext('2d');
  //         // const width = 100;
  //         // const height = 100;
  //         // set its dimension to target size
  //         canvas.width = width;
  //         canvas.height = height;

  //         // draw source image into the off-screen canvas:
  //         ctx.drawImage(img, 0, 0, width, height);

  //         // encode image to data-uri with base64 version of compressed image
  //         var dataURI = canvas.toDataURL();

  //         // This is the return of the Promise
  //         resolve(dataURI);
  //         // return canvas.toDataURL();
  //       });
  //     }
  //     var img = document.createElement('img');
  //     img.crossOrigin = 'anonymous';
  //     // When the event "onload" is triggered we can resize the image.
  //     img.onload = async function () {
  //       const maxWidth = 500;
  //       const maxHeight = 500;
  //       let height = img.height;
  //       let width = img.width;
  //       if (img.height > maxHeight || img.width > maxWidth) {
  //         const aspectratio = img.width / img.height;
  //         height = maxHeight;
  //         width = img.width * (height / img.height);
  //         if (width > maxWidth) {
  //           width = maxWidth;
  //           height = width / aspectratio;
  //         }
  //       }
  //       await imageToDataUri(this, width, height)
  //         .then((b64) => {
  //           // file.base64 = b64;
  //           console.log('b64: ', img.width, img.height, width, height);
  //           afs
  //             .doc(file.path)
  //             .update({
  //               base64: b64,
  //             })
  //             .then(() => {
  //               // if (length === index + 1) {
  //               //   console.log('--------------------------------------');
  //               //   console.log('--------------------------------------');
  //               //   console.log('--------FINISHED UPDATING BASE64------');
  //               //   console.log('--------------------------------------');
  //               //   console.log('--------------------------------------');
  //               // }
  //               console.log(
  //                 'File converted to base64 and uploaded to DB',
  //                 file
  //               );
  //             })
  //             .catch((err) => {
  //               console.log('Error: ', file, err.message);
  //             });
  //         })
  //         .catch((err) => {
  //           console.log('Error: ', file, err.message);
  //         });
  //     };
  //     img.src = file.downloadurl;
  //   } else {
  //     // console.log('Base64 exists or file is not jpg/jpeg/png: ', file);
  //   }
  // }

  // removeBase64(file: AventiFile) {
  //   const afs = this.afs;
  //   if (file.base64) {
  //     afs
  //       .doc(file.path)
  //       .update({
  //         base64: firebase.firestore.FieldValue.delete(),
  //       })
  //       .then(() => {
  //         console.log('Base64 removed from', file);
  //       })
  //       .catch((err) => {
  //         console.log('Error: ', file, err.message);
  //       });
  //   }
  // }

  // }
}

//   openDialog(file: AventiFile, locked: boolean = false, attachments: AventiFile[], images: AventiFile[]) {
// }
