<app-main></app-main
>
<!-- 
<mat-toolbar color="primary" *ngIf="showButton">
  <mat-toolbar-row>
    <div>APG</div>
    <div class="fill-space"></div>
    <button mat-raised-button color="accent" (click)="addToHomeScreen()">
      Add to home screen
    </button>
  </mat-toolbar-row>
</mat-toolbar> -->
