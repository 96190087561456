<!--          OPEN PDF            -->
<div *ngIf="(checklist.pdfexports && checklist.pdfexports?.length > 0) ||
                      !checklist.generatingPDFchecklist">
  <div *ngFor="let pdf of checklist.pdfexports" style="text-align: center">
    <p>
      <p-button
        icon="pi pi-file-pdf"
        (click)="openDialogPdfPreview(pdf, checklist)"
        [rounded]="true"
      >
      </p-button>
    </p>
  </div>
</div>
<!--          GENERATE PDF          -->
<div
  *ngIf="
                    checklist.pdfexports &&
                    checklist.objectid &&
                    checklist.projectid &&
                    checklist.id &&
                    checklist.status === 'signed' &&
                    (!checklist.pdfexports || checklist.pdfexports?.length === 0)
                  "
>
  <div style="text-align: center">
    <p>
      <p-button
        icon="pi pi-plus"
        [rounded]="true"
        (click)="generatePDF(checklist)"
        *ngIf="!checklist.generatingPDFstatus"
        severity="warning"
      >
      </p-button>
    </p>
  </div>
</div>
