<div class="row">
  <app-headertext text="Min side"></app-headertext>
  <div class="fill-space"></div>
</div>
<div *ngIf="user && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <mat-card class="maxwidth child-margin">
      <div class="row">
        <span class="fill-space"></span>
        <img
          class="avatar"
          style="margin: 15px"
          [src]="user.img"
          alt="..."
          onError="this.src='/assets/images/user_default.jpg';"
        />
        <span class="fill-space"></span>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Navn:
        <div class="fill-space"></div>
        {{ user.firstname }} {{ user.lastname }}
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Tittel:
        <div class="fill-space"></div>
        {{ user.title }}
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Telefon:
        <div class="fill-space"></div>
        <span class="pull-right" *ngIf="user.phone"
          >{{ user.phone.locale }} {{ user.phone.number }}</span
        >
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Epost:
        <div class="fill-space"></div>
        {{ user.email }}
      </div>
      <mat-divider [inset]="true" *ngIf="user.role?.name === 'admin'"></mat-divider>
      <div class="row" *ngIf="user.role?.name === 'admin'">
        Sist kjente appversjon:
        <div class="fill-space"></div>
        <span *ngIf="user.appVersion">{{ user.appVersion }}</span>
      </div>
      <!-- <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Passord:
        <div class="fill-space"></div>
        <button
          mat-raised-button
          (click)="changePassword()"
          style="margin-top: 0; margin-bottom: 0"
        >
          <mat-icon>vpn_key</mat-icon>
          Endre passord
        </button>
      </div> -->
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Sjekklistespråk:
        <div class="fill-space"></div>
        <div *ngIf="!editlanguage && user.language">
          <span *ngIf="!user.language || user.language.id === 'no'">{{ user.language.name }}</span>
          <span *ngIf="user.language?.id === 'en'">{{ user.language.name }}</span>
        </div>
        <div *ngIf="editlanguage">
          <app-select
            label="Språk"
            [selected]="user.language.id"
            (value)="savelanguage($event)"
            [items]="languages"
          ></app-select>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="editlanguage ? 'Avbryt endring' : 'Endre språk'"
            (click)="toggleeditlanguage()"
          >{{ editlanguage ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>
    </mat-card>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>
