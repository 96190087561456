import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AventiObject } from 'src/app/models/AventiObject';
import { Map } from 'src/app/models/Map';
import { Project } from 'src/app/models/Project';
import { IdService } from 'src/app/services/id.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  map: Map;
  project: Project;
  objects: AventiObject[];

  isLoading: boolean;

  mobileQuery: MediaQueryList;

  constructor(
    private sharedservice: SharedService,
    private idservice: IdService,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    this.sharedservice.projects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projects) => {
        if (projects !== null) {
          const projectid = this.idservice.projectId();
          projects.forEach((p) => {
            if ((p.id === projectid)) {
              this.project = p;
              this.buildMap();
            }
          });
        }
      });
    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects) => {
        if (objects !== null) {
          this.objects = objects;
          this.buildMap();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  buildMap() {
    if (this.project && this.objects) {
      this.map = this.project.map;
      this.map.objects = [];
      this.objects.forEach((o) => {
        if (o.pos) {
          this.map.objects.push(o);
        }
      });
      console.log(this.map);
      this.isLoading = false;
    }
  }
}
