import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Objecttype} from 'src/app/models/Objecttype';
import {SortService} from 'src/app/services/sort.service';
import {NewObjecttypeComponent} from './new-objecttype/new-objecttype.component';
import {SharedService} from 'src/app/services/shared.service';
import {ExcelService} from 'src/app/services/excel.service';
import {User} from 'src/app/models/User';
import {AdminService} from 'src/app/services/admin.service';
import * as _ from 'underscore';
import {ChecklistItemsLibraryItem} from 'src/app/models/Checklistitem';

@Component({
  selector: 'app-objecttypes',
  templateUrl: './objecttypes.component.html',
  styleUrls: ['./objecttypes.component.css'],
})
export class ObjecttypesComponent implements OnInit {
  objecttypes: Objecttype[];
  checklistItems: ChecklistItemsLibraryItem[];
  objectversions;
  isLoading = true;
  // projectversion: string;
  isEmpty = false;
  displayedColumns: string[] = [
    // 'id',
    'name',
    'enName',
    'category',
  ];
  dataSource: MatTableDataSource<Objecttype>;
  pageIndex = 0;
  pageSize = 100;
  page = 1;
  pages: number;
  numberOfObjectTypesWithoutEn = 0;
  numberOfChecklistItemsWithoutEn = 0;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filter: string;
  FormFilter = new UntypedFormGroup({
    filter: new UntypedFormControl('', []),
  });
  user: User;
  private ngUnsubscribe = new Subject();

  constructor(
    private dialogNewObject: MatDialog,
    private sortservice: SortService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedservice: SharedService,
    private excelservice: ExcelService,
    private adminservice: AdminService,
  ) {
    // projectservice.readProject().pipe(takeUntil(this.ngUnsubscribe)).subscribe((project) => {
    //   this.projectversion = project.objecttypeversion;
    // });
    // adminservice.getObjecttypeVersions().pipe(takeUntil(this.ngUnsubscribe)).subscribe((versions) => {
    //   this.objectversions = versions;
    // });
  }

  ngOnInit(): void {
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          this.user = user[0];
        }
      });

    this.adminservice.getAllChecklistitemsObs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(checklistitems => {
        this.numberOfChecklistItemsWithoutEn = 0;
        const checklistItemsTemp = checklistitems;
        checklistItemsTemp.map(item => {
          if (!item.enText || item.enText.length === 0) {
            item.missingEn = true;
            this.numberOfChecklistItemsWithoutEn++;
            console.log(item);
          }
        });
        this.checklistItems = checklistItemsTemp;
        this.updateTable();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  newObjecttype() {
    this.dialogNewObject.open(NewObjecttypeComponent, {
      backdropClass: 'blur-dialog-backdrop',
      // data: { version: this.projectversion },
    });
  }

  // onSelectionChange(version) {
  //   console.log('selection changed', version);
  //   this.projectversion = version;
  //   this.updateTable();
  // }

  downloadObjectTypesAsExcel() {
    const objecttypesToExport: Objecttype[] = JSON.parse(JSON.stringify(this.objecttypes));
    const checklistItemsToExport: any[] = [];
    for (const [index, objectType] of objecttypesToExport.entries()) {
      this.adminservice
        .getChecklistitems(objectType.id)
        .pipe(take(1))
        .subscribe((checklistitems) => {
          const checklistItemsNotNull = _.reject(checklistitems, _.isNull);
          checklistItemsNotNull.forEach(checklistItem => {
            checklistItemsToExport.push({
              objectTypeId: objectType.id,
              objectTypeName: objectType.name,
              objectTypeCategory: objectType.category.name,
              checklistItemId: checklistItem.id,
              checklistItemType: checklistItem.type.name,
              checklistItemText: checklistItem.text
            });
          });
          if (index === objecttypesToExport.length - 1) {
            this.excelservice.exportAsExcelFile(checklistItemsToExport, 'checklistitems');
          }
        });
    }
  }

  updateTable() {
    this.sharedservice.objecttypes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((types) => {
        this.objecttypes = types;

        this.objecttypes.sort(this.sortservice.dynamicSort('name'));

        this.dataSource = new MatTableDataSource(this.objecttypes);
        this.dataSource.data = this.objecttypes;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.isLoading = false;
        if (this.objecttypes.length === 0) {
          this.isEmpty = true;
          this.displayedColumns = [];
        } else {
          this.isEmpty = false;
        }
        this.getFilterOnLoad();
        this.getPagesOnLoad();

        this.numberOfObjectTypesWithoutEn = 0;
        this.objecttypes.map(objectType => {
          if (!objectType.enName || objectType.enName.length === 0) {
            objectType.missingEn = true;
            this.numberOfObjectTypesWithoutEn++;
          }
        });
        this.objecttypes.map(type => {
          type.numberOfItemsMissingEn = 0;
          this.checklistItems.map(item => {
            if (item.parentid === type.id && (!item.enText || item.enText.length === 0)) {
              type.numberOfItemsMissingEn++;
            }
          })
        })
      });
  }

  getFilterOnLoad() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.filter = params.filter;
        // document.getElementById('filter').nodeValue = filter;
        this.FormFilter.patchValue({
          filter: this.filter,
        });
        if (this.filter && this.filter.length > 0) {
          this.applyFilter(null, this.filter);
        }
      });
  }

  setFilter(filter: string) {
    if (this.objecttypes) {
      this.applyFilter(null, filter);
    }
  }

  applyFilter(event?: Event, value?: string) {
    let filterValue: string;
    if (event) {
      filterValue = (event.target as HTMLInputElement).value;
    } else {
      filterValue = value;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {filter: filterValue},
      queryParamsHandling: 'merge',
    });
  }

  pageChange(page) {
    // console.log(page)
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {pageIndex: page.pageIndex, pageSize: page.pageSize},
      queryParamsHandling: 'merge',
    });
    this.page = this.pageIndex + 1;
    this.pages = Math.ceil(page.length / page.pageSize);
  }

  getPagesOnLoad() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        // console.log(params);
        if (params.pageIndex) {
          this.pageIndex = parseInt(params.pageIndex, 10);
          this.page = this.pageIndex + 1;
        }
        if (params.pageSize) {
          this.pageSize = params.pageSize;
        }
      });
    this.pages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
  }
}
