import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { AdminService } from 'src/app/services/admin.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  FormAlert = new UntypedFormGroup({
    alerttext: new UntypedFormControl('', [Validators.required]),
  });
  FormMaintenance = new UntypedFormGroup({
    maintenanceheader: new UntypedFormControl('', [Validators.required]),
    maintenancetext: new UntypedFormControl('', [Validators.required]),
  });

  get alerttext() {
    return this.FormAlert.get('alerttext');
  }
  get maintenanceheader() {
    return this.FormMaintenance.get('maintenanceheader');
  }
  get maintenancetext() {
    return this.FormMaintenance.get('maintenancetext');
  }

  isLoading = true;
  user: User;

  alert: any;
  maintenance: any;

  // ismaintenance: boolean;
  // allowsuperadmin: boolean;

  mobileQuery: MediaQueryList;
  constructor(
    media: MediaMatcher,
    private adminservice: AdminService,
    private sharedservice: SharedService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.adminservice.getCategories();
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
        }
      });
    this.sharedservice.alert
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((a) => {
        console.log(a);
        if (this.alerttext !== null) {
        this.alert = a;
        this.FormAlert.patchValue({
          alerttext: a.text,
        });
        this.check();
      }
      });
    this.sharedservice.maintenance
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((m) => {
        if (m !== null) {
          console.log(m);
          this.maintenance = m;
          // this.ismaintenance = m.ismaintenance;
          // this.allowsuperadmin = m.allowsuperadmin;
          this.FormMaintenance.patchValue({
            maintenanceheader: m.maintenanceheader,
            maintenancetext: m.maintenancetext,
          });
          this.check();
        }
      });
  }

  check() {
    if (this.alert && this.maintenance) {
      this.isLoading = false;
    }
  }

  allowsuperadminChanged(state: boolean) {
    console.log(state);
    this.adminservice.setAppMaintenanceAllowsuperadmin(state);
  }
  ismaintenanceChanged(state: boolean) {
    console.log(state);
    this.adminservice.setAppMaintenanceActive(state);
  }
  isalertChanged(state: boolean) {
    console.log(state);
    this.adminservice.setAppAlertActive(state);
  }

  updateMaintenance(header: string, text: string) {
    console.log(header, text);
    this.adminservice.setAppMaintenanceHeaderAndText(header, text);
  }

  updateAlert(text: string) {
    console.log(text);
    this.adminservice.setAppAlerttext(text);
  }
}
