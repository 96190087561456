<!--
<mat-tab-group mat-align-tabs="center" class="shownotabs">
  <mat-tab label="Objekttyper" routerLink="/a/objekttyper">
    <app-objecttypes></app-objecttypes>
  </mat-tab>
  <mat-tab label="Endringslogg" routerLink="/a/changelog">
    <app-changelog></app-changelog>
  </mat-tab>
  <mat-tab *ngIf="user?.superAdmin" label="Brukere" routerLink="/a/brukere">
    <app-users></app-users>
  </mat-tab>
</mat-tab-group> -->

<app-headertext text="Admin" [hideback]="true"></app-headertext>

<div class="row">
  <div class="fill-space"></div>
  <!-- <div class="maxwidth"> -->
    <div class="child-margin maxwidth">

      <mat-card
        routerLink="a/objekttyper"
        class="cursor"
      >
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>text_fields</mat-icon>
          </div>
          <mat-card-title>Objekttyper</mat-card-title>
          <mat-card-subtitle>Endre objekttyper</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card
        routerLink="a/statuses"
        class="cursor"
      >
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>sell</mat-icon>
          </div>
          <mat-card-title>Statuser</mat-card-title>
          <mat-card-subtitle>Administrere statuser for objekter og sjekklistepunkt</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card
        routerLink="a/changelog"
        class="cursor"
      >
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>published_with_changes</mat-icon>
          </div>
          <mat-card-title>Endringslogg</mat-card-title>
          <mat-card-subtitle>APG versjonsoversikt</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card
        routerLink="a/brukere"
        class="cursor"
        *ngIf="user?.superAdmin"
      >
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>people</mat-icon>
          </div>
          <mat-card-title>Brukere</mat-card-title>
          <mat-card-subtitle>Administrer brukere</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card
        routerLink="a/varsler"
        class="cursor"
        *ngIf="user?.superAdmin"
      >
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>campaign</mat-icon>
          </div>
          <mat-card-title>Varslinger</mat-card-title>
          <mat-card-subtitle>Administrer varslinger</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>

    <!-- <div *ngIf="resizedfile && resizedfile.name.length > 0">
      <p>{{ resizedfile.name }}</p>
      <p><img [src]="resizedfile.blob" alt="" /></p>
    </div> -->
  <!-- </div> -->
  <div class="fill-space"></div>
</div>
