import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  constructor(private afs: AngularFirestore) {}

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  updateParentParent(
    // change: functions.Change<functions.firestore.QueryDocumentSnapshot>,
    // context: functions.EventContext
    path: string
  ) {
    if (false) {
      const newPath = path;
      console.log(newPath.split('/'));
      if (newPath.split('/').length > 5) {
        const parentpath = newPath.substring(0, newPath.lastIndexOf('/'));
        const parentpathName = parentpath.substring(
          parentpath.lastIndexOf('/') + 1
        );
        // console.log('parentpath', parentpath);
        const parentparentpath = parentpath.substring(
          0,
          parentpath.lastIndexOf('/')
        );
        // console.log('parentparentpath', parentparentpath);
        const docid = parentparentpath
          ? parentparentpath.substring(parentparentpath.lastIndexOf('/') + 1)
          : '';

        const parentparentparentpath = parentparentpath.substring(
          0,
          parentparentpath.lastIndexOf('/')
        );

        // const parentparentparentparentpath = parentparentparentpath.substring(
        //   0,
        //   parentparentparentpath.lastIndexOf('/')
        // );
        // const parentparentparentparentparentpath = parentparentparentparentpath.substring(
        //   0,
        //   parentparentparentparentpath.lastIndexOf('/')
        // );
        // console.log('parentparentparentpath', parentparentparentpath);
        const sourceCol = this.afs.collection(parentpath);
        const parentCol = this.afs.collection(parentparentparentpath);
        // const parentparentCol = this.afs.collection(parentparentparentparentparentpath);
        sourceCol
          .valueChanges()
          .pipe(take(1))
          .subscribe((items) => {
            // let items: string = '[';
            // querySnapshot.forEach((doc) => {
            //   items += JSON.stringify(doc.data()) + ',';
            // });

            // items = items.slice(0, -1);
            // if(items.length > 0){
            //   items += ']';
            // }
            let itemsString = JSON.stringify(items);
            if (itemsString.substring(0, 1) !== '[') {
              itemsString = '[' + itemsString + ']';
            }
            // console.log(itemsString);
            console.log(newPath);
            const parentDoc = parentCol.doc(docid);
            switch (parentpathName) {
              case 'checklists':
                console.log(parentpathName, items);
                parentDoc
                  .update({
                    z_checklistsstring: itemsString,
                    lastupdated:
                      firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    console.log('Updating parentparent', parentparentpath);
                    this.updateParentParent(parentparentpath);
                  });
                break;
              case 'checklistitems':
                console.log(parentpathName, items);
                parentDoc
                  .update({
                    z_checklistitemsstring: itemsString,
                    lastupdated:
                      firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    console.log('Updating parentparent', parentparentpath);
                    this.updateParentParent(parentparentpath);
                  });
                break;
              case 'files':
                console.log(parentpathName, items);
                parentDoc
                  .update({
                    z_filesstring: itemsString,
                    lastupdated:
                      firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    console.log('Updating parentparent', parentparentpath);
                    this.updateParentParent(parentparentpath);
                  });
                break;
              case 'pdfexports':
                console.log(parentpathName, items);
                parentDoc
                  .update({
                    z_pdfexportsstring: itemsString,
                    lastupdated:
                      firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    console.log('Updating parentparent', parentparentpath);
                    this.updateParentParent(parentparentpath);
                  });
                break;
              default:
                // null;
                break;
            }
          });
        // this.updateParentParent(parentparentpath);
      } else {
        console.log('path too short. Nothing updated.', newPath);
      }
    }
  }

  clearCacheAndRefresh() {
    // clearCache();
    location.reload();
  }
}
