<div class="row">
  <app-headertext text="Admin - Brukere - Bruker"></app-headertext>
  <div class="fill-space"></div>
</div>
<div *ngIf="user && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <mat-card class="maxwidth child-margin" style="font-size: small;">
      <div class="row">
        <span class="fill-space"></span>
        <img
          class="avatar"
          style="margin: 15px"
          [src]="user.img"
          alt="..."
          onError="this.src='/assets/images/user_default.jpg';"
        />
        <span class="fill-space"></span>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Navn:
        <div class="fill-space"></div>
        <div *ngIf="!editname && user.firstname && user.lastname">
          {{ user.firstname }} {{ user.lastname }}
        </div>
        <div *ngIf="editname">
          <form [formGroup]="FormName">
            <div class="row">
              <mat-form-field>
                <input
                  matInput
                  id="firstname"
                  type="firstname"
                  placeholder="Fornavn"
                  formControlName="firstname"
                  (keyup.enter)="savename(firstname.value, lastname.value)"
                />
                <mat-error *ngIf="firstname.touched && firstname.invalid">
                  <div *ngIf="!firstname.errors.required">
                    Please enter a object firstname
                  </div>
                  <div *ngIf="firstname.errors.required">
                    Firstname is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  id="lastname"
                  type="lastname"
                  placeholder="Etternavn"
                  formControlName="lastname"
                  (keyup.enter)="savename(firstname.value, lastname.value)"
                />
                <mat-error *ngIf="lastname.touched && lastname.invalid">
                  <div *ngIf="!lastname.errors.required">
                    Please enter a object lastname
                  </div>
                  <div *ngIf="lastname.errors.required">
                    Lastname is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                type="button"
                mat-icon-button
                [disabled]="FormName.invalid"
                *ngIf="editname"
                style="margin-left: 10px; margin-top: -10px"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="savename(firstname.value, lastname.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="editname ? 'Avbryt endring' : 'Endre tekst'"
            (click)="toggleeditname()"
          >
            {{ editname ? "cancel" : "edit" }}</mat-icon
          >
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Firestore ID:
        <div class="fill-space"></div>
        <div *ngIf="user.id">
          {{ user.id }}
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">Firebase Auth:</div>
      <div class="row">
        <div class="fill-space"></div>
        <div *ngIf="user.firebaseUser">
          <table>
            <tr>
              <td style="text-align: right; padding-right: 10px">UID:</td>
              <td>{{ user.firebaseUser?.uid }}</td>
            </tr>
            <tr>
              <td style="text-align: right; padding-right: 10px">Navn:</td>
              <td>{{ user.firebaseUser?.displayName }}</td>
            </tr>
            <tr>
              <td style="text-align: right; padding-right: 10px">Brukernavn (login):</td>
              <td>{{ user.firebaseUser.email }}</td>
            </tr>
          </table>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Tittel:
        <div class="fill-space"></div>
        <div *ngIf="!edittitle && user.title">
          {{ user.title }}
        </div>
        <div *ngIf="edittitle">
          <form [formGroup]="FormTitle">
            <div class="row">
              <mat-form-field>
                <input
                  matInput
                  id="title"
                  type="title"
                  placeholder="Tittel"
                  formControlName="title"
                  (keyup.enter)="savetitle(title.value)"
                />
                <mat-error *ngIf="title.touched && title.invalid">
                  <div *ngIf="!title.errors.required">Please enter a title</div>
                  <div *ngIf="title.errors.required">
                    Title is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                type="button"
                mat-icon-button
                [disabled]="FormTitle.invalid"
                *ngIf="edittitle"
                style="margin-left: 10px; margin-top: -10px"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="savetitle(title.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="edittitle ? 'Avbryt endring' : 'Endre tekst'"
            (click)="toggleedittitle()"
          >
            {{ edittitle ? "cancel" : "edit" }}</mat-icon
          >
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Telefon:
        <div class="fill-space"></div>
        <div *ngIf="!editphone && user.phone?.locale && user.phone?.number">
          {{ user.phone?.locale }} {{ user.phone?.number }}
        </div>
        <div *ngIf="editphone">
          <form [formGroup]="FormPhone">
            <div class="row">
              <mat-form-field>
                <input
                  matInput
                  id="phoneLocale"
                  type="phoneLocale"
                  placeholder="Landskode"
                  formControlName="phoneLocale"
                />
                <mat-error *ngIf="phoneLocale.touched && phoneLocale.invalid">
                  <div *ngIf="!phoneLocale.errors.required">
                    Please enter a phone locale
                  </div>
                  <div *ngIf="phoneLocale.errors.required">
                    Phone locale is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  id="phoneNumber"
                  type="phoneNumber"
                  placeholder="Telefonnummer"
                  formControlName="phoneNumber"
                  (keyup.enter)="savephone(phoneLocale.value, phoneNumber.value)"
                />
                <mat-error *ngIf="phoneNumber.touched && phoneNumber.invalid">
                  <div *ngIf="!phoneNumber.errors.required">
                    Please enter a phone number
                  </div>
                  <div *ngIf="phoneNumber.errors.required">
                    Phone number is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                type="button"
                mat-icon-button
                [disabled]="FormPhone.invalid"
                *ngIf="editphone"
                style="margin-left: 10px; margin-top: -10px"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="savephone(phoneLocale.value, phoneNumber.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="editphone ? 'Avbryt endring' : 'Endre tekst'"
            (click)="toggleeditphone()"
          >
            {{ editphone ? "cancel" : "edit" }}</mat-icon
          >
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Epost:
        <div class="fill-space"></div>
        <div *ngIf="!editemail && user.email">
          {{ user.email }}
        </div>
        <div *ngIf="editemail">
          <form [formGroup]="FormEmail">
            <div class="row">
              <mat-form-field>
                <input
                  matInput
                  id="email"
                  type="email"
                  placeholder="Tittel"
                  formControlName="email"
                  (keyup.enter)="saveemail(email.value)"
                />
                <mat-error *ngIf="email.touched && email.invalid">
                  <div *ngIf="!email.errors.required">Please enter a email</div>
                  <div *ngIf="email.errors.required">
                    Email is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                type="button"
                mat-icon-button
                [disabled]="FormEmail.invalid"
                *ngIf="editemail"
                style="margin-left: 10px; margin-top: -10px"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="saveemail(email.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="editemail ? 'Avbryt endring' : 'Endre tekst'"
            (click)="toggleeditemail()"
          >
            {{ editemail ? "cancel" : "edit" }}</mat-icon
          >
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Rolle:
        <div class="fill-space"></div>
        <div *ngIf="!editrole && user.role?.name">
          {{ user.role.name }}
        </div>
        <div *ngIf="editrole">
          <app-select
            label="Rolle"
            [selected]="user.role.id"
            (value)="saverole($event)"
            [items]="roles"
          ></app-select>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="editrole ? 'Avbryt endring' : 'Endre kategori'"
            (click)="toggleeditrole()"
            >{{ editrole ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Språk:
        <div class="fill-space"></div>
        <div *ngIf="!editlanguage && user.language">
          <span *ngIf="!user.language || user.language.id === 'no'">{{ user.language.name }}</span>
          <span *ngIf="user.language?.id === 'en'">{{ user.language.name }}</span>
        </div>
        <div *ngIf="editlanguage">
          <app-select
            label="Språk"
            [selected]="user.language.id"
            (value)="savelanguage($event)"
            [items]="languages"
          ></app-select>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="editlanguage ? 'Avbryt endring' : 'Endre språk'"
            (click)="toggleeditlanguage()"
            >{{ editlanguage ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Sist kjente appversjon:
        <div class="fill-space"></div>
        <span *ngIf="user.appVersion">{{ user.appVersion }}</span>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Sist sett:
        <div class="fill-space"></div>
        <span *ngIf="user.lastseenString">{{ user.lastseenString }}</span>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Sist oppdatert:
        <div class="fill-space"></div>
        <span *ngIf="user.lastupdatedString">{{ user.lastupdatedString }}</span>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Aktiv:
        <div class="fill-space"></div>
        <mat-icon
          *ngIf="user.active"
          color="primary"
          style="cursor: pointer"
          matTooltip="Deaktivér bruker"
          matTooltipPosition="left"
          (click)="setActive(false)"
          >check_circle_outline</mat-icon
        >
        <mat-icon
          *ngIf="!user.active"
          color="warn"
          style="cursor: pointer"
          matTooltip="Aktivér bruker"
          matTooltipPosition="left"
          (click)="setActive(true)"
          >close</mat-icon
        >
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Superadmin:
        <div class="fill-space"></div>
        <mat-icon
        *ngIf="user.superAdmin && user.role?.name === 'admin'"
          color="primary"
          style="cursor: pointer"
          matTooltip="Fjern som superAdmin"
          matTooltipPosition="left"
          (click)="setSuperadmin(false)"
          >check_circle_outline</mat-icon
        >
        <mat-icon
          *ngIf="!user.superAdmin && user.role?.name === 'admin'"
          color="warn"
          style="cursor: pointer"
          matTooltip="Sett som superAdmin"
          matTooltipPosition="left"
          (click)="setSuperadmin(true)"
          >close</mat-icon
        >
        <mat-icon
          *ngIf="user.role?.name !== 'admin'"
          matTooltip="Ikke tilgjengelig uten admin"
          matTooltipPosition="left"
          >unpublished</mat-icon
        >
      </div>
      <!-- <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Passord:
        <div class="fill-space"></div>
        <div *ngIf="!user.active">Bruker er deaktivert</div>
        <button
          *ngIf="user.active"
          mat-raised-button
          (click)="sendPasswordResetMail()"
          style="margin-top: 0; margin-bottom: 0"
        >
          <mat-icon>vpn_key</mat-icon>
          Send mail om nytt passord
        </button>
      </div> -->
      <mat-divider [inset]="true"></mat-divider>
      <!-- <div class="row">
        Prosjekttilgang kunde:
        <div class="fill-space"></div>
        <span>{{ user.projectaccess }}</span>
        <mat-form-field *ngIf="projects">
          <mat-label>Prosjekt</mat-label>
          <mat-select [formControl]="projects" multiple>
            <mat-option *ngFor="let project of projects" [value]="project.id"
              >{{ project.id }} - {{ project.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="row">Prosjekttilgang:</div>
      <div class="row">
        <div class="fill-space"></div>
        <div *ngIf="!editprojectaccess && user.projectaccess">
          <div *ngFor="let access of user.projectaccess">
            {{ access.id }} - {{ access.name }}
          </div>
        </div>
        <div *ngIf="editprojectaccess">
          <app-select
            label="Prosjekt"
            [selected]="user.projectaccess"
            (value)="saveprojectaccess($event)"
            [items]="projects"
            [multiple]="true"
            [showid]="true"
          ></app-select>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px"
            class="cursor"
            [matTooltip]="
              editprojectaccess ? 'Avbryt endring' : 'Endre kategori'
            "
            (click)="toggleeditprojectaccess()"
            >{{ editprojectaccess ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>
    </mat-card>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>
