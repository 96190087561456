import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {filter, take} from 'rxjs/operators';
import { Breadcrumbitem } from '../models/BreadcrumbItem';
import { Currentpath } from '../models/Currentpath';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentpathService {
  private currentpath: Currentpath = new Currentpath();

  constructor(
    private location: Location,
    private router: Router,
    private clipboard: Clipboard,
    private snackbarservice: SnackbarService
  ) {
    location = {} as Location;
    router = {} as Router;
  }

  getCurrentpath(): Currentpath {
    this.fillPath();
    this.makeBread();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1)
      )
      .subscribe((event) => {
        this.currentpath.event = event;
        this.fillPath();
        this.makeBread();
        if (
          this.currentpath.path[this.currentpath.length - 1].substr(0, 5) ===
          'print'
        ) {
          this.currentpath.print = true;
        } else {
          this.currentpath.print = false;
        }
        // console.log(this.currentpath);
        return this.currentpath;
      });
    return this.currentpath;
  }

  private fillPath() {
    this.currentpath.path = this.location.path(true).substring(1).split('/');
    this.currentpath.mode = this.location.path(true).substring(1).split('/')[0];
    this.currentpath.length = this.currentpath.path.length;
  }

  private makeBread() {
    this.currentpath.breadcrumb = [];
    let link: string;
    let crumb: Breadcrumbitem;

    link =
      '/' +
      this.currentpath.path[0] +
      '/' +
      this.currentpath.path[1] +
      '/' +
      this.currentpath.path[2];
    crumb = { link, label: this.currentpath.path[2] };
    this.currentpath.breadcrumb[0] = crumb;

    let i = 1;
    this.currentpath.path.forEach((item, index) => {
      if (index > 2 && index < 3) {
        // exclude project ID and object/checklist ID
        link += '/' + item;
        crumb = { link, label: item };
        this.currentpath.breadcrumb[i] = crumb;
        i++;
      }
    });
  }

  copyFilePathToClipboard() {
    const path = this.getCurrentpath().path;
    const copyPath = ['\\\\file02\\AG_Groupdata\\APG'];
    path.forEach((p) => {
      switch (p) {
        case 'p':
          p = 'projects';
          console.log(p);
          break;
        case 'o':
          p = 'objects';
          console.log(p);
          break;
        case 's':
          p = 'checklists';
          console.log(p);
          break;
        case 'sl':
          p = 'checklists';
          console.log(p);
          break;
      }
      console.log(p);
      copyPath.push(p);
    });
    this.clipboard.copy(copyPath.join('\\'));
    this.snackbarservice.openSnackBar('Filbane kopiert', '');
  }
}
