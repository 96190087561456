import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {environment} from 'src/environments/environment';
import {EventLogItem, EventLogItemType, EventLogSource} from 'src/app/models/EventLog';
import {AdminService} from 'src/app/services/admin.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
})
export class AuthenticationComponent implements OnInit {

  error: string;

  wiggleUsername: boolean;
  wigglePassword: boolean;

  passwordErrorMinLength = true;
  passwordErrorUpperCaseLetter = true;
  passwordErrorLowerCaseLetter = true;
  passwordErrorNumber = true;
  passwordErrorSpecialCharacter = true;

  signin: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    password: new UntypedFormControl('', [
      Validators.required,
      this.validatePasswordInput,
    ]),
  });
  passwordValidatorTimeout;

  hide = true;

  loggingIn = false;

  isLoading = false;

  constructor(
    private authservice: AuthService,
    private adminservice: AdminService,
    private snackbarservice: SnackbarService
  ) {
  }

  get emailInput() {
    return this.signin.get('email');
  }

  get passwordInput() {
    return this.signin.get('password');
  }

  validatePasswordInput(c: UntypedFormControl) {
    return (
      environment.passwordRegExMinLength.test(c.value) &&
      environment.passwordRegExUpperCaseLetter.test(c.value) &&
      environment.passwordRegExLowerCaseLetter.test(c.value) &&
      environment.passwordRegExNumber.test(c.value) &&
      environment.passwordRegExSpecialCharacter.test(c.value)
    ) ? null : {
      validateInput: {
        valid: false
      }
    };
  }

  validateAfterKeyUp(event) {
    clearTimeout(this.passwordValidatorTimeout);
    this.passwordValidatorTimeout = setTimeout(() => {
      this.passwordInput.markAsTouched();
    }, 2000);
    const value = event.target.value;
    this.passwordErrorMinLength = !environment.passwordRegExMinLength.test(value);
    this.passwordErrorUpperCaseLetter = !environment.passwordRegExUpperCaseLetter.test(value);
    this.passwordErrorLowerCaseLetter = !environment.passwordRegExLowerCaseLetter.test(value);
    this.passwordErrorNumber = !environment.passwordRegExNumber.test(value);
    this.passwordErrorSpecialCharacter = !environment.passwordRegExSpecialCharacter.test(value);
  }

  ngOnInit(): void {
    document.getElementById('email').focus();
  }

  login() {
    const passwordLength = this.passwordInput.value.length;
    if (passwordLength >= environment.passwordMinLength && !this.loggingIn) {
      this.wiggleUsername = false;
      this.wigglePassword = false;
      this.error = '';
      this.loggingIn = true;
      this.authservice
        .login(this.emailInput.value, this.passwordInput.value)
        .then(async (firebaseAuthUser) => {
          const firebaseUser = firebaseAuthUser.user;
          await this.authservice.checkUserAndUpdateIfMatching(firebaseUser, true);
        })
        .catch(async (err) => {
          console.log(err.message);
          let eventLogItemType = EventLogItemType.AUTH_ERROR;
          if (err.message.includes('auth/wrong-password')) {
            this.error = 'Feil passord';
            this.wigglePassword = true;
            eventLogItemType = EventLogItemType.WRONG_PASSWORD;
          } else if (err.message.includes('auth/user-disabled')) {
            this.error = 'Bruker er deaktivert. Ta kontakt med APG admin for å låse opp';
            this.wiggleUsername = true;
            eventLogItemType = EventLogItemType.USER_DEACTIVATED;
          } else if (err.message.includes('auth/user-not-found')) {
            this.error = 'Bruker finnes ikke. Ta kontakt med APG admin for å opprette bruker';
            this.wiggleUsername = true;
            this.wigglePassword = true;
            eventLogItemType = EventLogItemType.USER_NOT_FOUND;
          } else {
            this.error = err.message;
          }
          await this.buildEventLogItemAndAddToEventLog(eventLogItemType);
          this.snackbarservice.openSnackBar(this.error, 'dismiss', 10000);
          this.loggingIn = false;
        });
    }
  }

  forgotPassword() {
    this.authservice
      .sendPasswordResetEmail(this.emailInput.value)
      .then(() => {
        this.snackbarservice.openSnackBar(
          'En epost om tilbakestilling av passord er sendt til ' +
          this.emailInput.value,
          ''
        );
      })
      .catch((err) => {
        this.error = err.message;
        this.snackbarservice.openSnackBar(this.error, '');
      });
  }

  async buildEventLogItemAndAddToEventLog(type: EventLogItemType) {
    const newEventLogItem: EventLogItem = {
      type,
      loginEmail: this.emailInput.value,
      loginUrl: window.location.href,
      appVersion: environment.appVersion,
      errorMessage: this.error,
      time: new Date().toJSON(),
      source: EventLogSource.APG_AUTH
    };
    await this.adminservice.addToEventLog(newEventLogItem);
  }
}
