import {animate, state, style, transition, trigger} from '@angular/animations';
import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {AventiFile} from 'src/app/models/File';
import {FileService} from 'src/app/services/file.service';
import {PopupService} from 'src/app/services/popup.service';
import {environment} from 'src/environments/environment';
import urlExist from 'url-exist';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  animations: [
    trigger('animateDestroy', [
      state('void', style({opacity: '0'})),
      transition('* => void', animate('1000ms ease-in')),
    ]),
  ],
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.css'],
})
export class ThumbnailComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  @Input() file: AventiFile;
  @Input() files: AventiFile[];
  @Input() size = '85px';
  @Input() isLocked = false;
  @Input() disableclick = false;
  @Input() sanitize = true;
  @Input() newItem = false;

  downloadurl: string;
  format: string;
  // loadnumber: number = 0

  loaded = false;

  mobileQuery: MediaQueryList;

  warninglevel = -1;

  isimage: boolean;

  isOnline: boolean;

  class: any;

  // static _file: AventiFile;

  constructor(
    private popupservice: PopupService,
    media: MediaMatcher,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private fileservice: FileService,
    private sharedservice: SharedService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    this.class = ThumbnailComponent;
  }

  ngOnInit() {
    // console.log(this.file);
    // this.class._file = this.file;

    this.fileservice.updateid(this.file);

    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });

    let formatTemp: string;
    if (this.file?.type) {
      if (this.file.type.indexOf('image') > -1) {
        this.isimage = true;
        formatTemp = 'image';
      } else if (this.file.type.indexOf('video') > -1) {
        if (this.mobileQuery.matches) {
          formatTemp = 'image';
        } else {
          formatTemp = 'video';
        }
      } else {
        formatTemp = 'other';
      }
    } else {
      formatTemp = 'other';
    }
    if (formatTemp !== this.format) {
      this.format = formatTemp;
    }

    if (this.file?.type.indexOf('image') > -1) {
      if (this.file.downloadurl200 && this.file.downloadurl200.length > 0) {
        this.downloadurl = this.file.downloadurl200;
        this.warninglevel = 0;
      } else if (
        this.file.downloadurl500 &&
        this.file.downloadurl500.length > 0
      ) {
        this.downloadurl = this.file.downloadurl500;
        this.warninglevel = 1;
      } else if (this.file.downloadurl && this.file.downloadurl.length > 0) {
        this.downloadurl = this.file.downloadurl;
        this.warninglevel = 2;
      } else {
        this.isimage = false;
        this.downloadurl = 'no_image';
      }

      if (this.sanitize) {
        const storage = this.storage;
        const afs = this.afs;
        const file = this.file;
        const thumbTimer = setTimeout(() => {
          // console.log('Checking thumb urls');
          // const _file = ThumbnailComponent._file;
          // console.log('Checking thumb urls', _file);
          if (file) {
            if (
              !file.downloadurl200 ||
              !file.downloadurl200?.includes('firebasestorage')
            ) {
              const ref200 = storage.ref(
                file.storagepath + '/thumbs/' + file.storagename200
              );
              ref200
                .getDownloadURL()
                .pipe(take(1))
                .subscribe((url200) => {
                  afs
                    .doc(file.path)
                    .update({
                      downloadurl200: url200,
                    })
                    .then(() => {
                      console.log('Adding missing downloadurl200', file);
                      if (file.downloadurl500?.includes('firebasestorage')) {
                        // console.log('Exiting thumbCheck');
                        clearTimeout(thumbTimer);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      clearTimeout(thumbTimer);
                    });
                });
            }
            if (
              !file.downloadurl500 ||
              !file.downloadurl500?.includes('firebasestorage')
            ) {
              const ref500 = storage.ref(
                file.storagepath + '/thumbs/' + file.storagename500
              );
              ref500
                .getDownloadURL()
                .pipe(take(1))
                .subscribe((url500) => {
                  afs
                    .doc(file.path)
                    .update({
                      downloadurl500: url500,
                    })
                    .then(() => {
                      console.log('Adding missing downloadurl500', file);
                      if (file.downloadurl200?.includes('firebasestorage')) {
                        // console.log('Exiting thumbCheck');
                        clearTimeout(thumbTimer);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      clearTimeout(thumbTimer);
                    });
                });
            }
            if (
              file.downloadurl200?.includes('firebasestorage') &&
              file.downloadurl500?.includes('firebasestorage')
            ) {
              // console.log('Exiting thumbCheck');
              clearTimeout(thumbTimer);
            }
          }
        }, 4000);
      }
    } else {
      if (this.file?.downloadurl) {
        this.isimage = false;
        this.downloadurl = this.file.downloadurl;
      } else {
        this.isimage = false;
        this.downloadurl = 'no_image';
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  openDialogImagePreview() {
    if (!this.disableclick && this.isOnline) {
      let fileindex = 0;
      this.files.forEach((f, index) => {
        if (f.id === this.file.id) {
          fileindex = index;
        }
      });
      this.popupservice.openDialogFilePreview(
        this.files,
        fileindex,
        this.isLocked
      );
    }
  }

  async srcChange(thumbnail) {
    // if (this.warninglevel === -1) {
    // urlExists(this.file.downloadurl200, function (err, exists) {
    //   console.log(exists); // true/false
    //   console.log(err);
    // });
    if (this.downloadurl !== 'no_image') {
      const exist200 = await urlExist(this.file.downloadurl200 || '');
      console.log('200', exist200);
      const exist500 = await urlExist(this.file.downloadurl500 || '');
      console.log('500', exist500);
      const exist = await urlExist(this.file.downloadurl || '');
      console.log('main', exist);
      console.log(thumbnail.src);

      // const ref = this.storage.ref(this.file.storagepath + '/' + this.file.name);
      // // try {
      // ref
      //   .getDownloadURL()
      //   .pipe(take(1))
      //   .subscribe((url) => {
      //     console.log(url);
      //     if (url) {
      // console.log('file exists');
      if (
        exist200 &&
        this.file.downloadurl200 &&
        this.file.downloadurl200.length > 0 &&
        this.file.downloadurl200.includes('firebasestorage')
      ) {
        console.log('200');
        if (thumbnail.src !== this.file.downloadurl200) {
          thumbnail.src = this.file.downloadurl200;
          this.downloadurl = this.file.downloadurl200;
        }
        this.warninglevel = 0;
      } else if (
        exist500 &&
        this.file.downloadurl500 &&
        this.file.downloadurl500.length > 0 &&
        this.file.downloadurl500.includes('firebasestorage')
      ) {
        console.log('500');
        if (thumbnail.src !== this.file.downloadurl500) {
          thumbnail.src = this.file.downloadurl500;
          this.downloadurl = this.file.downloadurl500;
        }
        this.warninglevel = 1;
      } else if (
        exist &&
        this.file.downloadurl &&
        this.file.downloadurl.length > 0 &&
        this.file.downloadurl.includes('firebasestorage')
      ) {
        console.log('main');
        if (thumbnail.src !== this.file.downloadurl) {
          thumbnail.src = this.file.downloadurl;
          this.downloadurl = this.file.downloadurl;
        }
        this.warninglevel = 2;
      } else {
        console.log('no_image');
        thumbnail.src = 'no_image';
        this.downloadurl = 'no_image';
        this.warninglevel = 0;
      }
    } else {
      console.log('no_image');
      thumbnail.src = 'no_image';
      this.isimage = false;
      this.downloadurl = 'no_image';
      this.warninglevel = 0;
    }
    // Do whatever
    //   } else {
    //     // Doesn't exist... or potentially some other error
    //     if (thumbnail.src !== 'no_image') {
    //       thumbnail.src = 'no_image';
    //     }
    //     this.warninglevel = 0;
    //     console.log('file does not exist');
    //   }
    // });

    // const ref = this.storage.ref(
    //   this.file.storagepath + '/' + this.file.name
    // );
    // ref.getDownloadURL().subscribe((url) => {
    //   console.log(url);
    // });

    // if (false) {
    //   console.log('file exists');
    //   if (
    //     this.file.downloadurl200 &&
    //     this.file.downloadurl200.length > 0 &&
    //     this.file.downloadurl200.includes('firebasestorage')
    //   ) {
    //     thumbnail.src = this.file.downloadurl200;
    //     this.warninglevel = 0;
    //   } else if (
    //     this.file.downloadurl500 &&
    //     this.file.downloadurl500.length > 0 &&
    //     this.file.downloadurl500.includes('firebasestorage')
    //   ) {
    //     thumbnail.src = this.file.downloadurl500;
    //     this.warninglevel = 1;
    //   } else if (
    //     this.file.downloadurl &&
    //     this.file.downloadurl.length > 0 &&
    //     this.file.downloadurl.includes('firebasestorage')
    //   ) {
    //     thumbnail.src = this.file.downloadurl;
    //     this.warninglevel = 2;
    //   } else {
    //     thumbnail.src = 'no_image';
    //   }
    // } else {
    //   // console.log('file does not exist');
    //   thumbnail.src = 'no_image';
    // }
    // }
  }
}
