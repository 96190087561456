<div class="row">
  <app-headertext text="Admin - Objekttyper"></app-headertext>
  <div class="fill-space"></div>
  <button
    mat-button
    matTooltip="Ny objekttype"
    (click)="newObjecttype()"
    class="header-row-button"
  >
    <mat-icon color="primary">add</mat-icon>
    Ny objekttype
  </button>
  <button
    *ngIf="user.id === 'ZGf1xWuSjiMM5rDtIktunxbgn542'"
    mat-button
    matTooltip="Eksporter til Excel"
    (click)="downloadObjectTypesAsExcel()"
    class="header-row-button"
  >
    <mat-icon color="primary">save_alt</mat-icon>
    Eksporter til Excel
  </button>
</div>

<div style="margin: 0 15px 0 15px">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

  <div style="padding-right: 10px; padding-bottom: 10px;" *ngIf="numberOfObjectTypesWithoutEn > 0">
    <p-tag
      class="dimmed-20"
      icon="pi pi-exclamation-triangle"
      severity="warning"
      [style]="{'padding-right': '10px;'}"
      [value]="numberOfObjectTypesWithoutEn + ' objekttyper mangler engelsk navn'">
    </p-tag>
  </div>
  <div style="padding-right: 10px; padding-bottom: 10px;" *ngIf="numberOfChecklistItemsWithoutEn > 0">
    <p-tag
      class="dimmed-20"
      icon="pi pi-exclamation-triangle"
      severity="warning"
      [style]="{'padding': '10px;'}"
      [value]="numberOfChecklistItemsWithoutEn + ' sjekklistepunkter mangler engelsk tekst'">
    </p-tag>
  </div>

  <div>
    <app-empty-list *ngIf="isEmpty"></app-empty-list>
    <!--  <div>
      <div class="row" *ngIf="!isEmpty">
        <div class="fill-space"></div>
        <div>
          <mat-form-field style="width: 200px;">
            <mat-label>Filter</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="f.eks. 'Skilt'"
            />
          </mat-form-field>
        </div>
      </div>
      <div> -->
    <!-- <div> -->
    <table [hidden]="isEmpty" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="filter" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef
          [colSpan]="displayedColumns.length"
        >
          <div class="row" *ngIf="!isEmpty">
            <div
              style="
                margin-right: 15px;
                margin-top: 15px;
                font-size: 12px;
                color: darkgray;
              "
            >
              Side {{ page }} <span *ngIf="pages">av {{ pages }}</span>
            </div>
            <div class="fill-space"></div>
            <div *ngIf="objecttypes">
              <form [formGroup]="FormFilter">
                <mat-form-field style="width: 200px">
                  <mat-label>Filter</mat-label>
                  <input
                    cdkFocusInitial
                    id="filter"
                    formControlName="filter"
                    matInput
                    (keyup)="applyFilter($event)"
                    placeholder="f.eks. 'Skilt'"
                  />
                </mat-form-field>
              </form>
            </div>
            <div
              (click)="setFilter('')"
              class="cursor"
              style="
                margin-left: 5px;
                margin-top: 15px;
                font-size: 20px;
                color: darkgray;
              "
              *ngIf="filter && filter.length > 0"
            >
              <mat-icon inline="true">cancel</mat-icon>
            </div>
          </div>
        </th>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">
          {{ row.id }}
        </td>
      </ng-container>

      <!-- Navn Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Navn</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.name }}</p>
          <p
            [ngStyle]="{
              color:
                row.checklistitems.length === 0
                  ? 'red'
                  : row.checklistitems.length < 5
                  ? 'yellow'
                  : ''
            }"
            style="font-size: smaller"
            *ngIf="row.checklistitems"
          >
            {{ row.checklistitems.length }} sjekklistepunkt
          </p>
        </td>
      </ng-container>

      <!-- English Navn Column -->
      <ng-container matColumnDef="enName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <p>
            {{ row.enName }}
            <p-tag *ngIf="!row.enName || row.enName.length === 0"
                   class="dimmed-20"
                   icon="pi pi-exclamation-triangle"
                   severity="warning"
                   value="Mangler engelsk navn">
            </p-tag>
          </p>
          <p>
            <p-tag *ngIf="row.numberOfItemsMissingEn !== 0"
                   class="dimmed-20"
                   icon="pi pi-exclamation-triangle"
                   severity="warning"
                   [value]="row.numberOfItemsMissingEn + ' sjekklistepunkter mangler engelsk navn'">
            </p-tag>
          </p>
          <p
            [ngStyle]="{
              color:
                row.checklistitems.length === 0
                  ? 'red'
                  : row.checklistitems.length < 5
                  ? 'yellow'
                  : ''
            }"
            style="font-size: smaller"
            *ngIf="row.checklistitems"
          >
            {{ row.checklistitems.length }} sjekklistepunkt
          </p>
        </td>
      </ng-container>

      <!-- Kategori Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategori</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.category">{{ row.category.name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="paginator">
        <th
          mat-footer-cell
          *matFooterCellDef
          [colSpan]="displayedColumns.length"
        >
          <mat-paginator
            *ngIf="!isEmpty"
            [pageSize]="pageSize"
            (page)="pageChange($event)"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[10, 25, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </th>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['filter']; sticky: true"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        routerLink="a/objekttyper/{{ row.id }}"
        class="cursor"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
    </table>
    <!-- </div>
    </div> -->
  </div>
</div>
