<div
  *ngIf="!isOnline"
  style="margin-top:10px; font-size: x-small">
  <mat-icon
    color="warn"
    style="height: 17px; width: 15px; font-size: 15px; vertical-align: middle"
  >
    wifi_off
  </mat-icon>
  Filer er ikke tilgjengelig når du er offline
</div>
