import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';

import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChecklistItem, ChecklistItemsLibraryItem, ChecklistItemType} from '../models/Checklistitem';
import {Objecttype, ObjecttypeCategory} from '../models/Objecttype';
import {IdService} from './id.service';
import {SharedService} from './shared.service';
import {SortService} from './sort.service';
import {EventLogItem} from '../models/EventLog';
import {ObjectStatus} from '../models/Status';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  objecttype: Objecttype = new Objecttype();

  constructor(
    private afs: AngularFirestore,
    private idservice: IdService,
    private sortservice: SortService,
    private sharedservice: SharedService,
    private router: Router
  ) {
    // this.objecttype = new Objecttype();
  }

  dbPathObjecttypes() {
    return 'lists/types/objecttypes/';
  }

  dbPathChecklistitemtypes() {
    return 'lists/types/checklistitemtypes/';
  }

  dbPathObjectStatuses() {
    return 'lists/types/objectstatuses/';
  }

  dbPathUserRoles() {
    return 'lists/types/userroles';
  }

  dbPathObjecttype(objecttypeId: string) {
    return this.dbPathObjecttypes() + objecttypeId;
  }

  dbPathObjecttypeCategories() {
    return 'lists/categories/objecttypecategories/';
  }

  dbPathChecklistitems() {
    return `lists/types/objecttypes/${this.idservice.objecttypeId()}/checklistitemslibrary/`;
  }

  dbPathChecklistitem(checklistitemid?: string) {
    if (!checklistitemid) {
      checklistitemid = this.idservice.checklistitemId();
    }
    return `lists/types/objecttypes/${this.idservice.objecttypeId()}/checklistitemslibrary/${checklistitemid}`;
  }

  dbPathObjectStatus(objectStatusId: string) {
    return `${this.dbPathObjectStatuses()}${objectStatusId}`;
  }

  // dbPathObjecttypeVersions() {
  //   return 'objecttypes';
  // }
  // dbPathObjecttypeVersion(version: string) {
  //   return 'objecttypes/' + version;
  // }

  getAppVersions() {
    return this.afs
      .collection('app', (ref) => ref.orderBy('version', 'desc'))
      .valueChanges();
    // .snapshotChanges()
    // .pipe(
    //   map((actions) => {
    //     let data = [];
    //     actions.forEach((a) => {
    //       let change: any = a.payload.doc.data();
    //       change.id = a.payload.doc.id;
    //       data.push(change);
    //     })
    //     return data;
    //   })
    // );
  }

  getAppAlert() {
    return this.afs.doc('alerts/alert').valueChanges();
  }

  setAppAlertActive(isalert: boolean) {
    return this.afs.doc('alerts/alert').update({
      isalert,
    });
  }

  setAppAlerttext(text: string) {
    return this.afs.doc('alerts/alert').update({
      text,
    });
  }

  setAppMaintenanceHeaderAndText(
    maintenanceheader: string,
    maintenancetext: string
  ) {
    return this.afs.doc('alerts/maintenance').update({
      maintenanceheader,
      maintenancetext,
    });
  }

  setAppMaintenanceActive(ismaintenance: boolean) {
    return this.afs.doc('alerts/maintenance').update({
      ismaintenance,
    });
  }

  setAppMaintenanceAllowsuperadmin(allowsuperadmin: boolean) {
    return this.afs.doc('alerts/maintenance').update({
      allowsuperadmin,
    });
  }

  getAppMaintenance() {
    return this.afs.doc('alerts/maintenance').valueChanges();
  }

  getCategories() {
    return this.afs
      .collection(this.dbPathObjecttypeCategories())
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data: ObjecttypeCategory[] = [];
          let id: string;
          actions.forEach((a) => {
            data.push(a.payload.doc.data() as ObjecttypeCategory);
            id = a.payload.doc.id;
            data[data.length - 1].id = id;
          });
          return data;
        })
      );
    // .valueChanges();
  }

  getUserRoles() {
    return this.afs.collection(this.dbPathUserRoles()).valueChanges();
  }

  getObjecttypes() {
    const ObjecttypesObs = this.getAllObjecttypesObs();
    const checklistitemsObs = this.getAllChecklistitemsObs();
    return combineLatest([ObjecttypesObs, checklistitemsObs]).pipe(
      map(([a$, b$]) => {
        return a$.map((objecttype) => {
          return {
            ...objecttype,
            checklistitems: b$.filter((b) => b.parentid === objecttype.id),
          };
        });
      })
    );

    // return this.afs.collection(this.dbPathObjecttypes(), (ref) => ref.orderBy('name'))
    //   .stateChanges();
    // return (
    //   this.afs
    //     .collection(this.dbPathObjecttypes(), (ref) => ref.orderBy('name'))
    //     // .stateChanges()
    //     .valueChanges()
    //     .pipe(
    //       switchMap(blogPosts => {
    //         const authorIds = uniq(blogPosts.map(bp => bp.authorId))

    //         return combineLatest(
    //           of(blogPosts),
    //           combineLatest(
    //             authorIds.map(authorId =>
    //               this.af.collection<Author>('authors', ref => ref.where('id', '==', authorId)).valueChanges().pipe(
    //                 map(authors => authors[0])
    //               )
    //             )
    //           )
    //         )
    //       }),
    //       map(([blogPosts, authors]) => {

    //         return blogPosts.map(blogPost => {
    //           return {
    //             ...blogPost,
    //             author: authors.find(a => a.id === blogPost.authorId)
    //           }
    //         })
    //       })
    //     )
    //     // .snapshotChanges()
    // );
    // .pipe(
    //   flatMap((actions) => {
    //     const data: Objecttype[] = [];
    //     let id: string;
    //     actions.forEach((a) => {
    //       data.push(a.payload.doc.data() as Objecttype);
    //       id = a.payload.doc.id;
    //       data[data.length - 1].id = id;
    //     });
    //     data.sort(this.sortservice.dynamicSort('name'));
    //     return checklistitemsObs.pipe(
    //       map((c) => {
    //         data.forEach((type) => {
    //           type.checklistitems = [];
    //           c.forEach((item) => {
    //             if (item) {
    //               if (type.id === item.parentid) {
    //                 type.checklistitems.push(item);
    //               }
    //             }
    //           });
    //         });
    //         // console.log('objecttypes:', data);
    //         return data;
    //       })
    //     );
    //   })
    // );
  }

  updateObjecttype(objecttype: Objecttype) {
    const id = objecttype.id;
    // objecttype = _.omit(objecttype, 'id');
    this.sharedservice.user.subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        const timestamp = firebase.firestore.Timestamp.now();
        const usertimestamp = {
          user,
          timestamp,
        };
        if (!objecttype.editedBy) {
          objecttype.editedBy = [];
        }
        objecttype.editedBy.push(usertimestamp);
        objecttype.editedBy = this.sortservice.reduceArrayToMaxLength(objecttype.editedBy, 'timestamp');

        return this.afs.doc(this.dbPathObjecttype(id)).update(objecttype);
      }
    });
  }

  updateChecklistitem(checklistitem: ChecklistItem) {
    const id = checklistitem.id;
    // checklistitem = _.omit(checklistitem, 'id');
    return this.afs
      .doc(this.dbPathChecklistitem(id))
      .update(checklistitem)
      .then(() => {
        console.log('Checklistitem updated', checklistitem);
        return 'Success!';
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async deleteObjecttype() {
    this.afs.doc(this.dbPathObjecttype(this.idservice.objecttypeId())).delete();
  }

  async deleteChecklistitem() {
    this.afs
      .doc(this.dbPathChecklistitem(this.idservice.checklistitemId()))
      .delete();
  }

  getObjectType(objecttypeid: string) {
    return this.afs
      .doc(this.dbPathObjecttype(objecttypeid))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          let object: Objecttype = new Objecttype();
          object = actions.payload.data();
          object.id = actions.payload.id;
          return object;
        })
      );
  }

  // getObjecttypeVersions() {
  //   return this.afs
  //     .collection(this.dbPathObjecttypeVersions())
  //     .snapshotChanges()
  //     .pipe(
  //       map((actions) => {
  //         const data: Objecttype[] = [];
  //         let id: string;
  //         actions.forEach((a) => {
  //           data.push(a.payload.doc.data() as Objecttype);
  //           id = a.payload.doc.id;
  //           data[data.length - 1].id = id;
  //         });
  //         return data;
  //       })
  //     );
  // }

  getAllObjecttypesObs() {
    return (
      this.afs
        .collection(this.dbPathObjecttypes(), (ref) => ref.orderBy('name'))
        // .snapshotChanges(['added', 'removed'])
        .snapshotChanges()
        .pipe(
          map((actions) => {
            return actions.map((a) => {
              const data = a.payload.doc.data() as Objecttype;
              data.id = a.payload.doc.id;
              // const parentid = a.payload.doc.ref.parent.parent.id;
              return {...data};
            });
          })
        )
    );
  }

  getAllChecklistitemsObs(): Observable<ChecklistItemsLibraryItem[]> {
    return (
      this.afs
        .collectionGroup('checklistitemslibrary', (ref) => ref)
        // .snapshotChanges(['added', 'removed'])
        .snapshotChanges()
        .pipe(
          map((actions) => {
            return actions.map((a) => {
              const data = a.payload.doc.data() as ChecklistItemsLibraryItem;
              // console.log('objectProject.id', objectProject.id);
              data.id = a.payload.doc.id;
              data.parentid = a.payload.doc.ref.parent.parent.id;
              return {...data};
            });
          })
        )
    );
  }

  getChecklistitems(objecttypeid?: string) {
    if (!objecttypeid) {
      objecttypeid = this.idservice.objecttypeId();
    }
    // const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('checklistitemslibrary', (ref) => ref)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            // console.log(a.payload.doc.ref);
            if (a.payload.doc.ref.parent.parent) {
              const checklistitemParent = a.payload.doc.ref.parent.parent;
              if (
                checklistitemParent &&
                checklistitemParent.id === objecttypeid
              ) {
                const data = a.payload.doc.data() as ChecklistItem;
                // console.log('objectProject.id', objectProject.id);
                const id = a.payload.doc.id;
                const parentid = a.payload.doc.ref.parent.parent.id;
                return {id, parentid, ...data};
              } else {
                return null;
              }
            } else {
              return null;
            }
          });
        })
      );
  }

  getChecklistitem(checklistitemid?: string) {
    return this.afs
      .doc(this.dbPathChecklistitem(checklistitemid))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          let checklistitem: ChecklistItem = new ChecklistItem();
          checklistitem = actions.payload.data();
          checklistitem.id = actions.payload.id;
          return checklistitem;
        })
      );
  }

  getChecklistitemtypes() {
    return this.afs
      .collection(this.dbPathChecklistitemtypes(), (ref) => ref.orderBy('name'))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data: ChecklistItemType[] = [];
          let id: string;
          actions.forEach((a) => {
            data.push(a.payload.doc.data() as ChecklistItemType);
            id = a.payload.doc.id;
            data[data.length - 1].id = id;
          });
          data.sort(this.sortservice.dynamicSort('name'));
          return data;
        })
      );
  }

  getObjectStatuses() {
    return this.afs
      .collection(this.dbPathObjectStatuses(), (ref) => ref.orderBy('priority'))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data: ObjectStatus[] = [];
          let id: string;
          actions.forEach((a) => {
            data.push(a.payload.doc.data() as ObjectStatus);
            id = a.payload.doc.id;
            data[data.length - 1].id = id;
          });
          return data;
        })
      );
  }

  createChecklistitem(checklistitem: ChecklistItem) {
    this.afs
      .collection(this.dbPathChecklistitems())
      .add(checklistitem)
      .then(() => {
        console.log('Checklistitem added', checklistitem);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  createObjecttype(objecttype: Objecttype) {
    this.afs
      .collection(this.dbPathObjecttypes())
      .add(objecttype)
      .then((docRef) => {
        console.log('Objecttype added', objecttype);
        this.router.navigateByUrl('a/objekttyper/' + docRef.id);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  createObjectStatus(status: ObjectStatus) {
    this.afs
      .collection(this.dbPathObjectStatuses())
      .add(status)
      .then(() => {
        console.log('Objectstatus added', status);
        // this.router.navigateByUrl('a/statuses/' + docRef.id);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  updateObjectStatus(status: ObjectStatus) {
    const id = status.id;
    return this.afs
      .doc(this.dbPathObjectStatus(id))
      .update(status)
      .then(() => {
        console.log('Objectstatus updated', status);
        return 'Success!';
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  updateObjectStatuses(statuses: ObjectStatus[]) {
    const batch = this.afs.firestore.batch();
    statuses.forEach(status => {
      const docRef = this.afs.doc(this.dbPathObjectStatus(status.id)).ref;
      batch.update(docRef, status);
    });
    batch
      .commit()
      .then(() => {
        console.log('Object statuses updated');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // getObjecttypesNextId(version: string) {
  //   return this.afs
  //     .doc(this.dbPathObjecttypeVersion(version))
  //     .snapshotChanges()
  //     .pipe(take(1));
  // }

  // move(version) {
  //   console.log('start move (from service)...', version);
  //   return this.afs
  //     .collection(this.dbPathObjecttypesOld(version), (ref) =>
  //       ref.orderBy('name')
  //     )
  //     .snapshotChanges()
  //     .pipe(
  //       map((actions) => {
  //         console.log('going through objecttypes...');
  //         const data: Objecttype[] = [];
  //         let id: string;
  //         actions.forEach((a) => {
  //           data.push(a.payload.doc.data() as Objecttype);
  //           id = a.payload.doc.id;
  //           data[data.length - 1].id = id;
  //           console.log(data);
  //           console.log('objecttype', a.payload.doc.data());
  //           // const genId = this.afs.createId();
  //           this.afs
  //             .collection(this.dbPathObjecttypes())
  //             .add(a.payload.doc.data())
  //             .then(() => {
  //               console.log('Success');
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //         });
  //         return data;
  //       })
  //     );
  // }

  async addToEventLog(eventLogItem: EventLogItem) {
    // Temporary function to log errors to better support users.
    console.log('Adding to event log...', eventLogItem);
    const url = `https://dikxzttfvxjpdhka3mdtklnxpmk2xn.chrispo.no`;

    return await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventLogItem),
    });
  }
}
