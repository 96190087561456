
    <app-headertext text="Ny bruker" hideback="true"></app-headertext>
    <form
      [formGroup]="Form"
      (ngSubmit)="create(firstname.value, lastname.value, initials.value, role.value, title.value, email.value, phonelocale.value, phonenumber.value)"
    >
      <div class="form-group">
        <p>
          <mat-form-field>
            <input
              matInput
              id="firstname"
              type="text"
              placeholder="Fornavn"
              formControlName="firstname"
            />
            <mat-error *ngIf="firstname.touched && firstname.invalid">
              <div *ngIf="!firstname.errors.required">
                Please enter a object firstname
              </div>
              <div *ngIf="firstname.errors.required">
                User firstname is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              id="lastname"
              type="text"
              placeholder="Etternavn"
              formControlName="lastname"
            />
            <mat-error *ngIf="lastname.touched && lastname.invalid">
              <div *ngIf="!lastname.errors.required">
                Please enter a user lastname
              </div>
              <div *ngIf="lastname.errors.required">
                User lastname is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <input
              matInput
              id="initials"
              type="text"
              placeholder="Initialer"
              formControlName="initials"
            />
            <mat-error *ngIf="initials.touched && initials.invalid">
              <div *ngIf="!initials.errors.required">
                Please enter initials
              </div>
              <div *ngIf="initials.errors.required">
                User initials is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field *ngIf="!mobileQuery.matches">
            <mat-label>Rolle</mat-label>
            <mat-select formControlName="role" id="role" value="">
              <mat-option *ngFor="let role of roles" [value]="role">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="role.touched && role.invalid">
              <div *ngIf="!role.errors.required">
                Please select a role
              </div>
              <div *ngIf="role.errors.required">
                User role is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="mobileQuery.matches">
            <mat-label>Rolle</mat-label>
            <select formControlName="role" id="role" matNativeControl value="">
              <option *ngFor="let role of roles" [value]="role.id">
                {{ role.name }}
              </option>
            </select>

            <mat-error *ngIf="role.touched && role.invalid">
              <div *ngIf="!role.errors.required">
                Please select a role
              </div>
              <div *ngIf="role.errors.required">
                Object role is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <!-- <mat-icon>person_outline</mat-icon> -->
          <mat-form-field>
            <input
              matInput
              id="title"
              type="text"
              placeholder="Tittel"
              formControlName="title"
            />
            <mat-error *ngIf="title.touched && title.invalid">
              <div *ngIf="!title.errors.required">
                Please enter an title
              </div>
              <div *ngIf="title.errors.required">
                User title is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <!-- <mat-icon>person_outline</mat-icon> -->
          <mat-form-field>
            <input
              matInput
              id="email"
              type="text"
              placeholder="E-post"
              formControlName="email"
            />
            <mat-error *ngIf="email.touched && email.invalid">
              <div *ngIf="!email.errors.required">
                Please enter an email
              </div>
              <div *ngIf="email.errors.required">
                User email is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <!-- <mat-icon>person_outline</mat-icon> -->
          <mat-form-field>
            <input
              matInput
              id="phonelocale"
              type="text"
              placeholder="Landskode"
              formControlName="phonelocale"
            />
            <mat-error *ngIf="phonelocale.touched && phonelocale.invalid">
              <div *ngIf="!phonelocale.errors.required">
                Please enter a object phonelocale
              </div>
              <div *ngIf="phonelocale.errors.required">
                User phonelocale is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              id="phonenumber"
              type="text"
              placeholder="Telefonnummer"
              formControlName="phonenumber"
            />
            <mat-error *ngIf="phonenumber.touched && phonenumber.invalid">
              <div *ngIf="!phonenumber.errors.required">
                Please enter a object phonenumber
              </div>
              <div *ngIf="phonenumber.errors.required">
                User phonenumber is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
            Lagre
          </button>
        </p>
        <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
      </div>
    </form>
