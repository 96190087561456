import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
})
export class ProjectListComponent implements OnInit {
  @Input() projects: Project[];

  selectedProject: Project;

  mobileQuery: MediaQueryList;

  constructor(
    media: MediaMatcher,
    private sharedservice: SharedService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }
  ngOnInit(): void {
  }

  projectClicked(project: Project) {
    this.sharedservice.project.next(project);
    this.selectedProject = project;
  }
}
