import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NewChangelogItemComponent } from '../components/admin/changelog/new-changelog-item/new-changelog-item.component';
import { PreviewComponent } from '../components/common/file/preview/preview.component';
import { DeviationSignComponent } from '../components/deviations/deviation-sign/deviation-sign.component';
import { NewReceiptformPopupComponent } from '../components/popups/new-receiptform-popup/new-receiptform-popup.component';
import { AppVersion } from '../models/Admin';
import { Checklist } from '../models/Checklist';
import { ChecklistItem } from '../models/Checklistitem';
import { AventiFile } from '../models/File';
import { SortService } from './sort.service';
import {UpdateComponent} from '../components/popups/update/update.component';
import {User} from '../models/User';
import {AventiObject} from '../models/AventiObject';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private dialogAppVersion: MatDialog,
    private dialogDeviationSign: MatDialog,
    private dialogNewReceiptForm: MatDialog,
    private dialogFilePreview: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortservice: SortService,
    private dialog: MatDialog,
  ) {}

  ChangelogItem(data: AppVersion = null) {
    this.dialogAppVersion.open(NewChangelogItemComponent, {
      backdropClass: 'blur-dialog-backdrop',
      width: '90%',
      maxWidth: '600px',
      data: { inputVersion: data },
    });
  }

  openDialogDeviationSign(checklistitem: ChecklistItem) {
    const dialogRef: MatDialogRef<DeviationSignComponent> =
      this.dialogDeviationSign.open(DeviationSignComponent, {
        closeOnNavigation: true,
        disableClose: false,
        // width: '100%',
        backdropClass: 'blur-dialog-backdrop',
      });
    dialogRef.componentInstance.checklistitem = checklistitem;
  }

  openDialogNewReceiptForm(user: User, projectid: string, object?: AventiObject) {
    // console.log(user);
    user.projectaccess.sort(this.sortservice.dynamicSort('id'));
    const dialogRef = this.dialogNewReceiptForm.open(NewReceiptformPopupComponent, {
      maxWidth: '550px',
      data: {
        projects: user.projectaccess,
        currentprojectid: projectid,
        inputObject: object
      },
      backdropClass: 'blur-dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      // // this.projectid = result;
      // console.log('result:', result);
      if (result) {
      }
    });
  }

  openDialogFilePreview(
    files: AventiFile[],
    fileindex: number = 0,
    locked?: boolean,
    checklist?: Checklist,
    params?: Params,
  ) {
    //   checklist.generatingPDFchecklist = 'Sending';
    // console.log(file, checklist);
    // if (checklist) {
    //   checklist.generatingPDFstatusIcon = 'hourglass_empty';
    //   //   checklist.generatingPDFstatusText = 'Til generering';
    //   const attachments: AventiFile[] = [];
    //   checklist.files = [];
    //   checklist.checklistitems = [];
    //   this.sharedservice.checklistitems.pipe(take(1)).subscribe((clis) => {
    //     if (clis !== null) {
    //       clis.forEach((cli) => {
    //         cli.files = [];
    //         if (cli.checklistid === checklist.id) {
    //           checklist.checklistitems.push(cli);
    //         }
    //       });
    //       this.sharedservice.checklistfiles.pipe(take(1)).subscribe((fs) => {
    //         if (fs !== null) {
    //           fs.forEach((f) => {
    //             if (f.checklistid === checklist.id) {
    //               if (!f.checklistitemid) {
    //                 checklist.files.push(f);
    //               } else if (f.checklistitemid) {
    //                 checklist.checklistitems.forEach((checklistitem) => {
    //                   if (f.checklistitemid === checklistitem.id) {
    //                     checklistitem.files.push(f);
    //                   }
    //                 });
    //               }
    //             }
    //           });
    //         }

    //         // console.log(checklist.checklistitems);
    //         // console.log(checklist.files);
    //         // console.log(checklist);
    //         const images: AventiFile[] = [];
    //         if (checklist.files) {
    //           checklist.files.forEach((cFile) => {
    //             if (
    //               cFile.name
    //                 .substring(cFile.name.lastIndexOf('.'), cFile.name.length)
    //                 .toLowerCase() === '.pdf'
    //             ) {
    //               attachments.push(cFile);
    //             }
    //             if (cFile.type.indexOf('image') > -1) {
    //               images.push(cFile);
    //             }
    //           });
    //         }
    //         checklist.checklistitems.forEach((item) => {
    //           if (item.files) {
    //             item.files.forEach((iFile) => {
    //               if (
    //                 iFile.name
    //                   .substring(iFile.name.lastIndexOf('.'), iFile.name.length)
    //                   .toLowerCase() === '.pdf'
    //               ) {
    //                 attachments.push(iFile);
    //               }
    //               if (iFile.type.indexOf('image') > -1) {
    //                 images.push(iFile);
    //               }
    //             });
    //           }
    //         });
    //         // console.log('Pdf Preview');
    //         // console.log(file);
    //         // console.log(attachments);
    //         this.openDialog(file, locked, attachments, images);
    //       });
    //     }
    //   });
    // } else {
    // this.openDialog(file, locked, null, null);
    const dialogRef: MatDialogRef<PreviewComponent> = this.dialogFilePreview.open(
      PreviewComponent,
      {
        closeOnNavigation: true,
        disableClose: false,
        width: '100%',
        backdropClass: 'blur-dialog-backdrop',
      }
    );
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: params,
        queryParamsHandling: 'merge',
      });
    });
    dialogRef.componentInstance.files = files;
    dialogRef.componentInstance.fileindex = fileindex;
    dialogRef.componentInstance.checklist = checklist;
    // dialogRef.componentInstance.attachments = attachments;
    // dialogRef.componentInstance.images = images;
    dialogRef.componentInstance.isLocked = locked;
  }


  openUpdateDialog(versionMessage?: string, newVersion?: string) {
    this.dialog
      .open(UpdateComponent, {
        id: 'update-popup',
        closeOnNavigation: false,
        disableClose: true,
        backdropClass: 'blur-dialog-backdrop',
        data: {
          newVersion,
          versionMessage,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        console.log(result);
        if (!result) {
          await new Promise((r) => setTimeout(r, 120000));
          const dia = this.dialog.getDialogById('update-popup');
          if (dia) {
            dia.close();
          }
          this.openUpdateDialog(versionMessage, newVersion);
        }
      });
  }

}
