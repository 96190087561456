import { ChecklistStatus } from './ChecklistStatus';
import { AventiObject } from './AventiObject';
import { UserTimestamp } from './User';
import { AventiFile } from './File';

export class Project {
  public no?: string;
  public name?: string;
  public nameshort?: string;
  public objecttypeversion?: string;
  public id?: string;
  public url?: string;
  public checklistStatus?: ChecklistStatus;
  public objects?: AventiObject[];
  public editedBy?: UserTimestamp[];
  public customerids?: string[];
  public mapurl?: string;
  public map?: {
    center: google.maps.LatLngLiteral;
    cabinets: {
      pos: google.maps.LatLngLiteral;
      name: string;
    }[];
    polyline: google.maps.Polyline;
  };
  public files?: AventiFile[];
  public fromCache?: boolean;
  public useStatusLevel?: boolean;
  public statusLevelUpdateInProgress?: STATUS_LEVEL_UPDATE_STATE;
  public statusLevelUpdateInProgressCount?: number;
  public statusLevelUpdateInProgressTotal?: number;
}

export enum STATUS_LEVEL_UPDATE_STATE {
  DISABLED = 'DISABLED',
  REQUESTED = 'REQUESTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}
