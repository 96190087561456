<app-headertext text="Hjem" [hideback]="true"></app-headertext>

<!-- <div style="margin: 0 15px 0 15px">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div> -->

<div class="row">
  <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  <div>
    <div class="container">
      <div class="item project" routerLink="p">
        <div class="center">
          <mat-icon class="icon-full-size">developer_board</mat-icon>
          <p>Prosjekt</p>
        </div>
      </div>
      <div class="item objects" routerLink="o" *ngIf="user.role?.name !== 'customer'">
        <div class="center">
          <mat-icon class="icon-full-size">traffic</mat-icon>
          <p>Objekter</p>
        </div>
      </div>
      <div class="item checklists" routerLink="sl">
        <div class="center">
          <mat-icon class="icon-full-size">list_alt</mat-icon>
          <p>Sjekklister</p>
        </div>
      </div>
      <div class="item deviations" routerLink="av" *ngIf="user.role?.name !== 'customer'">
        <div class="center">
          <mat-icon class="icon-full-size">assignment_late</mat-icon>
          <p>Avvik</p>
        </div>
      </div>
      <div class="item receiptforms" routerLink="mt" *ngIf="user.role?.name !== 'customer'">
        <div class="center">
          <mat-icon class="icon-full-size">cases</mat-icon>
          <p>Mottak</p>
        </div>
      </div>
      <!-- <div class="item map" routerLink="m" *ngIf="user.role?.name !== 'customer'">
        <div class="center">
          <mat-icon class="icon-full-size">map</mat-icon>
          <p>Kart</p>
        </div>
      </div>
      <div class="item files" routerLink="f" *ngIf="user.role?.name !== 'customer'">
        <div class="center">
          <mat-icon class="icon-full-size">perm_media</mat-icon>
          <p>Filer</p>
        </div>
      </div> -->
    </div>
  </div>
  <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
</div>
