import {Injectable} from '@angular/core';
import {FilterMetadata, SortMeta} from 'primeng/api';
import {SortButton} from '../../models/PrimeNG';
import {Location} from '@angular/common';
import {Table} from 'primeng/table';
import {ActivatedRoute, Router} from '@angular/router';
import {Checklist} from '../../models/Checklist';
import {AventiObject} from '../../models/AventiObject';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  onSort(sortArray: SortMeta[],
         sortButtons: SortButton[],
         tableSort: SortMeta[],
         queryParamFilter: { [p: string]: FilterMetadata },
         tableFiltersReady: boolean
  ) {
    sortButtons.map(button => {
      button.sort.order = 0;
    });
    sortArray.map(sort => {
      sortButtons.map(button => {
        if (button.sort.field === sort.field) {
          button.sort.order = sort.order;
        }
      });
    });
    sortButtons = this.setSortButtonsIndex(sortButtons, tableSort);
    this.setQueryParamsFromTableEvents(tableSort, tableFiltersReady, queryParamFilter);
    return sortButtons;
  }

  sortFromButton(button: SortButton,
                 sortButtons: SortButton[],
                 tableSortInput: SortMeta[],
                 queryParamFilter: { [p: string]: FilterMetadata },
                 tableFiltersReady: boolean,
                 numberOfTableSorts: number
  ) {
    const buttonSort: SortMeta = JSON.parse(JSON.stringify(button.sort));
    switch (buttonSort.order) {
      case 1:
        buttonSort.order = -1;
        break;
      case 0:
      case -1:
        buttonSort.order = 1;
        break;
    }
    let tableSort: SortMeta[] = JSON.parse(JSON.stringify(tableSortInput) ?? '[]');
    if (!tableSort || tableSort.length === 0) {
      tableSort = [buttonSort];
    } else {
      const sortExists = tableSort.find(sort => sort.field === buttonSort.field);
      if (sortExists) {
        tableSort = tableSort.map(sort => {
          if (sort.field === buttonSort.field) {
            return buttonSort;
          }
          return sort;
        });
      } else {
        tableSort.push(buttonSort);
      }
    }
    sortButtons = this.setSortButtonsIndex(sortButtons, tableSort);
    this.setQueryParamsFromTableEvents(tableSort, tableFiltersReady, queryParamFilter);
    numberOfTableSorts = this.setNumberOfTableSorts(tableSort);
    return {tableSort, numberOfTableSorts, sortButtons};
  }


  clearTable(table: Table, tableSort: SortMeta[], sortButtons: SortButton[]) {
    table.clear();
    table.reset();
    table.clearFilterValues();
    table.clearState();
    sortButtons = this.resetSortButtonsIndex(sortButtons);
    tableSort = [{field: '', order: 0}];
    const numberOfTableFilters = 0;
    const numberOfTableSorts = 0;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
      queryParamsHandling: '',
    });
    return {
      tableSort,
      numberOfTableFilters,
      numberOfTableSorts,
      sortButtons
    };
  }

  resetTableSort(tableSortInput: SortMeta[],
                 sortButtonsInput: SortButton[],
                 tableFiltersInput: { [p: string]: FilterMetadata },
                 tableFiltersReadyInput: boolean,
                 queryParamFilter: { [p: string]: FilterMetadata }
  ) {
    tableSortInput = [{field: '', order: 0}];
    this.resetSortButtonsIndex(sortButtonsInput);
    this.setQueryParamsFromTableEvents(tableSortInput, tableFiltersReadyInput, queryParamFilter);
    const {
      tableSort,
      sortButtons,
      tableFilters,
      numberOfTableFilters,
      numberOfTableSorts,
      searchString
    } = this.setTableSortAndFilterFromQueryParams(tableSortInput, sortButtonsInput, tableFiltersInput, tableFiltersReadyInput);
    return {
      tableSort,
      sortButtons,
      tableFilters,
      numberOfTableFilters,
      numberOfTableSorts,
      searchString
    };
  }

  getChecklistStatusSeverity(status: string) {
    switch (status) {
      case 'signed':
        return 'success';
      case 'finished':
        return 'warning';
      case 'started':
        return 'info';
      case 'error':
        return 'danger';
      default:
        return 'none';
    }
  }

  openChecklist(checklist: Checklist, disableRoute: boolean, type: string) {
    if (!disableRoute) {
      if (type === 'receiptform') {
        this.router.navigateByUrl(
          'p/' +
          checklist.projectid +
          '/mt/' +
          checklist.id
        );
      } else {
        this.router.navigateByUrl(
          'p/' +
          checklist.projectid +
          '/o/' +
          checklist.objectid +
          '/s/' +
          checklist.id
        );
      }
    }
  }

  openObject(object: AventiObject, disableRoute: boolean) {
    if (!disableRoute) {
      this.router.navigateByUrl(
        'p/' +
        object.projectid +
        '/o/' +
        object.id
      );
    }
  }

  setQueryParamsFromTableEvents(
    tableSort: SortMeta[],
    tableFiltersReady: boolean,
    tableFilters: { [p: string]: FilterMetadata }
  ) {
    const queryParams = [];
    let numberOfTableFilters = 0;
    // tslint:disable-next-line:forin
    for (const key in tableFilters) {
      const filterProperty = tableFilters[key];
      const filterValue = filterProperty?.value;
      const value = filterValue?.length > 0 ? filterValue : (typeof filterValue === 'boolean') ? filterValue.toString() : null;
      if (value !== null) {
        numberOfTableFilters++;
        const urlValue = encodeURIComponent(value);
        if (filterProperty.matchMode === 'contains' || typeof filterValue === 'boolean') {
          queryParams.push(key + '=' + urlValue);
        } else {
          queryParams.push(key + '_multifilter=' + urlValue);
        }
      }
    }
    for (const key in tableSort) {
      if (Object.prototype.hasOwnProperty.call(tableSort, key)) {
        const property = tableSort[key];
        const field = property?.field ?? null;
        const order = property?.order;
        if (field !== null && order !== 0) {
          queryParams.push(field + '_sort=' + order);
        }
      }
    }
    if (tableFiltersReady) {
      let pathWithNewParams = this.location.path().split('?')[0] + '?';
      if (queryParams.length > 0) {
        pathWithNewParams += queryParams.join('&');
      }
      this.location.replaceState(pathWithNewParams);
    }
    return numberOfTableFilters;
  }

  setTableSortAndFilterFromQueryParams(tableSort: SortMeta[],
                                       sortButtons: SortButton[],
                                       tableFilters: { [p: string]: FilterMetadata },
                                       tableFiltersReady: boolean,
                                       urlInput: string = this.location.path()
  ) {
    let numberOfTableFilters = 0;
    let numberOfTableSorts = 0;
    let searchString = '';
    if (!urlInput.includes('?')) {
      return;
    } else {
      let paramsString = urlInput;
      if (urlInput.indexOf('?') > 0) {
        paramsString = urlInput.split('?')[1];
      }
      const paramsArray = paramsString.split('&').map(param => param.split('='));

      if (paramsString.length > 0 && paramsArray.length > 0) {
        Object.values(tableFilters).map((tFilter: FilterMetadata) => {
          tFilter.value = null;
        });

        for (const param of paramsArray) {
          const key = param[0];
          const value = param[1];
          let filterMetadata: FilterMetadata;
          if (key.indexOf('_sort') > 0) {
            if (tableFiltersReady) {
              const sort: SortMeta = {
                field: key.replace('_sort', ''),
                order: parseInt(value, 10)
              };
              let tableSortTemp: SortMeta[] = JSON.parse(JSON.stringify(tableSort) ?? '[]');
              const sortExitsting = tableSortTemp?.find(tsort => tsort?.field === sort?.field);
              if (sortExitsting) {
                for (const tsort of tableSortTemp) {
                  if (tsort.field === sort.field) {
                    tsort.order = sort.order;
                  }
                }
              } else {
                if (!tableSortTemp) {
                  tableSortTemp = [{field: '', order: 0}];
                }
                tableSortTemp.push(sort);
              }
              tableSort = tableSortTemp;
            }
          } else {
            const urlDecodedValue = decodeURIComponent(value);
            if (value === 'true' || value === 'false') {
              filterMetadata = {
                value: value === 'true',
                matchMode: 'equals'
              };
              tableFilters[key] = filterMetadata;
            } else if (key.indexOf('_multifilter') > 0) {
              const valueArray = urlDecodedValue.split(',');
              filterMetadata = {
                value: valueArray,
                matchMode: 'in',
                operator: 'and'
              };
              tableFilters[key.replace('_multifilter', '')] = filterMetadata;
            } else {
              filterMetadata = {
                value: urlDecodedValue,
                matchMode: 'contains',
                operator: 'and'
              };
              tableFilters[key] = filterMetadata;
            }
            if (key === 'global') {
              searchString = urlDecodedValue;
            }
          }
        }
        sortButtons = this.setSortButtonsIndex(sortButtons, tableSort);
        Object.values(tableFilters).forEach((tFilter) => {
          if (tFilter.value !== null) {
            numberOfTableFilters++;
          }
        });
        numberOfTableSorts = this.setNumberOfTableSorts(tableSort);
      }
      return {
        tableSort,
        sortButtons,
        tableFilters,
        tableFiltersReady,
        numberOfTableFilters,
        numberOfTableSorts,
        searchString
      };
    }
  }

  private resetSortButtonsIndex(sortButtons: SortButton[]) {
    const buttonSort: SortButton[] = sortButtons;
    buttonSort.map(button => {
      button.index = 0;
      button.sort.order = 0;
    });
    return buttonSort;
  }

  private setNumberOfTableSorts(tableSort: SortMeta[]) {
    let numberOfTableSorts = 0;
    tableSort?.forEach((tSort) => {
      if (tSort.order !== 0) {
        numberOfTableSorts++;
      }
    });
    return numberOfTableSorts;
  }

  private setSortButtonsIndex(buttonSort: SortButton[], tableSort: SortMeta[]) {
    let sortButtonIndex = 0;
    buttonSort.map(button => {
      button.index = 0;
    });
    tableSort?.map((tSort) => {
      buttonSort.map(button => {
        if (button.sort.field === tSort.field) {
          sortButtonIndex++;
          button.index = sortButtonIndex;
        }
      });
    });
    return buttonSort;
  }
}
