<app-headertext text="Nytt sjekklistepunkt" hideback="true"></app-headertext>
<form [formGroup]="Form" (ngSubmit)="create(text.value, enText.value, type.value, status.value)">
  <div class="form-group">
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="text"
          type="text"
          placeholder="Tekst"
          formControlName="text"
        />
        <mat-error *ngIf="text.touched && text.invalid">
          <div *ngIf="!text.errors.required">
            Please enter a checklistitem text
          </div>
          <div *ngIf="text.errors.required">
            Checklistitem text is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="enText"
          type="text"
          placeholder="Engelsk tekst"
          formControlName="enText"
        />
        <mat-error *ngIf="enText.touched && enText.invalid">
          <div *ngIf="!enText.errors.required">
            Please enter a checklistitem english text
          </div>
          <div *ngIf="enText.errors.required">
            Checklistitem english text is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field *ngIf="!mobileQuery.matches || mobileQuery.matches">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" id="type" value="">
          <mat-option *ngFor="let type of checklistitemtypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="type.touched && type.invalid">
          <div *ngIf="!type.errors.required">
            Please select a type
          </div>
          <div *ngIf="type.errors.required">
            Type is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field *ngIf="!mobileQuery.matches || mobileQuery.matches">
        <mat-label>Statusnivå</mat-label>
        <mat-select formControlName="status" id="status" value="">
          <mat-option *ngFor="let status of statuses" [value]="status.id">
            {{ status.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="status.touched && status.invalid">
          <div *ngIf="!status.errors.required">
            Please select a status
          </div>
          <div *ngIf="status.errors.required">
            Status is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
        Lagre
      </button>
    </p>
    <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
  </div>
</form>
