<!-- <div class="center-spinner-parent noselect" *ngIf="!disable || disable !== 'true'">
  <div class="center-spinner-child">
    <mat-spinner></mat-spinner>
  </div>
  <div class="center-spinner-child-text" *ngIf="text">
    <h2 *ngIf="header" style="margin-bottom: 60px;">{{ header }}</h2>
    <div *ngFor="let t of text" style="margin-top: -10px;color:gray">
      <h5>{{ t }}</h5>
    </div>
  </div>
</div> -->

<div class="overlay" *ngIf="visible">
  <div class="row">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <div class="overlay-text" *ngIf="text || header">
      <h2 *ngIf="header">{{ header }}</h2>
      <!-- <h2 *ngIf="header" style="margin-bottom: 60px;">{{ header }}</h2> -->
      <div *ngFor="let t of textFrontend" style="margin-top: -10px">
        <h5>{{ t }}</h5>
      </div>
    </div>
  </div>
  <mat-icon
    *ngIf="closeButtonVisible"
    matTooltip="Lukk spinner"
    (click)="closeSpinner()"
    style="position: fixed; top: 25px; right: 25px;"
    class="cursor"
  >close
  </mat-icon
  >
</div>
