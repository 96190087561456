<app-headertext text="Ny objekttype" hideback="true"></app-headertext>
<form [formGroup]="Form" (ngSubmit)="create(name.value, enName.value, category.value)">
  <div class="form-group">
    <!-- <p>
      <mat-form-field>
        <input
          matInput
          id="id"
          type="number"
          placeholder="ID"
          formControlName="id"
        />
        <mat-error *ngIf="id.touched && id.invalid">
          <div *ngIf="!id.errors.required">
            Please enter an ID
          </div>
          <div *ngIf="id.errors.required">
            Objecttype ID is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p> -->
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="name"
          type="text"
          placeholder="Navn"
          formControlName="name"
        />
        <mat-error *ngIf="name.touched && name.invalid">
          <div *ngIf="!name.errors.required">
            Please enter an objecttype name
          </div>
          <div *ngIf="name.errors.required">
            Objecttype name is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="enName"
          type="text"
          placeholder="Name"
          formControlName="enName"
        />
        <mat-error *ngIf="enName.touched && enName.invalid">
          <div *ngIf="!enName.errors.required">
            Please enter an objecttype english name
          </div>
          <div *ngIf="enName.errors.required">
            Objecttype english name is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field *ngIf="!mobileQuery.matches || mobileQuery.matches">
        <mat-label>Kategori</mat-label>
        <mat-select formControlName="category" id="category" value="">
          <mat-option
            *ngFor="let category of objecttypecategories"
            [value]="category.id"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="category.touched && category.invalid">
          <div *ngIf="!category.errors.required">
            Please select a category
          </div>
          <div *ngIf="category.errors.required">
            Category is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
      <!-- <mat-form-field *ngIf="mobileQuery.matches">
        <mat-label>Kategori</mat-label>
        <select
          formControlName="category"
          id="category"
          matNativeControl
          value=""
        >
          <option *ngFor="let item of objecttypecategories" [value]="item.id">
            {{ item.name }}
          </option>
        </select>

        <mat-error *ngIf="category.touched && category.invalid">
          <div *ngIf="!category.errors.required">
            Please select a category
          </div>
          <div *ngIf="category.errors.required">
            Category is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field> -->
    </p>
    <p>
      <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
        Lagre
      </button>
    </p>
    <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
  </div>
</form>
