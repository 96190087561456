<mat-form-field *ngIf="!mobileQuery.matches || mobileQuery.matches">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    *ngIf="multiple && selecteditems"
    multiple
    (selectionChange)="onSelectionChange($event)"
    (openedChange)="onOpenChange($event)"
    [(ngModel)]="selecteditems"
    [compareWith]="comparer"
  >
    <mat-option *ngFor="let item of items" [value]="item">
      <span *ngIf="showid">{{ item.id }} - </span>
      <span *ngIf="showtype">{{ item.type?.name }} - </span>
      {{ item.name }}
    </mat-option>
  </mat-select>
  <mat-select
    *ngIf="!multiple"
    [value]="selected"
    (selectionChange)="onSelectionChange($event)"
  >
    <mat-option *ngFor="let item of items" [value]="item.id">
      <span *ngIf="showid">{{ item.id }} - </span>
      <span *ngIf="showtype">{{ item.type?.name }} - </span>
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- <mat-form-field *ngIf="mobileQuery.matches">
  <mat-label>{{ label }}</mat-label>
  <select
    matNativeControl
    [(ngModel)]="selected"
    (change)="onSelectionChange($event.target)"
  >
    <option *ngFor="let item of items" [value]="item">
      {{ item.name }}
    </option>
  </select>
</mat-form-field> -->
