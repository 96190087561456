<div class="row">
  <app-headertext text="Admin - Objekttyper - Objekttype"></app-headertext>
  <div class="fill-space"></div>
  <button
    mat-button
    (click)="deleteObjecttypeConfirm()"
    class="header-row-button"
  >
    <mat-icon color="warn">delete</mat-icon>
    Slett objekttype
  </button>
</div>

<div style="margin: 0 15px 0 15px;">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>

<div *ngIf="objecttype && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth">
      <h2>{{ objecttype.name }}</h2>
      <mat-card class="child-margin">
<!--        <div class="row" *ngIf="objecttype.name">-->
<!--          Navn:-->
<!--          <div class="fill-space"></div>-->
<!--          <div>{{ objecttype.name }}</div>-->
<!--        </div>-->

<!--        <mat-divider [inset]="true"></mat-divider>-->

        <div class="row" *ngIf="objecttype.name">
          Engelsk navn:
          <div class="fill-space"></div>
          <div>{{ objecttype.enName }}</div>
          <div *ngIf="editEnName">
            <form [formGroup]="FormEnName">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="enName"
                    type="text"
                    placeholder="Tekst"
                    formControlName="enName"
                  />
                  <mat-error *ngIf="enName.touched && enName.invalid">
                    <div *ngIf="!enName.errors.required">
                      Please enter a object type name
                    </div>
                    <div *ngIf="enName.errors.required">
                      Object type name is <strong>required</strong>
                    </div>
                  </mat-error>
                </mat-form-field>
                <button
                  mat-icon-button
                  [disabled]="FormEnName.invalid"
                  *ngIf="editEnName"
                  style="padding-left: 10px;"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveEnName(enName.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div>
            <mat-icon
              style="margin-left: 10px;"
              class="cursor"
              [matTooltip]="editEnName ? 'Avbryt endring' : 'Endre engelsk navn'"
              (click)="toggleEditEnName()"
            >
              {{ editEnName ? "cancel" : "edit" }}
            </mat-icon>
          </div>
        </div>

        <mat-divider [inset]="true"></mat-divider>

        <div class="row" *ngIf="objecttype.name">
          Kategori:
          <div class="fill-space"></div>
          <div *ngIf="!editcategory && objecttype.category">
            {{ objecttype.category.name }}
          </div>
          <div *ngIf="editcategory">
            <app-select
              label="Objekttype"
              [selected]="objecttype.category.id"
              (value)="onSelectionChange($event)"
              [items]="objecttypecategories"
            ></app-select>
          </div>
          <div>
            <mat-icon
              style="margin-left: 10px;"
              class="cursor"
              [matTooltip]="editcategory ? 'Avbryt endring' : 'Endre kategori'"
              (click)="toggleEditCategory()"
            >{{ editcategory ? "cancel" : "edit" }}
            </mat-icon>
          </div>
        </div>
      </mat-card>
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>

<app-checklistitems-list></app-checklistitems-list>
