import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from 'src/app/models/User';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-receiptforms',
  templateUrl: './receiptforms.component.html',
  styleUrls: ['./receiptforms.component.css'],
})
export class ReceiptformsComponent implements OnInit {
  mobileQuery: MediaQueryList;
  user: User;
  isAdmin: boolean;
  private ngUnsubscribe = new Subject();

  constructor(
    media: MediaMatcher,
    sharedservice: SharedService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);

    sharedservice.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      if (user !== null) {
        this.user = user[0];
        if (this.user.role?.name === 'admin') {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      }
    });
  }

  ngOnInit(): void {
  }
}
