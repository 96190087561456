<!-- <div>
  <div class="row">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth"> -->
<div class="row">
  <div>
    <h2>
      <mat-icon
        style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >folder_open</mat-icon
      >Filer
    </h2>
  </div>
  <div class="fill-space"></div>
  <div class="position-child-bottom-right">
    <app-upload-button
      style="margin: 5px"
      *ngIf="!isLoading"
      [dbPath]="dbPath"
      text="Last opp fil"
      icon="add"
      [raised]="false"
      color="primary"
    ></app-upload-button>
  </div>
</div>
<mat-card class="child-margin">
  <!-- <mat-progress-bar
    *ngIf="isLoading || !files"
    mode="indeterminate"
  ></mat-progress-bar> -->
  <div class="row">
    <div class="fill-space"></div>
    <div>
      <mat-spinner
        diameter="50"
        style="margin: 32px"
        *ngIf="!files"
      ></mat-spinner>
      <app-empty-list
        message="Ingen filer"
        *ngIf="files?.length === 0"
      ></app-empty-list>
    </div>
    <div class="fill-space"></div>
  </div>
  <div class="row">
    <div class="fill-space">
      <table style="font-size: small" *ngIf="files" class="file-table">
        <tr *ngFor="let file of files">
          <td style="width: 96px" (click)="openDialogImagePreview(file)">
            <app-thumbnail
              [file]="file"
              [files]="files"
              [isLocked]="!isAdmin"
              [disableclick]="true"
            ></app-thumbnail>
          </td>
          <td
            (click)="openDialogImagePreview(file)"
            [style.opacity]="isOnline ? '1' : '0.5'"
          >
            <div>{{ file.name }}</div>
            <div style="font-size: x-small">{{ file.sizestring }}</div>
            <div style="font-size: x-small">
              {{ file.lastupdatedString }}
            </div>
            <app-not-available-when-offline></app-not-available-when-offline>
          </td>
          <td style="width: 40px">
            <span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_horiz</mat-icon>
                <!-- <span *ngIf="!mobileQuery.matches"> Meny </span> -->
              </button>
              <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
                <!-- <button mat-menu-item routerLink="">
                  <mat-icon>view_list</mat-icon>Prosjektliste
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="logout()">
                  <mat-icon>exit_to_app</mat-icon>Logg av
                </button> -->
                <!-- <div style="min-width: 250px"></div> -->

                <div *ngIf="user.role?.name !== 'customer'">
                  <button
                    mat-menu-item
                    style="width: 100%; text-align: left"
                    (click)="delete(file)"
                  >
                    <mat-icon>delete</mat-icon>
                    <span> Slett </span>
                  </button>
                  <mat-divider></mat-divider>
                </div>
                <div>
                  <button
                    mat-menu-item
                    style="width: 100%; text-align: left"
                    target="_blank"
                    (click)="filedownloadDirect(file)"
                  >
                    <mat-icon>
                      save
                      <!-- download -->
                    </mat-icon>
                    <span>
                      Last ned
                      <!-- Last ned uten vedlegg -->
                    </span>
                  </button>
                </div>
                <!-- <div>
                  <button
                    mat-menu-item
                    style="width: 100%; text-align: left"
                    *ngIf="
                      attachments && attachments.length > 0 && !mobileQuery.matches
                    "
                    target="_blank"
                    (click)="filedownloadZip()"
                    [disabled]="
                      (isDownloadingZip && !isDownloadingImages) ||
                      printlock ||
                      (images.length === 0 && attachments.length === 0)
                    "
                  >
                    <mat-icon>{{ iconZip }}</mat-icon>
                    <span>
                      {{ progressZip }}
                    </span>
                  </button>
                </div>
                <div>
                  <button
                    mat-menu-item
                    style="width: 100%; text-align: left"
                    *ngIf="images && images.length > 0 && !mobileQuery.matches"
                    target="_blank"
                    (click)="filedownloadImagesZip()"
                    [disabled]="
                      (isDownloadingZip && isDownloadingImages) ||
                      printlock ||
                      (images.length === 0 && attachments.length === 0)
                    "
                  >
                    <mat-icon>{{ iconZip }}</mat-icon>
                    <span>
                      {{ progressImageZip }}
                    </span>
                  </button>
                </div>
                <div>
                  <button
                    mat-menu-item
                    style="width: 100%; text-align: left"
                    *ngIf="
                      attachments && attachments.length > 0 && !mobileQuery.matches
                    "
                    target="_blank"
                    (click)="filedownloadConcat()"
                    [disabled]="
                      isDownloadingConcat ||
                      printlock ||
                      (images.length === 0 && attachments.length === 0)
                    "
                  >
                    <mat-icon>{{ iconConcat }} </mat-icon>
                    <span>
                      {{ progressConcat }}
                    </span>
                  </button>
                </div> -->
                <!-- <div>
                  <mat-divider></mat-divider>
                  <button
                    mat-menu-item
                    style="width: 100%; text-align: left"
                    (click)="toggleEditComment()"
                  >
                    <mat-icon>notes </mat-icon>
                    <span
                      >{{ file?.comment ? "Rediger kommentar" : "Legg til kommentar" }}
                    </span>
                  </button>
                </div> -->
              </mat-menu>
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</mat-card>
<!-- </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div> -->
