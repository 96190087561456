import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChangelogComponent} from './components/admin/changelog/changelog.component';
import {ChecklistitemComponent} from './components/admin/checklistitems/checklistitem/checklistitem.component';
import {ChecklistitemsComponent} from './components/admin/checklistitems/checklistitems.component';
import {NotificationsComponent} from './components/admin/notifications/notifications.component';
import {ObjecttypeComponent} from './components/admin/objecttypes/objecttype/objecttype.component';
import {ObjecttypesComponent} from './components/admin/objecttypes/objecttypes.component';
import {ProjectComponent} from './components/projects/project/project.component';
import {UserComponent} from './components/admin/users/user/user.component';
import {UsersComponent} from './components/admin/users/users.component';
import {ChecklistComponent} from './components/checklists/checklist/checklist.component';
import {ChecklistsComponent} from './components/checklists/checklists.component';
import {FilesComponent} from './components/files/files.component';
import {MypageComponent} from './components/mypage/mypage.component';
import {PageNotFoundComponent} from './components/navigation/page-not-found/page-not-found.component';
import {RefreshComponent} from './components/navigation/refresh/refresh.component';
import {ObjectComponent} from './components/objects/object/object.component';
import {ObjectsComponent} from './components/objects/objects.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {ReceiptformsComponent} from './components/receiptforms/receiptforms.component';
import {LandingpageComponent} from './components/projects/landingpage/landingpage.component';
import {MapComponent} from './components/map/map.component';
import {DocumentsComponent} from './components/documents/documents.component';
import {DeviationsComponent} from './components/deviations/deviations.component';
import {DeviationComponent} from './components/deviations/deviation/deviation.component';
import {CompareExcelComponent} from './components/common/compare/compare-excel/compare-excel.component';
import {ResetPasswordComponent} from './components/navigation/authentication/reset-password/reset-password.component';
import {StatusesComponent} from './components/admin/statuses/statuses.component';

const routes: Routes = [
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'p/:projectid', component: LandingpageComponent},
  {path: 'p/:projectid/p', component: ProjectComponent},
  {path: 'p/:projectid/sl', component: ChecklistsComponent},
  {path: 'p/:projectid/mt/:receiptformid', component: ChecklistComponent},
  {path: 'p/:projectid/mt', component: ReceiptformsComponent},
  {path: 'p/:projectid/av/:deviationid', component: DeviationComponent},
  {path: 'p/:projectid/av', component: DeviationsComponent},
  {path: 'p/:projectid/f', component: FilesComponent},
  {path: 'p/:projectid/d', component: DocumentsComponent},
  {
    path: 'p/:projectid/o/:objectid/s/:checklistid',
    component: ChecklistComponent,
  },
  {path: 'p/:projectid/o/:objectid', component: ObjectComponent},
  {path: 'p/:projectid/o', component: ObjectsComponent},
  {path: 'p/:projectid/m', component: MapComponent},
  {path: 'p/:projectid/p', component: ProjectComponent},
  {path: 'a/statuses', component: StatusesComponent},
  {
    path: 'a/objekttyper/:objecttypeid/sp/:checklistitemid',
    component: ChecklistitemComponent,
  },
  {
    path: 'a/objekttyper/:objecttypeid/sp',
    component: ChecklistitemsComponent,
  },
  {
    path: 'a/objekttyper/:objecttypeid',
    component: ObjecttypeComponent,
  },
  {path: 'a/objekttyper', component: ObjecttypesComponent},
  {path: 'a/changelog', component: ChangelogComponent},
  {path: 'a/brukere/:userid', component: UserComponent},
  {path: 'a/brukere', component: UsersComponent},
  {path: 'a/varsler', component: NotificationsComponent},
  {path: 'minside', component: MypageComponent},
  {path: 'refresh', component: RefreshComponent},
  {path: 'compare/excel', component: CompareExcelComponent},
  {path: 'compare', component: ProjectsComponent},
  {path: 'a', component: ProjectsComponent},
  {path: 'm', component: ProjectsComponent},
  {path: 'p', component: ProjectsComponent},
  {path: '', component: ProjectsComponent},
  {path: '**', component: PageNotFoundComponent}, // Wildcard route for a 404 page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
