<div class="noselect border" style="min-width: 120px;">
  <div class="row">
    <span class="fill-space"></span>
    <img
      style="
        margin-bottom: 15px;
        object-fit: cover;
        opacity: 0.5;
        height: 40px;
        width: 40px;
      "
      src="/assets/images/empty_box_white.png"
      alt="..."
    />
    <span class="fill-space"></span>
  </div>
  <!-- <mat-divider [inset]="true"></mat-divider> -->
  <div class="row" style="margin: 0 10px 0 10px;">
    <span class="fill-space"></span>
    <span style="text-align: center; font-size: smaller; opacity: 0.5;">{{
      message
    }}</span>
    <span class="fill-space"></span>
  </div>
</div>
