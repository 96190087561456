<app-headertext [text]="title" hideback="true"></app-headertext>
<div *ngIf="compare" style="width: 100%; font-size: 12px">
  <!-- <mat-list-item style="width: 100%; font-size: 14px"> -->
  <table style="background-color: #555; padding: 10px; border-radius: 5px">
    <tr *ngIf="compareTitles" style="font-size: 14px">
      <td
        style="
          font-weight: bold;
          border-bottom: solid #444 1px;
          padding: 5px;
          padding-left: 15px;
        "
      >
        {{ compareTitles.id }}
      </td>
      <td
        style="
          font-weight: bold;
          border-bottom: solid #444 1px;
          padding: 5px;
          padding-left: 15px;
        "
      >
        {{ compareTitles.before }}
      </td>
      <td
        style="
          font-weight: bold;
          border-bottom: solid #444 1px;
          padding: 5px;
          padding-left: 15px;
        "
      >
        {{ compareTitles.after }}
      </td>
    </tr>
    <tr *ngFor="let c of compare; let islast = last">
      <td
        [style.border-bottom]="islast ? '' : '1px solid #444'"
        style="padding: 5px; padding-left: 15px; font-weight: bold"
      >
        {{ c.id }}
      </td>
      <td
        [style.border-bottom]="islast ? '' : '1px solid #444'"
        style="padding: 5px; padding-left: 15px"
      >
        {{ c.before }}
      </td>
      <td
        [style.border-bottom]="islast ? '' : '1px solid #444'"
        style="padding: 5px; padding-left: 15px"
        [style.background-color]="c.change ? '#855' : 'none'"
        [style.border-radius]="c.change ? '5px' : ''"
      >
        {{ c.after }}
      </td>
    </tr>
  </table>
  <!-- </mat-list-item> -->
</div>
<mat-list style="width: 100%">
  <div *ngIf="text">
    <mat-list-item style="width: 100%; font-size: 14px">
      {{ text }}
    </mat-list-item>
  </div>
  <div *ngIf="multiLine">
    <mat-list-item *ngFor="let textLine of multiLine" style="width: 100%; font-size: 14px">
      {{ textLine }}
    </mat-list-item>
  </div>
  <div *ngIf="list">
    <ul>
      <li *ngFor="let listItem of list" style="width: 100%; font-size: 14px">
        {{ listItem }}
      </li>
    </ul>
  </div>
  <mat-divider></mat-divider>
  <p>
    <mat-list-item style="width: 100%">
      <button mat-raised-button style="width: 100%" (click)="yesClicked()">
        <mat-icon style="padding-right: 10px" color="primary">{{
          yes.icon
        }}</mat-icon
        >{{ yes.name }}
      </button>
    </mat-list-item>
  </p>
  <p>
    <mat-list-item style="width: 100%">
      <button mat-raised-button style="width: 100%" (click)="noClicked()">
        <mat-icon style="padding-right: 10px" color="warn">{{
          no.icon
        }}</mat-icon
        >{{ no.name }}
      </button>
    </mat-list-item>
  </p>
</mat-list>
