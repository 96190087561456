<div class="row">
  <app-headertext text="Admin - Varslinger"></app-headertext>
  <div class="fill-space"></div>
</div>
<div *ngIf="user && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div>
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon style="font-size: 30px; padding: 4px">ad_units</mat-icon>
          </div>
          <mat-card-title> Varslingsbanner</mat-card-title>
          <mat-card-subtitle>
            <mat-slide-toggle
              [checked]="alert?.isalert"
              (change)="isalertChanged($event.checked)"
              >Aktivert</mat-slide-toggle
            >
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form
            [formGroup]="FormAlert"
            (ngSubmit)="updateAlert(alerttext.value)"
          >
            <mat-form-field>
              <textarea
                matInput
                type="text"
                placeholder="Bannertekst"
                formControlName="alerttext"
              ></textarea>
            </mat-form-field>
            <button mat-raised-button id="btnSubmit" [disabled]="FormAlert.invalid" cdkFocusInitial>
              Lagre
            </button>
          </form>
        </mat-card-content>
      </mat-card>
      <p></p>
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon style="font-size: 30px; padding: 4px"
              >engineering</mat-icon
            >
          </div>
          <mat-card-title> Vedlikeholdsmodus</mat-card-title>
          <mat-card-subtitle>
            <div>
              <mat-slide-toggle
                [checked]="maintenance?.ismaintenance"
                (change)="ismaintenanceChanged($event.checked)"
                >Aktivert</mat-slide-toggle
              >
            </div>
            <div>
              <mat-slide-toggle
                [checked]="maintenance?.allowsuperadmin"
                (change)="allowsuperadminChanged($event.checked)"
                >Tillat superAdmin</mat-slide-toggle
              >
            </div>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form
            [formGroup]="FormMaintenance"
            (ngSubmit)="
              updateMaintenance(maintenanceheader.value, maintenancetext.value)
            "
          >
            <mat-form-field>
              <textarea
                matInput
                type="text"
                placeholder="Header"
                formControlName="maintenanceheader"
              ></textarea>
            </mat-form-field>
            <mat-form-field>
              <textarea
                matInput
                type="text"
                placeholder="Melding"
                formControlName="maintenancetext"
              ></textarea>
            </mat-form-field>
            <button mat-raised-button id="btnSubmit" [disabled]="FormMaintenance.invalid" cdkFocusInitial>
              Lagre
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>
