import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {firstValueFrom, Observable, of, Subject} from 'rxjs';
import {first, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {User} from '../models/User';
import {SnackbarService} from './snackbar.service';
import firebase from 'firebase/compat/app';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  private ngUnsubscribe = new Subject();

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private snackbarservice: SnackbarService
  ) {}

  isLoggedIn() {
    return this.afAuth.authState.pipe(first());
  }

  getUsers() {
    return this.afs.collection('users').valueChanges();
  }

  getCurrentUser() {
    return this.afAuth.authState.pipe(
      take(1),
      switchMap((firebaseUser) => {
        // const user: User = {
        //   firstname: firebaseUser.displayName,
        //   email: firebaseUser.email,
        // }
        return of(firebaseUser);
      })
    );
  }

  async getFirebaseUser(): Promise<firebase.User> {
    return await firstValueFrom(this.afAuth.authState.pipe(
      take(1))).then(firebaseUser => {
      return firebaseUser;
    });
  }

  getUserWithFirebaseUserData(firebaseUserData: any): Observable<User> {
    return this.afs
      .collection('users').snapshotChanges()
      .pipe(
        map((actions) => {
          const filledUsers = actions.map((action) => {
            const id = action.payload.doc.id;
            const user = {...action.payload.doc.data() as User};
            const fromCache = action.payload.doc.metadata.fromCache;
            return {id, fromCache, ...user} as User;
          });
          return filledUsers.find(user =>
            user.firebaseUser?.uid === firebaseUserData.uid ||
            user.id === firebaseUserData.uid ||
            user.email === firebaseUserData.email
          );
        }));
  }

  getUser(userId?: string): Observable<User> {
    return this.afAuth.authState.pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap((firebaseUser) => {
        if (firebaseUser) {
          // console.log(firebaseUser.email);
          let id: string;
          if (userId && userId.length > 0) {
            id = userId;
          } else {
            id = firebaseUser.uid;
          }
          // console.log(id);
          return this.afs.doc<User>(`users/${id}`).valueChanges();
          // .pipe(
          //   map((a) => {
          //     const data = a;
          //     const id = firebaseUser.uid;
          //     const email = firebaseUser.email;
          //     return { id, email, ...data };
          //   })
          // );
        } else {
          return of(undefined);
        }
      })
    );
  }

  isAdmin() {
    return this.getUser().subscribe((u) => {
      return u.role?.name === 'admin';
    });
  }

  isSuperAdmin() {
    return this.getUser().subscribe((u) => {
      return u.superAdmin;
    });
  }

  updateUserAppversion(u: User) {
    const appVersion = environment.appVersion;
    this.afs.doc('users/' + u.id).update({
      appVersion,
      lastupdated: firebase.firestore.Timestamp.now(),
    });
  }

  updateUserProjectAccess(u: User) {
    this.afs.doc('users/' + u.id).update({
      projectaccess: u.projectaccess,
      lastupdated: firebase.firestore.Timestamp.now(),
    });
  }

  updateUserLastseen(u: User) {
    this.afs.doc('users/' + u.id).update({
      lastseen: firebase.firestore.Timestamp.now(),
    });
  }

  updatePositionConsent(u: User) {
    this.afs.doc('users/' + u.id).update({
      positionconsent: {
        approved: true,
        lastupdated: firebase.firestore.Timestamp.now(),
      },
    });
  }

  updateUser(user: User): Promise<void> {
    return this.afs.doc('users/' + user.id).update(user);
  }

  sendPasswordResetMail(email: string) {
    this.afAuth.sendPasswordResetEmail(email).then(() => {
      this.snackbarservice.openSnackBar('Mail om passord reset sendt', '');
    });
  }

  createUser(user: User) {
    this.afs
      .collection('newusers')
      .add(user)
      .then(() => {
        console.log('Adding user', user);
        this.snackbarservice.openSnackBar(
          user.firstname + ' ' + user.lastname + ' opprettes...',
          ''
        );
      });
  }
}
