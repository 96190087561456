import { Component, OnInit } from '@angular/core';
import { Objecttype, ObjecttypeCategory } from 'src/app/models/Objecttype';
import { AdminService } from 'src/app/services/admin.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { IdService } from 'src/app/services/id.service';
import { ConfirmComponent } from 'src/app/components/common/bottom-sheet/confirm/confirm.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-objecttype',
  templateUrl: './objecttype.component.html',
  styleUrls: ['./objecttype.component.css'],
})
export class ObjecttypeComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  objecttype: Objecttype;
  isLoading = true;
  objecttypecategories: ObjecttypeCategory[];
  editcategory = false;

  mobileQuery: MediaQueryList;

  idserviceobjecttype: string; // { version: string; id: string };

  FormEnName = new UntypedFormGroup({
    enName: new UntypedFormControl('', [Validators.required]),
  });

  get enName() {
    return this.FormEnName.get('enName');
  }
  editEnName = false;

  constructor(
    idservice: IdService,
    private adminservice: AdminService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    media: MediaMatcher,
    private snackbarservice: SnackbarService
  ) {
    adminservice
      .getCategories()
      .subscribe((categories: ObjecttypeCategory[]) => {
        this.objecttypecategories = categories; // .objecttypecategories;
      });
    // this.idserviceobjecttype = idservice.objecttypeId();
    adminservice
      .getObjectType(
        idservice.objecttypeId()
        // this.idserviceobjecttype.version,
        // this.idserviceobjecttype.id
      )
      .subscribe((objecttype) => {
        this.objecttype = objecttype;
        this.isLoading = false;
      });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }


  toggleEditEnName() {
    this.editEnName = !this.editEnName;
    this.FormEnName.patchValue({
      enName: this.objecttype.enName,
    });
  }

  saveEnName(enName: string) {
    this.objecttype.enName = enName;
    this.adminservice.updateObjecttype(this.objecttype);
    this.editEnName = false;
  }

  toggleEditCategory() {
    this.editcategory = !this.editcategory;
  }

  onSelectionChange(category: ObjecttypeCategory) {
    // let objecttypecategoryname: string;
    // let category: ObjecttypeCategory = new ObjecttypeCategory();
    // this.objecttypecategories.forEach((cat: ObjecttypeCategory) => {
    //   // const catid = category.id.toString();
    //   if (cat.id === categoryid) {
    //     console.log(cat);
    //     category = {
    //       id: cat.id,
    //       name: cat.name,
    //     };
    //   }
    // });
    this.objecttype.category = category;
    this.adminservice.updateObjecttype(
      // this.idserviceobjecttype.version,
      this.objecttype
    );
    this.editcategory = false;
  }

  deleteObjecttypeConfirm(): void {
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'Er du sikker?',
        text: 'Slette objekttype: ' + this.objecttype.name,
        yes: {
          name: 'Ja',
          icon: 'delete',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet.afterDismissed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((confirm) => {
      if (confirm) {
        this.adminservice
          .deleteObjecttype()
          .then(() => {
            console.log('Objecttype deleted...');
            this.router.navigateByUrl(
              '/a/objekttyper'
            );
            this.snackbarservice.openSnackBar('Objekttype slettet...', '');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }
}
