import {Component} from '@angular/core';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  selector: 'app-not-available-when-offline',
  templateUrl: './not-available-when-offline.component.html',
  styleUrls: ['./not-available-when-offline.component.css']
})
export class NotAvailableWhenOfflineComponent {

  isOnline: boolean;

  constructor(
    private sharedservice: SharedService
  ) {
  }

  ngOnInit() {
    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });
  }
}
