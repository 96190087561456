<mat-icon
  class="header-icon header-icon-small noselect"
  [matTooltip]="tooltip"
  *ngIf="visible"
  color="warn"
  [matMenuTriggerFor]="domainMenu"
  >warning</mat-icon
>
<mat-menu #domainMenu="matMenu">
  <div style="padding: 5px 20px 20px 20px">
    <div>
      <mat-icon color="warn" style="padding: 0 0 10px 0">warning</mat-icon>
    </div>
    {{ domainMessage }}
  </div>
  <mat-divider></mat-divider>
  <a mat-menu-item color="warn" href="https://apg.aventi.no" style="margin-top:5px;">
    <mat-icon color="warn">launch</mat-icon>apg.aventi.no
  </a>
</mat-menu>
