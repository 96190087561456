<div *ngIf="isLoading">
  <app-spinner header="Laster inn kart..." [text]="['Vennligst vent']" disable="false"></app-spinner>
</div>
<div id="map-container">
  <div
    id="map-canvas"
    style="border-radius: 5px; border: 10px solid #303030"
  ></div>

  <!-- <map-marker
    #marker="mapMarker"
    *ngFor="let markerPosition of markerPositionsCabinets"
    [position]="markerPosition.pos"
    [options]="markerPosition.markerOptions"
    (mapClick)="openInfoWindow(marker)"
  ></map-marker>
  <map-polyline
    *ngFor="let polyline of map.polylines"
    [path]="polyline.pos"
    [options]="polyline.options"
  ></map-polyline>
  <map-info-window></map-info-window> -->
  <!-- <google-map
  style="color: black"
  height="400px"
  [width]="width"
  [center]="map.center"
  [zoom]="map.zoom"
  [options]="Options"
  (mapClick)="mapClicked($event)"
>
  <map-marker
    #marker="mapMarker"
    *ngFor="let markerPosition of markerPositionsCabinets"
    [position]="markerPosition.pos"
    [options]="markerPosition.markerOptions"
    (mapClick)="openInfoWindow(marker)"
  ></map-marker>
  <map-polyline
    *ngFor="let polyline of map.polylines"
    [path]="polyline.pos"
    [options]="polyline.options"
  ></map-polyline>
  <map-info-window></map-info-window>
</google-map> -->
  <div class="child-margin" style="position: absolute; bottom: 10px; left: 0">
    <div *ngIf="object && !object?.pos">
      <span style="padding: 10px">
        <button
          mat-raised-button
          matTooltip="Legg til skap"
          (click)="editCabinetClicked()"
        >
<!--          [color]="editCabinetColor"-->
        <mat-icon>traffic</mat-icon
        >
          <!-- <img
            src="../../../../assets/icons/electrical-panel.png"
            style="height: 24px"
            alt=""
          /> -->
          <mat-icon
            style="position: absolute; top: 0; right: 0; font-size: 18px"
            >add</mat-icon
          >
        </button></span
      >
    </div>
    <div *ngIf="user?.superAdmin">
      <span style="padding: 10px">
        <button
          mat-raised-button
          matTooltip="Legg til linje"
          (click)="editLineClicked()"
        >
<!--          [color]="editLineColor"-->
          <mat-icon>gesture</mat-icon
          ><mat-icon
            style="position: absolute; top: 0; right: 0; font-size: 18px"
            >add</mat-icon
          >
        </button></span
      >
      <span *ngIf="editLine && !routeSource && !routeDestination"
        >Sett startpunkt</span
      >
      <span *ngIf="editLine && routeSource && !routeDestination"
        >Sett målpunkt</span
      >
    </div>
    <!-- <div class="fill-space"></div> -->
  </div>
  <div class="child-margin" style="position: absolute; right: 0"
  [style.bottom]="isDesktopDevice ? '10px' : '10px'">
    <div>
      <span style="padding: 10px">
        <button mat-raised-button matTooltip="Zoom inn" (click)="zoomIn()">
          <mat-icon>zoom_in</mat-icon>
        </button></span
      >
    </div>
    <div>
      <span style="padding: 10px">
        <button mat-raised-button matTooltip="Zoom ut" (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button></span
      >
    </div>
    <div *ngIf="user?.superAdmin">
      <span style="padding: 10px">
        <button
          mat-raised-button
          matTooltip="Lagre zoom"
          (click)="updateZoomClicked()"
        >
          <mat-icon>search</mat-icon
          ><mat-icon
            style="position: absolute; top: 0; right: 0; font-size: 18px"
            >save</mat-icon
          >
        </button></span
      >
    </div>
  </div>
  <!-- <div class="fullscreen-control" style="margin-bottom: 10px">
    <span style="padding: 10px" *ngIf="isDesktopDevice">
      <button mat-raised-button matTooltip="Fullskjerm">
        <mat-icon id="fullscreen-control-icon">fullscreen</mat-icon>
      </button></span
    >
  </div> -->
  <!-- <div
    class="child-margin"
    style="position: absolute; top: 0; left: 0; right: 0; width: 100%"
  > -->
  <!-- <div class="row" *ngIf="showMapPill"> -->
  <!-- <div>
      <span style="padding: 10px">
        <button
          mat-raised-button
          matTooltip="Lagre zoom"
          (click)="updateZoomClicked()"
        >
          <mat-icon>search</mat-icon
          ><mat-icon
            style="position: absolute; top: 0; right: 0; font-size: 18px"
            >save</mat-icon
          >
        </button></span
      >
    </div> -->
  <div
    class="map-pill"
    [@inOutAnimation]
    style="
      left: 20px;
      right: 20px;
      position: absolute;
      background: #303030;
      border-radius: 5px;
    "
    *ngIf="showMapPill"
  >
    <!-- <div class="fill-space"></div> -->
    <!-- <div class="map-pill"> -->
    <div class="row" style="padding: 12px; width: 100%">
      <div><img class="youps-pill-logo" [src]="pillIcon" alt="" /></div>
      <div class="youps-pill-content">
        <div class="youps-pill-header">{{ pillHeader }}</div>
        <div class="youps-pill-label">
          {{ pillText }}
         <!-- <p>deviceInfo:{{deviceInfo | json}}</p>
         <p>isMobile:{{isMobile}}</p>
         <p>isTablet:{{isTablet}}</p>
         <p>isDesktopDevice:{{isDesktopDevice}}</p></div> -->
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="fill-space"></div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>

<!-- <div class="controls fullscreen-control">
  <button title="Toggle Fullscreen">
    <div
      class="fullscreen-control-icon fullscreen-control-top-left"
    ></div>
    <div
      class="fullscreen-control-icon fullscreen-control-top-right"
    ></div>
    <div
      class="fullscreen-control-icon fullscreen-control-bottom-left"
    ></div>
    <div
      class="fullscreen-control-icon fullscreen-control-bottom-right"
    ></div>
  </button>
</div> -->

</div>
