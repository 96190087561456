import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage, AngularFireUploadTask, } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';

import * as moment from 'moment';
import {Cell, IImg, Img, IText, PdfMakeWrapper, Stack, Table, Txt, } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts'; // fonts provided for pdfmake
import {Observable} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {Checklist} from 'src/app/models/Checklist';
import {
  AVENTI_LOGO_BASE64,
  AventiFile,
  BLOCK_GREY_BASE64,
  CANCEL_GREY_BASE64,
  CANCEL_RED_BASE64,
  CHECK_GREEN_BASE64,
} from 'src/app/models/File';
import _ from 'underscore';
import {ChecklistItem} from '../models/Checklistitem';
import {ChecklistService} from './checklist.service';
import {DateService} from './date.service';
import {SharedService} from './shared.service';
import {SnackbarService} from './snackbar.service';
import {SortService} from './sort.service';

moment.locale('nb-no');

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);
// const pdf = new PdfMakeWrapper();

// const pdfMake = require('/pdfmake/pdfmake');
// const vfsFonts = require('/pdfmake/vfs_fonts');
// pdfMake.vfs = vfsFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PrintChecklistService {

  constructor(
    // private datepipe: DatePipe,
    // private storage: AngularFireStorage,
    // private fileservice: FileService,
    // private idservice: IdService,
    // // private userservice: UserService,
    // private afs: AngularFirestore,
    // private http: HttpClient,
    private checklistservice: ChecklistService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private snackbarservice: SnackbarService,
    private sharedservice: SharedService,
    private dateservice: DateService,
    private sortservice: SortService
  ) {
    // (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  // private ngUnsubscribe = new Subject();
  // private ngUnsubscribeitems = new Subject();
  // private ngUnsubscribefiles = new Subject();

  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // downloadurl URL
  downloadurl: Observable<string>;
  downloadurlstring: string;

  newfileID: string;

  version = '0.1';

  checklist: Checklist;
  checklistitems: ChecklistItem[];
  checklistfiles: AventiFile[];

  loadingShared = [];

  // private uploadPercent: Observable<number>;
  // private downloadurl: Observable<any>;
  // private url: string;

  // convertImagetoBase64(url, callback) {
  //   const xhr = new XMLHttpRequest();
  //   xhr.onload = () => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       callback(reader.result);
  //     };
  //     reader.readAsDataURL(xhr.response);
  //   };
  //   xhr.open('GET', url);
  //   xhr.responseType = 'blob';
  //   xhr.send();
  // }

  ngOnDestroy() {
    // this.ngUnsubscribe.next(null);
    // this.ngUnsubscribe.complete();
    // this.ngUnsubscribefiles.next(null);
    // this.ngUnsubscribefiles.complete();
    // this.ngUnsubscribeitems.next(null);
    // this.ngUnsubscribeitems.complete();
  }

  showLoader(text: string, index: number = 2) {
    this.loadingShared[index] = text;
    this.sharedservice.isLoading.next([
      true,
      'Genererer PDF',
      this.loadingShared,
    ]);
  }

  sendChecklistToPrint(checklistfromcall: Checklist) {
    console.log(checklistfromcall);
    this.checklist = undefined;
    this.checklistfiles = undefined;
    this.checklistitems = undefined;
    this.downloadurl = null;
    this.task = null;
    this.downloadurlstring = null;
    this.newfileID = null;
    this.percentage = null;
    this.snapshot = null;

    this.loadingShared = [];

    const type = checklistfromcall.type ? checklistfromcall.type.name : '';
    this.loadingShared.push('Navn: ' + checklistfromcall.name, 'Type: ' + type);
    this.showLoader('Laster sjekklistepunkter og filer...');
    // this.showLoader('Laster filer...', 3);
    // this.sharedservice.isLoading.next([
    //   true,
    //   'Genererer PDF',
    //   this.loadingShared,
    // ]);

    // this.sharedservice.checklistfiles;
    this.checklistservice
      .getChecklistFiles(checklistfromcall.id)
      .pipe(take(1))
      .subscribe((files) => {
        if (files !== null) {
          // if (cfs.length > 0) {
          //   const files: AventiFile[] = [];
          //   cfs.forEach((file) => {
          //     if (file.checklistid === checklistfromcall.id) {
          //       files.push(file);
          //     }
          //   });
          this.checklistfiles = files;
          // this.showLoader('Filer lastet inn', 3);
          this.checkAndSendToPrint();
          // this.ngUnsubscribefiles.next(null);
          // this.ngUnsubscribefiles.complete();
          // }
        }
      });

    // this.sharedservice.checklistitems
    this.checklistservice
      .getAllChecklistItems() // (checklistfromcall.id, checklistfromcall.receiptform)
      .pipe(take(1))
      .subscribe((is: ChecklistItem[]) => {
        console.log(is);
        if (is?.length > 0) {
          const items: ChecklistItem[] = [];
          is.forEach((item) => {
            if (item.checklistid === checklistfromcall.id) {
              item.typename = item.type.name;
              items.push(item);
            }
          });
          console.log(items);
          const itemsbyname = _.sortBy(items, 'text');
          const checklistitems = _.sortBy(itemsbyname, 'typename');
          this.sharedservice.prepareChecklistsChecklist(checklistfromcall);
          // this.sharedservice.prepareChecklistsChecklistitems(checklistfromcall);
          checklistfromcall.generatingPDFchecklist = true;
          checklistfromcall.generatingPDFstatus = 'Sending';
          checklistfromcall.generatingPDFstatusIcon = 'hourglass_empty';
          checklistfromcall.generatingPDFstatusText = 'Til generering';
          this.checklist = checklistfromcall;
          this.checklistitems = checklistitems;
          // this.showLoader('Sjekklistepunkter lastet inn');
          this.checkAndSendToPrint();
          // this.ngUnsubscribeitems.next(null);
          // this.ngUnsubscribeitems.complete();
        }
      });

    // this.checklistservice
    //   .getChecklist(checklistfromcall)
    //   .pipe(take(1))
    //   .subscribe((c) => {
    //     // checklists.forEach((c) => {
    //     //   if (c.id === checklist.id) {
    //     // const checklist = c;
    //     const checklist = c;
    //     console.log(checklist);
    //     this.sharedservice.prepareChecklistsChecklist(checklist);
    //     this.sharedservice.prepareChecklistsChecklistitems(checklist);
    //     checklist.generatingPDFchecklist = true;
    //     checklist.generatingPDFstatus = 'Sending';
    //     checklist.generatingPDFstatusIcon = 'hourglass_empty';
    //     checklist.generatingPDFstatusText = 'Til generering';
    //     this.pdf_with_wrapper(checklist)
    //       .then(() => {})
    //       .catch((err) => {
    //         console.log(err);
    //         this.snackbarservice.openSnackBar(
    //           'Feil under generering av PDF: ' + err,
    //           ''
    //         );
    //       });
    //     //   }
    //     // });
    //   });
  }

  checkAndSendToPrint() {
    this.showLoader('Filer og sjekklistepunkter lastet inn');
    this.showLoader('Sjekker...', 3);
    if (this.checklist && this.checklistitems && this.checklistfiles) {
      console.log(this.checklist, this.checklistitems, this.checklistfiles);
      this.checklistfiles.forEach((file) => {
        file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
          file.timestamp
        );
        if (!file.checklistitemid) {
          if (!this.checklist.files) {
            this.checklist.files = [];
          }
          this.checklist.files.push(file);
          this.checklist.files = this.checklist.files.sort(
            this.sortservice.dynamicSort('timestampstring')
          );
        } else {
          this.checklistitems.forEach((clitem) => {
            if (clitem.id === file.checklistitemid) {
              if (!clitem.files) {
                clitem.files = [];
              }
              clitem.files.push(file);
              clitem.files = clitem.files.sort(
                this.sortservice.dynamicSort('timestampstring')
              );
            }
          });
        }
      });

      this.checklist.checklistitems = this.checklistitems;
      this.pdf_with_wrapper(this.checklist)
        .then(() => {})
        .catch((err) => {
          console.log(err);
          this.sharedservice.isLoading.next([false, '', ['']]);
          this.snackbarservice.openSnackBar(
            'Feil under generering av PDF: ' + err,
            ''
          );
        });
    }
  }

  generatePdfResetError(checklist: Checklist) {
    this.checklistservice.updateChecklistGeneratingPDF(false, checklist);
  }

  generatePdfWithImages(checklist: Checklist) {
    // let body = {
    //   checklistid: checklist.id,
    //   objectid: checklist.objectid,
    //   projectid: checklist.projectid,
    // };
    // // let body = {checklist: checklist};
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   // expressApiKey: cloudfunctionsExpressApiKey,
    // });
    // let options = { headers: headers };
    checklist.files.forEach((file) => {
      if (file.base64) {
        file.base64 = '';
      }
    });

    checklist.checklistitems.forEach((checklistitem) => {
      checklistitem.files.forEach((file) => {
        if (file.base64) {
          file.base64 = '';
        }
      });
    });
    // console.log(checklist);
    this.checklistservice.updateChecklistGeneratingPDF(true, checklist);
  }

  async pdf_with_wrapper(checklist: Checklist) {
    // this.checklistservice
    //   .getChecklistComplete(checklistfromcaller.id, checklistfromcaller.objectid, checklistfromcaller.projectid )
    //   .pipe(take(1))
    //   .subscribe(async (checklist: Checklist) => {

    checklist.checklistitems.forEach((item) => {
      item.typename = item.type.name;
    });
    checklist.checklistitems = _.sortBy(checklist.checklistitems, 'typename');
    const pdf = new PdfMakeWrapper();

    console.log('Starter');
    this.showLoader('Starter generering...', 3);
    pdf.compress(true);

    // function toDateTime(secs: number) {
    //   const t = new Date(1970, 0, 1); // Epoch
    //   t.setSeconds(secs);
    //   return t;
    // }

    function addLine() {
      pdf.add(
        new Table([
          [new Cell(new Txt('').end).border([false, false, false, true]).end],
        ]).widths(['*']).end
      );
    }

    // async function imageToDataUri(imgtod: any, width: number, height: number) {
    //   return new Promise<string>(async function (resolve, reject) {
    //     // create an off-screen canvas
    //     const canvas = document.createElement('canvas'),
    //       ctx = canvas.getContext('2d');
    //     // const width = 100;
    //     // const height = 100;
    //     // set its dimension to target size
    //     canvas.width = width;
    //     canvas.height = height;

    //     // draw source image into the off-screen canvas:
    //     if (ctx) {
    //       ctx.drawImage(imgtod, 0, 0, width, height);
    //     }

    //     // encode image to data-uri with base64 version of compressed image
    //     const dataURI = canvas.toDataURL();

    //     // This is the return of the Promise
    //     resolve(dataURI);
    //     // return canvas.toDataURL();
    //   });
    // }
    // const img = document.createElement('img');
    // img.crossOrigin = 'anonymous';
    // // When the event "onload" is triggered we can resize the image.
    // img.onload = async function () {
    //   const maxWidth = 50;
    //   const maxHeight = 50;
    //   let height = img.height;
    //   let width = img.width;
    //   if (img.height > maxHeight || img.width > maxWidth) {
    //     const aspectratio = img.width / img.height;
    //     height = maxHeight;
    //     width = img.width * (height / img.height);
    //     if (width > maxWidth) {
    //       width = maxWidth;
    //       height = width / aspectratio;
    //     }
    //   }
    //   await imageToDataUri(img, width, height)
    //     .then((b64) => {
    //       // file.base64 = b64;
    //       console.log('b64: ', img.width, img.height, width, height);
    //       // file.base64 = b64;
    //       console.log('File converted to base64 and uploaded to DB',);
    //     })
    //     .catch((err) => {
    //       console.log('Error: ', err.message);
    //     });
    // };
    // img.src = file.downloadurl;

    const getIImg = async (url: string): Promise<IImg> => {
      const data = await new Img(url)
        .fit([150, 100])
        .margin([0, 5, 5, 0])
        .alignment('justify')
        .build();
      return data;
    };
    const getTxt = async (localText: string): Promise<IText> => {
      return new Txt(localText ? localText : '').style('medium').end;
    };

    async function getitemimages(files: AventiFile[]) {
      const stackItems: any[] = [];
      if (files) {
        let i = 0;
        // let row = 0;
        const table = [];
        let tablerow = [];
        const emptyTableCell = new Cell(new Txt('').style('medium').end)
          .border([false, false, false, false])
          // .border([true, true, true, true])
          .alignment('right').end;
        for (const file of files) {
          const filetypesImage = ['.jpg', '.jpeg', '.png'];
          const fileextention = file.name.substring(
            file.name.lastIndexOf('.'),
            file.name.length
          );
          if (filetypesImage.indexOf(fileextention.toLowerCase()) > -1) {
            i++;
            // console.log(file);
            // console.log(
            //   file.downloadurl200
            //     ? 'file.downloadurl200'
            //     : file.downloadurl500
            //     ? 'file.downloadurl500'
            //     : 'file.downloadurl'
            // );
            const img = await getIImg(
              file.downloadurl200
                ? file.downloadurl200
                : file.downloadurl500
                ? file.downloadurl500
                : file.downloadurl
            );
            // stackItems.push(img);

            tablerow.push(
              new Cell(
                new Stack([
                  img,
                  file.comment,
                  // // [
                  //   new Cell(img)
                  //     .border([false, false, false, false])
                  //     // .border([true, true, true, true])
                  //     .margin([0, 0, 0, 0]).end,
                  // // ],
                  // // [
                  //   new Cell(deviationcomment)
                  //     .border([false, false, false, false])
                  //     // .border([true, true, true, true])
                  //     .margin([0, 0, 0, 0]).end,
                  // // ],
                ]).end
              )
                .border([false, false, false, false])
                // .border([true, true, true, true])
                .margin([0, 5, 0, 0]).end
            );
            if (i === 3) {
              i = 0;
              table.push([tablerow[0], tablerow[1], tablerow[2]]);
              // row++;
              tablerow = [];
            }
          } else {
            const txt = await getTxt('Vedlegg: ' + file.name);
            stackItems.push(txt);
          }
          // if (file.comment) {
          //   const txt = await getTxt('Filkommentar: ' + file.comment);
          //   stackItems.push(txt);
          // }
        }
        if (tablerow.length === 1) {
          tablerow.push(emptyTableCell);
        }
        if (tablerow.length === 2) {
          tablerow.push(emptyTableCell);
        }
        if (tablerow.length === 3) {
          table.push([tablerow[0], tablerow[1], tablerow[2]]);
        }
        if (table.length > 0) {
          stackItems.push(
            new Table(table)
              .widths(['*', '*', '*'])
              .dontBreakRows(true)
              .margin([0, 0, 0, 10]).end
          );
        }
        // pdf.add(
        //   new Table(table).widths(['*', '*', '*']).margin([0, 0, 0, 10]).end
        // );
      }
      return Promise.resolve(new Stack(stackItems).end);
    }

    const getStatusIImg = async (
      statusIcon: string,
      color: string
    ): Promise<IImg> => {
      const data = await new Img(statusIcon)
        .fit([20, 20])
        .color(color)
        .margin([0, 0, 0, 0])
        .alignment('right')
        .build();
      return data;
    };
    async function statusIconImg(statusIcon: string, color: string) {
      if (statusIcon) {
        const img = await getStatusIImg(statusIcon, color);
        return img;
      } else {
        const txt = await getTxt('');
        return txt;
      }
    }

    pdf.styles({
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 5, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 10],
      },
      large: {
        fontSize: 12,
      },
      medium: {
        fontSize: 10,
      },
      small: {
        fontSize: 8,
      },
    });

    const pnr = checklist.profilenumber ? checklist.profilenumber : null;
    const desc = checklist.desc ? checklist.desc : null;
    const comment = checklist.comment ? checklist.comment : null;

    const tags = [];
    let text = '';
    if (checklist.receiptform) {
      text = 'Mottak';
    } else {
      text = 'KS ' + checklist.type.name;
    }
    tags.push([
      // .border([false, false, false, false])
      new Cell(new Txt(text).style('header').end).border([false]).end,
      // .border([false, false, false, false])
      new Cell(
        await new Img(AVENTI_LOGO_BASE64)
          .fit([150, 150])
          .alignment('right')
          .build()
      ).border([false]).end,
    ]);
    tags.push([
      // .border([false, false, false, true])
      new Cell(new Txt('Navn: ' + checklist.name).style('large').end)
        .colSpan(2)
        .border([false, false, false, false]).end,
    ]);
    if (comment !== null) {
      tags.push([
        // .border([false, false, false, true])
        new Cell(new Txt('Kommentar: ' + comment).style('large').end)
          .colSpan(2)
          .border([false, false, false, false]).end,
      ]);
    }
    if (desc !== null) {
      tags.push([
        // .border([false, false, false, true])
        new Cell(new Txt('Beskrivelse: ' + desc).style('large').end)
          .colSpan(2)
          .border([false, false, false, false]).end,
      ]);
    }
    if (pnr !== null) {
      tags.push([
        // .border([false, false, false, true])
        new Cell(new Txt('Pnr: ' + pnr).style('large').end)
          .colSpan(2)
          .border([false, false, false, false]).end,
      ]);
    }
    checklist.tags?.forEach((tag) => {
      tags.push([
        new Cell(new Txt(tag.key + ': ' + tag.value).style('large').end)
          .colSpan(2)
          .border([false, false, false, false]).end,
      ]);
    });
    console.log(tags);

    pdf.add(
      new Table(
        // [
        // [
        //   // .border([false, false, false, false])
        //   new Cell(
        //     new Txt('KS ' + checklist.type.name).style('header').end
        //   ).border([false]).end,
        //   // .border([false, false, false, false])
        //   new Cell(
        //     await new Img(aventilogo_base64)
        //       .fit([150, 150])
        //       .alignment('right')
        //       .build()
        //   ).border([false]).end,
        // ],
        // [
        //   // .border([false, false, false, true])
        //   new Cell(
        //     new Txt(
        //       'Navn: ' +
        //         checklist.name +
        //         '\nBeskrivelse: ' +
        //         desc +
        //         '\nPnr: ' +
        //         pnr
        //     ).style('large').end
        //   )
        //     .colSpan(2)
        //     .border([false, false, false, false]).end,
        // ],
        tags
        // ]
      ).widths(['*', 'auto']).end
    );

    console.log('Legger til sjekklistepunkt');
    this.showLoader('Legger til sjekklistepunkt...', 3);

    addLine();

    if (checklist.checklistitems.length > 0) {
      pdf.add(new Txt('Sjekkpunkter').style('subheader').end);

      // checklist.checklistitems.forEach(async (item) => {
      for (const item of checklist.checklistitems) {
        let statusIcon: string;
        let color = 'black';
        if (item.type.name === 'Avkrysning') {
          switch (item.status) {
            case 'ok':
              color = 'green';
              statusIcon = CHECK_GREEN_BASE64;
              break;
            case 'deviation':
              color = item.deviationSignedBy ? 'gray' : 'red';
              statusIcon = item.deviationSignedBy
                ? CANCEL_GREY_BASE64
                : CANCEL_RED_BASE64;
              break;
            case 'not_applicable':
              color = 'gray';
              statusIcon = BLOCK_GREY_BASE64;
              if (!item.comment) {
                item.comment = 'Ikke aktuelt';
              }
              break;
          }
        }

        let localComment = '';
        let value = '';
        switch (item.type.name) {
          case 'Avkrysning':
            if (item.comment) {
              localComment = 'Kommentar: ' + item.comment;
            }
            break;
          case 'Kommentar':
            if (item.comment) {
              localComment = 'Kommentar: ' + item.comment;
            }
            break;
          case 'Ekstern dokumentasjon':
            break;
          case 'Måling':
            value = 'Verdi: ' + item.value;
            if (item.valuecomment) {
              localComment = 'Sted: ' + item.valuecomment;
            }
            break;
        }

        let localSignedByUser = '';
        let localSignedByTimestamp = '';
        if (item.signedBy && item.signedBy.timestamp && item.signedBy.user) {
          localSignedByUser = item.signedBy.user.initials;
          localSignedByTimestamp = this.dateservice.dateAsDDMMYYY(
            item.signedBy.timestamp
          );
        }

        const deviationSignedByUser = '';
        const deviationSignedByTimestamp = '';
        if (
          item.deviationSignedBy &&
          item.deviationSignedBy.timestamp &&
          item.deviationSignedBy.user
        ) {
          localSignedByUser = item.deviationSignedBy.user.initials;
          localSignedByTimestamp = this.dateservice.dateAsDDMMYYY(
            item.deviationSignedBy.timestamp
          );
        }
        const isDeviationSigned = item.deviationSignedBy ? true : false;

        const itemfiles: AventiFile[] = [];
        const deviationfiles: AventiFile[] = [];
        if (item.files?.length > 0) {
          for (const file of item.files) {
            if (file.isdeviationsign) {
              deviationfiles.push(file);
            } else {
              itemfiles.push(file);
            }
          }
        }

        const stackItemFiles: any = await getitemimages(itemfiles);
        const stackDeviationFiles: any = await getitemimages(deviationfiles);

        const checklistitemTable: any = [];
        // pdf.add(
        //   new Table([
        checklistitemTable.push([
          new Cell(new Txt(item.text).style('large').end)
            .border([true, true, false, false])
            .margin([0, 3, 0, 0]).end,
          new Cell(new Txt('Type: ' + item.type.name).style('medium').end)
            .border([false, true, false, false])
            .margin([0, 3, 0, 0])
            .alignment('right').end,
          new Cell(await statusIconImg(statusIcon, color)).border([
            false,
            true,
            true,
            false,
          ]).end,
        ]);
        checklistitemTable.push([
          new Cell(new Txt(localComment).style('medium').end).border([
            true,
            false,
            false,
            false,
          ]).end,
          new Cell(new Txt(value).style('medium').end)
            .border([false, false, false, false])
            .alignment('right').end,
          new Cell(new Txt('').style('medium').end)
            .border([false, false, true, false])
            .alignment('right').end,
        ]);
        checklistitemTable.push([
          new Cell(stackItemFiles).colSpan(3).border([true, false, true, false])
            .end,
        ]);
        checklistitemTable.push([
          new Cell(
            new Txt(localSignedByUser + ' - ' + localSignedByTimestamp)
              .style('small')
              .margin([0, 0, 0, 3]).end
          )
            .colSpan(3)
            .border([true, false, true, isDeviationSigned ? false : true]).end,
        ]);

        //   ])
        //     .widths(['*', 'auto', 20])
        //     .margin([0, 0, 0, 10]).end
        // );

        if (item.deviationSignedBy) {
          checklistitemTable.push([
            new Cell(new Txt('Avvik sjekket ut').style('large').end)
              .border([true, false, false, false])
              .margin([0, 3, 0, 0]).end,
            new Cell(new Txt('').style('medium').end)
              .border([false, false, false, false])
              .alignment('right').end,
            new Cell(await statusIconImg(CHECK_GREEN_BASE64, 'green')).border([
              false,
              false,
              true,
              false,
            ]).end,
          ]);
          checklistitemTable.push([
            new Cell(
              new Txt(
                item.deviationcomment?.length > 0
                  ? 'Kommentar for utsjekk: ' + item.deviationcomment
                  : ''
              ).style('medium').end
            ).border([true, false, false, false]).end,
            new Cell(new Txt('').style('medium').end)
              .border([false, false, false, false])
              .alignment('right').end,
            new Cell(new Txt('').style('medium').end)
              .border([false, false, true, false])
              .alignment('right').end,
          ]);
          checklistitemTable.push([
            new Cell(stackDeviationFiles)
              .colSpan(3)
              .border([true, false, true, false]).end,
          ]);
          checklistitemTable.push([
            new Cell(
              new Txt(
                deviationSignedByUser + ' - ' + deviationSignedByTimestamp
              )
                .style('small')
                .margin([0, 0, 0, 3]).end
            )
              .colSpan(3)
              .border([true, false, true, true]).end,
          ]);
        }
        pdf.add(
          new Table(checklistitemTable)
            .widths(['*', 'auto', 20])
            .margin([0, 0, 0, 10]).end
        );
      }

      addLine();
    }

    pdf.add(new Txt('Bilder').style('subheader').end);

    console.log('Legger til bilder');
    this.showLoader('Legger til bilder...', 3);
    const stackChecklist: any = await getitemimages(checklist.files);

    pdf.add(stackChecklist);

    console.log('Signerer');
    this.showLoader('Signerer...', 3);
    let signedByUser = '';
    let signedByTimestamp = '';
    if (
      checklist.signedBy &&
      checklist.signedBy.timestamp &&
      checklist.signedBy.user
    ) {
      signedByUser =
        checklist.signedBy.user.firstname +
        ' ' +
        checklist.signedBy.user.lastname;
      signedByTimestamp = this.dateservice.dateAsDDMMYYY(
        checklist.signedBy.timestamp
      );
    }

    addLine();

    pdf.add(new Txt('Signatur').style('subheader').end);

    pdf.add(
      new Table([
        [
          new Cell(new Txt('Bruker: ').style('large').end).border([false]).end,
          new Cell(new Txt(signedByUser).style('large').end).border([false])
            .end,
        ],
        [
          new Cell(new Txt('Dato: ').style('large').end).border([false]).end,
          new Cell(new Txt(signedByTimestamp).style('large').end).border([
            false,
          ]).end,
        ],
      ]).widths(['auto', '*']).end
    );
    // pdf.create().download();

    console.log('Ready to write PDF');

    // const docRef = db.collection('_').doc();
    // const newfileID = docRef.id;

    let checklistPath =
      'projects/' +
      checklist.projectid +
      '/objects/' +
      checklist.objectid +
      '/checklists/' +
      checklist.id;
    if (checklist.receiptform) {
      checklistPath =
        'projects/' + checklist.projectid + '/receiptforms/' + checklist.id;
    }
    console.log(checklistPath);

    const numberOfDeviations = checklist.numberOfDeviations?.toString();
    const numberOfDeviationsSigned =
      checklist.numberOfDeviationsSigned?.toString();
    let fileSuffix = '';
    if (checklist.numberOfDeviations) {
      if (
        checklist.numberOfDeviations - checklist.numberOfDeviationsSigned !== 0
      ) {
        fileSuffix = '_avvik';
      }
    }
    const checklistcomment = checklist.comment ? '_' + checklist.comment : '';

    const folderName = '/pdfexports/';
    const filePath = checklistPath + folderName; // + newfileID;
    let fileNamePrefix = '';
    if (!checklist.receiptform) {
      fileNamePrefix = 'KS_' + checklist.type.name + '_';
    }
    let fileName =
      fileNamePrefix + checklist.name + checklistcomment + fileSuffix;
    fileName +=
      checklist.pdfexports?.length > 0
        ? '_' + checklist.pdfexports.length.toString() + '.pdf'
        : '.pdf';
    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-');
    fileName = fileName.replace(/\r\n/g, '');
    const filePathName = filePath + '/' + fileName;

    console.log('Creating Pdf element');
    this.showLoader('Skriver til fil...', 3);

    const pdfDocGenerator = pdf.create();

    await pdfDocGenerator.getBlob((blob) => {
      const customMetadata = {
        app: 'APG',
        contentType: 'application/pdf',
        numberOfDeviations,
        numberOfDeviationsSigned,
        checklistcomment: checklist.comment ? checklist.comment : '',
      };

      const ref = this.storage.ref(filePathName);

      this.task = this.storage.upload(filePathName, blob, {
        customMetadata,
      });

      // Progress monitoring
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges();

      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            if (ref.child(filePathName)) {
              ref
                .getDownloadURL()
                .pipe(take(1))
                .subscribe((url) => {
                  console.log('...waiting for file...', url);
                  this.downloadurlstring = url;
                  this.newfileID = this.afs.createId();
                  const firestorePath = checklistPath + '/pdfexports/';
                  this.afs
                    .doc(firestorePath + this.newfileID)
                    .set({
                      id: this.newfileID,
                      projectid: checklist.projectid,
                      objectid: checklist.objectid || '',
                      checklistid: checklist.id,
                      path: firestorePath + this.newfileID,
                      storagepath: firestorePath,
                      storagename: fileName,
                      downloadurl: this.downloadurlstring,
                      timestamp: firebase.firestore.Timestamp.now(),
                      name: fileName,
                      type: 'application/pdf',
                      lastupdated: firebase.firestore.Timestamp.now(),
                      numberOfDeviations: checklist.numberOfDeviations
                        ? checklist.numberOfDeviations
                        : null,
                      numberOfDeviationsSigned:
                        checklist.numberOfDeviationsSigned
                          ? checklist.numberOfDeviationsSigned
                          : null,
                      checklistcomment,
                    })
                    .then(() => {
                      this.afs
                        .doc(
                          checklistPath
                          // `projects/${checklist.projectid}/objects/${checklist.objectid}/checklists/${checklist.id}`
                        )
                        .update({
                          // pdfexports: firebase.firestore.FieldValue.delete(),
                          pdfexports:
                            firebase.firestore.FieldValue.arrayUnion(
                              this.newfileID
                            ),
                        })
                        .then(() => {
                          console.log('Uploaded to Firestore');
                          this.snackbarservice.openSnackBar(
                            'PDF Generert for ' + checklist.name,
                            ''
                          );
                          this.sharedservice.isLoading.next([
                            false,
                            '',
                            [''],
                          ]);
                        })
                        .catch((err) => {
                          console.log('Error uploading to Firestore: ', err);
                          this.sharedservice.isLoading.next([
                            false,
                            '',
                            [''],
                          ]);
                        });
                    })
                    .catch((err) => {
                      console.log('Error uploading to Firestore: ', err);
                      this.sharedservice.isLoading.next([false, '', ['']]);
                    });
                });
              // return Promise.resolve('PDF Generated uccessfully!');
            }
          })
        )
        .subscribe((p) => {
          if (p.bytesTransferred === p.totalBytes) {
            console.log('PDF lastet opp');
            // this.sharedservice.isLoading.next([false, '', ['']]);
          }
        });
    });
  }
}
