import {Component, Input} from '@angular/core';
import {Checklist} from 'src/app/models/Checklist';
import {AventiFile} from 'src/app/models/File';
import {PopupService} from 'src/app/services/popup.service';
import {PrintChecklistService} from 'src/app/services/print-checklist.service';
import {Params} from '@angular/router';

@Component({
  selector: 'app-pdf-button',
  templateUrl: './pdf-button.component.html',
  styleUrls: ['./pdf-button.component.css']
})
export class PdfButtonComponent {

  @Input() checklist: Checklist;
  @Input() params: Params;

  constructor(
    private popupservice: PopupService,
    private printchecklistservice: PrintChecklistService,
    ) {
  }

  openDialogPdfPreview(file: AventiFile, checklist: Checklist) {
    this.popupservice.openDialogFilePreview(
      [file],
      0,
      false,
      checklist,
      this.params
    );
  }

  generatePDF(checklist: Checklist) {
    this.printchecklistservice.sendChecklistToPrint(checklist);
  }

}
