import {Component, OnInit} from '@angular/core';
import {ChecklistItemsLibraryItem, ChecklistItemType} from 'src/app/models/Checklistitem';
import {environment} from 'src/environments/environment';
import {MediaMatcher} from '@angular/cdk/layout';
import {AdminService} from 'src/app/services/admin.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {ConfirmComponent} from 'src/app/components/common/bottom-sheet/confirm/confirm.component';
import {IdService} from 'src/app/services/id.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ObjectStatus} from 'src/app/models/Status';

@Component({
  selector: 'app-checklistitem',
  templateUrl: './checklistitem.component.html',
  styleUrls: ['./checklistitem.component.css'],
})
export class ChecklistitemComponent implements OnInit {
  FormText = new UntypedFormGroup({
    text: new UntypedFormControl('', [Validators.required]),
  });
  FormEnText = new UntypedFormGroup({
    enText: new UntypedFormControl('', [Validators.required]),
  });
  FormUnit = new UntypedFormGroup({
    unit: new UntypedFormControl(''),
  });
  error: string;
  isLoading = true;
  libchecklistitemid: string;
  checklistitem: ChecklistItemsLibraryItem;
  checklistitemtypes: ChecklistItemType[];
  objectStatusLevels: ObjectStatus[];
  edittext = false;
  editEnText = false;
  edittype = false;
  editStatusLevel = false;
  editunit = false;
  mobileQuery: MediaQueryList;
  private ngUnsubscribe = new Subject();

  constructor(
    private adminservice: AdminService,
    media: MediaMatcher,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private idservice: IdService,
    private snackbarservice: SnackbarService
  ) {
    adminservice.getChecklistitem().pipe(takeUntil(this.ngUnsubscribe)).subscribe((checklistitem) => {
      this.checklistitem = checklistitem;
      this.checkIfLoadingComplete();
    });
    adminservice.getChecklistitemtypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((checklistitemtypes) => {
      this.checklistitemtypes = checklistitemtypes;
      this.checkIfLoadingComplete();
    });
    adminservice.getObjectStatuses().pipe(takeUntil(this.ngUnsubscribe)).subscribe((objectStatuses) => {
      this.objectStatusLevels = objectStatuses;
      this.checkIfLoadingComplete();
    });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  checkIfLoadingComplete() {
    if (this.checklistitem && this.checklistitemtypes && this.objectStatusLevels) {
      this.isLoading = false;
    }
  }

  get text() {
    return this.FormText.get('text');
  }

  get enText() {
    return this.FormEnText.get('enText');
  }

  get unit() {
    return this.FormUnit.get('unit');
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  toggleEditText() {
    this.edittext = !this.edittext;
    this.FormText.patchValue({
      text: this.checklistitem.text,
    });
  }

  toggleEditEnText() {
    this.editEnText = !this.editEnText;
    this.FormEnText.patchValue({
      enText: this.checklistitem.enText,
    });
  }

  toggleEditType() {
    this.edittype = !this.edittype;
  }

  toggleEditStatusLevel() {
    this.editStatusLevel = !this.editStatusLevel;
  }

  toggleEditUnit() {
    this.editunit = !this.editunit;
    this.FormUnit.patchValue({
      unit: this.checklistitem.unit,
    });
  }

  onTypeSelectionChange(type: ChecklistItemType) {
    this.checklistitem.type = type;
    this.adminservice.updateChecklistitem(this.checklistitem);
    this.edittype = false;
  }

  onStatusLevelSelectionChange(objectStatus: ObjectStatus) {
    const newObjectStatus = this.objectStatusLevels.find(status => status.id === objectStatus.id);
    this.checklistitem.statusLevel = {
      id: newObjectStatus.id,
      name: newObjectStatus.name,
      enName: newObjectStatus.enName,
      priority: newObjectStatus.priority
    };
    console.log(this.checklistitem);
    this.adminservice.updateChecklistitem(this.checklistitem);
    this.editStatusLevel = false;
  }

  saveText(text: string) {
    this.checklistitem.text = text;
    this.adminservice.updateChecklistitem(this.checklistitem);
    this.edittext = false;
  }

  saveEnText(enText: string) {
    this.checklistitem.enText = enText;
    this.adminservice.updateChecklistitem(this.checklistitem);
    this.editEnText = false;
  }

  saveUnit(unit: string) {
    this.checklistitem.unit = unit;
    this.adminservice.updateChecklistitem(this.checklistitem);
    this.editunit = false;
  }

  deleteChecklistitemConfirm(): void {
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'Er du sikker?',
        text: 'Slette sjekklistepunkt: ' + this.checklistitem.text,
        yes: {
          name: 'Ja',
          icon: 'delete',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet.afterDismissed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((confirm) => {
      if (confirm) {
        this.adminservice
          .deleteChecklistitem()
          .then(() => {
            console.log('Checklistitem deleted...');
            this.router.navigateByUrl(
              // 'p/' +
              //   this.idservice.projectId() +
              'a/objekttyper/' +
              this.idservice.objecttypeId()
            );
            this.snackbarservice.openSnackBar('Sjekklistepunkt slettet...', '');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }
}
