<app-spinner
  disable="false"
  [text]="['Laster inn avvik...']"
  header="Vennligst vent..."
  *ngIf="!deviationsReady"
></app-spinner>

<div class="row">
  <app-headertext [hideback]="mobileQuery.matches" text="Avvik"></app-headertext>
  <div class="fill-space"></div>
  <button
    *ngIf="user.role?.name === 'admin' && mobileQuery.matches"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span *ngIf="!mobileQuery.matches">Admin</span>
  </button>
  <button
    *ngIf="user.role?.name === 'admin' && !mobileQuery.matches"
    mat-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span *ngIf="!mobileQuery.matches">Admin</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="downloadDeviationListToExcel()" *ngIf="user.superAdmin">
      <mat-icon>save_alt</mat-icon>
      Exporter til Excel
    </button>
  </mat-menu>
</div>

<div style="margin: 0 15px 0 15px">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoading || !deviationsReady"
  ></mat-progress-bar>
  <!-- <div *ngIf="objects && !isLoading"> -->
  <div>
    <app-empty-list
      *ngIf="isEmpty && !isLoading && deviationsReady"
    ></app-empty-list>
    <div>
      <!-- <div class="row" *ngIf="!isEmpty">
        <div
          style="
            margin-left: 5px;
            margin-right: 15px;
            margin-top: 15px;
            font-size: 13px;
            color: darkgray;
          "
        >
          Side {{ page }} <span *ngIf="pages">av {{ pages }}</span>
        </div>
        <div class="fill-space"></div>
        <div *ngIf="checklists">
          <form [formGroup]="FormFilter">
            <mat-form-field style="width: 200px">
              <mat-label>Filter</mat-label>
              <input
                cdkFocusInitial
                id="filter"
                formControlName="filter"
                matInput
                (keyup)="applyFilter($event)"
                placeholder="f.eks. 'Ferdig'"
              />
            </mat-form-field>
          </form>
        </div>
        <div
          (click)="setFilter('')"
          class="cursor"
          style="
            margin-left: 5px;
            margin-top: 15px;
            font-size: 20px;
            color: darkgray;
          "
          *ngIf="filter && filter.length > 0"
        >
          <mat-icon inline="true">cancel</mat-icon>
        </div>
      </div> -->
      <div>
        <table
          [hidden]="isEmpty"
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="setSorting($event)"
        >
          <ng-container matColumnDef="filter" sticky>
            <th
              mat-header-cell
              *matHeaderCellDef
              [colSpan]="displayedColumns.length"
            >
              <div class="row" *ngIf="!isEmpty">
                <div
                  style="
                    margin-right: 15px;
                    margin-top: 15px;
                    font-size: 12px;
                    color: darkgray;
                  "
                >
                  Side {{ page }} <span *ngIf="pages">av {{ pages }}</span>
                </div>
                <!-- <button
                  mat-button
                  style="font-size: 12px; color: darkgray"
                  *ngIf="type === 'receiptform'"
                  (click)="toggleObjectVisibility()"
                >
                  <mat-icon>{{
                    objectsVisible ? "visibility" : "visibility_off"
                  }}</mat-icon
                  ><span
                    *ngIf="!mobileQuery.matches"
                    class="button-icon-text"
                    >{{
                      objectsVisible ? "Skujl objekter" : "Vis objekter"
                    }}</span
                  >
                </button> -->
                <div class="fill-space"></div>
                <div *ngIf="checklistsFrontend">
                  <form [formGroup]="FormFilter">
                    <mat-form-field style="width: 100px">
                      <mat-label>Filter</mat-label>
                      <input
                        cdkFocusInitial
                        id="filter"
                        formControlName="filter"
                        matInput
                        (keyup)="applyFilter($event)"
                        placeholder="f.eks. 'Skilt'"
                      />
                    </mat-form-field>
                  </form>
                </div>
                <div
                  (click)="setFilter('')"
                  class="cursor"
                  style="
                    margin-left: 5px;
                    margin-top: 15px;
                    font-size: 20px;
                    color: darkgray;
                  "
                  *ngIf="filter && filter.length > 0"
                >
                  <mat-icon inline="true">cancel</mat-icon>
                </div>
              </div>
            </th>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              {{ row.id }}
            </td>
          </ng-container>

          <!-- Mobile Column -->
          <ng-container matColumnDef="mobileName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Sjekkliste
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <p style="font-size: smaller" *ngIf="row.process">
                <b>Prosess: </b>{{ row.process }}
              </p>
              <p style="font-size: smaller" *ngIf="row.type">
                <b>Type: </b>{{ row.type.name }}
              </p>
              <p style="font-size: smaller" *ngIf="row.name">
                <b>Navn: </b>{{ row.name }}
              </p>
              <p style="font-size: smaller" *ngIf="row.desc">
                <b>Beskrivelse: </b>{{ row.desc }}
              </p>
              <p style="font-size: smaller" *ngIf="row.profilenumber">
                <b>Pnr: </b>{{ row.profilenumber }}
              </p>
              <p style="font-size: smaller" *ngIf="row.comment">
                <b>Kommentar: </b>{{ row.comment }}
              </p>
              <p *ngIf="!row.scheduledfordelete" [style.color]="row.color">
                {{ row.statusHuman }}
              </p>
              <p *ngIf="row.scheduledfordelete">Slettes...</p>
              <p
                style="font-size: smaller"
                *ngIf="row.numberOfDeviations && row.numberOfDeviations > 0"
              >
                Avvik: {{ row.numberOfDeviations }}
              </p>
              <div *ngIf="row.tags">
                <table style="font-size: smaller; text-align: left">
                  <tr *ngFor="let t of row.tags">
                    <td>
                      <b>{{ t.key }}:</b>
                    </td>
                    <td style="text-align: right">{{ t.value }}</td>
                  </tr>
                </table>
              </div>
            </td>
          </ng-container>

          <!-- Mobile Column -->
          <ng-container matColumnDef="mobileStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <p *ngIf="!row.scheduledfordelete" [style.color]="row.color">
                {{ row.statusHuman }}
              </p>
              <p *ngIf="row.scheduledfordelete">Slettes...</p>
              <p
                style="font-size: smaller"
                *ngIf="row.numberOfDeviations && row.numberOfDeviations > 0"
              >
                Avvik: {{ row.numberOfDeviations }}
              </p>
            </td>
          </ng-container>

          <!-- Text Column -->
          <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tekst</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              {{ row.text }}
            </td>
          </ng-container>

          <!-- comment Column -->
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kommentar</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              {{ row.comment }}
            </td>
          </ng-container>

          <!-- files Column -->
          <ng-container matColumnDef="files">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Filer</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <app-thumbnail
                [file]="file"
                [files]="row.files"
                [isLocked]="true"
                *ngFor="let file of row.files | slice:0:3"
                [sanitize]="false"
                size="40px"
              ></app-thumbnail>
            </td>
          </ng-container>

          <!-- Tags Column -->
          <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tags</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <table style="font-size: smaller; text-align: left">
                <tr *ngFor="let t of row.tags">
                  <td>
                    <b>{{ t.key }}:</b>
                  </td>
                  <td style="text-align: right">{{ t.value }}</td>
                </tr>
              </table>
            </td>
          </ng-container>

          <!-- Beskrivelse Column -->
          <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Beskrivelse
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              {{ row.desc }}
            </td>
          </ng-container>

          <!-- objects Column -->
          <!-- <ng-container matColumnDef="objects">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Objekter</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <div *ngIf="row.objects">
                <div>
                  {{ row.objects.length }}
                  {{ row.objects.length === 1 ? "objekt" : "objekter" }}
                </div>
                <div *ngIf="objectsVisible">
                  <p
                    *ngFor="let object of row.objects"
                    style="font-size: smaller; margin-left: 5px"
                  >
                    {{ object.type?.name }} - {{ object.name }}
                  </p>
                </div>
              </div>
            </td>
          </ng-container> -->

          <!-- Process Column -->
          <ng-container matColumnDef="process">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Prosess</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              {{ row.process }}
            </td>
          </ng-container>

          <!-- Checklist Column -->
          <ng-container matColumnDef="checklist">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Sjekkliste
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <!-- <table>
                <tr>
                  <td> -->
                    <p style="font-size: smaller" *ngIf="row.process">
                      <b>Prosess: </b>{{ row.process }}
                    </p>
                    <p style="font-size: smaller" *ngIf="row.type">
                      <b>Type: </b>{{ row.type.name }}
                    </p>
                    <p style="font-size: smaller" *ngIf="row.name">
                      <b>Navn: </b>{{ row.name }}
                    </p>
                    <p style="font-size: smaller" *ngIf="row.desc">
                      <b>Beskrivelse: </b>{{ row.desc }}
                    </p>
                    <p style="font-size: smaller" *ngIf="row.profilenumber">
                      <b>Pnr: </b>{{ row.profilenumber }}
                    </p>
                    <p style="font-size: smaller" *ngIf="row.comment">
                      <b>Kommentar: </b>{{ row.comment }}
                    </p>
                    <p
                      *ngIf="!row.scheduledfordelete"
                      [style.color]="row.color"
                    >
                      {{ row.statusHuman }}
                    </p>
                    <p *ngIf="row.scheduledfordelete">Slettes...</p>
                    <p
                      style="font-size: smaller"
                      *ngIf="
                        row.numberOfDeviations && row.numberOfDeviations > 0
                      "
                    >
                      Avvik: {{ row.numberOfDeviations }}
                    </p>
                    <div *ngIf="row.tags">
                      <table style="font-size: smaller; text-align: left">
                        <tr *ngFor="let t of row.tags">
                          <td>
                            <b>{{ t.key }}:</b>
                          </td>
                          <td style="text-align: right">{{ t.value }}</td>
                        </tr>
                      </table>
                    </div>
                  <!-- </td>
                  <td>
                    <app-thumbnail
                      [file]="file"
                      [files]="row.files"
                      [isLocked]="true"
                      *ngFor="let file of row.files"
                      [sanitize]="false"
                      size="40px"
                    ></app-thumbnail>
                  </td>
                </tr>
              </table> -->
            </td>
          </ng-container>

          <!-- ChecklistItems Column -->
          <ng-container matColumnDef="checklistitem">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Sjekklistepunkt
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <table *ngIf="row.checklistitems">
                <tr *ngFor="let item of row.checklistitems; let islast = last">
                  <td [style.border-bottom]="islast ? '' : '1px solid #444'">
                    <p style="font-size: smaller" *ngIf="item.type?.name">
                      <b>Type: </b>{{ item.type?.name }}
                    </p>
                    <p style="font-size: smaller" *ngIf="item.text">
                      <b>Tekst: </b>{{ item.text }}
                    </p>
                    <p style="font-size: smaller" *ngIf="item.comment">
                      <b>Kommentar: </b>{{ item.comment }}
                    </p>
                  </td>
                  <td style="align-items: flex-end;">
                    <!-- <app-thumbnail
                      [file]="file"
                      [files]="item.files"
                      [isLocked]="true"
                      *ngFor="let file of item.files | slice:0:3"
                      [sanitize]="false"
                      size="40px"
                    ></app-thumbnail> -->
                  </td>
                </tr>
              </table>
            </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <div *ngIf="row.type">
                {{ row.type.name }}
              </div>
            </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="profilenumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pnummer</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <div *ngIf="row.profilenumber">
                {{ row.profilenumber }}
              </div>
            </td>
          </ng-container>

          <!-- Deviations Column -->
          <ng-container matColumnDef="deviations">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Avvik</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
              style="text-align: center"
            >
              <div *ngIf="row.numberOfDeviations && row.numberOfDeviations > 0">
                {{ row.numberOfDeviations }}
              </div>
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="statusHuman">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <p *ngIf="!row.scheduledfordelete" [style.color]="row.color">
                {{ row.statusHuman }}
              </p>
              <p *ngIf="row.scheduledfordelete">Slettes...</p>
            </td>
          </ng-container>

          <!-- Last edited Column -->
          <ng-container matColumnDef="lastupdated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Sist endret
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <span *ngIf="row.lastupdatedString">
                {{ row.lastupdatedString }}
              </span>
            </td>
          </ng-container>

          <!-- Last edited Column -->
          <ng-container matColumnDef="pdfdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              PDF timestamp
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [routerLink]="row.scheduledfordelete ? null : row.tempPath"
              class="cursor"
            >
              <span *ngFor="let pdf of row.pdfexports">
                {{ pdf.lastupdatedString }}
              </span>
            </td>
          </ng-container>

          <!-- checklistpdfs Column -->
          <!-- <ng-container matColumnDef="checklistpdfs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              PDF<mat-spinner
                style="margin-left: 10px"
                diameter="25"
                *ngIf="!pdfsLoaded"
              ></mat-spinner>
            </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color">
              <div *ngIf="row">
                <button
                  *ngIf="!row.pdfexports"
                  style="
                    margin: 5px;
                    margin-left: 0;
                    margin-right: 15px;
                    opacity: 0;
                  "
                  mat-button
                  [style.width]="mobileQuery.matches ? null : '110px'"
                  [ngClass]="
                    mobileQuery.matches ? 'mat-mini-fab' : 'mat-button'
                  "
                  [disabled]="true"
                >
                  <span class="row">
                    <span class="fill-space"></span>
                    <span
                      [style.padding-top]="!mobileQuery.matches ? '6px' : '0'"
                      [style.padding-bottom]="
                        !mobileQuery.matches ? '6px' : '0'
                      "
                    >
                      <mat-spinner diameter="25"></mat-spinner>
                    </span>
                    <span *ngIf="!mobileQuery.matches" class="button-icon-text"
                      >Laster PDF...</span
                    >
                    <span class="fill-space"></span>
                  </span>
                </button>
                <div
                  *ngIf="
                    row.pdfexports &&
                    row.objectid &&
                    row.projectid &&
                    row.id &&
                    row.status === 'signed' &&
                    (!row.pdfexports || row.pdfexports.length === 0)
                  "
                >
                  <button
                    *ngIf="!row.generatingPDFstatus"
                    matTooltip="Generer PDF"
                    matTooltipPosition="left"
                    style="margin: 5px; margin-left: 0; margin-right: 15px"
                    mat-button
                    [style.width]="mobileQuery.matches ? null : '110px'"
                    [ngClass]="
                      mobileQuery.matches ? 'mat-mini-fab' : 'mat-raised-button'
                    "
                    (click)="generatePDF(row); toggleDisableRoute()"
                  >
                    <span class="row" *ngIf="row.generatingPDFchecklist">
                      <span class="fill-space"></span>
                      <span
                        [style.padding-top]="!mobileQuery.matches ? '6px' : '0'"
                        [style.padding-bottom]="
                          !mobileQuery.matches ? '6px' : '0'
                        "
                      >
                        <mat-spinner diameter="25"></mat-spinner>
                      </span>
                      <span
                        *ngIf="!mobileQuery.matches"
                        class="button-icon-text"
                        >Genererer</span
                      >
                      <span class="fill-space"></span>
                    </span>

                    <mat-icon *ngIf="!row.generatingPDFchecklist" color="accent"
                      >library_add</mat-icon
                    >
                    <span
                      *ngIf="
                        !mobileQuery.matches && !row.generatingPDFchecklist
                      "
                      class="button-icon-text"
                      >Opprett</span
                    >
                  </button>
                  <button
                    *ngIf="
                      row.generatingPDFstatus && row.generatingPDFchecklist
                    "
                    [matTooltip]="row.generatingPDFstatus"
                    matTooltipPosition="left"
                    style="
                      margin: 5px;
                      margin-left: 0;
                      margin-right: 15px;
                      width: 110px;
                    "
                    mat-button
                    [disabled]="
                      !(
                        row.generatingPDFstatus.substring(0, 5) === 'Error' ||
                        row.generatingPDFstatus.substring(0, 7) === 'Timeout'
                      )
                    "
                    (click)="generatePDFresetError(row); toggleDisableRoute()"
                  >
                    <mat-icon
                      [color]="
                        row.generatingPDFstatus.substring(0, 5) === 'Error'
                          ? 'warn'
                          : row.generatingPDFstatus.substring(0, 7) === 'Timeout'
                          ? 'accent'
                          : null
                      "
                      >{{ row.generatingPDFstatusIcon }}</mat-icon
                    >
                    <span class="button-icon-text">{{
                      row.generatingPDFstatusText
                    }}</span>
                  </button>
                </div>
                <div
                  *ngIf="
                    (row.pdfexports && row.pdfexports.length > 0) ||
                    !row.generatingPDFchecklist
                  "
                >
                  <div
                    *ngFor="let pdf of row.pdfexports"
                    style="text-align: center"
                  >
                    <button
                      (click)="
                      openDialogPdfPreview(pdf, row); toggleDisableRoute()
                      "
                      style="margin: 5px; margin-left: 0; margin-right: 15px"
                      mat-button
                      [style.width]="mobileQuery.matches ? null : '110px'"
                      [ngClass]="
                        mobileQuery.matches
                          ? 'mat-mini-fab'
                          : 'mat-raised-button'
                      "
                      matTooltip="Åpne PDF"
                      matTooltipPosition="left"
                      [disabled]="isOpeningPDF"
                    >
                      <span class="row" *ngIf="row.pdfButtonName === 'Åpner'">
                        <span class="fill-space"></span>
                        <span
                          [style.padding-top]="
                            !mobileQuery.matches ? '6px' : '0'
                          "
                          [style.padding-bottom]="
                            !mobileQuery.matches ? '6px' : '0'
                          "
                        >
                          <mat-spinner diameter="25"></mat-spinner>
                        </span>
                        <span
                          *ngIf="!mobileQuery.matches"
                          class="button-icon-text"
                          >Åpner</span
                        >
                        <span class="fill-space"></span>
                      </span>
                      <mat-icon
                        *ngIf="row.pdfButtonName !== 'Åpner'"
                        color="primary"
                        >picture_as_pdf</mat-icon
                      >
                      <span
                        *ngIf="
                          !mobileQuery.matches && row.pdfButtonName !== 'Åpner'
                        "
                        class="button-icon-text"
                        >PDF
                        <span *ngIf="pdf.sizestring" style="font-size: smaller"
                          >({{ pdf.sizestring }})</span
                        ></span
                      >
                    </button>
                    <div
                      *ngIf="mobileQuery.matches && pdf.sizestring"
                      style="font-size: smaller; color: #cacaca"
                    >
                      ({{ pdf.sizestring }})
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container> -->

          <!-- checklistpdfs Column -->
          <!-- <ng-container matColumnDef="pdfdeliveredToCustomer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Levert kunde
            </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color">
              <div *ngIf="user && row.pdfexports?.length > 0" style="padding-left: 10px">
                <button
                  [matTooltip]="
                    row.PDFDeliveredToCustomer &&
                    row.PDFDeliveredToCustomer.status
                      ? 'Levert'
                      : 'Ikke levert'
                  "
                  matTooltipPosition="left"
                  style="margin: 5px; margin-left: 0; margin-right: 15px"
                  mat-mini-fab
                  (click)="setDeliveredToCustomer(row); toggleDisableRoute()"
                  [disabled]="user.role?.name !== 'admin'"
                  [style.background-color]="
                    user.role?.name === 'admin'
                      ? 'rgba(80,80,80,0.5)'
                      : 'rgba(80,80,80,0)'
                  "
                >
                  <mat-icon
                    [color]="
                      row.PDFDeliveredToCustomer &&
                      row.PDFDeliveredToCustomer.status
                        ? 'primary'
                        : null
                    "
                    [style.color]="
                      row.PDFDeliveredToCustomer &&
                      row.PDFDeliveredToCustomer.status
                        ? null
                        : '#888'
                    "
                  >
                    {{
                      row.PDFDeliveredToCustomer &&
                      row.PDFDeliveredToCustomer.status
                        ? "check_circle"
                        : "unpublished"
                    }}
                  </mat-icon>
                  <span
                    *ngIf="!mobileQuery.matches"
                    class="button-icon-text"
                  ></span>
                </button>
              </div>
            </td>
          </ng-container> -->
          <ng-container matColumnDef="paginator">
            <th
              mat-footer-cell
              *matFooterCellDef
              [colSpan]="displayedColumns.length"
            >
              <mat-paginator
                *ngIf="!isEmpty"
                [pageSize]="pageSize"
                (page)="pageChange($event)"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="[10, 25, 50, 100]"
                showFirstLastButtons
              ></mat-paginator>
            </th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['filter']; sticky: true"></tr>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class]="row.scheduledfordelete ? '' : 'cursor'"
            [style.opacity]="row.scheduledfordelete ? '0.5' : '1'"
            [style.cursor]="row.scheduledfordelete ? 'default' : 'pointer'"
            [style.background-color]="
              row.scheduledfordelete ? '#8D3B39AA !important' : ''
            "
            [routerLink]="
              disableRoute || row.scheduledfordelete ? null : row.tempPath
            "
          ></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="['paginator']; sticky: true"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>
