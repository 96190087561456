<span
  [style.opacity]="visible ? 0.5 : 0"
  [class]="pullRight ? 'pull-right' : ''"
>
<mat-icon
  color="accent"
  class="noselect"
  style="vertical-align: middle; height: 20px; font-size: 17px; opacity: 0.5;"
  [matTooltip]="visible ? 'Item is from cache' : ''"
>cached</mat-icon
>
</span>
