import {Component, Inject, OnInit} from '@angular/core';
import {AdminService} from 'src/app/services/admin.service';
import {MediaMatcher} from '@angular/cdk/layout';
import {environment} from '../../../../../environments/environment';
import {ObjectStatus} from 'src/app/models/Status';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface DialogData {
  newPriority: number;
}

@Component({
  selector: 'app-new-objectstatus',
  templateUrl: './new-objectstatus.component.html',
  styleUrls: ['./new-objectstatus.component.css']
})
export class NewObjectstatusComponent implements OnInit {
  isLoading = true;
  mobileQuery: MediaQueryList;
  status: ObjectStatus;

  Form = new UntypedFormGroup({
    // id: new FormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    enName: new UntypedFormControl('', [Validators.required]),
    priority: new UntypedFormControl(this.data.newPriority, [Validators.required]),
  });
  error: string;

  constructor(
    private dialogRef: MatDialogRef<NewObjectstatusComponent>,
    private adminservice: AdminService,
    media: MediaMatcher,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  get name() {
    return this.Form.get('name');
  }

  get enName() {
    return this.Form.get('enName');
  }

  get priority() {
    return this.Form.get('priority');
  }

  ngOnInit(): void {
  }

  create(name: string, enName: string, priority: number) {
    const status: ObjectStatus = {
      name,
      enName,
      priority
    };
    this.adminservice.createObjectStatus(status);
    this.Form.reset();
    this.Form.markAsPristine();
    this.Form.markAsUntouched();
    this.dialogRef.close();
  }
}
