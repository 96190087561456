<div class="row" style="margin-top: -15px" *ngIf="popup">
  <div class="fill-space"></div>
  <div>
    <a mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
</div>

<div [attr.mat-dialog-title]="popup ? popup : null">
  <div class="row">
    <div>
      <h3>
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
          >list_alt</mat-icon
        >Sjekklister
      </h3>
    </div>
    <div class="fill-space"></div>
    <div style="position: relative">
      <button
        type="button"
        mat-button
        style="margin: 5px; position: absolute; bottom: 0; right: 0"
        class="cursor"
        matTooltip="Ny sjekkliste"
        matTooltipPosition="left"
        (click)="newChecklistClick()"
      >
        <mat-icon style="margin-top: 3px" color="primary"> add</mat-icon
        ><span>Ny</span><span *ngIf="!mobileQuery.matches"> sjekkliste</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="fill-space"></div>
    <div style="max-width: 200px">
      <app-select
        label="Sorter"
        [selected]="sortByType"
        (value)="onSelectionChange($event)"
        [items]="sortByItems"
      ></app-select>
    </div>
  </div>
</div>

<div [attr.mat-dialog-content]="popup ? popup : null" [style.padding]="popup ? '0 5px' : undefined">
  <mat-card>
    <div class="row">
      <div class="fill-space"></div>
      <div>
        <mat-spinner
          diameter="50"
          style="margin: 32px"
          *ngIf="!object.checklists"
        ></mat-spinner>
        <app-empty-list
          message="Ingen sjekklister"
          *ngIf="object.checklists.length === 0"
        ></app-empty-list>
      </div>
      <div class="fill-space"></div>
    </div>
    <div class="row">
      <div
        *ngIf="
          object.checklists &&
          object.checklists.length > 0 &&
          !object.generatingChecklist
        "
        style="width: 100%"
      >
        <table style="width: 100%">
          <tr style="text-align: center; font-size: medium">
            <td>Sjekkliste</td>
            <td>PDF</td>
            <td>Levert</td>
          </tr>
          <tr
            *ngFor="
              let checklist of object.checklists;
              let islast = last;
            "
          >
            <td colspan="3">
              <table>
                <tr>
                  <td [style.width]="mobileQuery.matches ? '40%' : '40%'">
                    <div style="margin: 10px" class="row">
                      <div class="fill-space"></div>
                      <div>
                        <div class="row">
                          <div class="fill-space"></div>
                          <div>
                            <button
                              mat-raised-button
                              [mat-dialog-close]="true"
                              routerLink="{{ checklist.tempPath }}"
                              [style.width]="mobileQuery.matches ? '' : '123px'"
                            >
                              <mat-icon [style.color]="checklist.color">{{
                                checklist.icon
                              }}</mat-icon>
                              <span
                                class="button-icon-text"
                                *ngIf="!mobileQuery.matches"
                                >{{ checklist.statusHuman }}</span
                              >
                            </button>
                          </div>
                          <div class="fill-space"></div>
                        </div>
                        <div
                          style="
                            font-size: x-small;
                            width: 100%;
                            text-align: center;
                          "
                        >
                          <div
                            *ngIf="mobileQuery.matches"
                            style="font-size: small"
                          >
                            {{ checklist.statusHuman }}
                          </div>
                          <div>
                            {{
                              checklist.lastsignedString ||
                                checklist.lastupdatedString
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="fill-space"></div>
                    </div>
                  </td>
                  <td [style.width]="mobileQuery.matches ? '40%' : '40%'">
                    <div style="margin: 10px" class="row">
                      <div class="fill-space"></div>
                      <div>
                        <button
                          *ngIf="
                            !pdfLoaded &&
                            checklist.status === 'signed' &&
                            !checklist.pdfexports
                          "
                          mat-button
                          [style.width]="mobileQuery.matches ? 'auto' : '123px'"
                          [disabled]="true"
                        >
                          <span class="row">
                            <span class="fill-space"></span>
                            <span
                              [style.padding-top]="
                                !mobileQuery.matches ? '6px' : '0'
                              "
                              [style.padding-bottom]="
                                !mobileQuery.matches ? '6px' : '0'
                              "
                            >
                              <mat-spinner diameter="25"></mat-spinner>
                            </span>
                            <span
                              *ngIf="!mobileQuery.matches"
                              class="button-icon-text"
                              >Laster PDF...</span
                            >
                            <span class="fill-space"></span>
                          </span>
                        </button>
                        <div
                          *ngIf="
                            user &&
                            checklist.objectid &&
                            checklist.projectid &&
                            checklist.id &&
                            checklist.status === 'signed' &&
                            (!checklist.pdfexports ||
                              checklist.pdfexports.length === 0)
                          "
                        >
                          <button
                            *ngIf="!checklist.generatingPDFstatus && pdfLoaded"
                            matTooltip="Generer PDF"
                            matTooltipPosition="left"
                            mat-button
                            [style.width]="
                              mobileQuery.matches ? 'auto' : '123px'
                            "
                            (click)="generatePDF(checklist)"
                            [disabled]="checklist.generatingPDFchecklist"
                          >
                            <span
                              class="row"
                              *ngIf="checklist.generatingPDFchecklist"
                            >
                              <span class="fill-space"></span>
                              <span
                                [style.padding-top]="
                                  !mobileQuery.matches ? '6px' : '0'
                                "
                                [style.padding-bottom]="
                                  !mobileQuery.matches ? '6px' : '0'
                                "
                              >
                                <mat-spinner diameter="25"></mat-spinner>
                              </span>
                              <span
                                *ngIf="!mobileQuery.matches"
                                class="button-icon-text"
                                >Sender</span
                              >
                              <span class="fill-space"></span>
                            </span>

                            <mat-icon
                              *ngIf="!checklist.generatingPDFchecklist"
                              color="accent"
                              >library_add</mat-icon
                            >
                            <span
                              *ngIf="
                                !mobileQuery.matches &&
                                !checklist.generatingPDFchecklist
                              "
                              class="button-icon-text"
                              >Opprett</span
                            >
                          </button>
                          <button
                            *ngIf="
                              checklist.generatingPDFstatus === 'sending' &&
                              !checklist.generatingPDFchecklist
                            "
                            matTooltip="Sender til generering"
                            mat-mini-fab
                            disabled
                          >
                            <mat-spinner diameter="20"></mat-spinner>
                          </button>
                          <button
                            *ngIf="
                              checklist.generatingPDFstatus &&
                              checklist.generatingPDFchecklist
                            "
                            [matTooltip]="checklist.generatingPDFstatus"
                            matTooltipPosition="left"
                            mat-button
                            [disabled]="
                              checklist.generatingPDFstatus.substring(0, 5) !=
                              'Error'
                            "
                            (click)="generatePDFresetError(checklist)"
                          >
                            <mat-icon
                              [color]="
                                checklist.generatingPDFstatus.substring(0, 5) ==
                                'Error'
                                  ? 'warn'
                                  : null
                              "
                              >{{ checklist.generatingPDFstatusIcon }}</mat-icon
                            >
                            <span class="button-icon-text">{{
                              checklist.generatingPDFstatusText
                            }}</span>
                          </button>
                        </div>
                        <div
                          *ngIf="
                            checklist.pdfexports ||
                            !checklist.generatingPDFchecklist
                          "
                        >
                          <div
                            *ngFor="
                              let pdf of checklist.pdfexports;
                              let isLast = last
                            "
                          >
                            <div class="row">
                              <div class="fill-space"></div>
                              <div>
                                <button
                                  [disabled]="!isOnline"
                                  (click)="openDialogPdfPreview(pdf, checklist)"
                                  mat-raised-button
                                  [style.width]="
                                    mobileQuery.matches ? 'auto' : '123px'
                                  "
                                  [matTooltip]="isOnline ? 'Åpne PDF' : 'Ikke tilgjengelig når du er offline'"
                                  matTooltipPosition="left"
                                >
                                  <mat-icon
                                    [color]="
                                      pdf.iconcolor ? pdf.iconcolor : 'primary'
                                    "
                                    >picture_as_pdf</mat-icon
                                  >
                                  <span
                                    class="button-icon-text"
                                    *ngIf="!mobileQuery.matches"
                                  >
                                    PDF
                                    <span
                                      *ngIf="pdf.sizestring"
                                      style="font-size: smaller"
                                      >{{ pdf.sizestring }}</span
                                    ></span
                                  >
                                </button>
                              </div>
                              <div class="fill-space"></div>
                            </div>
                            <div
                              style="
                                font-size: x-small;
                                width: 100%;
                                text-align: center;
                              "
                            >
                              <div
                                *ngIf="mobileQuery.matches"
                                style="font-size: small"
                              >
                                ({{ pdf.sizestring }})
                              </div>
                              <div>
                                {{ pdf.timestampstring }}
                              </div>
                              <div>
                                {{
                                  pdf.iconcolor === "warn" ? "PDF med avvik" : ""
                                }}
                              </div>
                            </div>
                            <mat-divider
                              [inset]="true"
                              *ngIf="!isLast"
                              style="margin-top: 5px; margin-bottom: 10px"
                            ></mat-divider>
                          </div>
                        </div>
                      </div>
                      <div class="fill-space"></div>
                    </div>
                  </td>
                  <td [style.width]="mobileQuery.matches ? '20%' : '20%'">
                    <div style="margin: 10px" class="row">
                      <div class="fill-space"></div>
                      <div>
                        <div>
                          <button
                            *ngIf="checklist.pdfexports?.length > 0"
                            mat-icon-button
                            [matTooltip]="
                              checklist.PDFDeliveredToCustomer &&
                              checklist.PDFDeliveredToCustomer.status
                                ? 'Levert'
                                : 'Ikke levert'
                            "
                            matTooltipPosition="left"
                            (click)="setDeliveredToCustomer(checklist)"
                            [disabled]="user.role?.name !== 'admin'"
                            style="margin-top: -10px; margin-bottom: -10px"
                          >
                            <mat-icon
                              [color]="
                                checklist.PDFDeliveredToCustomer &&
                                checklist.PDFDeliveredToCustomer.status
                                  ? 'primary'
                                  : null
                              "
                              [style.color]="
                                checklist.PDFDeliveredToCustomer &&
                                checklist.PDFDeliveredToCustomer.status
                                  ? null
                                  : '#888'
                              "
                              >{{
                                checklist.PDFDeliveredToCustomer &&
                                checklist.PDFDeliveredToCustomer.status
                                  ? "check_box"
                                  : "check_box_outline_blank"
                              }}</mat-icon
                            >
                          </button>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div class="fill-space"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    style="font-size: x-small; text-align: center"
                    [style.padding-bottom]="islast ? '' : '10px'"
                    [style.border-bottom]="islast ? '' : '1px solid #444'"
                  >
                    <div *ngIf="checklist.comment">
                      <div style="color: #ccc">Kommentar:</div>
                      <div>{{ checklist.comment }}</div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="!object.type || !object.type.name">Mangler objekttype</div>
    </div>
  </mat-card>
</div>
