<mat-icon
  color="warn"
  class="header-icon header-icon-small noselect"
  matTooltip="Device position disabled"
  *ngIf="!locationEnabled"
  [matMenuTriggerFor]="Menu"
>share_location</mat-icon
>

<!--<mat-icon-->
<!--  color="primary"-->
<!--  class="header-icon header-icon-small noselect"-->
<!--  *ngIf="locationEnabled"-->
<!--&gt;share_location</mat-icon-->
<!--&gt;-->

<mat-menu #Menu="matMenu">
  <div style="padding: 5px 20px 20px 20px">
    <div>
      <mat-icon color="warn" style="padding: 0 0 10px 0">share_location</mat-icon
      >Device position disabled
    </div>
    Degraded functionality
  </div>
</mat-menu>
