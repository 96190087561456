<!-- <mat-progress-bar #progressbar mode="determinate" value="0"></mat-progress-bar> -->
<app-spinner
  id="app_spinner"
  disable="false"
  [text]="class.progressstring"
  header="Vennligst vent..."
  *ngIf="class.progressstring.length !== 0"
></app-spinner>

<div class="row" style="margin-top: -15px">
  <div
    style="color: #aaa; font-size: small; opacity: 0.5"
    *ngIf="user?.superAdmin && file"
  >
    id: {{ file.id }}
  </div>
  <div class="fill-space"></div>
  <div>
    <a mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      <!-- <span *ngIf="!mobileQuery.matches"> Lukk </span> -->
    </a>
  </div>
</div>

<!-- <div *ngIf="files">
<mat-carousel
  timings="250ms ease-in"
  [autoplay]="false"
  interval="5000"
  color=""
  maxWidth="auto"
  proportion="100"
  [slides]="files.length"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="false"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
>
  <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of files; let i = index"
    [image]="slide.image"
    overlayColor="#00000040"
    [hideOverlay]="false"
  ></mat-carousel-slide>
</mat-carousel>
</div> -->

<div *ngIf="file"
     (keydown.ArrowLeft)="clickPreviousFile()"
     (keydown.ArrowRight)="clickNextFile()">
  <div class="row" *ngIf="format === 'other' || pdfFallback || pdfLoading">
    <div class="fill-space"></div>
    <div
      style="height: 300px; width: 225px; background-color: #666 !important"
      class="card card-2"
    >
      <i class="filename">
        {{ file.name }}
      </i>
      <span class="fileextension" *ngIf="file.name">
        {{ file.name | replaceFileExtension }}
      </span>
    </div>
    <div class="fill-space"></div>
  </div>
  <div class="row" *ngIf="format === 'image'">
    <div class="fill-space"></div>
    <div>
      <img
        #image
        id="image"
        [src]="downloadurl"
        onerror="this.style.display = 'none';"
      />
    </div>
    <div class="fill-space"></div>
  </div>
  <div class="row" *ngIf="format === 'video'">
    <div class="fill-space"></div>
    <div>
      <video
        #video
        id="video"
        style="outline: none"
        autoplay
        loop
        muted
        controls
        oncanplay="this.muted=true"
      >
        <source [src]="file.downloadurl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="fill-space"></div>
  </div>

  <!-- <object *ngIf="format === 'pdf' && file.downloadurl" [data]="file.downloadurl" type="application/pdf">
  <embed [src]="file.downloadurl" type="application/pdf" />
  </object> -->
  <div
    class="center-on-screen"
    *ngIf="format === 'pdf' && pdfLoading"
    style="text-align: center; vertical-align: middle; font-size: x-small"
  >
    <!-- <app-spinner text="" header="Laster inn pdf..."></app-spinner> -->

    <mat-spinner></mat-spinner>
    <h3>Laster inn pdf...</h3>
    <!-- <div style="color:gray">Data: {{ progress }}</div>
  <div style="color:gray">Side: {{ pdfPage }} av {{ pdfPages}}</div>
  <div style="color:gray">{{ progressTextlayer }}</div> -->
  </div>

  <!-- <pdf-viewer
  [style.opacity]="pdfLoading ? 0 : 1"
  *ngIf="format === 'pdf' && file.downloadurl"
  [src]="file.downloadurl"
  [original-size]="false"
  [show-all]="true"
  [render-text]="true"
  (after-load-complete)="callBackFnAfterLoadComplete($event)"
  (page-rendered)="pageRendered($event)"
  (text-layer-rendered)="textLayerRendered($event)"
  (on-progress)="onProgress($event)"
  (error)="onErrorPdfViewer($event)"
  style="display: block;height:300px;"
  ></pdf-viewer> -->

  <div
    [style.margin-left]="
      !mobileQuery.matches && this.format !== 'pdf' ? '0' : '0'
    "
    [style.margin-right]="
      !mobileQuery.matches && this.format !== 'pdf' ? '0' : '0'
    "
    [style.opacity]="pdfLoading ? 0 : 1"
    [style.display]="pdfLoading ? 'none' : 'block'"
    *ngIf="format === 'pdf' && file && file.downloadurl && !pdfFallback"
  >
    <ngx-extended-pdf-viewer
      [src]="src"
      [minZoom]="0.2"
      [maxZoom]="1.5"
      [mobileFriendlyZoom]="'100%'"
      [showSecondaryToolbarButton]="false"
      [showToolbar]="false"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="false"
      [showZoomButtons]="false"
      [showPresentationModeButton]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="true"
      [showBookModeButton]="false"
      [showSecondaryToolbarButton]="true"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      [showScrollingButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
      [useBrowserLocale]="true"
      [showBorders]="false"
      [height]="pdfLoading ? '300px' : '60vh'"
      [zoom]="'page-fit'"
      [backgroundColor]="'rgba(20,20,20,0)'"
      (pdfLoaded)="pdfLoaded()"
      (pdfLoadingFailed)="pdfLoadingFailed($event)"
      (progress)="pdfProgressEvent($event)"
      (pagesLoaded)="onPagesLoaded()"
    >
    </ngx-extended-pdf-viewer>
  </div>

  <div class="row" *ngIf="pdfProgress && pdfProgress.percent !== 100">
    <div class="fill-space"></div>
    <div>
      <mat-progress-bar
        style="margin-top: -4px; margin-bottom: 10px; width: 225px"
        mode="determinate"
        [value]="pdfProgress.percent"
      ></mat-progress-bar>
    </div>
    <div class="fill-space"></div>
  </div>
  <div class="row" *ngIf="pdfFallback">
    <div class="fill-space"></div>
    <div>Feil under innlasting av PDF</div>
    <div class="fill-space"></div>
  </div>

  <div class="row" *ngIf="files?.length > 1" style="margin-top: 5px">
    <div>
      <button
        mat-mini-fab
        style="background-color: #555; color: #eee"
        (click)="clickPreviousFile()"
        matTooltip="Forrige"
        matTooltipPosition="right"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <div class="fill-space"></div>
    <div style="vertical-align: center">{{ fileindex + 1 }} / {{ files.length }}</div>
    <div class="fill-space"></div>
    <div>
      <button
        mat-mini-fab
        style="background-color: #555; color: #eee"
        (click)="clickNextFile()"
        matTooltip="Neste"
        matTooltipPosition="left"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-actions>
    <!-- <mat-dialog-actions align="end"> -->
    <!-- <span *ngIf="!isLocked && !mobileQuery.matches">
    <button mat-button (click)="delete()" mat-dialog-close>Slett</button>
  </span> -->

    <div class="row" *ngIf="file.sizestring" style="margin-top: 10px">
      <!-- <div class="fill-space"></div> -->
      <div style="font-size: x-small">
        <div style="font-size: medium">{{ file.name }}</div>

        <div *ngIf="file.sizestring">{{ file.sizestring }}</div>
        <div *ngIf="file.timestampstring">
          {{ file.timestampstring }}
        </div>
        <div *ngIf="attachments?.length > 0">
          {{ attachments.length }} vedlegg
        </div>
        <div *ngIf="images?.length > 0">
          {{ images.length }} {{ images.length > 1 ? "bilder" : "bilde" }}
        </div>
        <div *ngIf="file.comment || editComment">
          <div *ngIf="isLocked || !editComment">
            <span style="color: #aaa">Kommentar:</span>
            <span style="white-space: pre-wrap">
              {{ file.comment }}
            </span>
          </div>
          <form [formGroup]="FormComment" *ngIf="!isLocked && editComment">
            <mat-form-field>
              <textarea
                cdkTextareaAutosize
                style="
                  padding: 0;
                  min-height: 14px !important;
                  padding-bottom: 3px;
                "
                [value]="file.comment"
                id="comment"
                #comment
                matInput
                placeholder="Kommentar"
                (keyup.enter)="updateComment(comment.value)"
                (blur)="updateComment(comment.value)"
              ></textarea>
            </mat-form-field>
          </form>
        </div>
        <!-- <div style="color: #aaa" *ngIf="user?.superAdmin && exif">
          EXIF data:
          <div *ngFor="let ex of exif">{{ ex[0] }}: {{ ex[1] | json }}</div>
        </div> -->
      </div>
      <!-- <div class="fill-space"></div> -->
    </div>

    <div class="fill-space"></div>

    <span>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
        <!-- <span *ngIf="!mobileQuery.matches"> Meny </span> -->
      </button>
      <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
        <!-- <button mat-menu-item routerLink="">
          <mat-icon>view_list</mat-icon>Prosjektliste
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>Logg av
        </button> -->
        <!-- <div style="min-width: 250px"></div> -->

        <div *ngIf="!isLocked && user?.role?.name !== 'customer'">
          <button
            mat-menu-item
            style="width: 100%; text-align: left"
            (click)="delete()"
            mat-dialog-close
          >
            <mat-icon>delete</mat-icon>
            <span> Slett </span>
          </button>
          <mat-divider></mat-divider>
        </div>
        <div>
          <button
            mat-menu-item
            style="width: 100%; text-align: left"
            target="_blank"
            (click)="filedownloadDirect()"
            [disabled]="isDownloadingDirect || printlock"
          >
            <mat-icon>
              {{ iconDirect }}
              <!-- download -->
            </mat-icon>
            <span>
              {{ progressDirect }}
              <!-- Last ned uten vedlegg -->
            </span>
          </button>
        </div>
        <div>
          <button
            mat-menu-item
            style="width: 100%; text-align: left"
            *ngIf="attachments && attachments.length > 0"
            target="_blank"
            (click)="filedownloadZip()"
            [disabled]="
              (isDownloadingZip && !isDownloadingImages) ||
              printlock ||
              (images.length === 0 && attachments.length === 0)
            "
          >
            <mat-icon>{{ iconZip }}</mat-icon>
            <span>
              {{ progressZip }}
            </span>
          </button>
        </div>
        <div>
          <button
            mat-menu-item
            style="width: 100%; text-align: left"
            *ngIf="images && images.length > 0"
            target="_blank"
            (click)="filedownloadImagesZip()"
            [disabled]="
              (isDownloadingZip && isDownloadingImages) ||
              printlock ||
              (images.length === 0 && attachments.length === 0)
            "
          >
            <mat-icon>{{ iconZip }}</mat-icon>
            <span>
              {{ progressImageZip }}
            </span>
          </button>
        </div>
        <!-- <div>
          <button
            mat-menu-item
            style="width: 100%; text-align: left"
            *ngIf="
              attachments && attachments.length > 0 && !mobileQuery.matches
            "
            target="_blank"
            (click)="filedownloadConcat()"
            [disabled]="
              isDownloadingConcat ||
              printlock ||
              (images.length === 0 && attachments.length === 0)
            "
          >
            <mat-icon>{{ iconConcat }} </mat-icon>
            <span>
              {{ progressConcat }}
            </span>
          </button>
        </div> -->
        <div *ngIf="!isLocked">
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            style="width: 100%; text-align: left"
            (click)="toggleEditComment()"
          >
            <mat-icon>notes </mat-icon>
            <span
              >{{ file?.comment ? "Rediger kommentar" : "Legg til kommentar" }}
            </span>
          </button>
        </div>
      </mat-menu>
    </span>
    <!-- <span>
      <a
        mat-button
        target="_blank"
        (click)="filedownloadDirect()"
        [disabled]="isDownloadingDirect"
        [ngClass]="mobileQuery.matches ? 'mat-icon-button' : 'mat-button'"
        ><mat-icon>{{ iconDirect }}</mat-icon>
        <span *ngIf="!mobileQuery.matches">
          {{ progressDirect }}
        </span>
      </a>
    </span>
    <span
      *ngIf="attachments && attachments.length > 0 && !mobileQuery.matches"
    >
      <a
        mat-button
        target="_blank"
        (click)="filedownloadZip()"
        [disabled]="isDownloadingZip"
        [ngClass]="mobileQuery.matches ? 'mat-icon-button' : 'mat-button'"
        ><mat-icon>{{ iconZip }}</mat-icon>
        <span *ngIf="!mobileQuery.matches">
          {{ progressZip }}
        </span>
      </a>
    </span>
    <span
      *ngIf="attachments && attachments.length > 0 && !mobileQuery.matches"
    >
      <a
        mat-button
        target="_blank"
        (click)="filedownloadConcat()"
        [disabled]="isDownloadingConcat"
        [ngClass]="mobileQuery.matches ? 'mat-icon-button' : 'mat-button'"
        ><mat-icon>{{ iconConcat }} </mat-icon>
        <span *ngIf="!mobileQuery.matches">
          {{ progressConcat }}
        </span>
      </a>
    </span> -->

    <!-- <span *ngIf="mobileQuery.matches && !attachments">
    <a mat-icon-button target="_blank" (click)="filedownloadDirect()" [disabled]="isDownloadingDirect"
      ><mat-icon>download</mat-icon></a
    >
  </span>
  <span *ngIf="mobileQuery.matches && attachments && attachments.length > 0">
    <a mat-icon-button target="_blank" (click)="filedownloadZip()" [disabled]="isDownloadingZip"
      ><mat-icon>compress</mat-icon></a
    >
  </span>
  <span *ngIf="mobileQuery.matches && attachments && attachments.length > 0">
    <a mat-icon-button target="_blank" (click)="filedownloadConcat()" [disabled]="isDownloadingConcat"
      ><mat-icon>cloud_download</mat-icon></a
    >
  </span> -->
    <!-- <span class="fill-space"></span> -->
    <!-- <span *ngIf="mobileQuery.matches">
    <button mat-icon-button mat-dialog-close></button>
  </span> -->
  </mat-dialog-actions>
</div>
