<div
  class="noselect"
  style="
    color: #999;
    font-size: 12px;
    text-align: right;
    margin-bottom: 2px;
    bottom: 0;
  "
  [style.position]="positionRelative ? 'relative' : 'absolute'"
  [style.right]="positionRelative ? 0 : '0'"
  [style.margin]="positionRelative ? 0 : '10px'"
  [style.padding]="positionRelative ? 0 : '10px'"
  [style.margin-right]="positionRelative ? 0 : '0'"
  [style.width]="positionRelative ? '100%' : null"
>
  <div
    *ngIf="domainMessage"
    style="padding-bottom: 10px; border-radius: 5px"
    [style.padding]="positionRelative ? 0 : '10px'"
    [style.background-color]="domainMessage ? '#603030A0' : 0"
  >
    <mat-icon color="warn">warning</mat-icon>
    <div style="font-size: 14px; padding-bottom: 10px; color: #cacaca">
      {{ domainMessage }}
    </div>
    <a
      mat-raised-button
      color="warn"
      style="width: 95%"
      href="https://apg.aventi.no"
    >
      <mat-icon>launch</mat-icon>
      <span class="button-icon-text"> apg.aventi.no </span>
    </a>
  </div>

  v.{{ appVersion }}
</div>
