import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {LogoutComponent} from '../components/popups/logout/logout.component';
import {SnackbarService} from './snackbar.service';
import firebase from 'firebase/compat/app';
import {SharedService} from './shared.service';
import {firstValueFrom} from 'rxjs';
import {EventLogItem, EventLogItemType, EventLogSource} from '../models/EventLog';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {AdminService} from './admin.service';
import User = firebase.User;


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private snackbarservice: SnackbarService,
    private dialog: MatDialog,
    private sharedservice: SharedService,
    private userservice: UserService,
    private adminservice: AdminService
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  async login(email: string, password: string) {
    return await this.afAuth.signInWithEmailAndPassword(
      email,
      password
    )
      .then((success) => {
        return success;
      })
      .catch((err) => {
        throw err;
      });
  }

  async checkUserAndUpdateIfMatching(firebaseUser: firebase.User, reload: boolean = false) {
    const user = await firstValueFrom(this.userservice.getUserWithFirebaseUserData(firebaseUser));
    user.firebaseUser = {
      email: firebaseUser.email,
      displayName: firebaseUser.displayName,
      uid: firebaseUser.uid,
    };
    if (user) {
      await this.userservice.updateUser(user).then(() => {
          this.sharedservice.user.next([user]);
          if (reload) {
            window.location.reload();
          }
        }
      );
    } else {
      const errorMessage = 'Bruker funnet i autentiseringsserver, men ikke i APG. Ta kontakt med APG admin';
      await this.buildEventLogItemAndAddToEventLog(errorMessage, EventLogItemType.USER_NOT_FOUND);
      this.snackbarservice.openSnackBar(errorMessage, 'dismiss', 10000);
    }
  }

  async buildEventLogItemAndAddToEventLog(errorMessage: string, type: EventLogItemType) {
    const newEventLogItem: EventLogItem = {
      type,
      loginEmail: this.user.email,
      loginUrl: window.location.href,
      appVersion: environment.appVersion,
      errorMessage,
      time: new Date().toJSON(),
      source: EventLogSource.APG_MAIN
    };
    await this.adminservice.addToEventLog(newEventLogItem);
  }


// async loginWithToken(token: string) {
  //   const result = await this.afAuth.signInWithCustomToken(token);
  //   // window.location.reload();
  //   // this.router.navigate(['admin/list']);
  // }

  // async register(email: string, password: string) {
  //   const result = await this.afAuth.createUserWithEmailAndPassword(email, password)
  //   this.sendEmailVerification();
  // }

  // async sendEmailVerification() {
  //   await this.afAuth.currentUser.sendEmailVerification();
  //   this.router.navigate(['admin/verify-email']);
  // }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail, {url: 'https://apg.aventi.no'});
  }

  logoutConfirm() {
    const dialogRef = this.dialog.open(LogoutComponent, {
      backdropClass: 'blur-dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sharedservice.isLoading.next([
          true,
          'Logger av...',
          [''],
        ]);
        this.logout()
          .then(() => {
          })
          .catch((err) => {
            console.error(err.message);
          });
      }
    });
  }

  async logout() {
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    window.location.reload();
  }

  // isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   console.log('isLoggedIn:', user);
  //   return user !== null;
  // }

  // isAuthenticated(): boolean {
  //   this.afAuth.authState
  //     .pipe(first())
  //     .pipe(
  //       tap((user) => {
  //         if (user) {
  //           console.log('isAuthenticated:', user);
  //           return true;
  //         } else {
  //           // do something else
  //           return false;
  //         }
  //       })
  //     )
  //     .subscribe();
  //   // return false;
  // }

  async updatePassword(oldPass: string, newPass: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.afAuth.user.pipe(take(1)).subscribe((user) => {
        const credentials = firebase.auth.EmailAuthProvider.credential(
          user.email,
          oldPass
        );
        user
          .reauthenticateWithCredential(credentials)
          .then(() => {
            console.log('Reauthentification successful');
            user
              .updatePassword(newPass)
              .then(() => {
                console.log('Password updated');
                this.snackbarservice.openSnackBar('Passord oppdatert', 'Takk');
                resolve('success');
                return 'success';
              })
              .catch((changepassErr) => {
                console.log(changepassErr);
                if (changepassErr.code === 'auth/user-token-expired') {
                  this.snackbarservice.openSnackBar(
                    'For mange forsøk. Logger ut...',
                    ''
                  );
                  resolve('user-token-expired');
                  return 'user-token-expired';
                } else {
                  resolve(changepassErr.code);
                  return changepassErr.code;
                }
              });
          })
          .catch((reauthenticateErr) => {
            if (reauthenticateErr.code === 'auth/wrong-password') {
              console.log(reauthenticateErr);
              this.snackbarservice.openSnackBar(
                'Gammelt passord stemmer ikke',
                'Ok'
              );
            }
            resolve('wrong password');
            return 'wrong password';
          });
      });
    });
  }
}
