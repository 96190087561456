<div
  style="padding: 15px 15px 0 15px; position: relative"
  *ngIf="checklistItemId && checklistItemFrontend"
>
  <button
    style="opacity: 0; height: 0; width: 0; margin: 0; position: absolute"
    mat-raised-button
    cdkFocusInitial
    #DummyFocusButton
  ></button>
  <div
    *ngIf="user.superAdmin"
    style="
      color: #aaa;
      font-size: small;
      position: absolute;
      top: 0;
      right: 15px;
      opacity: 0.5;
    "
  >
    id: {{ checklistItemId }}
  </div>
  <!-- <div class="row"> -->
  <!-- <div
    *ngIf="locked && checklistItemFrontend.type.name === 'Avkrysning'"
    class="pull-right"
    style="padding: 10px"
  >
    <mat-icon *ngIf="checklistItemFrontend.status === 'ok'" color="primary"
      >check_circle</mat-icon
    >
    <mat-icon *ngIf="checklistItemFrontend.status === 'deviation'" color="warn"
      >cancel</mat-icon
    >
    <mat-icon
      *ngIf="checklistItemFrontend.status === 'not_applicable'"
      style="color: #aaa"
      >block</mat-icon
    >
  </div> -->
  <!-- <div [ngClass]="mobileQuery.matches ? '' : 'row'"> -->
  <div>
    <div>
      <span *ngIf="locked && checklistItemFrontend.type.name === 'Avkrysning'">
        <mat-icon
          *ngIf="checklistItemFrontend.status === 'ok'"
          color="primary"
          style="vertical-align: middle; margin-bottom: 5px; margin-right: 5px"
          >check_circle</mat-icon
        >
        <mat-icon
          *ngIf="checklistItemFrontend.status === 'deviation'"
          [color]="checklistItemFrontend.deviationSignedBy ? undefined : 'warn'"
          style="vertical-align: middle; margin-bottom: 5px; margin-right: 5px"
          [style.color]="checklistItemFrontend.deviationSignedBy ? '#aaa' : ''"
          >cancel</mat-icon
        >
        <mat-icon
          *ngIf="checklistItemFrontend.status === 'not_applicable'"
          style="
            color: #aaa;
            vertical-align: middle;
            margin-bottom: 5px;
            margin-right: 5px;
          "
          >block</mat-icon
        ></span
      >
      <app-cached-indicator [pullRight]="true" [visible]="checklistItemFrontend.fromCache"></app-cached-indicator>
      <strong *ngIf="!user.language || user.language.id === 'no' || !checklistItemFrontend.enText || checklistItemFrontend.enText.length === 0">
        {{ checklistItemFrontend.text }}
      </strong>
      <strong *ngIf="user.language?.id === 'en'">{{ checklistItemFrontend.enText }}</strong>
      <p-tag *ngIf="user.language?.id === 'en' && (!checklistItemFrontend.enText || checklistItemFrontend.enText.length === 0)"
             class="dimmed-20 pull-right"
             icon="pi pi-exclamation-triangle"
             severity="warning"
             [value]="user.language.id === 'en' ? 'English text missing' : 'Mangler engelsk tekst'"
      >
      </p-tag>
    </div>
    <!-- <div style="padding-top: 5px">
        <app-upload-button
          *ngIf="!locked"
          [dbPath]="checklistItemFrontend.path"
        ></app-upload-button>
      </div> -->
    <!-- <div class="fill-space"></div> -->
    <!-- <p *ngIf="mobileQuery.matches"></p> -->
    <!-- <div class="row"> -->
    <!-- <div [ngClass]="mobileQuery.matches ? 'row' : ''"> -->
    <!-- <div class="fill-space"></div> -->
    <div *ngIf="checklistItemFrontend.type.name === 'Ekstern dokumentasjon'">
      <div style="color: #aaa">(Ekstern dokumentasjon)</div>
    </div>
    <div *ngIf="checklistItemFrontend.type.name === 'Måling'">
      <!-- <div class="fill-space"></div> -->
      <div *ngIf="locked">
        <div style="color: #aaa">Verdi:</div>
        <div style="white-space: pre-wrap">
          {{ checklistItemFrontend.value }}
        </div>
        <div style="color: #aaa">
          <span *ngIf="!user.language || user.language.id === 'no'">Kommentar:</span>
          <span *ngIf="user.language?.id === 'en'">Comment:</span>
        </div>
        <div style="white-space: pre-wrap">
          {{ checklistItemFrontend.valuecomment }}
        </div>
      </div>
      <form [formGroup]="FormValue" *ngIf="!locked">
        <mat-form-field>
          <textarea
            cdkTextareaAutosize
            style="padding: 0; min-height: 14px !important; padding-bottom: 3px"
            value="{{ valuestring }}"
            #value
            matInput
            required
            placeholder="Verdi"
            (keyup.enter)="updateValue(value.value)"
            (blur)="updateValue(value.value)"
          ></textarea>
          <span matSuffix *ngIf="checklistItemFrontend.unit"
            >{{ checklistItemFrontend.unit }} &nbsp;</span
          >
          <mat-error *ngIf="value.form.touched && value.form.invalid">
            Verdi skal fylles ut
          </mat-error>
        </mat-form-field>
      </form>
      <form [formGroup]="FormValueComment" *ngIf="!locked">
        <mat-form-field>
          <textarea
            cdkTextareaAutosize
            style="padding: 0; min-height: 14px !important; padding-bottom: 3px"
            value="{{ valuecommentstring }}"
            #valuecomment
            matInput
            required
            placeholder="Sted"
            (blur)="updateValueComment(valuecomment.value)"
            (keyup.enter)="updateValueComment(valuecomment.value)"
          ></textarea>
          <mat-error *ngIf="valuecomment.form.touched && valuecomment.form.invalid">
            Sted skal fylles ut
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <mat-button-toggle-group
      style="width: 100%; margin-top: 15px"
      *ngIf="!locked && checklistItemFrontend.type.name === 'Avkrysning'"
      [(value)]="checklistItemFrontend.status"
      name="fontStyle"
      aria-label="Font Style"
      appearance="legacy"
    >
      <mat-button-toggle
        style="width: 100%; padding: 5px"
        mat-fab
        value="ok"
        matTooltip="OK"
        (click)="updateStatus('ok')"
      >
        <mat-icon
          *ngIf="!loadingOk"
          [color]="checklistItemFrontend.status === 'ok' ? 'primary' : undefined"
          >check_circle</mat-icon
        >
        <mat-spinner
          *ngIf="loadingOk"
          diameter="24"
          style="margin-top: 6px"
        ></mat-spinner>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 100%; padding: 5px"
        mat-fab
        value="deviation"
        matTooltip="Avvik"
        (click)="updateStatus('deviation')"
      >
        <mat-icon
          *ngIf="!loadingDeviation"
          [color]="checklistItemFrontend.status === 'deviation' ? 'warn' : undefined"
          >cancel</mat-icon
        >
        <mat-spinner
          *ngIf="loadingDeviation"
          diameter="24"
          style="margin-top: 6px"
        ></mat-spinner>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 100%; padding: 5px"
        mat-fab
        value="not_applicable"
        matTooltip="N/A"
        (click)="updateStatus('not_applicable')"
      >
        <mat-icon
          *ngIf="!loadingNA"
          [color]="
            checklistItemFrontend.status === 'not_applicable' ? 'accent' : undefined
          "
          >block</mat-icon
        >
        <mat-spinner
          *ngIf="loadingNA"
          diameter="24"
          style="margin-top: 6px"
        ></mat-spinner>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <span *ngIf="statustext"> {{ statustext }}</span>
    <!-- </div> -->
  </div>
  <div style="margin-bottom: 0; padding-top: 10px">
    <!-- *ngIf="checklistItemFrontend.status === 'deviation' || checklistItemFrontend.type.name === 'Kommentar'" -->
    <!-- <div class="fill-space"></div> -->
    <div>
      <!-- <div class="fill-space"></div> -->
      <div
        *ngIf="locked && checklistItemFrontend.comment"
        style="margin-bottom: 10px"
      >
        <div style="color: #aaa">
          <span *ngIf="!user.language || user.language.id === 'no'">Kommentar:</span>
          <span *ngIf="user.language?.id === 'en'">Comment:</span>
        </div>
        <div style="white-space: pre-wrap">
          {{ checklistItemFrontend.comment }}
        </div>
      </div>
      <form [formGroup]="Form" *ngIf="!locked">
        <mat-form-field style="width: 100%">
          <textarea
            cdkTextareaAutosize
            style="padding: 0; min-height: 14px !important; padding-bottom: 3px"
            [value]="checklistItemFrontend.comment"
            id="comment"
            #comment
            matInput
            [required]="
              checklistItemFrontend.status === 'deviation' ||
              checklistItemFrontend.type.name === 'Kommentar'
            "
            [placeholder]="user.language?.id === 'en' ? 'Comment' : 'Kommentar'"
            (keyup.enter)="updateComment(comment.value)"
            (blur)="updateComment(comment.value)"
          ></textarea>
          <mat-icon
            matSuffix
            color="error"
            *ngIf="
              checklistItemFrontend.status === 'deviation' ||
              checklistItemFrontend.type.name === 'Kommentar'
            "
            >error_outline</mat-icon
          >
          <mat-error
            *ngIf="
              comment.form.touched &&
              comment.form.invalid &&
              (checklistItemFrontend.status === 'deviation' ||
                checklistItemFrontend.type.name === 'Kommentar')
            "
          >
            Kommentar skal fylles ut
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div *ngIf="files">
    <div class="flex-container">
      <!-- <span *ngFor="let file of files"> -->
      <!-- <span *ngIf="item.filesID | contains: file.id"> -->
      <app-thumbnail
        class="flex-item"
        [file]="file"
        [files]="files"
        [isLocked]="locked"
        *ngFor="let file of files"
      ></app-thumbnail>
      <!-- <app-thumbnail
        class="flex-item"
        *ngIf="!locked"
        [newItem]="true"
      ></app-thumbnail> -->
      <app-upload-button
        class="flex-item"
        *ngIf="!locked"
        [dbPath]="checklistItemFrontend.path"
        [button]="false"
      ></app-upload-button>
      <!-- </span> -->
      <!-- </span> -->
    </div>
    <app-not-available-when-offline></app-not-available-when-offline>
  </div>
  <div style="min-height: 20px">
    <div class="row">
      <div class="fill-space"></div>
      <app-signed-initials
        *ngIf="checklistItemFrontend.signedBy"
        [user]="checklistItemFrontend.signedBy.user"
        [datetime]="checklistItemFrontend.lastupdatedString"
        showInitials="true"
      ></app-signed-initials>
    </div>
  </div>

  <div
    style="margin-top: 10px"
    *ngIf="
      user.role?.name !== 'customer' &&
      checklistItemFrontend.status === 'deviation' &&
      locked &&
      !deviationPopup
    "
  >
    <div
      class="row"
      *ngIf="!checklistItemFrontend.deviationSignedBy && !deviationPopup"
    >
      <div class="fill-space"></div>
      <div>
        <button
          mat-raised-button
          (click)="openDeviationSign()"
          style="margin-bottom: 10px"
        >
          <mat-icon color="accent">build_circle</mat-icon>
          <span class="button-icon-text">Sjekk ut avvik</span>
        </button>
      </div>
    </div>
    <div *ngIf="checklistItemFrontend.deviationSignedBy">
      <div class="row">
        <div>
          <strong>
            <mat-icon
              color="primary"
              style="
                vertical-align: middle;
                margin-bottom: 5px;
                margin-right: 5px;
              "
              >check_circle</mat-icon
            >Avvik sjekket ut:</strong
          >
        </div>
        <div class="fill-space"></div>
        <div *ngIf="user?.role?.name === 'admin' && !deviationLock">
          <div
            matTooltip="Rediger utsjekk"
            style="
              vertical-align: middle;
              margin-bottom: 5px;
              margin-right: 5px;
              cursor: pointer;
            "
            (click)="openDeviationSign()"
          >
            <mat-icon>edit</mat-icon>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="deviationfiles">
          <app-thumbnail
            [file]="deviationfile"
            [files]="deviationfiles"
            [isLocked]="locked"
            *ngFor="let deviationfile of deviationfiles"
          ></app-thumbnail>
        </div>
      </div>
      <div style="color: #aaa">
        <span *ngIf="!user.language || user.language.id === 'no'">Kommentar:</span>
        <span *ngIf="user.language?.id === 'en'">Comment:</span></div>
      <div style="white-space: pre-wrap">
        {{ checklistItemFrontend.deviationcomment }}
      </div>
      <!-- <app-signed
        [(user)]="checklistItemFrontend.deviationSignedBy.user"
        [(timestamp)]="checklistItemFrontend.deviationSignedBy.timestamp"
      ></app-signed> -->
      <div class="row">
        <div class="fill-space"></div>
        <app-signed-initials
          [user]="checklistItemFrontend.deviationSignedBy.user"
          [datetime]="checklistItemFrontend.deviationSignedByString"
          showInitials="true"
        ></app-signed-initials>
      </div>
    </div>
  </div>
  <!-- <mat-divider *ngIf="!deviationPopup"></mat-divider> -->
  <!-- <mat-card style="margin-top: 5px;" *ngFor="let item of ksList">
  <div>
    <span>
      {{ item.text }}
    </span>
    <file-upload-button
      *ngIf="!isSigned"
      style="float: right;"
      [dbRefFileCol]="dbRefFileCol"
      [dbRefDoc]="dbRefDocItem + '/' + item.id"
      [storageFolder]="storageFolderItem + '/' + item.id"
    >
    </file-upload-button>
  </div>
  <div *ngIf="isSigned">
    <br />
    <div *ngIf="item.status === 'OK'">
      <mat-chip-list>
        <mat-chip selected>OK </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="item.status === 'N/A'">
      <mat-chip-list>
        <mat-chip color="accent" selected>N/A </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="item.status === 'Avvik'">
      <mat-chip-list>
        <mat-chip color="warn" selected>Avvik </mat-chip>
      </mat-chip-list>
      <br />
      <div style="font-style: italic;">
        <mat-icon style="color: grey; margin-bottom: -15px;">comment</mat-icon>
        {{ item.comment }}
      </div>
    </div>
  </div>
  <mat-card-actions *ngIf="!isSigned">
    <mat-chip-list style="min-width: 160px;">
      <mat-chip
        (click)="setValue(dbRefDocItem + '/' + item.id, 'OK')"
        [selected]="item.status === 'OK'"
        >OK
      </mat-chip>
      <mat-chip
        color="warn"
        (click)="setValue(dbRefDocItem + '/' + item.id, 'Avvik')"
        [selected]="item.status === 'Avvik'"
        >Avvik
      </mat-chip>
      <mat-chip
        color="accent"
        (click)="setValue(dbRefDocItem + '/' + item.id, 'N/A')"
        [selected]="item.status === 'N/A'"
        >N/A
      </mat-chip>
    </mat-chip-list>
    <p *ngIf="item.status === 'Avvik'" style="margin-top: 40px;">
      <mat-form-field>
        <input
          ngModel="{{ item.comment }}"
          #comment="ngModel"
          matInput
          required
          placeholder="Kommentar"
          (blur)="updateComment(comment, dbRefDocItem + '/' + item.id)"
        />
        <mat-error *ngIf="comment.touched && comment.invalid">
          Kommentar skal fylles ut
        </mat-error>
      </mat-form-field>
    </p>
  </mat-card-actions>
  <div *ngIf="item">
    <div *ngIf="item.filesID && item.filesID.length > 0">
      <div style="margin-top: 20px;">
        <mat-progress-bar
          *ngIf="!filesList"
          mode="indeterminate"
        ></mat-progress-bar>
        <span *ngFor="let file of filesList">
          <span *ngIf="item.filesID | contains: file.id">
            <image-tumbnail
              [file]="file"
              [dbRefChecklistDoc]="dbRefChecklistDoc"
              [dbRefFileDoc]="dbRefFileCol + file.id"
              [isLocked]="isSigned"
            ></image-tumbnail>
          </span>
        </span>
      </div>
    </div>
  </div>
</mat-card> -->
</div>
