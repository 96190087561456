<!-- <div class="row"> -->
<div class="background"></div>
<img
  src="/assets/logo/logo_dark.png"
  routerLink="/"
  [style.height]="
    mobileQuery.matches ? (mobileQueryLogo.matches ? '20px' : '35px') : '40px'
  "
  [style.padding-top]="
    mobileQuery.matches ? (mobileQueryLogo.matches ? '23px' : '3px') : '0px'
  "
  style="
    cursor: pointer;
    padding-left: 10px;
    position: absolute;
    left: 10px;
    top: 10px;
  "
  alt=""
  *ngIf="!mobileQuery.matches"
  />
<div
  [style.position]="mobileQuery.matches ? 'fixed' : 'relative'"
  [style.bottom]="mobileQuery.matches ? '0' : ''"
  [style.left]="mobileQuery.matches ? '0' : ''"
  [style.width]="mobileQuery.matches ? '100%' : ''"
  [style.z-index]="mobileQuery.matches ? '900' : ''"
  [style.background]="mobileQuery.matches ? '#303030' : ''"
>
  <mat-divider *ngIf="mobileQuery.matches"></mat-divider>
  <!-- <app-headertext text="Aventi APG" hideback="true"></app-headertext> -->
  <div *ngIf="pathsplit && user && user.role?.name === 'customer'" class="row">
    <div class="fill-space"></div>
    <button
      mat-button
      class="header-row-button"
      routerLink="/"
      [color]="
        !pathsplit[0] || pathsplit[0] === '' || pathsplit[0] === 'p'
          ? 'primary'
          : undefined
      "
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>supervised_user_circle</mat-icon
      ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches"
        >Kundeportal</span
      >
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Kundeportal
      </div>
    </button>
    <div class="fill-space" *ngIf="mobileQuery.matches"></div>
    <button
      mat-button
      (click)="logout()"
      class="header-row-button"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>exit_to_app</mat-icon
      ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches"
        >Logg av</span
      >
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Logg av
      </div>
    </button>
    <div class="fill-space" *ngIf="mobileQuery.matches"></div>
  </div>
  <div *ngIf="pathsplit && user && user.role?.name !== 'customer'" class="row">
    <div class="fill-space"></div>
    <button
      mat-button
      class="header-row-button"
      routerLink="/"
      [color]="
        !pathsplit[0] || pathsplit[0] === '' || pathsplit[0] === 'p'
          ? 'primary'
          : undefined
      "
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>view_list</mat-icon
      ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches"
        >Prosjekt</span
      >
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Prosjekt
      </div>
    </button>
    <div class="fill-space" *ngIf="mobileQuery.matches"></div>
    <!-- <button
      *ngIf="true || (isAdmin && page !== 'admin')"
      mat-button
      class="header-row-button"
      routerLink="/a"
      [color]="pathsplit[0] === 'a' ? 'primary' : ''"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>admin_panel_settings</mat-icon
      ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches">Admin</span>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Admin
      </div>
    </button>
    <div class="fill-space" *ngIf="mobileQuery.matches"></div> -->
    <button
      mat-button
      (click)="newReceiptform()"
      class="header-row-button"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>cases</mat-icon>
      <span style="padding-left: 5px" *ngIf="!mobileQuery.matches">Mottak</span>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Mottak
      </div>
    </button>
    <div class="fill-space" *ngIf="mobileQuery.matches"></div>
    <!-- <button
      mat-button
      (click)="bugReport()"
      class="header-row-button"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>bug_report</mat-icon
      ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches"
        >Meld feil</span
      >
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Meld feil
      </div>
    </button>
    <div class="fill-space" *ngIf="mobileQuery.matches"></div>
    <button
      mat-button
      (click)="logout()"
      class="header-row-button"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>exit_to_app</mat-icon
      ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches"
        >Logg av</span
      >
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Logg av
      </div>
    </button> -->
    <button
      mat-button
      class="header-row-button"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>menu</mat-icon>
      <span style="padding-left: 5px" *ngIf="!mobileQuery.matches">Meny</span>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
        *ngIf="mobileQuery.matches"
      >
        Meny
      </div>
    </button>
    <!-- <button
      *ngIf="isAdmin && mobileQuery.matches"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="header-row-button"
    >
      <mat-icon>menu</mat-icon>
      <span style="padding-left: 5px">Meny</span>
    </button>
    <button
      *ngIf="isAdmin && !mobileQuery.matches"
      mat-button
      [matMenuTriggerFor]="menu"
      class="header-row-button"
    >
      <mat-icon>menu</mat-icon>
      <span style="padding-left: 5px">Meny</span>
    </button> -->
    <div class="fill-space" *ngIf="mobileQuery.matches"></div>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item (click)="toggleEditMode()">
    <mat-icon>edit</mat-icon>
    {{ editModeButtonName }}
  </button> -->
      <!-- <button mat-menu-item routerLink="/a/objekttyper">
        <mat-icon>text_fields</mat-icon>
        Objekttyper
      </button> -->

      <button
        mat-menu-item
        routerLink="/m"
        [disabled]="!user"
        [style.color]="!pathsplit[2] && pathsplit[0] === 'm' ? '#398E3C' : ''"
      >
        <mat-icon
          [color]="!pathsplit[2] && pathsplit[0] === 'm' ? 'primary' : undefined"
          >account_circle</mat-icon
        ><span style="padding-left: 5px">Min side</span>
        <div
          style="
            font-size: 10px;
            padding: 0;
            margin-top: -15px;
            margin-bottom: 0;
          "
          *ngIf="mobileQuery.matches"
        >
          Min side
        </div>
      </button>
      <button
        mat-menu-item
        routerLink="/compare"
        [disabled]="!user"
        [style.color]="pathsplit[0] === 'compare' ? '#398E3C' : ''"
      >
        <mat-icon [color]="pathsplit[0] === 'compare' ? 'primary' : undefined"
          >compare_arrows</mat-icon
        ><span style="padding-left: 5px">Sammenlign filer</span>
        <div
          style="
            font-size: 10px;
            padding: 0;
            margin-top: -15px;
            margin-bottom: 0;
          "
          *ngIf="mobileQuery.matches"
        >
          Sammenlign filer
        </div>
      </button>

      <button
        mat-menu-item
        routerLink="/a"
        [disabled]="!user || user?.role?.name !== 'admin'"
        [style.color]="pathsplit[0] === 'a' ? '#398E3C' : ''"
      >
        <mat-icon [color]="pathsplit[0] === 'a' ? 'primary' : undefined"
          >admin_panel_settings</mat-icon
        ><span style="padding-left: 5px">Admin</span>
        <div
          style="
            font-size: 10px;
            padding: 0;
            margin-top: -15px;
            margin-bottom: 0;
          "
          *ngIf="mobileQuery.matches"
        >
          Admin
        </div>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="reloadVersion()">
        <mat-icon color="primary">cached</mat-icon
        ><span style="margin-left: 5px">Tving oppdatering</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="bugReport()" [disabled]="!user">
        <mat-icon>bug_report</mat-icon
        ><span style="padding-left: 5px">Meld feil</span>
        <div
          style="
            font-size: 10px;
            padding: 0;
            margin-top: -15px;
            margin-bottom: 0;
          "
          *ngIf="mobileQuery.matches"
        >
          Meld feil
        </div>
      </button>
      <button mat-menu-item (click)="logout()" [disabled]="!user">
        <mat-icon>exit_to_app</mat-icon
        ><span style="padding-left: 5px">Logg av</span>
        <div
          style="
            font-size: 10px;
            padding: 0;
            margin-top: -15px;
            margin-bottom: 0;
          "
          *ngIf="mobileQuery.matches"
        >
          Logg av
        </div>
      </button>
    </mat-menu>

    <!-- <mat-menu #adminmenu="matMenu">
      <button mat-menu-item routerLink="/a/objekttyper">
        <mat-icon>text_fields</mat-icon>
        Objekttyper
      </button>
      <button mat-menu-item routerLink="/a/changelog">
        <mat-icon>published_with_changes</mat-icon>
        Changelog
      </button>
      <button mat-menu-item routerLink="/a/brukere">
        <mat-icon>people</mat-icon>
        Brukere
      </button>
    </mat-menu> -->
  </div>
</div>

<mat-divider *ngIf="!mobileQuery.matches"></mat-divider>
<div
  [style.height]="
    mobileQuery.matches ? 'calc(100% - 60px)' : 'calc(100% - 60px)'
  "
  [style.position]="mobileQuery.matches ? 'absolute' : 'relative'"
  [style.width]="mobileQuery.matches ? '100%' : '100%'"
>
  <!-- <div class="fill-space"></div> -->
  <app-aventibackground
    *ngIf="page === 'admin' || page === ''"
    [showversion]="true"
  ></app-aventibackground>
  <!-- </div> -->
  <!-- <mat-tab-group mat-align-tabs="center">
  <mat-tab label="Prosjekt" routerLink="/"> -->
  <div *ngIf="page === ''">
    <mat-progress-bar
      *ngIf="!projectsFrontend"
      mode="indeterminate"
    ></mat-progress-bar>
    <div *ngIf="projectsFrontend">
      <div class="row">
        <app-headertext text="Prosjektliste" hideback="true"></app-headertext>
        <div class="fill-space"></div>
        <div>
          <button
            *ngIf="user && user.superAdmin"
            mat-button
            matTooltip="Nytt prosjekt"
            (click)="newProject()"
            class="header-row-button"
          >
            <mat-icon color="primary">add</mat-icon>
            Nytt prosjekt
          </button>
        </div>
      </div>
    </div>
    <app-project-list
      *ngIf="projectsFrontend"
      [projects]="projectsFrontend"
    ></app-project-list>
  </div>
  <div *ngIf="page === 'admin'">
    <app-admin></app-admin>
  </div>
  <div
    *ngIf="page === 'objekttyper'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-objecttypes></app-objecttypes>
  </div>
  <div
    *ngIf="page === 'statuses'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-statuses></app-statuses>
  </div>
  <div
    *ngIf="page === 'objekttype'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-objecttype></app-objecttype>
  </div>
  <div
    *ngIf="page === 'sjekklistepunkter'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-checklistitems></app-checklistitems>
  </div>
  <div
    *ngIf="page === 'sjekklistepunkt'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-checklistitem></app-checklistitem>
  </div>
  <div
    *ngIf="page === 'changelog'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-changelog></app-changelog>
  </div>
  <div
    *ngIf="page === 'brukere'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-users></app-users>
  </div>
  <div
    *ngIf="page === 'bruker'"
    [style.padding-bottom]="mobileQuery.matches ? '80px' : '0'"
  >
    <app-user></app-user>
  </div>
  <div *ngIf="page === 'varsler'">
    <app-notifications></app-notifications>
  </div>
  <div *ngIf="page === 'minside'">
    <app-mypage></app-mypage>
  </div>
  <div *ngIf="page === 'compare'">
    <app-compare></app-compare>
  </div>
  <div *ngIf="page === 'compareExcel'">
    <app-compare-excel></app-compare-excel>
  </div>
</div>

<!-- </mat-tab>
  <mat-tab label="Admin" [disabled]="!isAdmin" routerLink="/a">
    <app-admin></app-admin>
  </mat-tab>
</mat-tab-group> -->
<!--
<div style="position: absolute; left: 20px; bottom: 20px">

  <button
  mat-button
  (click)="bugReport()"
  class="header-row-button"
  [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
  [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
  [disabled]="!user"
>
  <mat-icon>bug_report</mat-icon
  ><span style="padding-left: 5px" *ngIf="!mobileQuery.matches"
    >Feilrapportering</span
  >
  <div
    style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
    *ngIf="mobileQuery.matches"
  >
  Feilrapportering
  </div>
</button>
</div> -->
