<app-headertext text="Sammenlign filer" [hideback]="true"></app-headertext>

<div class="row">
  <div class="fill-space"></div>
  <!-- <div class="maxwidth"> -->
  <div class="child-margin maxwidth">
    <mat-card routerLink="compare/excel" class="cursor">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>calculate</mat-icon>
        </div>
        <mat-card-title>Excel</mat-card-title>
        <mat-card-subtitle>Sammenlign excel filer</mat-card-subtitle>
      </mat-card-header>
    </mat-card>

    <!-- <mat-card
        routerLink="a/changelog"
        class="cursor"
      >
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>published_with_changes</mat-icon>
          </div>
          <mat-card-title>Endringslogg</mat-card-title>
          <mat-card-subtitle>APG versjonsoversikt</mat-card-subtitle>
        </mat-card-header>
      </mat-card> -->
  </div>

  <div class="fill-space"></div>
</div>
