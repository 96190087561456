import {animate, state, style, transition, trigger,} from '@angular/animations';
import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {DateService} from 'src/app/services/date.service';
import {IdService} from 'src/app/services/id.service';
import {PopupService} from 'src/app/services/popup.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {ChecklistService} from 'src/app/services/checklist.service';
import {AdminService} from 'src/app/services/admin.service';

@Component({
  animations: [
    trigger('animateDestroy', [
      state('void', style({opacity: '0'})),
      transition('* => void', animate('1000ms ease-in')),
    ]),
  ],
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.css'],
})
export class ChecklistItemComponent implements OnInit {
  checklistId: string;
  @Input() checklistItemId: string;
  @Input() checklistItem: ChecklistItem;
  @Input() locked: boolean;
  @Input() isReceiptform = false;
  @Input() files: AventiFile[];
  deviationfiles: AventiFile[];
  @Input() deviationPopup = false;
  @Input() deviationLock = false;
  @Input() objectTypeId: string;
  checklistItemFrontend: ChecklistItem;
  checklistitems: ChecklistItem[];
  user: User;
  loadingOk: boolean;
  loadingDeviation: boolean;
  loadingNA: boolean;
  Form = new UntypedFormGroup({
    comment: new UntypedFormControl('', [Validators.required]),
  });
  FormValue = new UntypedFormGroup({
    value: new UntypedFormControl('', [Validators.required]),
  });
  FormValueComment = new UntypedFormGroup({
    valuecomment: new UntypedFormControl('', [Validators.required]),
  });
  error: string;
  valuestring = '';
  valuecommentstring = '';
  statustext = '';
  mobileQuery: MediaQueryList;
  private ngUnsubscribe = new Subject();

  constructor(
    private checklistservice: ChecklistService,
    private idservice: IdService,
    private dateservice: DateService,
    media: MediaMatcher,
    private sharedservice: SharedService,
    private popupservice: PopupService,
    private adminservice: AdminService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  get comment() {
    return this.Form.get('comment');
  }

  get value() {
    return this.FormValue.get('value');
  }

  get valuecomment() {
    return this.FormValueComment.get('valuecomment');
  }

  ngOnInit() {
    this.checklistId = this.idservice.checklistId();
    let checklistItemPath: string;
    if (this.isReceiptform) {
      checklistItemPath =
        'projects/' +
        this.idservice.projectId() +
        '/receiptforms/' +
        this.idservice.receiptformId() +
        '/checklistitems/' +
        this.checklistItemId;
    } else {
      checklistItemPath =
        'projects/' +
        this.idservice.projectId() +
        '/objects/' +
        this.idservice.objectId() +
        '/checklists/' +
        this.checklistId +
        '/checklistitems/' +
        this.checklistItemId;
    }

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
        }
      });

    this.checklistservice.getChecklistItem(this.checklistItemId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((is) => {
        if (is !== null && is !== undefined) {
          const checklistItemTemp = is;
          if (checklistItemTemp.value) {
            this.valuestring = checklistItemTemp.value;
          }
          if (checklistItemTemp.valuecomment) {
            this.valuecommentstring = checklistItemTemp.valuecomment;
          }
          if (checklistItemTemp.lastupdated) {
            checklistItemTemp.lastupdatedString =
              this.dateservice.dateAsHHNNSSDDMMYYY(
                checklistItemTemp.lastupdated
              );
          }
          if (checklistItemTemp.deviationSignedBy?.timestamp) {
            checklistItemTemp.deviationSignedByString =
              this.dateservice.dateAsHHNNSSDDMMYYY(
                checklistItemTemp.deviationSignedBy?.timestamp
              );
          }
          checklistItemTemp.path = checklistItemPath;
          if (!this.objectTypeId && this.isReceiptform) {
            this.objectTypeId = 'aA32zATR143hYG8Sx4Yh';
          }
          this.adminservice
            .getChecklistitems(this.objectTypeId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((checklistitems) => {
              this.checklistitems = _.reject(checklistitems, _.isNull);
              if (this.checklistitems?.length > 0) {
                checklistItemTemp.enText =
                  this.checklistitems.find((item: ChecklistItem) => item.text === checklistItemTemp.text)?.enText;
                if (checklistItemTemp.enText === '-') {
                  checklistItemTemp.enText = checklistItemTemp.text;
                }
                this.checklistItemFrontend = checklistItemTemp;
                this.loadingOk = false;
                this.loadingDeviation = false;
                this.loadingNA = false;
              }
            });
        }
      });

    this.checklistservice.getChecklistItemFiles(this.checklistId, this.checklistItemId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((fs) => {
        if (fs !== null) {
          const files: AventiFile[] = [];
          const deviationfiles: AventiFile[] = [];
          fs.forEach((file) => {
            if (file.checklistitemid === this.checklistItemId) {
              file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
                file.timestamp
              );
              if (file.isdeviationsign) {
                deviationfiles.push(file);
              } else {
                files.push(file);
              }
            }
          });
          this.files = _.sortBy(files, 'timestamp');
          this.deviationfiles = _.sortBy(deviationfiles, 'timestamp');
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  async updateStatus(status) {
    switch (status) {
      case 'ok':
        this.loadingOk = true;
        break;
      case 'deviation':
        this.loadingDeviation = true;
        break;
      case 'not_applicable':
        this.loadingNA = true;
        break;
    }
    this.checklistservice.updateChecklistitemStatus(
      this.checklistItemFrontend.id,
      status,
      this.isReceiptform
    );
  }

  updateComment(comment) {
    console.log(comment);
    this.checklistservice.updateChecklistitemComment(
      this.checklistItemFrontend.id,
      comment,
      this.isReceiptform
    );
  }

  updateValue(value, silenced: boolean = false) {
    this.checklistservice.updateChecklistitemValue(
      this.checklistItemFrontend.id,
      value,
      silenced,
      this.isReceiptform
    );
  }

  updateValueComment(valuecomment) {
    this.checklistservice.updateChecklistitemValueComment(
      this.checklistItemFrontend.id,
      valuecomment,
      this.isReceiptform
    );
  }

  openDeviationSign() {
    this.popupservice.openDialogDeviationSign(this.checklistItemFrontend);
  }
}
