<div class="row border noselect">
  <mat-icon style="font-size: 38px; margin-right: 8px; height: 38px; width: 38px;" color="primary"
    >check_circle_outline</mat-icon
  >
  <div>
    <div>
      <span style="color: #aaa">Signert av:</span> {{ user.firstname }}
      {{ user.lastname }}
    </div>
    <div><span style="color: #aaa">Dato:</span> {{ date }}</div>
  </div>
</div>
<!-- <button
  mat-button
  (click)="generatePdf()"
  class="header-row-button"
  *ngIf="isAdmin"
>
  <mat-icon color="warn">picture_as_pdf</mat-icon>
  <span *ngIf="!running">Generer PDF</span>
  <span *ngIf="running"><mat-spinner diameter="30"></mat-spinner></span>
</button>
<a *ngIf="filename">Filnavn: {{ filename }}</a> -->
