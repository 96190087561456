import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChecklistItem } from 'src/app/models/Checklistitem';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-checklistitems',
  templateUrl: './checklistitems.component.html',
  styleUrls: ['./checklistitems.component.css'],
})
export class ChecklistitemsComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  checklistitems: ChecklistItem[];

  constructor(adminservice: AdminService) {
    adminservice.getChecklistitems().pipe(takeUntil(this.ngUnsubscribe)).subscribe((items) => {
      this.checklistitems = items;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSelectionChange() {}
}
