<div class="row">
  <app-headertext text="Sjekklistepunkter" hideback="true"></app-headertext>
  <div class="fill-space"></div>
  <button mat-button (click)="newChecklistitem()" class="header-row-button">
    <mat-icon color="primary">add</mat-icon>
    Nytt sjekklistepunkt
  </button>
  <!-- <button mat-button (click)="editChecklistitems()" class="header-row-button">
    <mat-icon color="accent">edit</mat-icon>
    Rediger sjekklistepunkt
  </button> -->
</div>

<div style="margin: 0 15px 0 15px;">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>

<div *ngIf="checklistitems && !isLoading && mobileQuery">
  <div class="row">
    <div class="fill-space"></div>
    <app-empty-list *ngIf="checklistitems.length === 0"></app-empty-list>
    <div class="fill-space"></div>
  </div>

  <div *ngIf="checklistitems.length > 0">
    <div class="row child-margin">
      <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
      <div class="maxwidth">
        <div *ngFor="let status of statuses; let first = first; let last = last">
          <mat-divider
            *ngIf="!first"
            style="margin-top: 10px;margin-bottom: 10px;"
            [inset]="false"
          ></mat-divider>

          <div class="row">
            <app-headertext [text]="status.name" hideback="true"></app-headertext>
            <div class="fill-space"></div>
            <button mat-icon-button (click)="newChecklistitem(status.id)" class="header-row-button"
                    *ngIf="status.name !== 'Uten statusnivå'">
              <mat-icon color="primary">add</mat-icon>
            </button>
          </div>

          <div *ngFor="let item of checklistitems">
            <mat-card
              *ngIf="item.statusLevel?.name === status.name || (status.name === 'Uten statusnivå' && !statusNames.includes(item.statusLevel?.name))"
              style="margin-bottom: 5px; padding-top: 0; padding-bottom: 0;"
            >
              <div
                class="row cursor"
                *ngIf="item.text"
                routerLink="{{ item.path }}"
              >
                <div>
                  <p style="padding-top: 0;">
                    <p-tag severity="success" value="NO" [rounded]="true" [style]="{'margin-right': '10px'}"></p-tag>
                    {{ item.text }}
                  </p>
                  <p>
                    <p-tag severity="info" value="EN" [rounded]="true" [style]="{'margin-right': '10px'}"></p-tag>
                    {{ item.enText }}
                    <p-tag *ngIf="!item.enText || item.enText.length === 0"
                           class="dimmed-20"
                           icon="pi pi-exclamation-triangle"
                           severity="warning"
                           value="Mangler engelsk tekst">
                    </p-tag>
                  </p>
                </div>
                <div class="fill-space"></div>
                <p>
                  {{ item.type.name }}
                  <span *ngIf="item.unit">Enhet: {{ item.unit }}</span>
                </p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    </div>
  </div>
</div>
