import {Timestamp} from '@firebase/firestore-types';
import {Checklist} from './Checklist';
import {AventiFile} from './File';
import {UserTimestamp} from './User';
import {ObjectStatus} from './Status';

export class ChecklistItem {
  public id?: string;
  public parentid?: string;
  public projectid?: string;
  public objectid?: string;
  public checklistid?: string;
  public text?: string;
  public enText?: string;
  public status?: string;
  public statusLevel?: ObjectStatus;
  public value?: string;
  public valuecomment?: string;
  public unit?: string;
  public comment?: string;
  public deviationcomment?: string;
  public type?: ChecklistItemType;
  public typename?: string;
  public signedBy?: UserTimestamp;
  public editedBy?: UserTimestamp[];
  public lastupdated?: Timestamp;
  public lastupdatedString?: string;
  public path?: string;
  public files?: AventiFile[];
  public imageCounter?: number;
  // tslint:disable-next-line:variable-name
  public z_filesstring?: string;
  // public deviationSigned?: boolean;
  public deviationSignedBy?: UserTimestamp;
  public deviationSignedByString?: string;
  public parentChecklist?: Checklist;
  public location?: any;
  public fromCache?: boolean;
}

export class ChecklistItemType {
  public id?: string;
  public name?: string;
}

export class ChecklistItemsLibraryItem {
  public text?: string;
  public enText?: string;
  public type?: ChecklistItemType;
  public statusLevel?: ObjectStatus;
  public id?: string;
  public parentid?: string;
  public unit?: string;
  public missingEn?: boolean;
}
