import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {User} from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor() {}

  lineBreak = '%0D%0A';

  bugReport(user: User) {
    const subject = 'APG - Feilmelding'; // message.subject;
    const body =
      'Hei,' +
      this.lineBreak +
      this.lineBreak +
      'Feilmeldingsinfo:' +
      this.lineBreak +
      'URL: ' +
      window.location.href +
      this.lineBreak +
      'UserID: ' +
      user.id +
      this.lineBreak +
      'Appversjon: ' +
      environment.appVersion +
      this.lineBreak +
      'Bruker Appversjon: ' +
      user.appVersion +
      this.lineBreak +
      this.lineBreak +
      'Følgende feil ble oppdaget i APG:' +
      this.lineBreak +
      this.lineBreak +
      '---------' +
      this.lineBreak +
      this.lineBreak +
      'SKRIV INN FEILMELDING HER' +
      this.lineBreak +
      this.lineBreak +
      '---------';
    this.sendEmail(environment.supportemail, subject, body);
  }

  sendEmail(toemail: string, subject: string, body: string) {
    window.location.href =
      'mailto:' + toemail + '?subject=' + subject + '&body=' + body;
  }
}
