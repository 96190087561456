import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Project} from 'src/app/models/Project';
import {ProjectAccess, User, UserRole} from 'src/app/models/User';
import {AdminService} from 'src/app/services/admin.service';
import {IdService} from 'src/app/services/id.service';
import {ProjectService} from 'src/app/services/project.service';
import {UserService} from 'src/app/services/user.service';
import {environment} from 'src/environments/environment';
import firebase from 'firebase/compat/app';

import {DateService} from 'src/app/services/date.service';
import {SortService} from 'src/app/services/sort.service';
import {SharedService} from 'src/app/services/shared.service';
import {Language} from 'src/app/models/Admin';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  isLoading = true;

  FormName = new UntypedFormGroup({
    firstname: new UntypedFormControl('', [Validators.required]),
    lastname: new UntypedFormControl('', [Validators.required]),
  });

  FormRole = new UntypedFormGroup({
    role: new UntypedFormControl('', [Validators.required]),
  });

  FormLanguage = new UntypedFormGroup({
    language: new UntypedFormControl('', [Validators.required]),
  });

  FormTitle = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
  });

  FormPhone = new UntypedFormGroup({
    phoneLocale: new UntypedFormControl('', [Validators.required]),
    phoneNumber: new UntypedFormControl('', [Validators.required]),
  });

  FormEmail = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
  });

  FormProjectaccess = new UntypedFormGroup({
    projectaccess: new UntypedFormControl('', [Validators.required]),
  });

  get firstname() {
    return this.FormName.get('firstname');
  }
  get lastname() {
    return this.FormName.get('lastname');
  }
  get role() {
    return this.FormRole.get('role');
  }
  get language() {
    return this.FormLanguage.get('language');
  }
  get title() {
    return this.FormTitle.get('title');
  }
  get phoneLocale() {
    return this.FormPhone.get('phoneLocale');
  }
  get phoneNumber() {
    return this.FormPhone.get('phoneNumber');
  }
  get email() {
    return this.FormEmail.get('email');
  }
  get projectaccess() {
    return this.FormProjectaccess.get('projectaccess');
  }

  editname = false;
  editrole = false;
  editlanguage = false;
  edittitle = false;
  editphone = false;
  editemail = false;
  editprojectaccess = false;

  user: User;
  roles: UserRole[] = [];
  languages: Language[] = [];
  projects: Project[] = [];

  mobileQuery: MediaQueryList;
  constructor(
    private userservice: UserService,
    private sharedservice: SharedService,
    private idservice: IdService,
    media: MediaMatcher,
    private adminservice: AdminService,
    private projectservice: ProjectService,
    private dateservice: DateService,
    private sortservice: SortService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.languages = this.sharedservice.getLanguages();
    this.projectservice
      .getAllShortProjects()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projects) => {
        if (projects !== null) {
          this.projects = projects;
          this.projects.sort((a, b) => (a.id > b.id ? 1 : -1));
        }
      });
    const userid: string = this.idservice.userId();
    this.sharedservice.users
      // .getUser(userid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users) => {
        if (users !== null) {
          users.forEach((user) => {
            if (user.id === userid) {
              if (user.lastseen) {
                user.lastseenString = this.dateservice.dateAsHHNNDDMMYYY(
                  user.lastseen
                );
              }
              if (user.lastupdated) {
                user.lastupdatedString = this.dateservice.dateAsHHNNDDMMYYY(
                  user.lastupdated
                );
              }
              if (!user.language) {
                user.language = this.languages.find(language => language.id === 'no');
              }
              this.user = user;
              this.isLoading = false;
              console.log(this.user);
            }
          });
        }
      });
    this.adminservice
      .getUserRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((roles: any) => {
        this.roles = roles;
      });
  }

  sendPasswordResetMail() {
    this.userservice.sendPasswordResetMail(this.user.email);
  }

  toggleeditname() {
    this.editname = !this.editname;
    this.FormName.patchValue({
      firstname: this.user.firstname,
      lastname: this.user.lastname,
    });
  }
  savename(firstname: string, lastname: string) {
    this.user.firstname = firstname;
    this.user.lastname = lastname;
    this.updateObject();
    this.editname = false;
  }

  toggleeditrole() {
    this.editrole = !this.editrole;
    this.FormRole.patchValue({
      role: this.user.role,
    });
  }
  saverole(role: UserRole) {
    this.user.role = role;
    this.updateObject();
    this.editrole = false;
  }

  toggleeditlanguage() {
    this.editlanguage = !this.editlanguage;
    this.FormLanguage.patchValue({
      language: this.language,
    });
  }
  savelanguage(languageInput: Language) {
    this.user.language = this.languages.find(language => language.id === languageInput.id);
    this.updateObject();
    this.editlanguage = false;
  }

  toggleedittitle() {
    this.edittitle = !this.edittitle;
    this.FormTitle.patchValue({
      title: this.user.title,
    });
  }
  savetitle(title: string) {
    this.user.title = title;
    this.updateObject();
    this.edittitle = false;
  }

  toggleeditphone() {
    this.editphone = !this.editphone;
    this.FormPhone.patchValue({
      phoneLocale: this.user.phone?.locale,
      phoneNumber: this.user.phone?.number,
    });
  }
  savephone(phoneLocale: string, phoneNumber: string) {
    if (!this.user.phone) {
      this.user.phone = { locale: '', number: '' };
    }
    this.user.phone.locale = phoneLocale;
    this.user.phone.number = phoneNumber;
    this.updateObject();
    this.editphone = false;
  }

  toggleeditemail() {
    this.editemail = !this.editemail;
    this.FormEmail.patchValue({
      email: this.user.email,
    });
  }
  saveemail(email: string) {
    this.user.email = email;
    this.updateObject();
    this.editemail = false;
  }

  toggleeditprojectaccess() {
    this.editprojectaccess = !this.editprojectaccess;
    this.user.projectaccess = this.user.projectaccess.sort(
      this.sortservice.dynamicSort('id')
    );
    this.FormProjectaccess.patchValue({
      projectaccess: this.user.projectaccess,
    });
  }
  saveprojectaccess(projectaccess: ProjectAccess[]) {
    let newprojectaccess: ProjectAccess[] = [];
    projectaccess.forEach((pa) => {
      const newpa: ProjectAccess = {
        id: pa.id,
        name: pa.name,
      };
      newprojectaccess.push(newpa);
    });
    newprojectaccess = newprojectaccess.sort(
      this.sortservice.dynamicSort('id')
    );
    this.user.projectaccess = newprojectaccess;
    this.updateObject();
    this.editprojectaccess = false;
  }

  updateObject() {
    const user = this.user;
    user.lastupdated = firebase.firestore.Timestamp.now();
    this.userservice.updateUser(user);
  }

  setActive(state: boolean) {
    this.user.active = state;
    this.updateObject();
  }

  setSuperadmin(state: boolean) {
    this.user.superAdmin = state;
    this.updateObject();
  }
}
