import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {Project} from 'src/app/models/Project';
import {ProjectService} from 'src/app/services/project.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {environment} from 'src/environments/environment';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css'],
  providers: [ProjectService],
})
export class NewProjectComponent implements OnInit {

  Form = new UntypedFormGroup({
    no: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    nameshort: new UntypedFormControl('', [Validators.required]),
  });
  error: string;
  message = 'Laster opp...';
  showMessage = false;
  projectDoc: AngularFirestoreDocument<Project>;
  projectData: Project;
  projectIds: string[] = [];
  mobileQuery: MediaQueryList;
  private ngUnsubscribe = new Subject();

  constructor(
    private dialogRef: MatDialogRef<NewProjectComponent>,
    private projectservice: ProjectService,
    media: MediaMatcher,
    private snackbarservice: SnackbarService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  get name() {
    return this.Form.get('name');
  }

  get no() {
    return this.Form.get('no');
  }

  get nameshort() {
    return this.Form.get('nameshort');
  }


  projectNoValidator(projectIds: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = projectIds.includes(control.value);
      return forbidden ? {projectNoExists: {value: control.value}} : null;
    };
  }

  ngOnInit(): void {
    this.projectservice.getAllProjectIds().pipe(takeUntil(this.ngUnsubscribe)).subscribe(projectIds => {
      if (projectIds?.length > 0) {
        this.projectIds = projectIds;
        this.Form.controls['no'].addValidators(this.projectNoValidator(projectIds));
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  create(no: string, name: string, nameshort?: string) {
    this.projectData = {
      no,
      name,
      nameshort
    };
    this.projectservice.createProject(this.projectData);
    this.Form.reset();
    this.Form.markAsPristine();
    this.Form.markAsUntouched();
    this.dialogRef.close();
    this.snackbarservice.openSnackBar(
      'Prosjekt opprettet: ' + this.projectData.name,
      ''
    );
  }
}
