<div class="row">
  <app-headertext text="Admin - Brukere"></app-headertext>
  <div class="fill-space"></div>
  <button
    mat-button
    matTooltip="Ny bruker"
    (click)="newUser()"
    class="header-row-button"
  >
    <mat-icon color="primary">person_add</mat-icon>
    Ny bruker
  </button>
</div>

<div style="margin: 0 15px 0 15px">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div>
    <app-empty-list *ngIf="isEmpty"></app-empty-list>
    <!-- <div>
      <div class="row" *ngIf="!isEmpty">
        <div class="fill-space"></div>
        <div>
          <mat-form-field style="width: 200px">
            <mat-label>Filter</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="f.eks. 'Skilt'"
            />
          </mat-form-field>
        </div>
      </div>
      <div> -->
    <!-- <div> -->
    <table [hidden]="isEmpty" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="filter" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef
          [colSpan]="displayedColumns.length"
        >
          <div class="row" *ngIf="!isEmpty">
            <div
              style="
                margin-right: 15px;
                margin-top: 15px;
                font-size: 12px;
                color: darkgray;
              "
            >
              Side {{ page }} <span *ngIf="pages">av {{ pages }}</span>
            </div>
            <div class="fill-space"></div>
            <div *ngIf="users">
              <form [formGroup]="FormFilter">
                <mat-form-field style="width: 200px">
                  <mat-label>Filter</mat-label>
                  <input
                    cdkFocusInitial
                    id="filter"
                    formControlName="filter"
                    matInput
                    (keyup)="applyFilter($event)"
                    placeholder="f.eks. 'Ola'"
                  />
                </mat-form-field>
              </form>
            </div>
            <div
              (click)="setFilter('')"
              class="cursor"
              style="
                margin-left: 5px;
                margin-top: 15px;
                font-size: 20px;
                color: darkgray;
              "
              *ngIf="filter && filter.length > 0"
            >
              <mat-icon inline="true">cancel</mat-icon>
            </div>
          </div>
        </th>
      </ng-container>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">
          {{ row.id }}
        </td>
      </ng-container>

      <!-- Mobile Name Column -->
      <ng-container matColumnDef="mobilename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bruker</th>
        <td mat-cell *matCellDef="let row">
          <div style="margin-bottom: 5px; margin-top: 5px">
            <div style="font-size: 14px; margin-bottom: 5px">
              <span *ngIf="row.firstname"> {{ row.firstname }}</span>
              <span *ngIf="row.lastname"> {{ row.lastname }}</span>
            </div>
            <div>
              <span *ngIf="row.email"> {{ row.email }}</span>
            </div>
            <div>
              <span *ngIf="row.phone">
                {{ row.phone.locale }} {{ row.phone.number }}</span
              >
            </div>
            <!-- <div>
              <span *ngIf="row.role?.name"> {{ row.role.name }}</span>
            </div>
            <div>
              <span *ngIf="row.superAdmin" style="color: rgb(234, 234, 34)"
                >Superadmin</span
              > -->
            <!-- <span *ngIf="!row.active" color="warn">Inaktiv</span> -->
            <!-- <mat-icon *ngIf="row.superadmin" color="primary">check_circle_outline</mat-icon>
                  <mat-icon *ngIf="!row.superadmin" color="warn">close</mat-icon>
            </div>-->
            <!-- <div>
              <span *ngIf="row.appVersion"> {{ row.appVersion }}</span>
            </div>
            <div>
              <span *ngIf="row.lastseenString"
                >Sist sett: {{ row.lastseenString }}</span
              >
            </div> -->

            <table
              style="padding-left: 0; padding-right: 15px; font-size: x-small"
            >
              <!-- <tr style="font-size: 14px; margin-bottom: 5px">
                <td><span *ngIf="row.firstname"> {{ row.firstname }}</span>
                  <span *ngIf="row.lastname"> {{ row.lastname }}</span></td>
                <td style="text-align: right"></td>
              </tr> -->
              <!-- <tr>
                <td><span *ngIf="row.email"> {{ row.email }}</span></td>
                <td style="text-align: right"><span *ngIf="row.phone">
                  {{ row.phone.locale }} {{ row.phone.number }}</span
                ></td>
              </tr> -->
              <tr>
                <td>
                  <span *ngIf="row.rolename">{{ row.rolename }}</span>
                </td>
                <td style="text-align: right">
                  <span *ngIf="row.superAdmin" style="color: rgb(234, 234, 34)"
                    >Superadmin</span
                  >
                </td>
              </tr>
              <tr *ngIf="row.appVersion">
                <td>Appversjon</td>
                <td style="text-align: right">{{ row.appVersion }}</td>
              </tr>
              <tr *ngIf="row.lastseenString">
                <td>Sist sett</td>
                <td style="text-align: right">{{ row.lastseenString }}</td>
              </tr>
              <tr *ngIf="row.lastupdatedString">
                <td>Sist oppdatert</td>
                <td style="text-align: right">{{ row.lastupdatedString }}</td>
              </tr>
            </table>
          </div>
        </td>
      </ng-container>
      <!-- Mobile setting Column -->
      <ng-container matColumnDef="mobilesetup">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Innstillinger</th>
        <td mat-cell *matCellDef="let row">
          <!-- <div>
                <span *ngIf="row.active" style="color:rgb(74,200,74)">Aktiv</span>
                <span *ngIf="!row.active" style="color:rgb(214,34,34)">Inaktiv</span>
              </div> -->
          <div>
            <span *ngIf="row.superAdmin" style="color: rgb(234, 234, 34)"
              >Superadmin</span
            >
            <!-- <span *ngIf="!row.active" color="warn">Inaktiv</span> -->
            <!-- <mat-icon *ngIf="row.superadmin" color="primary">check_circle_outline</mat-icon>
                <mat-icon *ngIf="!row.superadmin" color="warn">close</mat-icon> -->
          </div>
          <div>
            <span *ngIf="row.appVersion"> {{ row.appVersion }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Navn Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Navn</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.firstname"> {{ row.firstname }}</span>
          <span *ngIf="row.lastname"> {{ row.lastname }}</span>
        </td>
      </ng-container>
      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rolle</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.rolename"> {{ row.rolename }}</span>
        </td>
      </ng-container>
      <!-- Role Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.email"> {{ row.email }}</span>
        </td>
      </ng-container>
      <!-- Role Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.phone">
            {{ row.phone.locale }} {{ row.phone.number }}</span
          >
        </td>
      </ng-container>
      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktiv</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.active" color="primary"
            >check_circle_outline</mat-icon
          >
          <mat-icon *ngIf="!row.active" color="warn">close</mat-icon>
        </td>
      </ng-container>
      <!-- superAdmin Column -->
      <ng-container matColumnDef="superadmin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Superadmin</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.superAdmin" color="primary"
            >check_circle_outline</mat-icon
          >
          <mat-icon *ngIf="!row.superAdmin" color="warn">close</mat-icon>
        </td>
      </ng-container>
      <!-- Firebase Column -->
      <ng-container matColumnDef="firebaseAuth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Firebase Auth</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.firebaseUser" color="primary"
            >check_circle_outline</mat-icon
          >
          <mat-icon *ngIf="!row.firebaseUser" color="warn">close</mat-icon>
        </td>
      </ng-container>
      <!-- Role Column -->
      <ng-container matColumnDef="projectaccess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Prosjekt</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.projectaccess">
            <div *ngFor="let project of row.projectaccess">
              {{ project.id }}
            </div>
          </div>
        </td>
      </ng-container>
      <!-- Last app version Column -->
      <ng-container matColumnDef="appversion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Appversjon</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.appVersion"> {{ row.appVersion }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Info</th>
        <td mat-cell *matCellDef="let row">
          <table>
            <tr *ngIf="row.appVersion">
              <td>Appversjon</td>
              <td style="text-align: right">{{ row.appVersion }}</td>
            </tr>
            <tr *ngIf="row.lastseenString">
              <td>Sist sett</td>
              <td style="text-align: right">{{ row.lastseenString }}</td>
            </tr>
            <tr *ngIf="row.lastupdatedString">
              <td>Sist oppdatert</td>
              <td style="text-align: right">{{ row.lastupdatedString }}</td>
            </tr>
          </table>
          <!-- <div *ngIf="row.appVersion">
            Appversjon:
            <span style="text-align: right">{{ row.appVersion }}</span>
          </div>
          <div *ngIf="row.lastseenString">
            Sist sett:
            <span style="text-align: right">{{ row.lastseenString }}</span>
          </div>
          <div *ngIf="row.lastupdatedString">
            Sist oppdatert:
            <span style="text-align: right">{{ row.lastupdatedString }}</span>
          </div> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="paginator">
        <th
          mat-footer-cell
          *matFooterCellDef
          [colSpan]="displayedColumns.length"
        >
          <mat-paginator
            *ngIf="!isEmpty"
            [pageSize]="pageSize"
            (page)="pageChange($event)"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[10, 25, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </th>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['filter']; sticky: true"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        routerLink="a/brukere/{{ row.id }}"
        class="cursor"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
    </table>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</div>
