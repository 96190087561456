import {animate, state, style, transition, trigger} from '@angular/animations';
import {MediaMatcher} from '@angular/cdk/layout';
import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {BehaviorSubject, firstValueFrom, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {ChecklistService} from 'src/app/services/checklist.service';
import {CurrentpathService} from 'src/app/services/currentpath.service';
import {DateService} from 'src/app/services/date.service';
import {FileService} from 'src/app/services/file.service';
import {IdService} from 'src/app/services/id.service';
import {PopupService} from 'src/app/services/popup.service';
import {PrintChecklistService} from 'src/app/services/print-checklist.service';
import {SharedService} from 'src/app/services/shared.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';
import {ConfirmComponent} from '../../common/bottom-sheet/confirm/confirm.component';
import {CanDeactivateComponent} from '../../common/can-deactivate/can-deactivate.component';
import {AdminService} from 'src/app/services/admin.service';
import {ObjectStatus} from 'src/app/models/Status';
import {Project} from 'src/app/models/Project';
import {ObjectService} from 'src/app/services/object.service';

@Component({
  animations: [
    trigger('animateDestroy', [
      state('void', style({opacity: '0'})),
      transition('* => void', animate('1000ms ease-in')),
    ]),
  ],
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css'],
})
export class ChecklistComponent
  extends CanDeactivateComponent
  implements OnInit {
  checklistNotFound: boolean;
  checklist: Checklist;
  checklistTemp: Checklist;
  checklistitems: ChecklistItem[];
  checklistitemsChanges: ChecklistItem[];
  checklistfiles: AventiFile[];
  checklistpdfs: AventiFile[];
  object: AventiObject;
  isLoading = true;
  isAdmin = false;
  isSigning = false;
  isRemovingSignature = false;
  diffChecklistObject = false;
  sanitycheckNoPicture: boolean;
  sanitycheckNoData: boolean;
  sanitycheckDeviations: boolean;
  sanitycheckNoValue: boolean;
  sanitycheckNoComment: boolean;
  sanitycheckNoValueComment: boolean;
  isReceiptform = false;
  loadingText: string[];
  loadingHeaderText = 'Åpner skjema...';
  isOnline: boolean;
  user: User;
  FormComment = new UntypedFormGroup({
    comment: new UntypedFormControl('', []),
  });
  mobileQuery: MediaQueryList;
  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
  statuses: ObjectStatus[] = [];
  statusNames: string[] = [];
  statusCount: Record<string, { count: number, checked: number, priority: number }>;
  groupOnce = true;
  project: Project;
  private ngUnsubscribe = new Subject();

  constructor(
    private checklistservice: ChecklistService,
    private bottomSheet: MatBottomSheet,
    private snackbarservice: SnackbarService,
    private idservice: IdService,
    private fileservice: FileService,
    private sharedservice: SharedService,
    media: MediaMatcher,
    private printchecklistservice: PrintChecklistService,
    private dateservice: DateService,
    private popupservice: PopupService,
    private currentpathservice: CurrentpathService,
    private adminservice: AdminService,
    private objectservice: ObjectService,
  ) {
    super();
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  get comment() {
    return this.FormComment.get('comment');
  }

  canDeactivate(): boolean {
    return !this.checklist.generatingPDF;
  }

  ngOnInit() {
    this.sharedservice.project
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((project) => {
        if (project !== null) {
          this.project = project;
        }
      });
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          this.user = user[0];
        }
      });
    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });
    this.loadingText = [
      'Laster inn skjema...',
      'Laster inn punkter...',
      'Laster inn filer...',
    ];
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User[]) => {
        if (user !== null) {
          this.isAdmin = user[0].role.name === 'admin';
        }
      });
    let checklistid: string;
    let objectid: string;

    this.isReceiptform = this.idservice.isReceiptformPath();

    if (this.isReceiptform) {
      checklistid = this.idservice.receiptformId();
    } else {
      checklistid = this.idservice.checklistId();
    }
    objectid = this.idservice.objectId();

    this.checklistservice.getChecklistItems(checklistid, this.isReceiptform)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (is) => {
        if (is !== null) {
          const items: ChecklistItem[] = [];
          is.forEach((item) => {
            if (item.checklistid === checklistid) {
              item.typename = item.type.name;
              items.push(item);
            }
          });
          const itemsbyname = _.sortBy(items, 'text');
          const checklistitems = _.sortBy(itemsbyname, 'typename');
          this.loadingText[1] = 'Punkter lastet inn';
          this.checkAll();
          const allStatuses = await firstValueFrom(this.adminservice.getObjectStatuses());
          const allAdminChecklistitems = await firstValueFrom(this.adminservice.getAllChecklistitemsObs());
          const {
            statuses,
            statusNames,
            statusCount,
            isLoading,
            adminChecklistItems
          } = await this.checklistservice.groupChecklistItems(
            this.object,
            this.checklist,
            this.isReceiptform,
            this.project.useStatusLevel,
            allStatuses,
            allAdminChecklistitems,
            checklistitems
          );

          this.checklistitemsChanges = checklistitems;
          if (!this.checklistitems) {
            this.checklistitems = checklistitems.map(cl => {
                cl.statusLevel = adminChecklistItems?.find(acl => acl?.text === cl?.text)?.statusLevel;
                return cl;
              }
            )
            ;
          }

          if (JSON.stringify(_.sortBy(this.statuses, 'name')) !== JSON.stringify(_.sortBy(statuses, 'name'))) {
            this.statuses = statuses;
          }

          this.statusNames = statusNames;
          this.statusCount = statusCount;
          this.isLoading = isLoading;
        }
      });

    this.checklistservice.getChecklistFiles(checklistid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cfs) => {
        if (cfs !== null) {
          const files: AventiFile[] = [];
          cfs.forEach((file) => {
            if (!file.checklistitemid && file.checklistid === checklistid) {
              file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
                file.timestamp
              );
              files.push(file);
            }
          });
          this.checklistfiles = _.sortBy(files, 'timestamporiginal');
          this.loadingText[2] = 'Filer lastet inn';
          this.checkAll();
        }
      });
    if (this.isReceiptform) {
      this.object = {};
      this.checkAll();
    } else {
      this.sharedservice.objects
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((objects) => {
          if (objects !== null) {
            objects.forEach((object) => {
              if (object.id === objectid) {
                this.object = object;
                this.checkAll();
              }
            });
          }
        });
    }

    let checklistObs: BehaviorSubject<Checklist[]>;
    if (this.isReceiptform) {
      checklistObs = this.sharedservice.receiptforms;
    } else {
      checklistObs = this.sharedservice.checklists;
    }
    checklistObs.pipe(takeUntil(this.ngUnsubscribe)).subscribe((checklists) => {
      if (checklists !== null) {
        new Promise((resolve) => {
          checklists.forEach((checklist, index) => {
            if (checklist?.id === checklistid) {
              resolve(true);
              this.checklistTemp = checklist;
              this.sharedservice.prepareChecklistsChecklist(
                this.checklistTemp
              );
              if (!this.checklistTemp.generatingPDFchecklist) {
                this.isSigning = false;
              }
              if (
                !this.checklistTemp.pdfexports ||
                this.checklistTemp.pdfexports.length === 0
              ) {
                this.isRemovingSignature = false;
              }
              if (this.isReceiptform) {
                this.checklistTemp.path =
                  'projects/' +
                  this.idservice.projectId() +
                  '/receiptforms/' +
                  checklist.id;
              } else {
                this.checklistTemp.path =
                  'projects/' +
                  this.idservice.projectId() +
                  '/objects/' +
                  this.idservice.objectId() +
                  '/checklists/' +
                  checklist.id;
              }

              if (this.checklistTemp.pdfexports) {
                this.checklistTemp.pdfexports.forEach((pdf) => {
                  if (!pdf.sizeB || !pdf.sizeMB || !pdf.sizestring) {
                    this.fileservice.filesize(pdf).subscribe((size) => {
                      if (size) {
                        pdf.sizestring = size;
                      }
                    });
                  }
                });
              }
              this.checklistTemp.comment = this.checklistTemp.comment ?? '';
              this.checklist = this.checklistTemp;
              this.loadingText[0] = 'Skjema lastet inn';
              this.checkAll();
            } else if (index === checklists.length - 1 && !this.checklistTemp) {
              resolve(false);
              console.log('Ingen sjekkliste funnet...');
            }
          });
        }).then((result) => {
          if (!result) {
            console.log('checklists failed to load');
            this.failedToLoadChecklist();
          }
        });
      }
    });
  }

  failedToLoadChecklist() {
    this.checklistNotFound = true;
  }

  checkAll() {
    if (
      this.object &&
      this.checklist &&
      this.checklistitems &&
      this.checklistitemsChanges &&
      this.checklistfiles &&
      this.statuses
    ) {
      this.diffChecklistObject = false;
      if (!this.isReceiptform) {
        let checkO = this.object.name || '';
        let checkC = this.checklist.name || '';
        if (checkO !== checkC) {
          this.diffChecklistObject = true;
        }
        checkO = this.object.desc || '';
        checkC = this.checklist.desc || '';
        if (checkO !== checkC) {
          this.diffChecklistObject = true;
        }
        checkO = this.object.process || '';
        checkC = this.checklist.process || '';
        if (checkO !== checkC) {
          this.diffChecklistObject = true;
        }
        checkO = this.object.profilenumber || '';
        checkC = this.checklist.profilenumber || '';
        if (checkO !== checkC) {
          this.diffChecklistObject = true;
        }
      }

      let numberOfDeviations = 0;
      let numberOfDeviationsSigned = 0;
      let numberOfNoValue = 0;
      let numberOfNoComment = 0;
      let numberOfNoValueComment = 0;
      let numberOfNoData = 0;

      this.checklistitemsChanges.forEach((item) => {
        switch (item.type?.name) {
          case 'Avkrysning':
            if (item.status === 'deviation') {
              numberOfDeviations++;
              if (item.deviationSignedBy) {
                numberOfDeviationsSigned++;
              }
              if (!item.comment || item.comment?.length === 0) {
                numberOfNoComment++;
              }
            }
            if (!item.status) {
              numberOfNoData++;
            }
            break;
          case 'Kommentar':
            if (!item.comment || item.comment?.length === 0) {
              numberOfNoComment++;
            }
            break;
          case 'Måling':
            if (!item.value || item.value?.length === 0) {
              numberOfNoValue++;
            }
            if (!item.valuecomment || item.valuecomment?.length === 0) {
              numberOfNoValueComment++;
            }
            break;
          case 'Ekstern dokumentasjon':
            break;
          default:
            break;
        }
      });
      this.checklist.numberOfDeviations = numberOfDeviations;
      this.checklist.numberOfDeviationsSigned = numberOfDeviationsSigned;
      this.checklist.numberOfNoComment = numberOfNoComment;
      this.checklist.numberOfNoData = numberOfNoData;
      this.checklist.numberOfNoValue = numberOfNoValue;
      this.checklist.numberOfNoValueComment = numberOfNoValueComment;
      this.sanitycheckDeviations = numberOfDeviations > 0 &&
        numberOfDeviationsSigned !== numberOfDeviations;
      this.sanitycheckNoValue = numberOfNoValue > 0;
      this.sanitycheckNoComment = numberOfNoComment > 0;
      this.sanitycheckNoValueComment = numberOfNoValueComment > 0;
      this.sanitycheckNoData = numberOfNoData > 0;
      this.sanitycheckNoPicture = this.checklistfiles.length <= 0;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  signChecklistConfirm(): void {
    let text = 'Signér sjekkliste: ';
    let title = 'Signere?';
    if (this.checklist.receiptform) {
      text = 'Signér mottaksskjema: ';
    }
    text += this.checklist.name;
    if (this.sanitycheckDeviations) {
      text += ' med avvik';
      title = 'Signere med avvik?';
    }

    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title,
        text,
        yes: {
          name: 'Ja',
          icon: 'edit',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          this.checklistservice.updateChecklistSignature(this.checklist);
          this.adminservice
            .getObjectStatuses()
            .pipe(take(1))
            .subscribe((statuses) => {
              let statusesForObject = _.reject(statuses, _.isNull);
              statusesForObject = _.sortBy(statusesForObject, 'priority');

              this.object.status = statusesForObject.find(st => st.name === 'Signert');
              this.objectservice.updateObject(this.object);
            });
          if (this.isOnline) {
            this.generatePDF(this.checklist);
          }
        }
      });
  }

  deleteChecklistConfirm(): void {
    let text = 'Slette sjekkliste: ';
    if (this.checklist.receiptform) {
      text = 'Slette mottaksskjema: ';
    }
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'Er du sikker?',
        text: text + this.checklist.name,
        yes: {
          name: 'Ja',
          icon: 'delete',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          this.checklistservice
            .deleteChecklist(this.checklist)
            .then(() => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }

  editChecklistStatus(): void {
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'OBS! Dette fjerner også PDF tilknyttet sjekklisten!',
        text: 'Fjerne signering for: ' + this.checklist.name,
        yes: {
          name: 'Ja',
          icon: 'directions_off',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          this.isRemovingSignature = true;
          this.checklistservice
            .updateChecklistStatus('finished', this.checklist)
            .then(async () => {
              console.log('Checkliststatus changed to finished...');
              if (
                this.checklist.pdfexports &&
                this.checklist.pdfexports.length > 0
              ) {
                this.checklist.pdfexports.forEach((pdf) => {
                  this.fileservice.deleteFile(pdf, true);
                });
              }
              this.checklistservice.updateChecklistGeneratingPDF(
                false,
                this.checklist
              );
              this.snackbarservice.openSnackBar(
                'Sjekklistesignatur fjernet. Sjekkliste kan nå redigeres.',
                ''
              );
              const allStatuses = await firstValueFrom(this.adminservice.getObjectStatuses());
              const allChecklistitems = await firstValueFrom(this.adminservice.getAllChecklistitemsObs());
              const {
                statuses,
                statusNames,
                statusCount,
                isLoading
              } = await this.checklistservice.groupChecklistItems(
                this.object,
                this.checklist,
                this.isReceiptform,
                this.project.useStatusLevel,
                allStatuses,
                allChecklistitems,
                this.checklistitems
              );

              if (JSON.stringify(_.sortBy(this.statuses, 'name')) !== JSON.stringify(_.sortBy(statuses, 'name'))) {
                this.statuses = statuses;
              }

              this.statusNames = statusNames;
              this.statusCount = statusCount;
              this.isLoading = isLoading;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }

  openDialogPdfPreview(
    file: AventiFile,
    checklist: Checklist,
    locked: boolean = false
  ) {
    this.popupservice.openDialogFilePreview([file], 0, locked, checklist);
  }

  generatePDF(checklist: Checklist) {
    this.printchecklistservice.sendChecklistToPrint(checklist);
  }

  updateComment(comment) {
    this.checklistservice.updateChecklistComment(this.checklist, comment);
  }

  copyFileLocation() {
    this.currentpathservice.copyFilePathToClipboard();
  }

  updateChecklistFromObject() {
    const checklist = this.checklist;
    const object = this.object;
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'Oppdatere sjekklistedata',
        text: 'Dette oppdaterer sjekklistedata med data fra objekt. Er du sikker?',
        compareTitles: {
          id: 'Attributt',
          before: 'Objektdata',
          after: 'Sjekklistedata',
        },
        compare: [
          {
            id: 'Navn',
            before: object.name,
            after: checklist.name,
          },
          {
            id: 'Beskrivelse',
            before: object.desc,
            after: checklist.desc,
          },
          {
            id: 'Profilnummer',
            before: object.profilenumber,
            after: checklist.profilenumber,
          },
          {
            id: 'Prosess',
            before: object.process,
            after: checklist.process,
          },
        ],
        yes: {
          name: 'Ja',
          icon: 'check',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          checklist.name = object.name || '';
          checklist.desc = object.desc || '';
          checklist.profilenumber = object.profilenumber || '';
          checklist.process = object.process || '';
          this.checklistservice.updateChecklist(object.id, checklist);
          this.snackbarservice.openSnackBar('Sjekklistedata oppdatert', '');
        }
      });
  }

}
