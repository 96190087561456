<app-spinner
  disable="false"
  [text]="['Laster inn objektliste...']"
  header="Vennligst vent..."
  *ngIf="!objectsReady"
></app-spinner>

<div class="row">
  <app-headertext [hideback]="mobileQuery.matches" text="Objekter"></app-headertext>
  <div class="fill-space"></div>
  <button
    mat-button
    matTooltip="Nytt objekt"
    (click)="newObject()"
    class="header-row-button"
  >
    <mat-icon color="primary">add</mat-icon>
    Nytt objekt
  </button>

  <button
    *ngIf="isAdmin && mobileQuery.matches"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span *ngIf="!mobileQuery.matches">Admin</span>
  </button>
  <button
    *ngIf="isAdmin && !mobileQuery.matches"
    mat-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span *ngIf="!mobileQuery.matches">Admin</span>
  </button>
  <mat-menu #menu="matMenu">
    <!-- <button mat-menu-item (click)="toggleEditMode()">
      <mat-icon>edit</mat-icon>
      {{ editModeButtonName }}
    </button> -->
    <button mat-menu-item routerLink="/a/objekttyper">
      <mat-icon>text_fields</mat-icon>
      Objekttyper
    </button>
    <button mat-menu-item (click)="downloadTemplateForMultipleObjects()" *ngIf="isSuperAdmin">
      <mat-icon>download</mat-icon>
      Excel mal for import
    </button>
    <button mat-menu-item (click)="downloadObjectsToExcel()" *ngIf="isSuperAdmin">
      <mat-icon>save_alt</mat-icon>
      Eksporter til Excel
    </button>
    <button mat-menu-item (click)="downloadObjectUrlsToExcel()" *ngIf="isSuperAdmin">
      <mat-icon>save_alt</mat-icon>
      Eksporter URL liste
    </button>
    <button mat-menu-item (click)="uploadMultipleObjects()" *ngIf="isSuperAdmin">
      <mat-icon>difference</mat-icon>
      Importer fra Excel
    </button>
    <input hidden type="file" accept=".xlsx" id="objectimportinput" appReadexcel
           (eventEmitter)="dataFromExcelEventEmitter($event)">
  </mat-menu>
</div>


<div style="margin: 0 15px 0 15px">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoading || !tableFiltersReady || isImportingObjects"
  ></mat-progress-bar>

  <div [hidden]="isLoading && isImportingObjects && !project">

    <p-table
      #dataTable
      [value]="objectsFrontend"
      [tableStyle]="{ 'min-height': '400px','max-width': '100vw', 'font-size': '11px', 'font-weight': '100' }"
      [paginator]="true"
      [alwaysShowPaginator]="true"
      paginatorDropdownAppendTo="body"
      [rows]="25"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} - {last} / {totalRecords}"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      styleClass="p-datatable-striped p-datatable-sm"
      [globalFilterFields]="filterFields"
      [selectionMode]="isOnline && !mobileQuery.matches ? 'multiple' : 'single'"
      [(selection)]="selectedRows"
      dataKey="id"
      [scrollable]="true"
      [scrollHeight]="mobileQuery.matches ? 'calc(100dvh - 310px)' : 'calc(100dvh - 240px)'"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnSelect($event)"
      (onFilter)="onFilter($event)"
      [filters]="tableFilters"
      (onSort)="onSort($event)"
      sortMode="multiple"
      [multiSortMeta]="tableSort"
    >
      <!--      responsiveLayout="stack"-->
      <!--      [breakpoint]="'500px'"-->
      <!--      stateStorage="session"-->
      <!--      stateKey="checklistList"-->

      <ng-template pTemplate="caption">
        <div class="row"
             [hidden]="!tableFiltersReady">
          <div>
            <p-skeleton width="85px" height="34px"
                        [hidden]="tableFiltersReady"></p-skeleton>
            <p-button icon="pi pi-filter-slash"
                      label="Clear"
                      [style]="{'margin-right': '5px'}"
                      [outlined]="true"
                      [hidden]="!tableFiltersReady"
                      *ngIf="tableFiltersReady"
                      (click)="clearTable(dataTable)"
            ></p-button>
          </div>
          <div class="fill-space"></div>

          <div *ngIf="!mobileQuery.matches" class="child-margin" style="margin: 0 15px 0 15px">
            <p-tag *ngFor="let status of statusCount"
                   class="cursor"
                   [value]="status.count + ' - ' + (user?.language?.id === 'en' ? status.enName : status.name)"
                   [severity]="tableFilters['statusSearchString']?.value?.includes(user?.language?.id === 'en' ? status.enName : status.name) ? 'success' : ''"
                   [style]="tableFilters['statusSearchString']?.value?.includes(user?.language?.id === 'en' ? status.enName : status.name) ? {'margin': '5px'} : { 'margin': '5px', 'background': '#777' }"
                   (click)="toggleStatusFilter(user?.language?.id === 'en' ? status.enName : status.name)"
            ></p-tag>
          </div>

          <div>
            <p-skeleton width="165px" height="34px"
                        [hidden]="tableFiltersReady"></p-skeleton>
            <span class="p-input-icon-left ml-auto"
                  *ngIf="tableFiltersReady">
                    <i class="pi pi-search" *ngIf="tableFiltersReady"></i>
                    <input pInputText type="text" [value]="searchString" style="width: 100%"
                           (input)="dataTable.filterGlobal($any($event.target)?.value, 'contains')" placeholder="Søk"
                           [hidden]="!tableFiltersReady"
                    />
              </span>
          </div>
        </div>
        <div class="row"
             *ngIf="selectedRows.length > 1 && isOnline">
          <p-button icon="pi pi-plus-circle"
                    label="Opprett sjekklister for valgte objekter"
                    [style]="{'margin-right': '5px', 'margin-top': '5px'}"
                    [outlined]="true"
                    *ngIf="selectedRows.length > 1 && isOnline"
                    (click)="createMultipleChecklists()"
          ></p-button>
          <p-button icon="pi pi-trash"
                    label="Slett valgte objekter"
                    [style]="{'margin-right': '5px', 'margin-top': '5px'}"
                    [outlined]="true"
                    *ngIf="selectedRows.length > 1 && isOnline && user.role?.name === 'admin'"
                    (click)="deleteMultipleObjects()"
          ></p-button>
        </div>
        <div style="padding-top: 5px;">
          <p-overlayPanel #opFilter>
            <p-columnFilter type="text" field="nameSearchString" matchMode="contains" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input type="text"
                       pInputText
                       [ngModel]="value"
                       (ngModelChange)="filter($event)"
                       class="p-inputtext"
                       placeholder="Søk i Objekt">
              </ng-template>
            </p-columnFilter>
            <p-columnFilter field="comment" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="comments"
                               placeholder="Søk i Kommentar"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                               optionLabel="label"
                               optionValue="value"
                ></p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter field="typename" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="types"
                               placeholder="Søk i Type"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                               optionLabel="label"
                               optionValue="value"
                ></p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter field="profilenumber" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="profilenumbers"
                               placeholder="Søk i Profilnummer"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                               optionLabel="label"
                               optionValue="value"
                ></p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter field="statusSearchString" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="statusNames"
                               placeholder="Søk i Status"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                ></p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter type="text" field="tagsSearchString" matchMode="contains" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input type="text"
                       pInputText
                       [ngModel]="value"
                       (ngModelChange)="filter($event)"
                       class="p-inputtext"
                       placeholder="Søk i Tags"
                       style="min-width: 75px;"
                >
              </ng-template>
            </p-columnFilter>
            <div class="row">
              <span style="font-size: 11px; font-weight: 200; margin-top: 9px; margin-right: 15px; margin-left: 10px;">Sjekkliste finnes</span>
              <p-columnFilter type="boolean" field="checklistPresent"></p-columnFilter>
            </div>
          </p-overlayPanel>
          <p-overlayPanel #opSort>
            <div class="row">
              <div class="fill-space"></div>
              <div>
                <p-button icon="pi pi-sort-alt-slash"
                          [rounded]="true"
                          [text]="true"
                          label="Clear"
                          (click)="resetTableSort();"
                ></p-button>
              </div>
            </div>
            <p *ngFor="let button of sortButtons">
              <p-button
                [icon]="'pi ' + ((button.sort.order === 1) ? 'pi-sort-amount-up-alt' : (button.sort.order === -1) ? 'pi-sort-amount-down-alt' : 'pi-sort-alt')"
                (click)="sortFromButton(button)"
                [outlined]="button.sort.order === 0"
              ></p-button>
              <span style="padding-left: 15px; font-size: 13px">{{ button.name }}</span>
              <p-badge [style]="{ 'margin-left': '15px', 'background-color': '#444', 'color': '#ccc' }"
                       [value]="button.index.toString()" *ngIf="button.index && button.sort.order !== 0"></p-badge>
            </p>
          </p-overlayPanel>
          <div class="row" *ngIf="mobileQuery.matches">
            <div>
              <p-skeleton width="105px" height="34px"
                          [hidden]="tableFiltersReady"></p-skeleton>
              <p-button (click)="opSort.toggle($event)" icon="pi pi-sort-alt" label="Sortering"
                        [style]="{'margin-right': '5px'}"
                        [outlined]="!numberOfTableSorts || numberOfTableSorts === 0"
                        [hidden]="!tableFiltersReady"
              ></p-button>
            </div>
            <div class="fill-space"></div>
            <div>
              <p-skeleton width="105px" height="34px"
                          [hidden]="tableFiltersReady"></p-skeleton>
              <p-button (click)="opFilter.toggle($event)"
                        icon="pi pi-filter"
                        label="Filter"
                        [outlined]="!numberOfTableFilters || numberOfTableFilters === 0"
                        [hidden]="!tableFiltersReady"
              ></p-button>
            </div>
          </div>
        </div>
      </ng-template>


      <ng-template pTemplate="header">

        <tr [hidden]="mobileQuery.matches">
          <!--          DESKTOP         -->
          <th [hidden]="mobileQuery.matches" pSortableColumn="nameSearchString" style="width:20%">Objekt
            <p-sortIcon field="nameSearchString"></p-sortIcon>
          </th>
          <th [hidden]="mobileQuery.matches" pSortableColumn="comment">Kommentar
            <p-sortIcon field="comment"></p-sortIcon>
          </th>
          <th [hidden]="mobileQuery.matches" pSortableColumn="typename" style="width:20%">Type
            <p-sortIcon field="typename"></p-sortIcon>
          </th>
          <th [hidden]="mobileQuery.matches" pSortableColumn="profilenumber">Pnummer
            <p-sortIcon field="profilenumber"></p-sortIcon>
          </th>
          <th [hidden]="mobileQuery.matches" pSortableColumn="tagsSearchString">Tags
            <p-sortIcon field="tagsSearchString"></p-sortIcon>
          </th>
          <th [hidden]="mobileQuery.matches || !project?.useStatusLevel" pSortableColumn="statusSearchString"
              [style]="{ 'width': '150px' }">Status
            <p-sortIcon field="statusSearchString"></p-sortIcon>
          </th>
          <th [hidden]="mobileQuery.matches" pSortableColumn="checklistPresent" style="width:110px">Sjekklister
            <p-sortIcon field="checklistPresent"></p-sortIcon>
          </th>
        </tr>

        <tr *ngIf="!tableFiltersReady && !mobileQuery.matches" style="margin: 10px;">
          <!--          DESKTOP        -->
          <td>
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
          </td>
          <td>
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
          </td>
          <td>
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
          </td>
          <td>
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
          </td>
          <td>
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
          </td>
          <td *ngIf="project?.useStatusLevel">
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
          </td>
          <td>
            <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="20px" width="20px"></p-skeleton>
          </td>
        </tr>


        <tr [hidden]="mobileQuery.matches || !tableFiltersReady">
          <!--          DESKTOP         -->
          <th [hidden]="mobileQuery.matches">
            <p-columnFilter type="text" field="nameSearchString" matchMode="contains" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input type="text"
                       pInputText
                       [ngModel]="value"
                       (ngModelChange)="filter($event)"
                       class="p-inputtext"
                       placeholder="Søk i Objekt">
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="comment" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="comments"
                               placeholder="Søk i Kommentar"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                               optionLabel="label"
                               optionValue="value"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [hidden]="mobileQuery.matches">
            <p-columnFilter field="typename" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="types"
                               placeholder="Søk i Type"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                               optionLabel="label"
                               optionValue="value"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [hidden]="mobileQuery.matches">
            <p-columnFilter field="profilenumber" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="profilenumbers"
                               placeholder="Søk i Profilnummer"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                               optionLabel="label"
                               optionValue="value"
                ></p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [hidden]="mobileQuery.matches">
            <p-columnFilter type="text" field="tagsSearchString" matchMode="contains" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input type="text"
                       pInputText
                       [ngModel]="value"
                       (ngModelChange)="filter($event)"
                       class="p-inputtext"
                       placeholder="Søk i Tags"
                       style="min-width: 75px"
                >
              </ng-template>
            </p-columnFilter>
          </th>
          <th [hidden]="mobileQuery.matches || !project?.useStatusLevel">
            <p-columnFilter field="statusSearchString" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="statusNames"
                               placeholder="Søk i Status"
                               (onChange)="filter($event.value)"
                               [ngModel]="value"
                ></p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [hidden]="mobileQuery.matches">
            <p-columnFilter type="boolean" field="checklistPresent"></p-columnFilter>
          </th>
        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-object>

        <tr *ngIf="!tableFiltersReady">
          <!--          MOBILE         -->
          <td [hidden]="!mobileQuery.matches" style="width: 80%">
            <p-skeleton [style]="{ 'margin-bottom': '20px', 'margin-top': '20px' }" width="80%"
                        height="30px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="40%" height="20px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="70%" height="10px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="60%" height="10px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="80%" height="10px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="60%" height="10px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="70%" height="10px"></p-skeleton>
          </td>
          <td [hidden]="!mobileQuery.matches" style="text-align: center;align-content: center; vertical-align: top;">
            <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="40px" height="20px"></p-skeleton>
            <p-skeleton [style]="{ 'margin-left': '3px' }" shape="circle" size="34px"></p-skeleton>
          </td>
          <!--          DESKTOP        -->
          <td [hidden]="mobileQuery.matches">
            <p-skeleton height="30px"></p-skeleton>
          </td>
          <td [hidden]="mobileQuery.matches">
            <p-skeleton height="30px"></p-skeleton>
          </td>
          <td [hidden]="mobileQuery.matches">
            <p-skeleton height="30px"></p-skeleton>
          </td>
          <td [hidden]="mobileQuery.matches">
            <p-skeleton height="30px"></p-skeleton>
          </td>
          <td [hidden]="mobileQuery.matches">
            <p-skeleton height="30px"></p-skeleton>
          </td>
          <td [hidden]="mobileQuery.matches || !project?.useStatusLevel">
            <p-skeleton height="30px"></p-skeleton>
          </td>
          <td [hidden]="mobileQuery.matches">
            <p-skeleton shape="circle" size="34px"></p-skeleton>
          </td>
        </tr>

        <tr [pSelectableRow]="object" [hidden]="!tableFiltersReady">
          <!--          MOBILE         -->
          <td [hidden]="!mobileQuery.matches" class="p-column-title"
              style="vertical-align: top">
            <p style="font-size: 16px; font-weight: 600;">{{ object.name }}</p>
            <p>
              <span class="dimmed-50 margin-right-10">Beskrivelse:</span>
              {{ object.desc }}
            </p>
            <p>
              <span class="dimmed-50 margin-right-10">Kommentar:</span>
              {{ object.comment }}
            </p>
            <p>
              <span class="dimmed-50 margin-right-10">Prosess:</span>
              {{ object.process }}
            </p>
            <p>
              <span class="dimmed-50 margin-right-10">Profilnummer:</span>
              {{ object.profilenumber }}
            </p>
            <div class="row">
              <div>
                <span class="dimmed-50 margin-right-10">Tags:</span>
              </div>
              <div>
                <table>
                  <tr *ngFor="let t of object.tags">
                    <td style="font-size: smaller; text-align: left">
                      <b>{{ t.key }}:</b>
                    </td>
                    <td style="font-size: smaller; text-align: right">{{ t.value }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
          <td [hidden]="!mobileQuery.matches" class=""
              style="text-align: center;align-content: center;vertical-align: top">
            <p class="align-top" [hidden]="!object.checklists || object.checklists.length === 0">
              <p-button
                icon="pi pi-list"
                (click)="openDialogObjectChecklists(object)"
                [rounded]="true"
                [outlined]="true"
              >
              </p-button>
            </p>
            <p
              class="align-top"
              [hidden]="
                  !((object.type &&
                    object.type.name &&
                    object.type.name.length > 0 &&
                    object.checklists &&
                    (!object.checklists || object.checklists?.length === 0)) ||
                  object.generatingChecklist)
                "
            >
              <app-new-checklist-button
                (click)="toggleDisableRoute()"
                [object]="object"
                [redirect]="true"
                [loadingscreen]="true"
                [shouldNavigateBack]="false"
              ></app-new-checklist-button>
            </p>
          </td>
          <!--          DESKTOP         -->
          <td [hidden]="mobileQuery.matches" class="p-column-title">
            <p style="font-size: 13px; font-weight: 600;">{{ object.name }}</p>
            <p>
              <p-tag [hidden]="!object.desc" icon="pi pi-book" [value]="object.desc" severity=""></p-tag>
            </p>
            <p>
              <p-tag [hidden]="!object.process" icon="pi pi-hashtag" [value]="object.process" severity="info"></p-tag>
            </p>
          </td>
          <td [hidden]="mobileQuery.matches" class="p-column-title">
            {{ object.comment }}
          </td>
          <td [hidden]="mobileQuery.matches" class="p-column-title">{{ object.typename }}</td>
          <td [hidden]="mobileQuery.matches" class="p-column-title">
            {{ object.profilenumber }}
          </td>
          <td [hidden]="mobileQuery.matches" class="p-column-title">
            <div *ngIf="object.tags?.length > 0">
              <table style="font-size: smaller; text-align: left">
                <tr *ngFor="let t of object.tags">
                  <td>
                    <b>{{ t.key }}:</b>
                  </td>
                  <td style="text-align: right">{{ t.value }}</td>
                </tr>
              </table>
            </div>
          </td>
          <td [hidden]="mobileQuery.matches || !project?.useStatusLevel" class="p-column-title">
            <p-tag
              [hidden]="!object.status?.name"
              [value]="user.language?.id === 'en' ? object.status?.enName : object.status?.name"
              [severity]="object.status?.name === 'Signert' ? 'success' : object.status?.priority === '1' ? 'warning' : 'info'"
            ></p-tag>
          </td>
          <td [hidden]="mobileQuery.matches" class="p-column-title">
            <mat-spinner
              diameter="24"
              *ngIf="!object.checklists"
              style="opacity: 0.3"
            ></mat-spinner>
            <div *ngIf="object.checklists">
              <button
                (click)="openDialogObjectChecklists(object)"
                *ngIf="!object.generatingChecklist && object.checklists.length > 0"
                matTooltip="Åpne sjekklisteoversikt"
                matTooltipPosition="left"
                style="
                    font-size: small;
                    margin: 5px;
                    margin-left: 0;
                    margin-right: 15px;
                    max-width: 110px;
                  "
                [style.width]="mobileQuery.matches ? '' : '90%'"
                mat-raised-button
              >
                {{ object.checklists.length }} {{ object.checklists.length === 1 ? 'sjekkliste' : 'sjekklister' }}
              </button>
            </div>
            <div
              *ngIf="
                  (object.type &&
                    object.type.name &&
                    object.type.name.length > 0 &&
                    object.checklists &&
                    (!object.checklists || object.checklists?.length === 0)) ||
                  object.generatingChecklist
                "
            >
              <app-new-checklist-button
                (click)="toggleDisableRoute()"
                [object]="object"
                [redirect]="true"
                [loadingscreen]="true"
                [shouldNavigateBack]="false"
              ></app-new-checklist-button>
            </div>
            <div *ngIf="!object.type || !object.type.name">Mangler objekttype</div>
          </td>

        </tr>
      </ng-template>


      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="project?.useStatusLevel ? 7 : 6" style="font-size: 24px; font-weight: 100; text-align: center">
            Ingen objekter funnet
          </td>
        </tr>
      </ng-template>


      <!--        <ng-template pTemplate="paginatorleft">-->
      <!--          <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>-->
      <!--        </ng-template>-->
      <!--        <ng-template pTemplate="paginatorright">-->
      <!--          <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>-->
      <!--        </ng-template>-->
      <!--      <ng-template pTemplate="footer">-->
      <!--        <tr [style]="{'height': '200px'}">-->
      <!--          <td [colSpan]="displayedColumns.length + 1" style="text-align: center" class="dimmed-50">-->
      <!--            <i class="pi pi-database" style="font-size: 70px"></i>-->
      <!--          </td>-->
      <!--        </tr>-->
      <!--      </ng-template>-->
    </p-table>
  </div>
</div>
