<span
  class="noselect"
  *ngIf="user && datetime"
  style="color: #aaa; font-size: 11px;"
>
  <span *ngIf="prefix">{{ prefix }}&nbsp;</span>
  <span *ngIf="showInitials">{{ user.initials }}&nbsp;</span>
  <!-- <span *ngIf="date">{{ date }}&nbsp;</span>
  <span *ngIf="time">{{ time }}&nbsp;</span> -->
  <span *ngIf="datetime">{{ datetime }}&nbsp;</span>
</span>