import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { SharedService } from 'src/app/services/shared.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Project } from 'src/app/models/Project';
import { SortService } from 'src/app/services/sort.service';
import { NewProjectComponent } from './new-project/new-project.component';
import { AuthService } from 'src/app/services/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { EmailService } from 'src/app/services/email.service';
import { CurrentpathService } from 'src/app/services/currentpath.service';
import { GlobalService } from 'src/app/services/global.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
})
export class ProjectsComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  projects: Project[];
  projectsFrontend: Project[];
  user: User;

  projectid: string;

  isAdmin = false;

  page = '';
  path: string;
  pathsplit: string[];

  mobileQuery: MediaQueryList;
  mobileQueryLogo: MediaQueryList;

  constructor(
    private sharedservice: SharedService,
    private dialogNewProject: MatDialog,
    private sortservice: SortService,
    private authservice: AuthService,
    media: MediaMatcher,
    private router: Router,
    private currentpathservice: CurrentpathService,
    private emailservice: EmailService,
    private globalservice: GlobalService,
    private popupservice: PopupService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    this.mobileQueryLogo = media.matchMedia(
      environment.mobileQueryMaxwidthExtraSmall
    );
  }

  pathHelper(pathsplit: string[]) {
    this.pathsplit = pathsplit;
    if (this.user?.role?.name === 'customer') {
      console.log('customer');
      if (pathsplit[0] === 'p') {
        console.log('opening project...');
      } else if (pathsplit[0] === '') {
        this.page = '';
      } else {
        this.router.navigateByUrl('/');
      }
    } else {
      if (pathsplit[0] === '') {
        this.page = '';
      }
      if (pathsplit[0].startsWith('compare')) {
        if (!pathsplit[1]) {
          this.page = 'compare';
        }
        if (pathsplit[1]) {
          this.page = 'compareExcel';
        }
      }
      if (pathsplit[0] === 'm') {
        this.page = 'minside';
      }
      if (pathsplit[0] === 'a') {
        if (!pathsplit[1]) {
          this.page = 'admin';
        } else {
          if (pathsplit[1].startsWith('objekttyper')) {
            if (!pathsplit[2]) {
              this.page = 'objekttyper';
            } else {
              if (!pathsplit[3]) {
                this.page = 'objekttype';
              } else {
                if (pathsplit[3] === 'sp') {
                  if (!pathsplit[4]) {
                    this.page = 'sjekklistepunkter';
                  } else {
                    this.page = 'sjekklistepunkt';
                  }
                }
              }
            }
          }
          if (pathsplit[1].startsWith('changelog')) {
            this.page = 'changelog';
          }
          if (pathsplit[1].startsWith('statuses')) {
            this.page = 'statuses';
          }
          if (pathsplit[1].startsWith('brukere')) {
            if (!pathsplit[2]) {
              this.page = 'brukere';
            }
            if (pathsplit[2]) {
              this.page = 'bruker';
            }
          }
          if (pathsplit[1].startsWith('varsler')) {
            this.page = 'varsler';
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.sharedservice.project.next(null);
    this.pathHelper(this.currentpathservice.getCurrentpath().path);
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.pathHelper(event.url.substring(1).split('/'));
      });

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
          this.fillUser();
        }
      });
    this.sharedservice.projects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projects) => {
        if (projects !== null && projects !== undefined) {
          projects.sort(this.sortservice.dynamicSort('no'));
          this.projects = projects;
          this.fillUser();
        }
      });
  }

  fillUser() {
    if (this.user && this.projects) {
      if (this.user?.role?.name === 'admin') {
        this.isAdmin = true;
      }
      if (this.user) {
        this.projectsFrontend = [];
        this.projects.forEach((project) => {
          if (this.user.projectaccess) {
            this.user.projectaccess.forEach((p) => {
              if (p.id === project.id) {
                this.projectsFrontend.push(project);
              }
            });
          }
        });
        this.projectsFrontend.sort(this.sortservice.dynamicSort('no'));
        this.projectsFrontend.forEach((project) => {
          if (this.user.role?.name === 'customer') {
            project.url = 'p/' + project.id + '/';
          } else {
            project.url = 'p/' + project.id + '/';
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  newReceiptform() {
    this.popupservice.openDialogNewReceiptForm(this.user, this.projectid);
  }

  newProject() {
    this.dialogNewProject.open(NewProjectComponent, {
      backdropClass: 'blur-dialog-backdrop',
    });
  }

  logout() {
    this.authservice.logoutConfirm();
  }

  bugReport() {
    this.emailservice.bugReport(this.user);
  }

  reloadVersion() {
    this.globalservice.clearCacheAndRefresh();
  }

}
