import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AventiObject} from 'src/app/models/AventiObject';
import {ChecklistService} from 'src/app/services/checklist.service';
import {IdService} from 'src/app/services/id.service';
import {ObjectService} from 'src/app/services/object.service';

@Component({
  selector: 'app-new-checklist-button',
  templateUrl: './new-checklist-button.component.html',
  styleUrls: ['./new-checklist-button.component.css'],
  providers: [ObjectService],
})
export class NewChecklistButtonComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  // projectid: string;
  @Input() object: AventiObject;
  @Input() type = 'checklist';
  @Input() loadingscreen: boolean;
  @Input() redirect: boolean;
  @Input() shouldNavigateBack = true;
  @Input() receiptform = false;
  @Input() projectid: string = this.idservice.projectId();
  isGenerating: boolean;
  tooltip = 'Ny sjekkliste';
  spinnerHeader = 'Oppretter sjekkliste...';
  // object: AventiObject;

  constructor(
    private idservice: IdService,
    private checklistservice: ChecklistService,
  )
  {
  }

  ngOnInit(): void {

    if (this.type === 'receipt') {
      this.receiptform = true;
      this.tooltip = 'Nytt mottaksskjema';
      this.spinnerHeader = 'Oppretter mottaksskjema...';
    }
    // this.objectservice.getObject(this.objectid).pipe(takeUntil(this.ngUnsubscribe)).subscribe((object) => {
    //   this.object = object;
    // });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  newClick() {
    // if (this.loadingscreen){this.isGenerating = true; }
    this.checklistservice.newChecklistClick(this.object, this.redirect, this.receiptform, this.shouldNavigateBack);
  }

}
