import { Component, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css'],
})
export class ConfirmComponent {
  title: string;
  text?: string;
  multiLine?: string[];
  list?: string[];
  compare?: {
    id: string;
    before: string;
    after: string;
    change: boolean;
  }[];
  compareTitles?: {
    id: string;
    before: string;
    after: string;
  };
  yes: { name: string; icon: string };
  no: { name: string; icon: string };

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ConfirmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.title = data.title;
    this.text = data.text;
    this.multiLine = data.multiLine;
    this.list = data.list;
    this.compare = data.compare;
    this.compareTitles = data.compareTitles;
    this.yes = data.yes;
    this.no = data.no;
  }

  ngOnInit() {
    this.compare?.forEach((c) => {
      const b = c.before || '';
      const a = c.after || '';
      if (b !== a) {
        c.change = true;
      } else {
        c.change = false;
      }
    });
  }

  yesClicked(): void {
    this.bottomSheetRef.dismiss(true);
  }
  noClicked(): void {
    this.bottomSheetRef.dismiss(false);
  }
}
