import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserRole } from 'src/app/models/User';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css'],
})
export class NewUserComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    firstname: new UntypedFormControl('', [Validators.required]),
    lastname: new UntypedFormControl('', [Validators.required]),
    initials: new UntypedFormControl('', [Validators.required]),
    role: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required]),
    phonelocale: new UntypedFormControl('', [Validators.required]),
    phonenumber: new UntypedFormControl('', [Validators.required]),
  });
  error: string;

  message = 'Laster opp...';

  get firstname() {
    return this.Form.get('firstname');
  }
  get lastname() {
    return this.Form.get('lastname');
  }
  get initials() {
    return this.Form.get('initials');
  }
  get role() {
    return this.Form.get('role');
  }
  get title() {
    return this.Form.get('title');
  }
  get email() {
    return this.Form.get('email');
  }
  get phonelocale() {
    return this.Form.get('phonelocale');
  }
  get phonenumber() {
    return this.Form.get('phonenumber');
  }

  roles: UserRole[] = [];

  mobileQuery: MediaQueryList;

  constructor(
    private userservice: UserService,
    adminservice: AdminService,
    media: MediaMatcher,
    private dialogRef: MatDialogRef<NewUserComponent>
  ) {
    adminservice
      .getUserRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((roles: UserRole[]) => {
        this.roles = roles;
      });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  create(
    firstname: string,
    lastname: string,
    initials: string,
    role: UserRole,
    title: string,
    email: string,
    locale: string,
    phoneNumber: string
  ) {
    const u: User = {
      firstname,
      lastname,
      initials,
      role,
      title,
      email,
      phone: { locale, number: phoneNumber },
    };
    this.userservice.createUser(u);
    this.Form.reset();
    this.Form.markAsPristine();
    this.Form.markAsUntouched();
    this.dialogRef.close();
  }
}
