import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AventiObject } from '../models/AventiObject';
import { Checklist } from '../models/Checklist';
import {
  ChecklistItem,
  ChecklistItemsLibraryItem,
} from '../models/Checklistitem';
import { AventiFile } from '../models/File';
import { Objecttype } from '../models/Objecttype';
import { Project } from '../models/Project';
import { User } from '../models/User';
import {Language} from '../models/Admin';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor() {}

  user: BehaviorSubject<User[]> = new BehaviorSubject(null);
  users: BehaviorSubject<User[]> = new BehaviorSubject(null);

  isLoading: BehaviorSubject<any[]> = new BehaviorSubject(null);

  alert: BehaviorSubject<any> = new BehaviorSubject(null);
  maintenance: BehaviorSubject<any> = new BehaviorSubject(null);
  appVersions: BehaviorSubject<any[]> = new BehaviorSubject(null);
  latestAppVersion: BehaviorSubject<any[]> = new BehaviorSubject(null);
  newAppVersion: BehaviorSubject<any[]> = new BehaviorSubject(null);
  online: BehaviorSubject<boolean> = new BehaviorSubject(null);
  locationEnabled: BehaviorSubject<boolean> = new BehaviorSubject(null);

  versionMessage: BehaviorSubject<any[]> = new BehaviorSubject(null);
  domainMessage: BehaviorSubject<any[]> = new BehaviorSubject(null);

  objecttypes: BehaviorSubject<Objecttype[]> = new BehaviorSubject(null);
  checklistitemtypes: BehaviorSubject<ChecklistItemsLibraryItem[]> =
    new BehaviorSubject(null);

  project: BehaviorSubject<Project> = new BehaviorSubject(null);
  projects: BehaviorSubject<Project[]> = new BehaviorSubject(null);
  objectsallprojects: BehaviorSubject<AventiObject[]> = new BehaviorSubject(
    null
  );
  objects: BehaviorSubject<AventiObject[]> = new BehaviorSubject(null);
  checklists: BehaviorSubject<Checklist[]> = new BehaviorSubject(null);
  receiptforms: BehaviorSubject<Checklist[]> = new BehaviorSubject(null);
  checklistsandreceiptforms: BehaviorSubject<Checklist[]> = new BehaviorSubject(
    null
  );
  // checklistitems: BehaviorSubject<ChecklistItem[]> = new BehaviorSubject(null);
  checklistItemsWithDeviation: BehaviorSubject<ChecklistItem[]> = new BehaviorSubject(null);
  checklistpdfs: BehaviorSubject<AventiFile[]> = new BehaviorSubject(null);
  // checklistfiles: BehaviorSubject<AventiFile[]> = new BehaviorSubject(null);

  objectsSingle: BehaviorSubject<AventiObject[]> = new BehaviorSubject(null);
  checklistsSingle: BehaviorSubject<Checklist[]> = new BehaviorSubject(null);
  checklistitemsSingle: BehaviorSubject<ChecklistItem[]> = new BehaviorSubject(
    null
  );
  checklistpdfsSingle: BehaviorSubject<AventiFile[]> = new BehaviorSubject(
    null
  );
  checklistfilesSingle: BehaviorSubject<AventiFile[]> = new BehaviorSubject(
    null
  );
  // properties
  // };

  getLanguages(): Language[] {
    const languages: Language[] = [
      {
        id: 'no',
        name: 'Norsk',
        enName: 'Norwegian'
      },
      {
        id: 'en',
        name: 'Engelsk',
        enName: 'English'
      }
    ];
    return languages;
  }

  humanizeStatus(status: string) {
    // console.log(status);
    switch (status) {
      case 'creating': {
        // numberOfNotStarted++;
        return ['Genererer', 'cached'];
      }
      case 'started': {
        // numberOfStarted++;
        return ['Påbegynt', 'fact_check'];
      }
      case 'finished': {
        // numberOfFinished++;
        return ['Ferdig', 'check_circle'];
      }
      case 'signed': {
        // numberOfSigned++;
        return ['Signert', 'gesture'];
      }
      default: {
        // numberOfNotStarted++;
        return ['Ikke utfylt', 'check_box_outline_blank'];
      }
    }
  }
  prepareChecklistsChecklist(checklist: Checklist) {
    if (checklist) {
      if (checklist.editedBy) {
        checklist.editedBy = [checklist.editedBy[0]];
      }
      checklist.checklistitems = [];
      const humanizedStatus = this.humanizeStatus(checklist.status);
      checklist.statusHuman = humanizedStatus[0];
      checklist.icon = humanizedStatus[1];
      if (checklist.numberOfDeviations > 0) {
        checklist.color = 'red';
      } else if (checklist.status === 'signed') {
        checklist.color = 'green';
      }
      // if (
      //   checklist.z_pdfexportsstring &&
      //   checklist.z_pdfexportsstring.length > 2
      // ) {
      //   if (checklist.z_pdfexportsstring.substring(0, 1) !== '[') {
      //     checklist.z_pdfexportsstring =
      //       '[' + checklist.z_pdfexportsstring + ']';
      //   }
      //   checklist.pdfexports = JSON.parse(checklist.z_pdfexportsstring);
      //   checklist.z_pdfexportsstring = '';
      // } else {
      //   checklist.pdfexports = [];
      // }
    }
  }

  // prepareChecklistsChecklistitems(checklist: Checklist) {
  //   checklist = checklist;
  //   //   if (
  //   //     checklist.z_checklistitemsstring &&
  //   //     checklist.z_checklistitemsstring.length > 2
  //   //   ) {
  //   //     if (checklist.z_checklistitemsstring.substring(0, 1) !== '[') {
  //   //       checklist.z_checklistitemsstring =
  //   //         '[' + checklist.z_checklistitemsstring + ']';
  //   //     }
  //   //     checklist.checklistitems = JSON.parse(checklist.z_checklistitemsstring);
  //   //     checklist.z_checklistitemsstring = '';
  //   //   } else {
  //   //     checklist.checklistitems = [];
  //   //   }
  //   //   if (checklist.z_filesstring && checklist.z_filesstring.length > 2) {
  //   //     if (checklist.z_filesstring.substring(0, 1) !== '[') {
  //   //       checklist.z_filesstring = '[' + checklist.z_filesstring + ']';
  //   //     }
  //   //     checklist.files = JSON.parse(checklist.z_filesstring);
  //   //     checklist.z_filesstring = '';
  //   //   } else {
  //   //     checklist.files = [];
  //   //   }
  //   //   checklist.checklistitems.forEach((checklistitem: ChecklistItem) => {
  //   //     if(checklistitem.editedBy) {checklistitem.editedBy = [checklistitem.editedBy[0]];}
  //   //     if (
  //   //       checklistitem.z_filesstring &&
  //   //       checklistitem.z_filesstring.length > 2
  //   //     ) {
  //   //       if (checklistitem.z_filesstring.substring(0, 1) !== '[') {
  //   //         checklistitem.z_filesstring = '[' + checklistitem.z_filesstring + ']';
  //   //       }
  //   //       checklistitem.files = JSON.parse(checklistitem.z_filesstring);
  //   //       checklistitem.z_filesstring = '';
  //   //     } else {
  //   //       checklistitem.files = [];
  //   //     }
  //   //   });
  // }
}
