import { Component, Input } from '@angular/core';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent {
  @Input() header: string;
  @Input() text: any;
  @Input() disable: string;
  textFrontend: string[];

  closeButtonVisible: boolean;
  visible: boolean = true;

  constructor(private sharedservice: SharedService) {
  }
  ngOnInit() {
    this.closeButtonVisible = false;
    if (Array.isArray(this.text)){
      this.textFrontend = this.text;
    } else {
      this.textFrontend = [this.text];
    }
    setTimeout(() => this.closeButtonVisible = true , 5000);
  }

  closeSpinner() {
    this.sharedservice.isLoading.next([false, '', ['']]);
    this.visible = false;
  }
}
