import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Objecttype, ObjecttypeCategory } from 'src/app/models/Objecttype';
import { AdminService } from 'src/app/services/admin.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-objecttype',
  templateUrl: './new-objecttype.component.html',
  styleUrls: ['./new-objecttype.component.css'],
})
export class NewObjecttypeComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    // id: new FormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    enName: new UntypedFormControl('', [Validators.required]),
    category: new UntypedFormControl('', [Validators.required]),
  });
  error: string;

  // get id() {
  //   return this.Form.get('id');
  // }
  get name() {
    return this.Form.get('name');
  }
  get enName() {
    return this.Form.get('enName');
  }
  get category() {
    return this.Form.get('category');
  }

  objecttypeData: Objecttype;
  objecttypecategories: [];

  mobileQuery: MediaQueryList;

  constructor(
    private dialogRef: MatDialogRef<NewObjecttypeComponent>,
    private adminservice: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    media: MediaMatcher,
    private snackbarservice: SnackbarService,
  ) {
    adminservice.getCategories().pipe(takeUntil(this.ngUnsubscribe)).subscribe((categories: any) => {
      // console.log(categories);
      this.objecttypecategories = categories;
    });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }


  create(name: string, enName: string, categoryid: string) {
    // tslint:disable-next-line:prefer-const
    let category: ObjecttypeCategory = new ObjecttypeCategory();
    this.objecttypecategories.forEach((cat: ObjecttypeCategory) => {
      // const catid = cat.id.toString();
      if (cat.id === categoryid.toString()) {
        category = {
          id: cat.id,
          name: cat.name,
        };
      }
    });
    this.objecttypeData = {
      name,
      enName,
      category,
    };
    this.adminservice.createObjecttype(this.objecttypeData);
    this.Form.reset();
    this.Form.markAsPristine();
    this.Form.markAsUntouched();
    this.dialogRef.close();
    this.snackbarservice.openSnackBar(
      'Objekttype opprettet: ' + this.objecttypeData.name,
      ''
    );
  }
}
