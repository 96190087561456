import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { IdService } from 'src/app/services/id.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
})
export class LandingpageComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  projectpath: string;
  objectspath: string;
  checklistspath: string;
  receiptformspath: string;

  mobileQuery: MediaQueryList;

  user: User;

  constructor(
    private idservice: IdService,
    media: MediaMatcher,
    private sharedservice: SharedService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    const initpath = 'p/' + this.idservice.projectId() + '/';
    this.projectpath = initpath + 'p';
    this.objectspath = initpath + 'o';
    this.checklistspath = initpath + 's';
    this.receiptformspath = initpath + 'mt';

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
