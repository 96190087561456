import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AventiFile } from 'src/app/models/File';
import { User } from 'src/app/models/User';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  user: User;
  files: AventiFile[] = [];
  resizedfile: {
    name: string;
    blob: Blob;
  } = {
    name: '',
    blob: new Blob(),
  };

  constructor(
    // private userservice: UserService,
    private sharedservice: SharedService
  ) // private storage: AngularFireStorage,
  // private afs: AngularFirestore,
  // private idservice: IdService,
  // private globalservice: GlobalService,
  {}

  ngOnInit(): void {
    this.sharedservice.user
      // this.userservice.getUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          this.user = user[0];
        }
      });

    // this.globalservice.updateParentParent(
    // 'projects/12345/objects/WR1tV7bXBLiK68Zd5mrB/checklists/rvJkPsUC8LzvSjBsim5L/files/AyglKzCAcrqXnOK3Wybf'
    // );

    // // const projectid = this.idservice.projectId();
    // console.log('Starting update...');
    // this.afs
    //   .collectionGroup(
    //     'checklists',
    //     (ref) => ref
    //   )
    //   .snapshotChanges()
    //   .pipe(
    //     take(1),
    //     map((actions) => {
    //       return actions.map((a) => {
    //         const data = a.payload.doc.data() as any;
    //         // console.log('objectProject.id', objectProject.id);
    //         // data.docid = a.payload.doc.id;
    //         console.log(a.payload.doc.id);
    //         const ref = a.payload.doc.ref;
    //         ref.update({
    //           update: true
    //         }).then(() => {
    //           console.log('updated...');
    //         })
    //         return { ...data };
    //       });
    //     })
    //   )
    //   .subscribe(items => {
    //     console.log('itemlength', items.length);
    //     // items.forEach((item, index) => {
    //       console.log('items', items);
    //     //   if(item.id !== item.docid && !item.new_data) {
    //     //     console.log(item);
    //     //   }
    //     // })
    //   })

    // async function resizefromurl(file, url, maxSize, storage, afs) {
    //   const axios = require('axios').default;
    //   await axios
    //     .get(url, { responseType: 'blob' })
    //     // .get(url, { responseType: 'buffer' })
    //     .then((response) => {
    //       // photo(response.data, maxSize, outputType, callback);
    //       // async function photo(dataURL, maxSize, outputType, callback) {
    //       // var _this = this;
    //       var reader = new FileReader();
    //       reader.onload = (readerEvent) => {
    //         // resize(readerEvent.target.result, maxSize, outputType, callback);

    //         // function resize(dataURL, maxSize, outputType, callback) {
    //         // var _this = this;
    //         const image = new Image();
    //         image.onload = (imageEvent) => {
    //           // Resize image
    //           var canvas = document.createElement('canvas'),
    //             width = image.width,
    //             height = image.height;
    //           if (width > height) {
    //             if (width > maxSize) {
    //               height *= maxSize / width;
    //               width = maxSize;
    //             }
    //           } else {
    //             if (height > maxSize) {
    //               width *= maxSize / height;
    //               height = maxSize;
    //             }
    //           }
    //           canvas.width = width;
    //           canvas.height = height;
    //           canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    //           // output(canvas, outputType, callback);
    //           // function output(canvas, outputType, callback) {

    //           // const fileextention = file.name.substring(
    //           //   file.name.lastIndexOf('.'),
    //           //   file.name.length
    //           // );
    //           canvas.toBlob(
    //             (blob) => {
    //               console.log(blob);

    //               // const folderName = '/thumbs/';
    //               // // const filePath = file.path + folderName; //+ newfileID;
    //               let storagefilename = file.storagename;
    //               // const path = `${this.dbPath}/${filename}`;
    //               let storagefilename200 =
    //                 storagefilename.substring(
    //                   0,
    //                   storagefilename.lastIndexOf('.')
    //                 ) +
    //                 '_' +
    //                 200 +
    //                 'x' +
    //                 200 +
    //                 storagefilename.substring(
    //                   storagefilename.lastIndexOf('.'),
    //                   storagefilename.length
    //                 );
    //               // const time = new Date().getTime();
    //               storagefilename200 = storagefilename200.replace(
    //                 /[/\\?%*:|"<>]/g,
    //                 '-'
    //               );
    //               let storagefilename500 =
    //                 storagefilename.substring(
    //                   0,
    //                   storagefilename.lastIndexOf('.')
    //                 ) +
    //                 '_' +
    //                 500 +
    //                 'x' +
    //                 500 +
    //                 storagefilename.substring(
    //                   storagefilename.lastIndexOf('.'),
    //                   storagefilename.length
    //                 );
    //               storagefilename500 = storagefilename500.replace(
    //                 /[/\\?%*:|"<>]/g,
    //                 '-'
    //               );
    //               // const path500 = `${file.storagepath}/thumbs/${storagefilename500}`;
    //               // const filePathName = filePath + filename500;
    //               // Totally optional metadata
    //               const customMetadata = {
    //                 app: 'APG',
    //               };
    //               const storagepathname =
    //                 file.storagepath + '/' + file.storagename;
    //               // const storagepathname200 =
    //               //   file.storagepath + '/thumbs/' + storagefilename200;
    //               // const storagepathname500 =
    //               //   file.storagepath + '/thumbs/' + storagefilename500;
    //               const ref = storage.ref(storagepathname);
    //               // ref.delete();
    //               const task = storage.upload(storagepathname, blob, {
    //                 customMetadata,
    //               });

    //               const delay = (ms: number) =>
    //                 new Promise((res) => setTimeout(res, ms)).catch(
    //                   (err: any) => {
    //                     console.log(err);
    //                   }
    //                 );

    //               // // Progress monitoring
    //               // this.percentage = this.task.percentageChanges();
    //               // this.snapshot = this.task.snapshotChanges();

    //               task
    //                 .snapshotChanges()
    //                 .pipe(
    //                   finalize(() => {
    //                     if (ref.child(storagepathname)) {
    //                       ref
    //                         .getDownloadURL()
    //                         .pipe(take(1))
    //                         .subscribe(async (url) => {
    //                           console.log('...waiting for file...', url);

    //                           await delay(5000).catch((err: any) => {
    //                             console.log(err);
    //                           });
    //                           afs
    //                             .doc(file.path)
    //                             .update({
    //                               storagename500: storagefilename500,
    //                               downloadurl: url,
    //                               thumbnailpath: file.storagepath + '/thumbs',
    //                               storagename200: storagefilename200,
    //                               thumbnailName: firebase.firestore.FieldValue.delete(),
    //                               thumbnailPath: firebase.firestore.FieldValue.delete(),
    //                               thumbnailUrl: firebase.firestore.FieldValue.delete(),
    //                               downloadurl: firebase.firestore.FieldValue.delete(),
    //                               downloadurl500: firebase.firestore.FieldValue.delete(),
    //                               name500: firebase.firestore.FieldValue.delete(),
    //                               path500: firebase.firestore.FieldValue.delete(),
    //                             })
    //                             .then(async () => {
    //                               console.log('Uploaded to Firestore');

    //                               await delay(5000).catch((err: any) => {
    //                                 console.log(err);
    //                               });

    //                               const ref500 = storage.ref(
    //                                 file.storagepath +
    //                                   '/thumbs/' +
    //                                   storagefilename500
    //                               );
    //                               ref500
    //                                 .getDownloadURL()
    //                                 .pipe(take(1))
    //                                 .subscribe((url500) => {
    //                                   console.log(
    //                                     '...waiting for file...',
    //                                     url
    //                                   );
    //                                   afs
    //                                     .doc(file.path)
    //                                     .update({
    //                                       downloadurl500: url500,
    //                                     })
    //                                     .then(() => {
    //                                       console.log(
    //                                         '500 updated in firestore'
    //                                       );
    //                                       const ref200 = storage.ref(
    //                                         file.storagepath +
    //                                           '/thumbs/' +
    //                                           storagefilename200
    //                                       );
    //                                       ref200
    //                                         .getDownloadURL()
    //                                         .pipe(take(1))
    //                                         .subscribe((url200) => {
    //                                           console.log(
    //                                             '...waiting for file...',
    //                                             url
    //                                           );
    //                                           afs
    //                                             .doc(file.path)
    //                                             .update({
    //                                               downloadurl200: url200,
    //                                             })
    //                                             .then(() => {
    //                                               console.log(
    //                                                 '200 updated in firestore'
    //                                               );
    //                                             });
    //                                         });
    //                                     });
    //                                 });
    //                             })
    //                             .catch((err) => {
    //                               console.log(
    //                                 'Error uploading to Firestore: ',
    //                                 err
    //                               );
    //                             });
    //                         });
    //                       // return Promise.resolve('PDF Generated uccessfully!');
    //                     }
    //                   })
    //                 )
    //                 .subscribe((p) => {
    //                   // console.log('p:',p);
    //                   if (p.bytesTransferred === p.totalBytes) {
    //                     console.log('Fil lastet opp');
    //                   }
    //                 });
    //             },
    //             'image/jpeg',
    //             0.8
    //           );

    //           // switch (outputType) {
    //           //   case 'file':
    //           //     canvas.toBlob(
    //           //       (blob) => {
    //           //         console.log(blob);
    //           //         resizedfile = {
    //           //           name: file.name,
    //           //           blob: blob,
    //           //         };
    //           //       },
    //           //       'image/jpeg',
    //           //       0.8
    //           //     );
    //           //     break;

    //           //   case 'dataURL':
    //           //     canvas.toDataURL('image/jpeg', 0.8);
    //           //     break;
    //           // }
    //           // }
    //         };
    //         image.src = readerEvent.target.result.toString();
    //         // }
    //       };
    //       reader.readAsDataURL(response.data);
    //       // }
    //     });
    // }

    //   // .valueChanges()
    //   .pipe(take(1))
    //   .subscribe((objects: AventiObject[]) => {
    //     console.log(objects);
    //     const obj = _.reject(objects, _.isNull);
    //     console.log(obj);
    //     obj.forEach((object, index) => {
    //       // const patharr = file.path.split('/');
    //       // patharr.pop();
    //       // patharr.pop();
    //       // const path = patharr.join('/');
    //       if (object.checklists) {
    //         console.log('updating', object.id);
    //         console.log('updatingcounter');
    //         this.afs
    //           .doc(
    //             `projects/${object.projectid}/objects/${object.id}`
    //           )
    //           .update({
    //             checklists: firebase.firestore.FieldValue.delete(),
    //           })
    //           .then(() => {
    //             console.log('updated');
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       }

    // // this.files.forEach(async (file, index) => {
    // const fileextention = file.name.substring(
    //   file.name.lastIndexOf('.'),
    //   file.name.length
    // );
    // file.fileextention = fileextention;
    // const filetypesImage = ['.jpg', '.jpeg', '.png'];
    // // const filetypesPdf = ['.pdf'];

    // const storagepathSplit = file.storagepath.split("/");

    // const projectid = storagepathSplit[1];
    // // console.log(projectid);
    // const objectid = storagepathSplit[3];
    // // console.log(objectid);
    // const checklistid = storagepathSplit[5];
    // // console.log(checklistid);
    // let checklistitemid: string;
    // if(storagepathSplit.length > 6) {
    //   checklistitemid = storagepathSplit[7];
    //   // console.log(checklistitemid);
    // }
    // const path = file.storagepath + '/files/' + file.id;

    // this.afs
    //   .doc(path)
    //   .update({
    //     id: file.id,
    //   }).then(() => {
    //     console.log("updated");
    //   }).catch(err => {
    //     console.log(err);
    //   });
    // if (
    //   filetypesImage.indexOf(fileextention.toLowerCase()) > -1
    //   // && !file.base64
    // ) {
    //   if (file.storagepath.indexOf('/thumbs') > 0) {
    //     return console.log('Already a Thumbnail.', file.name);
    //   } else {
    //     console.log('updating ', file.id);
    //     // const url = file.downloadurl ? file.downloadurl : file.downloadurl;
    //     // this.afs.doc(file.path).update({
    //     //   downloadurl: url,
    //     //   downloadurl: firebase.firestore.FieldValue.delete(),
    //     // });

    //     // const url = file.downloadurl ? file.downloadurl : file.downloadurl;
    //     // await resizefromurl(
    //     //   file,
    //     //   url,
    //     //   2000,
    //     //   this.storage,
    //     //   this.afs
    //     // );

    //     const storagefilename = file.storagename;

    //     let storagefilename200 =
    //       storagefilename.substring(
    //         0,
    //         storagefilename.lastIndexOf('.')
    //       ) +
    //       '_' +
    //       200 +
    //       'x' +
    //       200 +
    //       storagefilename.substring(
    //         storagefilename.lastIndexOf('.'),
    //         storagefilename.length
    //       );
    //     // const time = new Date().getTime();
    //     storagefilename200 = storagefilename200.replace(
    //       /[/\\?%*:|"<>]/g,
    //       '-'
    //     );
    //     let storagefilename500 =
    //       storagefilename.substring(
    //         0,
    //         storagefilename.lastIndexOf('.')
    //       ) +
    //       '_' +
    //       500 +
    //       'x' +
    //       500 +
    //       storagefilename.substring(
    //         storagefilename.lastIndexOf('.'),
    //         storagefilename.length
    //       );
    //     storagefilename500 = storagefilename500.replace(
    //       /[/\\?%*:|"<>]/g,
    //       '-'
    //     );

    //     const storagepathname =
    //       file.storagepath + '/' + file.storagename;

    //     if (storagepathname) {
    //       const ref = this.storage.ref(storagepathname);
    //       ref
    //         .getDownloadURL()
    //         .pipe(take(1))
    //         .subscribe((url) => {
    //           console.log('...waiting for file...', url);
    //           this.afs
    //             .doc(path)
    //             .update({
    //               id: file.id,
    //               path,
    //               downloadurl: url,
    //               thumbnailName: firebase.firestore.FieldValue.delete(),
    //               thumbnailPath: firebase.firestore.FieldValue.delete(),
    //               thumbnailUrl: firebase.firestore.FieldValue.delete(),
    //               downloadurl: firebase.firestore.FieldValue.delete(),
    //               name500: firebase.firestore.FieldValue.delete(),
    //               path500: firebase.firestore.FieldValue.delete(),
    //               projectid,
    //               objectid,
    //               checklistid,
    //               checklistitemid: checklistitemid ? checklistitemid : firebase.firestore.FieldValue.delete()
    //             })
    //             .then(() => {
    //               console.log('image updated in firestore');
    //               if (this.files.length === index + 1) {
    //                 console.log('----------------------------');
    //                 console.log('----------------------------');
    //                 console.log('------------image-----------');
    //                 console.log('----------------------------');
    //                 console.log('----------------------------');
    //               }
    //             });
    //         });
    //     }
    //     if (storagefilename500) {
    //       const ref500 = this.storage.ref(
    //         file.storagepath + '/thumbs/' + storagefilename500
    //       );
    //       ref500
    //         .getDownloadURL()
    //         .pipe(take(1))
    //         .subscribe((url500) => {
    //           console.log('...waiting for file...', url500);
    //           this.afs
    //             .doc(path)
    //             .update({
    //               storagename500: storagefilename500,
    //               downloadurl500: url500,
    //             })
    //             .then(() => {
    //               console.log('500 updated in firestore');
    //               if (this.files.length === index + 1) {
    //                 console.log('----------------------------');
    //                 console.log('----------------------------');
    //                 console.log('------------500-------------');
    //                 console.log('----------------------------');
    //                 console.log('----------------------------');
    //               }
    //             });
    //         });
    //     }
    //     if (storagefilename200) {
    //       const ref200 = this.storage.ref(
    //         file.storagepath + '/thumbs/' + storagefilename200
    //       );
    //       ref200
    //         .getDownloadURL()
    //         .pipe(take(1))
    //         .subscribe((url200) => {
    //           console.log('...waiting for file...', url200);
    //           this.afs
    //             .doc(path)
    //             .update({
    //               storagename200: storagefilename200,
    //               downloadurl200: url200,
    //             })
    //             .then(() => {
    //               console.log('200 updated in firestore');
    //               if (this.files.length === index + 1) {
    //                 console.log('----------------------------');
    //                 console.log('----------------------------');
    //                 console.log('------------200-------------');
    //                 console.log('----------------------------');
    //                 console.log('----------------------------');
    //               }
    //             });
    //         });
    //     }
    //   }
    // } else {
    //   console.log('File is not image', file.name);
    //   // const url = file.downloadurl ? file.downloadurl : file.downloadurl;
    //   // this.afs.doc(path).update({
    //   //   path,
    //   //   projectid,
    //   //   objectid,
    //   //   checklistid,
    //   //   checklistitemid: checklistitemid ? checklistitemid : firebase.firestore.FieldValue.delete()
    //   // });
    // }

    //   this.afs
    //     .doc(path)
    //     .update({
    //       files: firebase.firestore.FieldValue.arrayUnion(file),
    //       // files: firebase.firestore.FieldValue.delete(),
    //     })
    //     .then(() => {
    //       console.log(file.id, 'updated');
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //       let downloadurl = file.downloadurl;
    //       this.afs.doc(file.path).update({
    //         downloadurl,

    //       })
    //     }
    // });
    // checklists.forEach(checklist => {
    //   const checklistsArr = [];
    //   checklistsArr.push(checklist);
    //   const id = checklist.id;
    //   console.log('updating', checklist.objectid)
    //   this.afs.doc(`projects/${checklist.projectid}/objects/${checklist.objectid}`).update({
    //     // checklists: firebase.firestore.FieldValue.arrayUnion(checklist),
    //     checklists: firebase.firestore.FieldValue.delete(),
    //   }).then(() => {
    //     console.log(checklist.objectid, 'updated')
    //   }).catch(err => {
    //     console.log(err);
    //   })
    //   });
    // });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}
