import { Injectable } from '@angular/core';
import { Checklist } from '../models/Checklist';
import { IdService } from './id.service';

@Injectable({
  providedIn: 'root',
})
export class DbpathService {
  constructor(private idservice: IdService) {}

  dbPathProject() {
    return `projects/${this.idservice.projectId()}`;
  }

  // dbPathChecklistsGroup() {
  //   return 'projects/' + this.idservice.projectId() + '/objects'; // + this.idservice.objectId() + '/checklists';
  // }
  dbPathReceiptforms(projectid?: string) {
    if (!projectid) {
      projectid = this.idservice.projectId();
    }
    return `projects/${projectid}/receiptforms/`;
  }
  dbPathReceiptform(receiptform?: Checklist, projectid?: string) {
    let receiptformid = '';
    if (receiptform && receiptform.id) {
      receiptformid = receiptform.id;
    } else {
      receiptformid = this.idservice.receiptformId();
    }
    if (!projectid) {
      if (receiptform && receiptform.projectid) {
        projectid = receiptform.projectid;
      } else {
        projectid = this.idservice.projectId();
      }
    }
    return `projects/${projectid}/receiptforms/${receiptformid}`;
  }
  dbPathReceiptformItems() {
    return this.dbPathReceiptform() + '/checklistitems';
  }
  dbPathChecklists(objectid?: string, projectid?: string) {
    if (!objectid) {
      objectid = this.idservice.objectId();
    }
    if (!projectid) {
      projectid = this.idservice.projectId();
    }
    return `projects/${projectid}/objects/${objectid}/checklists/`;
  }
  dbPathChecklist(checklist?: Checklist, objectid?: string, projectid?: string) {
    let checklistid = '';
    if (checklist?.id) {
      checklistid = checklist.id;
    } else {
      checklistid = this.idservice.checklistId();
    }
    if (!objectid) {
      if (checklist && checklist.objectid) {
        objectid = checklist.objectid;
      } else {
        objectid = this.idservice.objectId();
      }
    }
    if (!projectid) {
      if (checklist && checklist.projectid) {
        projectid = checklist.projectid;
      } else {
        projectid = this.idservice.projectId();
      }
    }
    return `projects/${projectid}/objects/${objectid}/checklists/${checklistid}`;
  }
  dbPathChecklistItems(isReceiptform?: boolean, checklist?: Checklist) {
    if (isReceiptform){
    return this.dbPathReceiptform() + '/checklistitems';
    } else {
      if (checklist) {
        return this.dbPathChecklist(checklist, checklist.objectid, checklist.projectid) + '/checklistitems';
      }
      return this.dbPathChecklist() + '/checklistitems';
      }
  }
  dbPathChecklistItem(checklistItemId: string, isReceiptform?: boolean) {
    if (!isReceiptform) {
      isReceiptform = this.idservice.isReceiptformPath();
    }
    return this.dbPathChecklistItems(isReceiptform) + '/' + checklistItemId;
  }

  dbPathObjects(projectid?: string) {
    if (!projectid || projectid === '') {
      projectid = this.idservice.projectId();
    }
    return 'projects/' + projectid + '/objects';
  }

  dbPathObjectFiles(objectid: string, projectid?: string) {
    if (!projectid || projectid === '') {
      projectid = this.idservice.projectId();
    }
    return 'projects/' + projectid + '/objects/' + objectid + '/files';
  }
  dbPathObject(objectid?: string, projectid?: string) {
    if (!objectid || objectid === '') {
      objectid = this.idservice.objectId();
    }
    if (!projectid || projectid === '') {
      projectid = this.idservice.projectId();
    }
    return this.dbPathObjects(projectid) + '/' + objectid;
  }

  dbPathProjectFiles(projectid?: string) {
    if (!projectid || projectid === '') {
      projectid = this.idservice.projectId();
    }
    return 'projects/' + projectid + '/files';
  }
}
