<mat-icon
  color="warn"
  class="header-icon header-icon-small noselect"
  matTooltip="No Connection"
  *ngIf="!(online$ | async)"
  [matMenuTriggerFor]="offlineMenu"
  >wifi_off</mat-icon
>
<!-- <mat-icon
  class="header-icon header-icon-small noselect"
  matTooltip="Online"
  *ngIf="online$ | async"
  >wifi</mat-icon
> -->

<mat-menu #offlineMenu="matMenu">
  <div style="padding: 5px 20px 20px 20px">
    <div>
      <mat-icon color="warn" style="padding: 0 0 10px 0">wifi_off</mat-icon
      >Offline
    </div>
    Endringer synkroniserer når du er online igjen
  </div>
  <!-- <mat-divider></mat-divider> -->
  <!-- <a mat-menu-item color="primary" (click)="reloadVersion()" style="margin-top:5px;">
    <mat-icon color="primary">cached</mat-icon>Oppdater
  </a> -->
</mat-menu>
