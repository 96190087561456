import { AventiObject, Tag } from './AventiObject';
import { ChecklistItem } from './Checklistitem';
import { AventiFile } from './File';
import { Objecttype } from './Objecttype';
import { UserTimestamp } from './User';
import { Timestamp } from '@firebase/firestore-types';

export class Checklist {
  // public objectId?: string;
  public id ? = '';
  public parentid ? = '';
  public projectid ? = '';
  public objectid ? = '';
  // public parent: AventiObject = ';
  public icon ? = '';
  public color ? = '';
  public name ? = '';
  public desc ? = '';
  public comment ? = '';
  public status ? = '';
  public statusHuman ? = '';
  public numberOfDeviations ? = 0;
  public numberOfDeviationsSigned?: number;
  public numberOfNoValue?: number;
  public numberOfNoValueComment?: number;
  public numberOfNoComment?: number;
  public numberOfNoData?: number;
  public filesId?: string[];
  public missingChecklistitems?: string;
  public checklistitems?: ChecklistItem[];
  public isSigned?: boolean;
  public signedBy?: UserTimestamp;
  public editedBy?: UserTimestamp[];
  public lastupdated?: Timestamp;
  public lastupdatedString?: string;
  public lastsignedString?: string;
  public type?: Objecttype;
  public typename?: string;
  public tempPath?: string;
  public tempColor?: string;
  public path?: string;
  public profilenumber ? = '';
  public files?: AventiFile[];
  public imageCounter?: number;
  public pdfexports?: AventiFile[];
  public pdfexportsBoolean?: boolean;
  public isgenerating?: boolean;
  public generatingPDF?: boolean;
  public generatingPDFstatus?: string;
  public generatingPDFstatusIcon?: string;
  public generatingPDFstatusText?: string;
  public generatingPDFchecklist?: boolean;
  public PDFDeliveredToCustomerBoolean ? = false;
  public PDFDeliveredToCustomer?: {
    status: boolean,
    pending?: boolean,
    editedBy?: UserTimestamp[],
  } = {
    status: false,
    pending: false
  };
  public filesToAppend?: string[];
  public location?: {
    error: string,
    lat: string,
    lng: string
  };
  public pdfButtonName?: string;
  public receiptform?: boolean;
  // tslint:disable-next-line:variable-name
  public z_filesstring?: string;
  // tslint:disable-next-line:variable-name
  public z_pdfexportsstring?: string;
  // tslint:disable-next-line:variable-name
  public z_checklistitemsstring?: string;
  public objects?: AventiObject[];
  public objectsSearchString?: string;
  public objectsstring?: string;
  public pos?: {
    lat: number;
    lng: number;
  };
  public process ? = '';
  public scheduledfordelete?: boolean;
  public tags?: Tag[];
  public isarchived?: boolean;
  public sortbystring?: string;
  public fromCache?: boolean;
  public nameSearchString?: string;
  public tagsSearchString?: string;
}
