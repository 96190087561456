import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AventiObject } from 'src/app/models/AventiObject';
import { AventiFile } from 'src/app/models/File';
import { Project } from 'src/app/models/Project';
import { FileService } from 'src/app/services/file.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import { Checklist } from 'src/app/models/Checklist';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css'],
})
export class FilesComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  project: Project;
  objects: AventiObject[];
  singleobject: AventiObject;
  checklists: Checklist[];
  singlechecklist: Checklist;
  files: AventiFile[];
  path: string;

  canNavigateUp: boolean;

  isOnline: boolean;

  fileElements: AventiFile[];

  mobileQuery: MediaQueryList;
  constructor(
    private sharedservice: SharedService,
    private fileservice: FileService,
    media: MediaMatcher,
    private popupservice: PopupService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });
    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects) => {
        if (objects !== null) {
          this.objects = objects;
          this.objects.forEach((object) => {
            object.checklists = [];
          });
          this.sortFiles();
        }
      });
    this.sharedservice.checklists
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklists) => {
        if (checklists !== null) {
          this.checklists = checklists;
          this.sortFiles();
        }
      });
    this.fileservice
      .getAllFilesInProject()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((files: AventiFile[]) => {
        this.files = files;
        this.sortFiles();
      });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  sortFiles() {
    if (this.files && this.objects && this.checklists) {
      console.log('sorting files');
      this.objects.forEach((object) => {
        object.files = [];
      });
      this.checklists.forEach((checklist) => {
        checklist.files = [];
        this.objects.forEach((object) => {
          if (object.id === checklist.objectid) {
            object.checklists.push(checklist);
          }
        });
      });
      console.log(this.objects);
      this.files.forEach((file) => {
        const typesplit = file.type.split('/');
        file.typestring = file.type;
        switch (typesplit[1]) {
          case 'pdf':
            file.typestring = 'PDF';
            break;
        }
        switch (typesplit[0]) {
          case 'image':
            file.typestring = 'Bilde';
            break;
          case 'video':
            file.typestring = 'Video';
            break;
        }
        this.checklists.forEach((checklist) => {
          this.objects.forEach((object) => {
            if (
              file.objectid &&
              !file.checklistid &&
              !file.checklistitemid &&
              file.objectid === object.id
            ) {
              if (!object.files) {
                object.files = [];
              }
              object.files.push(file);
            }
          });
          if (file.checklistid && file.checklistid === checklist.id) {
            if (!checklist.files) {
              checklist.files = [];
            }
            checklist.files.push(file);
          }
        });
      });
    }
  }
  navigateUp() {
    if (this.singlechecklist) {
      this.singlechecklist = null;
      this.path = this.singleobject.name;
    } else {
      this.singleobject = null;
      this.path = null;
      this.canNavigateUp = false;
    }
  }

  // navigate(element) {
  //   console.log(element);
  // }

  // openRenameDialog(element) {
  //   console.log(element);
  // }

  // deleteElement(element) {
  //   console.log(element);
  // }

  // moveElement(self, element) {
  //   console.log(self, element);
  // }

  // openMenu(event, menuTrigger) {
  //   console.log(event, menuTrigger);
  // }
  // openNewFolderDialog() {}

  openObject(object: AventiObject) {
    if (this.isOnline) {
      this.objects.forEach((o) => {
        if ((o.id === object.id)) {
          this.path = object.name;
          this.canNavigateUp = true;
          this.singleobject = object;
        }
      });
    }
  }

  openChecklist(checklist: Checklist) {
    this.checklists.forEach((c) => {
      if ((c.id === checklist.id)) {
        this.path = this.singleobject.name + ' / ' + checklist.name;
        this.canNavigateUp = true;
        this.singlechecklist = checklist;
      }
    });
  }

  openDialogImagePreview(file: AventiFile) {
    this.popupservice.openDialogFilePreview([file], 0, true);
  }
}
