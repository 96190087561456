<!-- <mat-tab-group>
  <mat-tab label="Ett objekt"> -->
<app-headertext text="Nytt objekt" hideback="true"></app-headertext>
<div *ngIf="!objecttypes" style="min-height: 366px; min-width: 180px">
  <div class="center-on-screen">
    <mat-spinner></mat-spinner>
    <p>Henter objekttyper...</p>
  </div>
</div>
<form
  *ngIf="objecttypes"
  [formGroup]="Form"
  (ngSubmit)="
    create(
      name.value,
      type.value,
      desc.value,
      process.value,
      profilenumber.value
    )
  "
>
  <div class="form-group">
    <!-- <p>
      <mat-form-field>
        <input
          matInput
          id="no"
          type="number"
          placeholder="Nummer"
          formControlName="no"
        />
        <mat-error *ngIf="no.touched && no.invalid">
          <div *ngIf="!no.errors.required">Please enter a valid number</div>
          <div *ngIf="no.errors.required">
            Object number is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p> -->
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          matInput
          id="name"
          type="text"
          placeholder="Navn"
          formControlName="name"
        />
        <mat-error *ngIf="name.touched && name.invalid">
          <div *ngIf="!name.errors.required">Please enter a object name</div>
          <div *ngIf="name.errors.required">
            Object name is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <mat-label>Objekttype</mat-label>
        <mat-select formControlName="type" id="type" value="">
          <mat-option *ngFor="let item of objecttypes" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="type.touched && type.invalid">
          <div *ngIf="!type.errors.required">Please select a type</div>
          <div *ngIf="type.errors.required">
            Object type is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
      <!-- <mat-form-field *ngIf="mobileQuery.matches">
        <mat-label>Objekttype</mat-label>
        <select formControlName="type" id="type" matNativeControl value="">
          <option *ngFor="let item of objecttypes" [value]="item.id">
            {{ item.name }}
          </option>
        </select>

        <mat-error *ngIf="type.touched && type.invalid">
          <div *ngIf="!type.errors.required">Please select a type</div>
          <div *ngIf="type.errors.required">
            Object type is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field> -->
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          matInput
          id="desc"
          type="text"
          placeholder="Beskrivelse"
          formControlName="desc"
        />
        <mat-error *ngIf="desc.touched && desc.invalid">
          <div *ngIf="!desc.errors.required">Please enter a description</div>
          <div *ngIf="desc.errors.required">
            Object description is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          matInput
          id="process"
          type="text"
          placeholder="Prosess"
          formControlName="process"
        />
        <mat-error *ngIf="process.touched && process.invalid">
          <div *ngIf="!process.errors.required">Please enter a process</div>
          <div *ngIf="process.errors.required">
            Object process is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          matInput
          id="profilenumber"
          type="text"
          placeholder="Profilnummer"
          formControlName="profilenumber"
        />
        <mat-error *ngIf="profilenumber.touched && profilenumber.invalid">
          <div *ngIf="!profilenumber.errors.required">
            Please enter a profilenumber
          </div>
          <div *ngIf="profilenumber.errors.required">
            Object profilenumber is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
        Lagre
      </button>
    </p>
    <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
  </div>
</form>
<p style="font-size: small" *ngIf="objecttypes && !hideOpenAfterCreated">
  <button
    mat-icon-button
    (click)="toggleOpenAfterCreate()"
    style="margin-bottom: -15px"
  >
    <mat-icon>{{
      openAfterCreate ? "check_box" : "check_box_outline_blank"
    }}</mat-icon>
    Åpne etter lagring
  </button>
</p>
<!-- </mat-tab>
  <mat-tab label="Flere objekter">
    <input type="file" (change)="fileChange($event)" name="file" />

    <h4 *ngIf="showMessage">{{ message }}</h4>
  </mat-tab>
</mat-tab-group> -->
