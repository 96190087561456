<mat-dialog-content
  [style.width]="mobileQuery.matches ? '100%' : '600px'"
  [style.padding]="mobileQuery.matches ? '' : ''"
>
  <!-- <h2 mat-dialog-title>Avvik</h2> -->
  <mat-card style="max-height: 220px; overflow: auto; padding: 0">
    <!-- <mat-card-header>
      <mat-card-title>Avvik: </mat-card-title>
    </mat-card-header> -->
    <mat-card-content>
      <app-checklist-item
        style="padding: 0"
        style="width: 100%"
        [checklistItemId]="checklistitem.id"
        [locked]="true"
        [deviationPopup]="true"
        [objectTypeId]="objectTypeId"
      ></app-checklist-item>
    </mat-card-content>
  </mat-card>
  <p></p>
  <h2 mat-dialog-title>Sjekk ut avvik</h2>
  <div>
    <form [formGroup]="Form">
      <mat-form-field style="width: 100%">
        <textarea
          cdkTextareaAutosize
          style="padding: 0; min-height: 14px !important; padding-bottom: 3px"
          [value]="checklistitem.deviationcomment"
          id="deviationcomment"
          #deviationcomment
          matInput
          [required]="true"
          placeholder="Kommentar"
          (keyup.enter)="updateDeviationComment(deviationcomment.value)"
          (blur)="updateDeviationComment(deviationcomment.value)"
        ></textarea>
        <mat-icon matSuffix color="error">error_outline</mat-icon>
        <mat-error *ngIf="deviationcomment.form.touched && deviationcomment.form.invalid">
          Kommentar skal fylles ut
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="deviationfiles">
    <div class="flex-container">
      <app-thumbnail
        class="flex-item"
        [file]="file"
        [files]="deviationfiles"
        [isLocked]="false"
        *ngFor="let file of deviationfiles"
      ></app-thumbnail>
      <app-upload-button
        class="flex-item"
        *ngIf="!checklistitem.deviationSignedBy"
        [dbPath]="checklistitem.path"
        [button]="false"
        [isDeviationSign]="true"
      ></app-upload-button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    [disabled]="sanitycheckNoDeviationPicture || sanitycheckNoDeviationComment"
    mat-raised-button
    color="primary"
    class="align-right"
    (click)="signDeviationConfirm()"
  >
    {{ checklistitem.deviationSignedBy ? "Lagre endringer" : "Signer utsjekk" }}
  </button>
</mat-dialog-actions>
