<h2 mat-dialog-title *ngIf="dataFrontend">
  <mat-icon
    style="
      margin-bottom: 12px;
      vertical-align: bottom;
      font-size: 40px;
      margin-right: 20px;
    "
  >compare_arrows
  </mat-icon
  >
  {{ dataFrontend.length }} objekter klare for import
</h2>
<mat-dialog-content>
  <div *ngIf="dataFrontend">

<!--    <mat-list [style.height.px]="500" [style.overflow]="'auto'">-->
<!--      <mat-list-item *ngFor="let d of dataFrontend">-->
<!--        &lt;!&ndash; Customize the content based on your data structure &ndash;&gt;-->
<!--        {{ d.process }} - {{ d.name }} - {{ d.desc }} - {{ d.profilenumber }} - {{ d.drawing }} - {{ d.comment }}-->
<!--      </mat-list-item>-->
<!--    </mat-list>-->


    <!--    <p-table [value]="lazyData" [lazy]="true" [scrollable]="true" [scrollHeight]="'500px'"-->
<!--             (onLazyLoad)="loadData($event)">-->
<!--      <ng-template pTemplate="header">-->
<!--        &lt;!&ndash; Your header template &ndash;&gt;-->
<!--      </ng-template>-->
<!--      <ng-template pTemplate="body" let-rowData let-columns="columns">-->
<!--        <tr>-->
<!--          <td *ngFor="let col of columns">-->
<!--            {{ rowData[col.field] }}-->
<!--          </td>-->
<!--        </tr>-->
<!--      </ng-template>-->
<!--    </p-table>-->

    <p-table [value]="dataFrontend"
             [scrollable]="true"
             [scrollHeight]="'500px'"
             [paginator]="true"
             [alwaysShowPaginator]="true"
             paginatorDropdownAppendTo="body"
             [rows]="25"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="{first} - {last} / {totalRecords}"
             [rowsPerPageOptions]="[10, 25, 50, 100]"
             styleClass="p-datatable-striped p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr style="text-align: left">
          <th></th>
          <th style="border-bottom: 1px solid #666">Prosess</th>
          <th style="border-bottom: 1px solid #666">Navn</th>
          <th style="border-bottom: 1px solid #666; max-width: 200px">Type</th>
          <th style="border-bottom: 1px solid #666">Beskrivelse</th>
          <th style="border-bottom: 1px solid #666">Profilnummer</th>
          <th style="border-bottom: 1px solid #666">Tegning</th>
          <th style="border-bottom: 1px solid #666">Kommentar</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-d let-islast="last">
        <tr [ngClass]="{'errortext': d.change === 'error'}">
          <td>
            <mat-icon
              class="noselect"
              style="opacity: {{ d.change === 'error' ? 1 : 0.2 }}; margin-right: 10px"
              *ngIf="d.change === 'error'"
              matTooltip="Finner ikke objekt i prosjektet"
              [ngStyle]="{'color': d.change === 'error' ? 'warn' : 'primary'}"
            >{{ d.change === 'error' ? 'error' : 'add' }}</mat-icon
            >
          </td>
          <td [style.border-bottom]="islast ? '' : '1px solid #666'">
            <div *ngIf="d.old_process" style="opacity: 0.3" class="strikethrough">
              {{ d.old_process }}
            </div>
            {{ d.process }}
          </td>
          <td [style.border-bottom]="islast ? '' : '1px solid #666'">
            <div *ngIf="d.old_name" style="opacity: 0.3" class="strikethrough">
              {{ d.old_name }}
            </div>
            {{ d.name }}
          </td>
          <td
            style="max-width: 200px"
            [style.border-bottom]="islast ? '' : '1px solid #666'"
          >
            <div *ngIf="d.old_type" style="opacity: 0.3" class="strikethrough">
              {{ d.old_type }}
            </div>
            <mat-icon
              class="noselect"
              color="warn"
              *ngIf="!d.type"
              matTooltip="Mangler type"
            >warning</mat-icon
            >
            <div *ngIf="d.change === 'error'">{{ d.type?.name }}</div>
            <form
              *ngIf="!isLoading && d.change !== 'error' && objecttypes"
              style="max-width: 200px"
            >
              {{ d.type?.name }}
              <mat-form-field>
                <mat-label>Endre objekttype</mat-label>
                <mat-select [(value)]="d.type">
                  <mat-option *ngFor="let item of objecttypes" [value]="item">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="type.touched && type.invalid">
                  <div *ngIf="!type.errors.required">Please select a type</div>
                  <div *ngIf="type.errors.required">
                    Object type is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
            </form>
          </td>
          <td [style.border-bottom]="islast ? '' : '1px solid #666'">
            <div *ngIf="d.old_desc" style="opacity: 0.3" class="strikethrough">
              {{ d.old_desc }}
            </div>
            {{ d.desc }}
          </td>
          <td [style.border-bottom]="islast ? '' : '1px solid #666'">
            <div
              *ngIf="d.old_profilenumber"
              style="opacity: 0.3"
              class="strikethrough"
            >
              {{ d.old_profilenumber }}
            </div>
            {{ d.profilenumber }}
          </td>
          <td [style.border-bottom]="islast ? '' : '1px solid #666'">
            <div *ngIf="d.old_drawing" style="opacity: 0.3" class="strikethrough">
              {{ d.old_drawing }}
            </div>
            {{ d.drawing }}
          </td>
          <td [style.border-bottom]="islast ? '' : '1px solid #666'">
            <div *ngIf="d.old_comment" style="opacity: 0.3" class="strikethrough">
              {{ d.old_comment }}
            </div>
            {{ d.comment }}
          </td>
        </tr>
      </ng-template>
    </p-table>


  </div>


  <!--  <table>-->
  <!--    <tr style="text-align: left">-->
  <!--      <th></th>-->
  <!--      <th style="border-bottom: 1px solid #666">Prosess</th>-->
  <!--      <th style="border-bottom: 1px solid #666">Navn</th>-->
  <!--      <th style="border-bottom: 1px solid #666; max-width: 200px">Type</th>-->
  <!--      <th style="border-bottom: 1px solid #666">Beskrivelse</th>-->
  <!--      <th style="border-bottom: 1px solid #666">Profilnummer</th>-->
  <!--      <th style="border-bottom: 1px solid #666">Tegning</th>-->
  <!--      <th style="border-bottom: 1px solid #666">Kommentar</th>-->
  <!--    </tr>-->
  <!--    <tr-->
  <!--      *ngFor="let d of data; let islast = last"-->
  <!--      [class.errortext]="d.change === 'error'"-->
  <!--    >-->
  <!--      <td>-->
  <!--        <mat-icon-->
  <!--          class="noselect"-->
  <!--          style="opacity: 1; margin-right: 10px"-->
  <!--          *ngIf="d.change === 'error'"-->
  <!--          matTooltip="Finner ikke objekt i prosjektet"-->
  <!--          color="warn"-->
  <!--          >error</mat-icon-->
  <!--        >-->
  <!--        <mat-icon-->
  <!--          class="noselect"-->
  <!--          style="opacity: 0.2; margin-right: 10px"-->
  <!--          *ngIf="d.change === 'change'"-->
  <!--          matTooltip="Endring på objekt"-->
  <!--          >edit</mat-icon-->
  <!--        >-->
  <!--        <mat-icon-->
  <!--          class="noselect"-->
  <!--          style="opacity: 0.2; margin-right: 10px"-->
  <!--          *ngIf="!d.objectid"-->
  <!--          matTooltip="Nytt objekt"-->
  <!--          >add</mat-icon-->
  <!--        >-->
  <!--      </td>-->
  <!--      <td [style.border-bottom]="islast ? '' : '1px solid #666'">-->
  <!--        <div *ngIf="d.old_process" style="opacity: 0.3" class="strikethrough">-->
  <!--          {{ d.old_process }}-->
  <!--        </div>-->
  <!--        {{ d.process }}-->
  <!--      </td>-->
  <!--      <td [style.border-bottom]="islast ? '' : '1px solid #666'">-->
  <!--        <div *ngIf="d.old_name" style="opacity: 0.3" class="strikethrough">-->
  <!--          {{ d.old_name }}-->
  <!--        </div>-->
  <!--        {{ d.name }}-->
  <!--      </td>-->
  <!--      <td-->
  <!--        style="max-width: 200px"-->
  <!--        [style.border-bottom]="islast ? '' : '1px solid #666'"-->
  <!--      >-->
  <!--        <div *ngIf="d.old_type" style="opacity: 0.3" class="strikethrough">-->
  <!--          {{ d.old_type }}-->
  <!--        </div>-->
  <!--        <mat-icon-->
  <!--          class="noselect"-->
  <!--          color="warn"-->
  <!--          *ngIf="!d.type"-->
  <!--          matTooltip="Mangler type"-->
  <!--          >warning</mat-icon-->
  <!--        >-->
  <!--        <div *ngIf="d.change === 'error'">{{ d.type?.name }}</div>-->
  <!--        <form-->
  <!--          *ngIf="!isLoading && d.change !== 'error'"-->
  <!--          style="max-width: 200px"-->
  <!--        >-->
  <!--          <mat-form-field>-->
  <!--            <mat-label>Objekttype</mat-label>-->
  <!--            <mat-select [(value)]="d.type">-->
  <!--              <mat-option *ngFor="let item of objecttypes" [value]="item">-->
  <!--                {{ item.name }}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--            <mat-error *ngIf="type.touched && type.invalid">-->
  <!--              <div *ngIf="!type.errors.required">Please select a type</div>-->
  <!--              <div *ngIf="type.errors.required">-->
  <!--                Object type is <strong>required</strong>-->
  <!--              </div>-->
  <!--            </mat-error>-->
  <!--          </mat-form-field>-->
  <!--        </form>-->
  <!--      </td>-->
  <!--      <td [style.border-bottom]="islast ? '' : '1px solid #666'">-->
  <!--        <div *ngIf="d.old_desc" style="opacity: 0.3" class="strikethrough">-->
  <!--          {{ d.old_desc }}-->
  <!--        </div>-->
  <!--        {{ d.desc }}-->
  <!--      </td>-->
  <!--      <td [style.border-bottom]="islast ? '' : '1px solid #666'">-->
  <!--        <div-->
  <!--          *ngIf="d.old_profilenumber"-->
  <!--          style="opacity: 0.3"-->
  <!--          class="strikethrough"-->
  <!--        >-->
  <!--          {{ d.old_profilenumber }}-->
  <!--        </div>-->
  <!--        {{ d.profilenumber }}-->
  <!--      </td>-->
  <!--      <td [style.border-bottom]="islast ? '' : '1px solid #666'">-->
  <!--        <div *ngIf="d.old_drawing" style="opacity: 0.3" class="strikethrough">-->
  <!--          {{ d.old_drawing }}-->
  <!--        </div>-->
  <!--        {{ d.drawing }}-->
  <!--      </td>-->
  <!--      <td [style.border-bottom]="islast ? '' : '1px solid #666'">-->
  <!--        <div *ngIf="d.old_comment" style="opacity: 0.3" class="strikethrough">-->
  <!--          {{ d.old_comment }}-->
  <!--        </div>-->
  <!--        {{ d.comment }}-->
  <!--      </td>-->
  <!--    </tr>-->
  <!--  </table>-->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <div class="row">
    <div class="fill-space"></div> -->
  <!-- <div> -->
  <button
    [disabled]="objectnotfound > 0"
    mat-raised-button
    (click)="downloadChangeInObjects()"
  >
    Last ned endringsliste
  </button>
  <button
    [disabled]="objectnotfound > 0"
    mat-raised-button
    (click)="saveObjects()"
  >
    Legg til objekter
  </button>
  <!-- </div> -->
  <!-- </div> -->
</mat-dialog-actions>
<div class="row" style="margin-top: 25px">
  <div class="fill-space"></div>
  <div class="errortext" *ngIf="objectnotfound > 0">
    {{ objectnotfound }} objekter ikke funnet i database. Import ikke mulig.
  </div>
</div>
