import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppVersion} from 'src/app/models/Admin';
import {User} from 'src/app/models/User';
import {AdminService} from 'src/app/services/admin.service';
import {PopupService} from 'src/app/services/popup.service';
import {SharedService} from 'src/app/services/shared.service';
import _ from 'underscore';

@Component({
  selector: 'app-changelog-list',
  templateUrl: './changelog-list.component.html',
  styleUrls: ['./changelog-list.component.css'],
})
export class ChangelogListComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  @Input() oldVersion?: string;
  @Input() newVersion?: string;
  @Input() expanded?: boolean;

  isLoading = true;
  user: User;
  userVersion: string[];
  userVersionstring: string;

  changes: AppVersion[];
  changesSorted: {
    mainversion: string;
    subversion: string;
    subversions: AppVersion[];
  }[];

  constructor(
    private adminservice: AdminService,
    private sharedservice: SharedService,
    private popupservice: PopupService,
  ) {}

  ngOnInit(): void {
    this.adminservice
      .getAppVersions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((appVersions: AppVersion[]) => {
        this.userVersionstring = null;
        this.userVersion = null;
        this.sharedservice.user
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((u) => {
            if (u !== null) {
              this.user = u[0];
              if (this.user.appVersion !== this.userVersionstring) {
                this.userVersionstring = this.user.appVersion;
                this.userVersion = this.userVersionstring.split('.');
                new Promise((resolve) => {
                  appVersions.forEach((version, index) => {
                    const v = version.version.split('.');
                    version.mainversion = parseInt(v[0], 10);
                    version.subversion = parseInt(v[1], 10);
                    version.subsubversion = parseInt(v[2], 10);
                    if (index === appVersions.length - 1) {
                      resolve(true);
                    }
                  });
                }).then(() => {
                    return new Promise((resolve) => {
                      if (this.newVersion && this.oldVersion) {
                        this.changes = [];
                        appVersions.forEach((version, index) => {
                          if (
                            version.version > this.oldVersion &&
                            version.version <= this.newVersion
                          ) {
                            this.changes.push(version);
                          }
                          if (index === appVersions.length - 1) {
                            resolve(true);
                          }
                        });
                      } else {
                        this.changes = appVersions;
                        resolve(true);
                      }
                    });
                }).then(() => {
                  const majorList = [];
                  this.changes.forEach(changeItem => {
                    majorList.push({
                      groupKey: changeItem.mainversion + '.' + changeItem.subversion,
                      mainversion: changeItem.mainversion.toString(),
                      subversion: changeItem.subversion.toString(),
                      subversions: changeItem
                    });
                  });

                  const finalObject = {};
                  majorList.forEach(majorItem => {
                    const key = majorItem.mainversion + '.' + majorItem.subversion;
                    if (!finalObject[key]) {
                      finalObject[key] = {};
                    }
                    if (!finalObject[key].subversions) {
                      finalObject[key].subversions = [];
                    }
                    finalObject[key].mainversion = majorItem.mainversion;
                    finalObject[key].subversion = majorItem.subversion;
                    finalObject[key].subversions.push(majorItem.subversions);
                  });

                  const finalList = [];

                  Object.keys(finalObject).forEach(key => {
                    finalList.push({
                      mainversion: finalObject[key].mainversion,
                      subversion: finalObject[key].subversion,
                      subversions: finalObject[key].subversions
                    });
                  });
                  this.changesSorted = finalList;

                  this.isLoading = false;
                });
              }
            }
          });
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  editVersion(change: AppVersion) {
    this.popupservice.ChangelogItem(change);
  }
}
