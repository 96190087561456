<h1 mat-dialog-title>
  <mat-icon style="vertical-align: middle; margin-right: 5px">cases</mat-icon>
  Nytt mottak
</h1>
<mat-dialog-content>
  <div>
    <mat-form-field *ngIf="data.projects && !data.currentprojectid">
      <mat-label>Prosjekt</mat-label>
      <mat-select (selectionChange)="projectselect($event)">
        <mat-option *ngFor="let project of data.projects" [value]="project.id">
          {{ project.id }} - {{ project.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingObjects"
    ></mat-progress-bar>
    <div *ngIf="!isLoadingObjects">
      <button
        *ngIf="projectid"
        mat-raised-button
        matTooltip="Nytt objekt"
        (click)="newObject()"
        class="header-row-button"
      >
        <mat-icon color="primary">add</mat-icon>
        Nytt objekt
      </button>

      <div *ngIf="!isLoadingObjects && objects && projectid && receiptForms">

        <p-treeTable #tt class="p-treetable-sm" [value]="types" [scrollable]="true" scrollHeight="400px" [(selection)]="selectedObjects">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <input pInputText type="text" (input)="tt.filter($event.target?.value, 'name', 'contains')" placeholder="Søk"/>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode">
              <td [style]="rowData.count !== null ? {} : {'background-color': '#252525'}">
                <div
                  [style]="rowData.complete ? {'color': 'green'} : {}"
                  [style.font-weight]="rowData.count !== null ? 'bold' : 'normal'"
                  [style.margin-left]="rowData.count !== null ? '10px' : '25px'"
                  style="margin-bottom: 10px;"
                >{{ rowData.name }}
                </div>
                <div class="row">
                  <p-treeTableToggler style="margin-top: -7px;" [rowNode]="rowNode"></p-treeTableToggler>
                  <div class="fill-space"></div>
                  <span *ngIf="rowData.count !== null" style="right: 60px; font-size: 1rem">{{ rowData.count }} / {{ rowData.total }}</span>
                  <i [style.opacity]="rowData.complete ? 1 : 0" style="margin-left: 10px; margin-right: 10px; color: green; font-size: 1.3rem" class="pi pi-check-circle"></i>
                  <p-treeTableCheckbox [disabled]="rowData.count !== null" [value]="rowNode"></p-treeTableCheckbox>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-treeTable>

      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<!--  <div *ngIf="selectedObjects?.length > 0">-->
<!--    <div style="padding-top: 10px; padding-bottom: 10px; font-size: small">-->
<!--      Valgte objekter:-->
<!--    </div>-->
<!--    <table>-->
<!--      <tr *ngFor="let so of selectedObjects" style="font-size: x-small">-->
<!--        <td>{{ so.type?.name }}</td>-->
<!--        <td>{{ so.name }}</td>-->
<!--      </tr>-->
<!--    </table>-->
<!--  </div>-->
<!--  <div class="fill-space"></div>-->
  <button mat-button (click)="onNoClick()">Avbryt</button>
  <button
    [disabled]="!projectid || selectedObjects.length === 0"
    mat-button
    (click)="createReceiptform()"
    cdkFocusInitial
  >
    Opprett
  </button>
</mat-dialog-actions>
