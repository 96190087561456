import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  constructor() {
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      // next line works with strings and numbers, and you may want to customize it to your needs
      const result =
        (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
      return result * sortOrder;
    };
  }

  reduceArrayToMaxLength(array: any[], firebaseTimestampField: string, maxLength: number = 10) {
    // console.log(`Reducing array to max length from ${array.length} to ${maxLength}`);
    // Check if the array is empty
    if (array.length === 0) {
      // console.log('Array is empty, returning empty array');
      return [];
    }

    // If the array has only one element, return it directly
    if (array.length <= maxLength) {
      // console.log('Array length is less than or equal to max length, returning array');
      return array;
    }

    // Sort the array based on the timestamp field in descending order
    array.sort((a, b) => b[firebaseTimestampField].seconds - a[firebaseTimestampField].seconds);

    // Slice the array to a maximum length of maxLength
    const slicedArray = array.slice(0, maxLength);

    // console.log(`Array reduced to ${slicedArray.length} elements`);

    return slicedArray;
  }

}
