import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeFileExtension' })
export class RemoveExtension implements PipeTransform {
  transform(item) {
    return item.substring(0, item.indexOf('.'));
  }
}

@Pipe({ name: 'replaceFileExtension' })
export class ReturnExtension implements PipeTransform {
  transform(item) {
    return item.substring(item.lastIndexOf('.'), item.length);
  }
}
