import {MediaMatcher} from '@angular/cdk/layout';
import {Component, DoCheck, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {ChecklistService} from 'src/app/services/checklist.service';
import {FileService} from 'src/app/services/file.service';
import {PopupService} from 'src/app/services/popup.service';
import {PrintChecklistService} from 'src/app/services/print-checklist.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';

@Component({
  selector: 'app-objectchecklists',
  templateUrl: './objectchecklists.component.html',
  styleUrls: ['./objectchecklists.component.css'],
})
export class ObjectchecklistsComponent implements OnInit, DoCheck {
  @Input() object: AventiObject;
  @Input() popup = false;
  pdfLoaded: boolean;
  user: User;
  isAdmin = false;
  mobileQuery: MediaQueryList;
  isOnline: boolean;
  sortByItems = [
    {id: 'Sjekklistedato', name: 'Sjekklistedato'},
    {id: 'PDF dato', name: 'PDF dato'},
    {id: 'Kommentar', name: 'Kommentar'},
    {id: 'Levert', name: 'Levert'},
  ];
  sortByType = 'Sjekklistedato';
  private ngUnsubscribe = new Subject();

  constructor(
    private checklistservice: ChecklistService,
    private printchecklistservice: PrintChecklistService,
    media: MediaMatcher,
    private fileservice: FileService,
    private popupservice: PopupService,
    private sharedservice: SharedService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    sharedservice.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      if (user !== null) {
        this.user = user[0];
        this.isAdmin = this.user.role?.name === 'admin';
      }
    });
  }

  ngDoCheck() {
    if (!this.pdfLoaded) {
      this.sortChecklists();
    }
    this.object.checklists = _.sortBy(this.object.checklists, 'sortbystring');
  }

  ngOnInit(): void {
    this.sortChecklists();
    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });
  }

  sortChecklists() {
    if (this.object.checklists?.length === 0) {
      this.pdfLoaded = true;
    }
    if (this.object.checklists) {
      this.object.checklists.forEach((cl) => {
        switch (this.sortByType) {
          case 'PDF dato':
            cl.sortbystring = cl.pdfexports[0]?.timestampstring || '0';
            break;
          case 'Kommentar':
            cl.sortbystring = cl.comment;
            break;
          case 'Levert':
            cl.sortbystring = cl.PDFDeliveredToCustomer ? 'a' : cl.pdfexports ? 'b' : 'c';
            break;
          default:
            if (cl.isSigned) {
              cl.sortbystring = cl.lastsignedString;
            } else {
              cl.sortbystring = cl.lastupdatedString;
            }
            break;
        }
        if (cl.pdfexports?.length === 0) {
          this.pdfLoaded = true;
        }
        if (cl.pdfexports !== null) {
          if (cl.pdfexports.length === 0) {
            this.pdfLoaded = true;
            console.log('no pdfexportss on object');
          }
          cl.pdfexports.forEach((pdf) => {
            if (!pdf.sizeB || !pdf.sizeMB || !pdf.sizestring) {
              this.fileservice.filesize(pdf).subscribe((size) => {
                if (size) {
                  pdf.sizestring = size;
                }
              });
            }
            this.pdfLoaded = true;
          });
        }
      });
      this.object.checklists = _.sortBy(this.object.checklists, 'sortbystring');
    }
    // console.log(this.object.checklists);
  }

  newChecklistClick() {
    this.checklistservice.newChecklistClick(this.object, true, false, false);
  }

  setDeliveredToCustomer(checklist: Checklist) {
    let s = true;
    if (
      checklist.PDFDeliveredToCustomer &&
      checklist.PDFDeliveredToCustomer.status
    ) {
      s = false;
    }
    if (!checklist.PDFDeliveredToCustomer) {
      checklist.PDFDeliveredToCustomer = {status: false};
    }
    checklist.PDFDeliveredToCustomer.status = s;
    this.checklistservice.updateChecklistDeliveredPdfToCustomer(
      s,
      checklist,
      this.user
    );
  }

  generatePDF(checklist: Checklist) {
    this.printchecklistservice.sendChecklistToPrint(checklist);
  }

  generatePDFresetError(checklist: Checklist) {
    if (checklist.generatingPDFstatus.substring(0, 5) === 'Error') {
      this.printchecklistservice.generatePdfResetError(checklist);
    }
  }

  openDialogPdfPreview(file: AventiFile, checklist: Checklist) {
    this.popupservice.openDialogFilePreview([file], 0, false, checklist);
  }

  onSelectionChange(type: any) {
    // this.checklistitem.type = type;
    // this.adminservice.updateChecklistitemtype(this.checklistitem);
    // this.edittype = false;
    this.sortByType = type.id;
    // console.log(type);
    this.sortChecklists();
  }
}
