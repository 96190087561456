import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {AventiFile} from 'src/app/models/File';
import {Project} from 'src/app/models/Project';
import {User} from 'src/app/models/User';
import {ChecklistService} from 'src/app/services/checklist.service';
import {DateService} from 'src/app/services/date.service';
import {IdService} from 'src/app/services/id.service';
import {ObjectService} from 'src/app/services/object.service';
import {ProjectService} from 'src/app/services/project.service';
import {ReceiptformService} from 'src/app/services/receiptform.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
  providers: [ObjectService],
})
export class ContentComponent implements OnInit {
  @Input() project: Project;
  objects: AventiObject[];
  objectsTemp: AventiObject[];
  receiptforms: Checklist[];
  checklists: Checklist[];
  checklistsAll: Checklist[];
  checklistitems: ChecklistItem[];
  // checklistItemsAll: ChecklistItem[];
  checklistpdfs: AventiFile[];
  checklistfiles: AventiFile[];
  projectfiles: AventiFile[];
  user: User;
  mobileQuery: MediaQueryList;
  isLoadingUser = true;
  isLoadingObjects = true;
  isLoadingReceiptforms = true;
  isLoadingChecklists = true;
  LoadingHeader: string;
  LoadingText: string[] = [];
  isLoadingTextObjectsIndex = 0;
  isLoadingTextReceiptformsIndex = 0;
  isLoadingTextChecklistsIndex = 0;
  runonce = true;
  newVersion: string;
  private ngUnsubscribe = new Subject();

  constructor(
    private objectservice: ObjectService,
    private sharedservice: SharedService,
    private receiptformservice: ReceiptformService,
    private checklistservice: ChecklistService,
    private projectservice: ProjectService,
    private idservice: IdService,
    private dateservice: DateService,
    media: MediaMatcher,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.sharedservice.isLoading.next(null);
    this.sharedservice.objects.next(null);
    this.sharedservice.checklists.next(null);
    this.sharedservice.receiptforms.next(null);
    // this.sharedservice.checklistitems.next(null);
    this.sharedservice.checklistItemsWithDeviation.next(null);
    this.sharedservice.checklistpdfs.next(null);
    // this.sharedservice.checklistfiles.next(null);
    this.sharedservice.checklistsandreceiptforms.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadProject();
    this.LoadingHeader = this.project.id + ' - ' + this.project.name;
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
          this.isLoadingUser = false;
        }
      });

    this.checklistservice
      .getAllChecklistItemsWithDeviation()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklistItemsWithDeviation) => {
        this.sharedservice.checklistItemsWithDeviation.next(checklistItemsWithDeviation);
      });

    this.checklistservice
      .getAllChecklistPDFs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pdfs) => {
        pdfs.forEach((pdf) => {
          if (pdf.lastupdated) {
            pdf.lastupdatedString = this.dateservice.dateAsYYYYMMDDHHNNSS(
              pdf.timestamp
            );
          } else {
            pdf.lastupdatedString = '';
          }
        });
        this.checklistpdfs = pdfs;
        this.sharedservice.checklistpdfs.next(pdfs);
        this.fillChecklistsAndEmit();
        this.fillReceiptformsAndEmit();
      });

    const projectid = this.idservice.projectId();
    this.projectservice
      .getProjectFiles(projectid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((files) => {
        this.projectfiles = files;
        this.fillProjectAndEmit();
      });
  }

  fillProjectAndEmit() {
    if (this.project && this.projectfiles) {
      this.project.files = null;
      if (this.projectfiles) {
        this.project.files = [];
        this.projectfiles.forEach((projectfile) => {
          this.project.files.push(projectfile);
        });
      }
      this.sharedservice.project.next(this.project);
    }
  }

  async loadProject() {
    if (this.runonce) {
      if (this.isLoadingObjects) {
        this.isLoadingTextObjectsIndex = this.LoadingText.length;
        this.LoadingText.push('Laster inn objekter...');
      }
      if (this.isLoadingChecklists) {
        this.isLoadingTextChecklistsIndex = this.LoadingText.length;
        this.LoadingText.push('Laster inn  sjekklister...');
      }

      this.checklistservice
        .getChecklists()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((checklists: Checklist[]) => {
          this.fillChecklists(checklists);
        });

      // this.checklistservice
      //   .getAllChecklistItemsShort()
      //   .pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe((checklistItems: ChecklistItem[]) => {
      //     this.fillChecklistItems(checklistItems);
      //   });

      // if (projectid === '12344') {
      //   console.log('filling with static objects', objects12344);
      //   this.fillObjects(objects12344);
      // } else {

      // console.log('filling with firebase objects');
      // let i = 0;
      // let result = 1000;
      // let objectsArr: AventiObject[] = [];
      // while (result === 1000) {
      //   console.log('getting items from ' + (i * result));
      //   const obj = await this.objectservice.getObjectsByProjectIdPromise(projectid, 1000, i);
      //   console.log(obj[0].id);
      //   objectsArr = [...objectsArr, ...obj];
      //   result = obj.length;
      //   i++;
      // }
      // this.fillObjects(objectsArr);


      this.objectservice
        .getObjectsByProjectId()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((objects: AventiObject[]) => {
          this.fillObjects(objects);
        });

      this.receiptformservice
        .getReceiptforms()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((receiptforms: Checklist[]) => {
          this.fillReceiptforms(receiptforms);
        });
      this.runonce = false;
    }
  }

  fillObjects(objects: AventiObject[]) {
    this.objectsTemp = objects;
    this.fillObjectsAndEmit();
  }

  fillReceiptforms(receiptforms: Checklist[]) {
    receiptforms.forEach((checklist) => {
      checklist.pdfexports = null;
    });
    this.receiptforms = receiptforms;
    this.fillReceiptformsAndEmit();
  }

  fillChecklists(checklists: Checklist[]) {
    checklists.forEach((checklist) => {
      checklist.pdfexports = null;
    });
    this.checklistsAll = checklists;
    this.fillChecklistsAndEmit();
  }

  // fillChecklistItems(checklistItems: ChecklistItem[]) {
  //   this.checklistItemsAll = checklistItems;
  //   this.fillChecklistsAndEmit();
  // }

  fillObjectsAndEmit() {
    if (this.objectsTemp && this.checklists) {
      const projectid = this.idservice.projectId();
      this.objectsTemp.forEach((object) => {
        if (!object.projectid && projectid) {
          object.projectid = projectid;
          console.log(
            'Updating object with projectid',
            object.id,
            object.projectid
          );
          this.objectservice.updateObject(object);
        }
        delete object.z_checklistsstring;
        object.editedBy = null;

        object.typename = object.type?.name;

        if (object.lastupdated) {
          object.lastupdatedString = this.dateservice.dateAsYYYYMMDDHHNNSS(
            object.lastupdated
          );
        } else {
          object.lastupdatedString = '';
        }
        object.checklists = null;
        if (this.checklists) {
          const checklists = this.checklists.filter(checklist => checklist.objectid === object.id);
          checklists.forEach(checklist => delete checklist.checklistitems);
          object.checklists = checklists ?? [];
          if ((checklists.length === 0) && object.status) {
            delete object.status;
          }
        }
      });

      this.objectsTemp = _.sortBy(this.objectsTemp, 'name');
      this.objectsTemp = _.sortBy(this.objectsTemp, 'typename');
      this.objectsTemp = _.sortBy(this.objectsTemp, 'process');

      this.objects = this.objectsTemp;
      this.sharedservice.objects.next(this.objects);
      if (this.isLoadingObjects) {
        this.LoadingText[this.isLoadingTextObjectsIndex] =
          'Objekter lastet inn';
      }
      this.isLoadingObjects = false;
    }
  }

  fillChecklistsAndEmit() {
    if (this.checklistpdfs && this.checklistsAll) {
      const checklistsTemp: Checklist[] = [];
      const checklistsandreceiptforms: Checklist[] = [];
      this.checklistsAll.forEach((checklist) => {
        this.sharedservice.prepareChecklistsChecklist(checklist);
        delete checklist.z_checklistitemsstring;
        delete checklist.z_filesstring;
        delete checklist.z_pdfexportsstring;

        let tempPath = `/p/${checklist.projectid}/o/${checklist.objectid}/s/${checklist.id}`;
        if (checklist.receiptform) {
          tempPath = `/p/${checklist.projectid}/mt/${checklist.id}`;
        }
        checklist.tempPath = tempPath;
        if (checklist.lastupdated) {
          checklist.lastupdatedString = this.dateservice.dateAsYYYYMMDDHHNNSS(
            checklist.lastupdated
          );
        } else {
          checklist.lastupdatedString = '';
        }

        if (checklist.signedBy?.timestamp) {
          checklist.lastsignedString = this.dateservice.dateAsYYYYMMDDHHNNSS(
            checklist.signedBy?.timestamp
          );
        } else {
          checklist.lastsignedString = null;
        }

        checklist.pdfexports = null;
        if (this.checklistpdfs) {
          if (this.checklistpdfs.length > 0) {
            checklist.pdfexports = [];
            this.checklistpdfs.forEach((pdf) => {
              if (checklist.id === pdf.checklistid && !pdf.checklistitemid) {
                if (
                  pdf.numberOfDeviations > 0 &&
                  pdf.numberOfDeviationsSigned !== pdf.numberOfDeviations
                ) {
                  pdf.iconcolor = 'warn';
                } else {
                  pdf.iconcolor = 'primary';
                }
                pdf.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
                  pdf.timestamp
                );
                checklist.pdfexports.push(pdf);
              }
            });
            checklist.pdfexports = _.sortBy(
              checklist.pdfexports,
              'timestampstring'
            );
          } else {
            checklist.pdfexports = [];
          }
        }
        // checklist.checklistitems = this.checklistItemsAll.filter(item => item.checklistid === checklist.id);
        if (checklist.receiptform) {
          checklistsandreceiptforms.push(checklist);
        } else {
          checklistsTemp.push(checklist);
          checklistsandreceiptforms.push(checklist);
        }
      });
      this.checklists = checklistsTemp;
      this.sharedservice.checklists.next(checklistsTemp);
      this.sharedservice.checklistsandreceiptforms.next(
        checklistsandreceiptforms
      );
      this.fillObjectsAndEmit();
      if (this.isLoadingChecklists) {
        this.LoadingText[this.isLoadingTextChecklistsIndex] =
          'Sjekklister lastet inn';
      }
      this.isLoadingChecklists = false;
    }
  }

  fillReceiptformsAndEmit() {
    if (this.receiptforms && this.checklistpdfs) {
      const receiptformsTemp: Checklist[] = [];
      this.receiptforms.forEach((checklist: Checklist) => {
        this.sharedservice.prepareChecklistsChecklist(checklist);

        delete checklist.z_checklistitemsstring;
        delete checklist.z_filesstring;
        delete checklist.z_pdfexportsstring;

        let tempPath = `/p/${checklist.projectid}/o/${checklist.objectid}/s/${checklist.id}`;
        if (checklist.receiptform) {
          tempPath = `/p/${checklist.projectid}/mt/${checklist.id}`;
        }
        checklist.tempPath = tempPath;
        if (checklist.lastupdated) {
          checklist.lastupdatedString = this.dateservice.dateAsYYYYMMDDHHNNSS(
            checklist.lastupdated
          );
        } else {
          checklist.lastupdatedString = '';
        }

        if (checklist.signedBy?.timestamp) {
          checklist.lastsignedString = this.dateservice.dateAsYYYYMMDDHHNNSS(
            checklist.signedBy?.timestamp
          );
        } else {
          checklist.lastsignedString = null;
        }

        checklist.pdfexports = null;
        if (this.checklistpdfs) {
          if (this.checklistpdfs.length > 0) {
            checklist.pdfexports = [];
            this.checklistpdfs.forEach((pdf) => {
              if (checklist.id === pdf.checklistid && !pdf.checklistitemid) {
                if (
                  pdf.numberOfDeviations > 0 &&
                  pdf.numberOfDeviationsSigned !== pdf.numberOfDeviations
                ) {
                  pdf.iconcolor = 'warn';
                } else {
                  pdf.iconcolor = 'primary';
                }
                pdf.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
                  pdf.timestamp
                );
                checklist.pdfexports.push(pdf);
              }
            });
            checklist.pdfexports = _.sortBy(
              checklist.pdfexports,
              'timestampstring'
            );
          } else {
            checklist.pdfexports = [];
          }
        }
        if (checklist.receiptform) {
          receiptformsTemp.push(checklist);
        }
      });
      this.receiptforms = receiptformsTemp;

      this.sharedservice.receiptforms.next(this.receiptforms);
      if (this.isLoadingReceiptforms) {
        this.LoadingText[this.isLoadingTextReceiptformsIndex] =
          'Mottakskjema lastet inn';
      }
      this.isLoadingReceiptforms = false;
    }
  }
}
