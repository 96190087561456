<app-headertext text="Ny objekt status" hideback="true"></app-headertext>
<form [formGroup]="Form" (ngSubmit)="create(name.value, enName.value, priority.value)">
  <div class="form-group">
    <!-- <p>
      <mat-form-field>
        <input
          matInput
          id="id"
          type="number"
          placeholder="ID"
          formControlName="id"
        />
        <mat-error *ngIf="id.touched && id.invalid">
          <div *ngIf="!id.errors.required">
            Please enter an ID
          </div>
          <div *ngIf="id.errors.required">
            Objecttype ID is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p> -->
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="name"
          type="text"
          placeholder="Navn"
          formControlName="name"
        />
        <mat-error *ngIf="name.touched && name.invalid">
          <div *ngIf="!name.errors.required">
            Please enter a status name
          </div>
          <div *ngIf="name.errors.required">
            Status name is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="enName"
          type="text"
          placeholder="Name"
          formControlName="enName"
        />
        <mat-error *ngIf="enName.touched && enName.invalid">
          <div *ngIf="!enName.errors.required">
            Please enter a status english name
          </div>
          <div *ngIf="enName.errors.required">
            Status english name is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="priority"
          type="number"
          placeholder="Prioritet"
          formControlName="priority"
        />
        <mat-error *ngIf="priority.touched && priority.invalid">
          <div *ngIf="!priority.errors.required">
            Please enter a status priority
          </div>
          <div *ngIf="priority.errors.required">
            Status priority is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
        Lagre
      </button>
    </p>
    <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
  </div>
</form>
