import { MediaMatcher } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
} from '@angular/fire/compat/storage';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import FileSaver, {saveAs} from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import {
  PdfDocumentPropertiesExtractor,
  ProgressBarEvent,
} from 'ngx-extended-pdf-viewer';
import { PDFDocument } from 'pdf-lib';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Checklist } from 'src/app/models/Checklist';
import { AventiFile } from 'src/app/models/File';
import { User } from 'src/app/models/User';
import { DateService } from 'src/app/services/date.service';
import { FileService } from 'src/app/services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import {ChecklistService} from 'src/app/services/checklist.service';
import {IdService} from 'src/app/services/id.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
})
export class PreviewComponent implements OnInit {

  get comment() {
    return this.FormComment.get('comment');
  }

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private fileservice: FileService,
    private checklistservice: ChecklistService,
    media: MediaMatcher,
    private sharedservice: SharedService,
    private dateservice: DateService,
    private idservice: IdService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    this.class = PreviewComponent;
  }
  static progressstring: string[] = [];
  private ngUnsubscribe = new Subject();
  @ViewChild('progressbar') myProgressbar: ElementRef;

  @Input() width = '100';

  @Input() files: AventiFile[];
  @Input() fileindex: number;
  // @Input() fileid: string;
  // @Input() path: string;
  attachments: AventiFile[] = [];
  images: AventiFile[] = [];
  @Input() checklist: Checklist;
  @Input() isLocked = false;
  // @Input() isPdfexport = false;

  file: AventiFile;

  projectId: string;

  exif: any[];

  storageRef: AngularFireStorageReference;
  storageThumbnailRef: AngularFireStorageReference;
  fileRef: AngularFireStorageReference;
  thumbnailRef: AngularFireStorageReference;
  checklistDoc: AngularFirestoreDocument<any>;
  checklistDocObs: Observable<any>;
  fileDoc: AngularFirestoreDocument<any>;
  fileDocObs: Observable<any>;
  fileID: string;
  filesID: string[];
  filesList: any[];

  mobileQuery: MediaQueryList;

  format: string;
  pdfFallback = false;

  pdfLoading = true;
  pdfPage = 1;
  pdfPages: number;

  progressDirectDefault = 'Last ned fil';
  progressZipDefault = '.zip med vedlegg';
  progressImageZipDefault = '.zip med bilder';
  progressConcatDefault = 'Last ned sammenslått';
  progressDirect: string = this.progressDirectDefault;
  progressZip: string = this.progressZipDefault;
  progressImageZip: string = this.progressImageZipDefault;
  progressConcat: string = this.progressConcatDefault;

  iconDownload = 'download';
  iconDownloading = 'downloading';
  iconDirectDefault: string = this.iconDownload;
  iconZipDefault: string = this.iconDownload;
  iconConcatDefault: string = this.iconDownload;
  iconDirect: string = this.iconDirectDefault;
  iconZip: string = this.iconZipDefault;
  iconConcat: string = this.iconConcatDefault;

  progressTextlayer: string;

  isDownloadingDirect: boolean;
  isDownloadingZip: boolean;
  isDownloadingConcat: boolean;
  isDownloadingImages: boolean;

  readyToGenerrateZipAttachments: boolean;
  readyToGenerrateZipImages: boolean;

  filesReady: boolean;
  checklistitemsReady: boolean;
  printlock = true;
  editComment = false;

  FormComment = new UntypedFormGroup({
    comment: new UntypedFormControl('', []),
  });

  base64src: string;
  src: string;

  downloadurl: string;

  user: User;

  filesizestring: string;

  fileInfo: any;
  pdfProgress: ProgressBarEvent;

  class: any;

  ngOnInit() {
    this.projectId = this.idservice.projectId();
    // console.log(this.files);

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users) => {
        if (users !== null) {
          this.user = users[0];
        }
      });

    if (this.checklist) {
      this.checklist.files = null;
      this.checklist.checklistitems = null;

      // this.sharedservice.checklistitems
      this.checklistservice.getChecklistItems(this.checklist.id, this.checklist.receiptform)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((clis) => {
          if (clis !== null) {
            clis.forEach((cli) => {
              cli.files = [];
              if (cli.checklistid === this.checklist.id) {
                if (this.checklist.checklistitems === null) {
                  this.checklist.checklistitems = [];
                }
                this.checklist.checklistitems.push(cli);
              }
            });
            this.checklistitemsReady = true;
            this.buildChecklist();
          }
        });

      // this.sharedservice.checklistfiles
      this.checklistservice.getChecklistFiles(this.projectId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((fs) => {
          if (fs !== null) {
            fs.forEach((f) => {
              if (f.checklistid === this.checklist.id) {
                if (!f.checklistitemid) {
                  if (this.checklist.files === null) {
                    this.checklist.files = [];
                  }
                  this.checklist.files.push(f);
                } else if (f.checklistitemid) {
                  this.checklist.checklistitems.forEach((checklistitem) => {
                    if (f.checklistitemid === checklistitem.id) {
                      checklistitem.files.push(f);
                    }
                  });
                }
              }
            });
            this.filesReady = true;
            this.buildChecklist();
          }
        });
    } else {
      this.filesReady = true;
      this.checklistitemsReady = true;
      this.buildChecklist();
    }

    this.buildFilePreview();
  }
  buildFilePreview() {
    this.pdfFallback = false;
    this.format = null;
    this.file = this.files[this.fileindex];
    // console.log(this.file);
    // console.log(this.fileindex);
    // console.log(this.files);
    this.files?.forEach((file) => {
      if (file.type.indexOf('image') > -1) {
        // if (file.downloadurl200 && file.downloadurl200.length > 0) {
        //   this.downloadurl = file.downloadurl200;
        // } else
        if (file.downloadurl500 && file.downloadurl500.length > 0) {
          file.image = file.downloadurl500;
        } else if (file.downloadurl && file.downloadurl.length > 0) {
          file.image = file.downloadurl;
        } else {
          file.image = 'no_image';
        }
      }
    });
    this.exif = this.file.exif ? Object.entries(this.file.exif) : null;

    this.storageRef = this.storage.ref(this.file.storagepath);
    this.storageThumbnailRef = this.storage.ref(this.file.thumbnailpath);

    this.fileDoc = this.afs.doc(this.file.path);
    this.fileID = this.file.id;

    if (!this.file.sizeB || !this.file.sizeMB || !this.file.sizestring) {
      this.fileservice.filesize(this.file).subscribe((size) => {
        if (size) {
          this.file.sizestring = size;
        }
      });
    }

    if (this.file.lastupdated && !this.file.lastupdatedString) {
      this.file.lastupdatedString = this.dateservice.dateAsHHNNDDMMYYY(
        this.file.lastupdated
      );
    }
    if (this.file.timestamp) {
      this.file.timestampstring = this.dateservice.dateAsHHNNDDMMYYY(
        this.file.timestamp
      );
    }

    if (this.file.type) {
      if (this.file.type.indexOf('video') > -1) {
        if (this.mobileQuery.matches) {
          this.format = 'image';
        } else {
          this.format = 'video';
        }
      } else if (this.file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (this.file.type.indexOf('pdf') > -1) {
        // } && !this.mobileQuery.matches) {
        this.format = 'pdf';
        this.pdfsrc();
      } else {
        this.format = 'other';
      }
    } else {
      this.format = 'other';
    }
    if (this.format !== 'pdf') {
      this.pdfLoading = false;
      // this.src = this.file.downloadurl;
    }
    if (this.file.type.indexOf('image') > -1) {
      // if (this.file.downloadurl200 && this.file.downloadurl200.length > 0) {
      //   this.downloadurl = this.file.downloadurl200;
      // } else
      if (this.file.downloadurl500 && this.file.downloadurl500.length > 0) {
        this.downloadurl = this.file.downloadurl500;
      } else if (this.file.downloadurl && this.file.downloadurl.length > 0) {
        this.downloadurl = this.file.downloadurl;
      } else {
        this.downloadurl = 'no_image';
      }
    }
  }
  buildChecklist() {
    if (this.filesReady && this.checklistitemsReady) {
      if (this.checklist) {
        this.checklist.files?.forEach((cFile) => {
          if (cFile.type.indexOf('image') === -1) {
            this.attachments.push(cFile);
          }
          if (cFile.type.indexOf('image') > -1) {
            this.images.push(cFile);
          }
        });
        this.checklist.checklistitems?.forEach((item) => {
          item.files?.forEach((iFile) => {
            if (iFile.type.indexOf('image') === -1) {
              this.attachments.push(iFile);
            }
            if (iFile.type.indexOf('image') > -1) {
              this.images.push(iFile);
            }
          });
        });
        if (this.attachments && this.attachments.length > 0) {
          this.progressImageZipDefault = '.zip med vedlegg og bilder';
          this.progressImageZip = this.progressImageZipDefault;
          this.progressDirectDefault = 'kun PDF rapport';
        }
        if (this.images && this.images.length > 0) {
          this.progressDirectDefault = 'kun PDF rapport';
        }
        this.progressDirect = this.progressDirectDefault;
      }
      this.printlock = false;
      // console.log(this.attachments);
      // console.log(this.images);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  delete() {
    this.fileservice.deleteFile(this.file);
  }

  pdfLoaded() {
    this.pdfLoading = false;
    // console.log('pdfLoaded', event);
  }

  pdfProgressEvent(progress: ProgressBarEvent): void {
    // console.log(progress);
    if (progress.type === 'load') {
      this.pdfProgress = progress;
    }
  }

  pdfLoadingFailed(event) {
    this.pdfFallback = true;
    this.pdfLoading = false;
    console.log('pdfLoadingFailed', event);
  }

  onPagesLoaded() {
    new PdfDocumentPropertiesExtractor()
      .getDocumentProperties()
      .then((result) => {
        this.fileInfo = result;
        // console.log(this.fileInfo);
      });
  }

  // callBackFnAfterLoadComplete(pdf: PDFDocumentProxy) {
  //   // do anything with "pdf"
  //   // this.pdfLoading = false;
  //   // console.log(pdf);
  //   this.pdfPages = pdf.numPages;
  //   if (this.pdfPage >= this.pdfPages) {
  //     this.pdfLoading = false;
  //   }
  // }

  // pageRendered(e: CustomEvent) {
  //   // console.log('(page-rendered)', e);
  //   const event: any = e;
  //   this.pdfPage = event.pageNumber;
  //   if (this.pdfPage >= this.pdfPages) {
  //     this.pdfLoading = false;
  //   }
  // }

  // textLayerRendered(e: CustomEvent) {
  //   // console.log('(text-layer-rendered)', e);
  //   this.progressTextlayer = 'Text lastet inn';
  //   this.pdfLoading = false;
  // }

  // onProgress(progressData: PDFProgressData) {
  //   // do anything with progress data. For example progress indicator
  //   // console.log(progressData.loaded + ' / ' + progressData.total);
  //   this.progress =
  //     Math.round((100 / progressData.total) * progressData.loaded).toString() +
  //     ' %';
  //   // if(progressData.loaded >= progressData.total) {this.pdfLoading = false;}
  // }

  // onErrorPdfViewer(error: any) {
  //   // do anything
  //   this.snackbarservice.openSnackBar(
  //     'PDF preview error: ' + error.message,
  //     ''
  //   );
  // }

  urlToPromise(url: string) {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }
  // async pdfBase64src() {
  //   await this.urlToPromise(this.file.downloadurl).then((data: any) => {
  //     var base64 = btoa(
  //       new Uint8Array(data).reduce(
  //         (data, byte) => data + String.fromCharCode(byte),
  //         ''
  //       )
  //     );
  //     this.base64src = base64;
  //   });
  // }
  async pdfsrc() {
    this.src = this.file.downloadurl;
    // await this.urlToPromise(this.file.downloadurl).then((data: any) => {
    //   this.src = data;
    //   // console.log('pdfsrc is set');
    // });
  }

  // _appendBuffer = function(buffer1: any, buffer2: any) {
  //   var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  //   tmp.set(new Uint8Array(buffer1), 0);
  //   tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  //   return tmp.buffer;
  // };

  filedownloadDirect() {
    this.iconDirect = this.iconDownloading;
    this.isDownloadingDirect = true;
    this.progressDirect = 'Laster ned';
    FileSaver.saveAs(this.file.downloadurl, this.file.name);
    this.isDownloadingDirect = false;
    this.progressDirect = this.progressDirectDefault;
    this.iconDirect = this.iconDirectDefault;
  }

  async filedownloadConcat() {
    this.iconConcat = this.iconDownloading;
    this.isDownloadingConcat = true;
    this.progressConcat = 'Slår sammen filer...';
    if (this.attachments && this.attachments.length > 0) {
      const pdfDoc = await PDFDocument.create();
      let bytes = await fetch(this.file.downloadurl).then((res) =>
        res.arrayBuffer()
      );
      // Load a PDFDocument from each of the existing PDFs
      const donorPdfDoc = await PDFDocument.load(bytes);

      // Copy pages from the donor document
      const pages = donorPdfDoc.getPages();
      for (const element of pages) {
        const index = pages.indexOf(element);
        const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [index]);
        // Add the copied page
        pdfDoc.addPage(donorPage);
      }
      let attIndex = 0;
      for (const attachement of this.attachments) {
        bytes = await fetch(attachement.downloadurl).then((res) =>
          res.arrayBuffer()
        );
        // Load a PDFDocument from each of the existing PDFs
        const donorPdfDocLooped = await PDFDocument.load(bytes);

        // Copy pages from the donor document
        const pagesLooped = donorPdfDocLooped.getPages();
        attIndex++;
        let pageindex = 0;
        for (const {} of pagesLooped) {
          pageindex++;
          const [donorPage] = await pdfDoc.copyPages(donorPdfDocLooped, [pageindex]);
          // Add the copied page
          pdfDoc.addPage(donorPage);
          // console.log(attindex, this.attachments.length -1)
          // console.log(pageindex, pages.length -1)
          if (
            attIndex === this.attachments.length - 1 &&
            pageindex === pagesLooped.length - 1
          ) {
            this.progressConcat = 'Laster ned';
            await pdfDoc
              .save()
              .then((buffer) => {
                const blob = new Blob([buffer]);
                console.log('saving');
                FileSaver.saveAs(blob, this.file.name);
                this.isDownloadingConcat = false;
                this.progressConcat = this.progressConcatDefault;
                this.iconConcat = this.iconConcatDefault;
              })
              .catch((err) => {
                console.log(err);
                this.isDownloadingConcat = false;
                this.progressConcat = 'En feil har oppstått!';
              });
          }
        }
      }
    } else {
      FileSaver.saveAs(this.file.downloadurl, this.file.name);
    }
  }

  filedownloadImagesZip() {
    this.isDownloadingImages = true;
    this.downloadZip();
  }

  filedownloadZip() {
    this.isDownloadingImages = false;
    this.downloadZip();
  }

  downloadZip() {
    this.iconZip = this.iconDownloading;
    this.isDownloadingZip = true;
    const filename = this.file.name.substring(0, this.file.name.length - 4);

    if (
      (this.attachments && this.attachments.length > 0) ||
      (this.isDownloadingImages && this.images && this.images.length > 0)
    ) {
      const zip = new JSZip();
      console.log(this.file.name);
      this.urlToPromise(this.file.downloadurl).then((data: any) => {
        this.class.progressstring[0] = 'Legger til sjekkliste';
        if (this.isDownloadingImages) {
          this.progressImageZip = this.class.progressstring[0];
        } else {
          this.progressZip = this.class.progressstring[0];
        }
        zip.file(this.file.name, data, { binary: true });
        console.log(this.file.name + 'added');

        if (!this.attachments || this.attachments.length === 0) {
          console.log('No attachments');
          this.readyToGenerrateZipAttachments = true;
        } else {
          this.class.progressstring[0] =
            'Legger til vedlegg (' + this.attachments.length + ' stk)';
          if (this.isDownloadingImages) {
            this.progressImageZip = this.class.progressstring[0];
          } else {
            this.progressZip = this.class.progressstring[0];
          }
        }
        let attIndex = 0;
        this.attachments.forEach((attachement) => {
          console.log(attachement.name);
          this.urlToPromise(attachement.downloadurl).then((downloadData: any) => {
            zip.file(
              filename + '_vedlegg_' + (attIndex + 1) + '_' + attachement.name,
              downloadData,
              { binary: true }
            );
            console.log(attachement.name + 'added');
            attIndex++;
            this.class.progressstring[0] =
              'Legger til vedlegg (' +
              attIndex +
              '/' +
              this.attachments.length +
              ' stk)';
            if (this.isDownloadingImages) {
              this.progressImageZip = this.class.progressstring[0];
            } else {
              this.progressZip = this.class.progressstring[0];
            }
            if (attIndex === this.attachments.length) {
              this.readyToGenerrateZipAttachments = true;
              console.log('Ready to generate attachments');
              this.generateZipFile(zip, filename);
            }
          });
        });

        if (this.isDownloadingImages) {
          if (!this.images || this.images.length === 0) {
            console.log('No images');
            this.readyToGenerrateZipImages = true;
          } else {
            this.class.progressstring[0] =
              'Legger til bilder (' + this.images.length + ' stk)';
            if (this.isDownloadingImages) {
              this.progressImageZip = this.class.progressstring[0];
            } else {
              this.progressZip = this.class.progressstring[0];
            }
          }
          let imagesIndex = 0;
          this.images.forEach((image) => {
            console.log(image.name);
            this.urlToPromise(image.downloadurl).then((downloadData: any) => {
              zip.file(
                filename + '_bilde_' + (imagesIndex + 1) + '_' + image.name,
                downloadData,
                { binary: true }
              );
              console.log(image.name + 'added');
              imagesIndex++;
              this.class.progressstring[0] =
                'Legger til bilder (' +
                imagesIndex +
                '/' +
                this.images.length +
                ' stk)';
              if (this.isDownloadingImages) {
                this.progressImageZip = this.class.progressstring[0];
              } else {
                this.progressZip = this.class.progressstring[0];
              }
              if (imagesIndex === this.images.length) {
                this.readyToGenerrateZipImages = true;
                console.log('Ready to generate images');
                this.generateZipFile(zip, filename);
              }
            });
          });
        }
      });
    } else {
      this.class.progressstring[0] = 'Laster ned';
      if (this.isDownloadingImages) {
        this.progressImageZip = this.class.progressstring[0];
      } else {
        this.progressZip = this.class.progressstring[0];
      }
      FileSaver.saveAs(this.file.downloadurl, this.file.name);
      this.progressZip = this.progressZipDefault;
      this.progressImageZip = this.progressImageZipDefault;
      this.isDownloadingZip = false;
      this.iconZip = this.iconZipDefault;
    }
  }

  generateZipFile(zip: JSZip, filename: string) {
    // console.log(
    //   this.isDownloadingImages,
    //   this.readyToGenerrateZipImages,
    //   this.readyToGenerrateZipAttachments
    // );
    if (
      (!this.isDownloadingImages && this.readyToGenerrateZipAttachments) ||
      (this.isDownloadingImages &&
        this.readyToGenerrateZipImages &&
        this.readyToGenerrateZipAttachments)
    ) {
      // this.class.progressstring[0] = 'Lagrer...';
      // this.class.progressstring[1] =
      //   'Dette kan ta lang tid avhengig av størrelsen på filene';
      zip
        .generateAsync(
          { type: 'blob' }, // this.updateCallback)
          metadata => {
            // console.log(document
            //   .getElementById('progressbar'));
            // updatePercent(Math.round(metadata.percent) , $('#progressbar'));
            // let msg = 'Progression : ' + metadata.percent.toFixed(2) + ' %';
            // console.log(msg);
            PreviewComponent.progressstring[0] = `Genererer zip fil... ${metadata.percent.toFixed(
              0
            )}%`;
            // this.progressstring[0] = 'Lagrer... ' + metadata.percent.toFixed(1) + ' %';
            // if (this.isDownloadingImages) {
            //   this.progressImageZip = msg;
            // } else {
            //   this.progressZip = msg;
            // }
            // if (metadata.currentFile) {
            //   msg += ', current file = ' + metadata.currentFile;
            //   console.log(msg);
            //   // this.progressstring[1] = metadata.currentFile
            // } else {
            //   msg = 'Progression : ' + metadata.percent.toFixed(2) + ' %';
            //   console.log(msg);
            // }

            // showMessage(msg);
            // updatePercent(metadata.percent|0);
            // function resetMessage () {
            //     $("#result")
            //     .removeClass()
            //     .text("");
            // }
            // function showMessage(text) {
            //     resetMessage();
            //     $("#result")
            //     .addClass("alert alert-success")
            //     .text(text);
            // }
            // function showError(text) {
            //     resetMessage();
            //     $("#result")
            //     .addClass("alert alert-danger")
            //     .text(text);
            // // }
            // function updatePercent(percent, element) {
            //   // const value = 'Lagrer... ' + percent + ' %';
            //   // element
            //   //   // .removeClass('hide')
            //   //   // .find('.progress-bar')
            //   //   .attr('value', percent);
            //   // .css({
            //   //   width: percent + '%',
            //   // });
            // }

            // if(!JSZip.support.blob) {
            //     showError("This demo works only with a recent browser !");
            //     return;
            // }
          }
        )
        .then(
          function callback(blob) {
            // see FileSaver.js
            saveAs(blob, filename + '.zip');
            // showMessage("done !");
          },
          e => {
            console.error(e);
            zip.generateAsync({ type: 'base64' }).then(
              base64 => {
                const anchor = document.createElement('a');
                anchor.download = filename + '.zip';
                anchor.href = 'data:application/zip;base64,' + base64;
                anchor.target = '_blank';
                anchor.click();
              },
              (err) => {
                console.error(err);
                this.progressZip = this.progressZipDefault;
                this.isDownloadingZip = false;
                this.iconZip = this.iconZipDefault;
                this.readyToGenerrateZipAttachments = false;
                this.readyToGenerrateZipImages = false;
                this.class.progressstring = [];
                document
                  .getElementById('app_spinner')
                  .setAttribute('textstring', null);
              }
            );
            // showError(e);
          }
        )
        .then(() => {
          this.progressZip = this.progressZipDefault;
          this.progressImageZip = this.progressImageZipDefault;
          this.isDownloadingZip = false;
          this.iconZip = this.iconZipDefault;
          this.readyToGenerrateZipAttachments = false;
          this.readyToGenerrateZipImages = false;
          this.class.progressstring = [];
          document
            .getElementById('app_spinner')
            .setAttribute('textstring', null);
        });
    }
  }

  toggleEditComment() {
    this.editComment = !this.editComment;
    if (!this.file.comment) {
      this.file.comment = '';
    }
  }

  updateComment(comment) {
    // console.log(comment);
    this.fileservice.updateFileComment(this.file, comment);
    this.editComment = false;
    this.file.comment = comment;
  }

  // updateCallback(metaData) {
  //   const percent = metaData.percent;
  //   const currentFile = metaData.currentFile;
  //   let msg = 'Progression : ' + percent.toFixed(2) + ' %';
  //   console.log(msg);
  //   this.progressstring[0] = 'Lagrer... ' + percent.toFixed(1) + ' %';
  //   if (this.isDownloadingImages) {
  //     this.progressImageZip = msg;
  //   } else {
  //     this.progressZip = msg;
  //   }
  //   if (currentFile) {
  //     msg += ', current file = ' + currentFile;
  //     console.log(msg);
  //     this.progressstring[1] = currentFile;
  //   } else {
  //     msg = 'Progression : ' + percent.toFixed(2) + ' %';
  //     console.log(msg);
  //   }

  //   setTimeout(function () {
  //     //console.log(percent);
  //     this.progressstring[0] = 'Genererer... ' + percent.toFixed(1) + ' %';
  //   }, 10);
  // }

  clickNextFile() {
    if (this.files.length - 1 === this.fileindex) {
      this.fileindex = 0;
    } else {
      this.fileindex++;
    }
    this.downloadurl = 'no_image';
    this.buildFilePreview();
  }

  clickPreviousFile() {
    if (this.fileindex === 0) {
      this.fileindex = this.files.length - 1;
    } else {
      this.fileindex--;
    }
    this.downloadurl = 'no_image';
    this.buildFilePreview();
  }
}
