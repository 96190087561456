import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChecklistItem, ChecklistItemType } from 'src/app/models/Checklistitem';
import { AdminService } from 'src/app/services/admin.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { environment } from 'src/environments/environment';
import {ObjectStatus} from 'src/app/models/Status';
import _ from 'underscore';

@Component({
  selector: 'app-new-checklistitem',
  templateUrl: './new-checklistitem.component.html',
  styleUrls: ['./new-checklistitem.component.css'],
})
export class NewChecklistitemComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    // id: new FormControl('', [Validators.required]),
    text: new UntypedFormControl('', [Validators.required]),
    enText: new UntypedFormControl('', [Validators.required]),
    type: new UntypedFormControl('', [Validators.required]),
    status: new UntypedFormControl(this.data.statusId ?? '', [Validators.required]),
  });
  error: string;

  // get id() {
  //   return this.Form.get('id');
  // }
  get text() {
    return this.Form.get('text');
  }
  get enText() {
    return this.Form.get('text');
  }
  get type() {
    return this.Form.get('type');
  }
  get status() {
    return this.Form.get('status');
  }

  checklistitemData: ChecklistItem;
  checklistitemtypes: ChecklistItemType[];
  statuses: ObjectStatus[];

  mobileQuery: MediaQueryList;

  constructor(
    private dialogRef: MatDialogRef<NewChecklistitemComponent>,
    private adminservice: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    media: MediaMatcher,
    private snackbarservice: SnackbarService
  ) {
    adminservice
      .getObjectStatuses()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((statuses) => {
        if (statuses?.length > 0) {
          let tempStatuses = _.reject(statuses, _.isNull);
          tempStatuses = tempStatuses.filter(s => s.priority !== 1 && s.priority !== tempStatuses.length);
          tempStatuses = _.sortBy(tempStatuses, 'priority');
          this.statuses = tempStatuses;
        }
      });
    adminservice.getChecklistitemtypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((types) => {
      this.checklistitemtypes = types;
    });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }


  create(text: string, enText: string, typeid: string, statusId: string) {
    // tslint:disable-next-line:prefer-const
    let type: ChecklistItemType = new ChecklistItemType();
    const status = this.statuses.find(s => s.id === statusId);
    this.checklistitemtypes.forEach((typ: ChecklistItemType) => {
      // const catid = cat.id.toString();
      if (typ.id === typeid.toString()) {
        type = {
          id: typ.id,
          name: typ.name
        };
      }
    });
    this.checklistitemData = {
      text,
      enText,
      type,
      statusLevel: status,
    };
    this.adminservice.createChecklistitem(this.checklistitemData);
    this.Form.reset();
    this.Form.markAsPristine();
    this.Form.markAsUntouched();
    this.dialogRef.close();
    this.snackbarservice.openSnackBar(
      'Sjekklistepunkt opprettet: ' + this.checklistitemData.text,
      ''
    );
  }
}
