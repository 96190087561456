<div class="center-on-screen noselect">
  <mat-card class="card" style="min-width: 250px;">
    <mat-card-header>
      <div mat-card-avatar  class="header-logo"></div>
      <mat-card-title>Aventi</mat-card-title>
      <mat-card-subtitle>KS system</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form class="form" [formGroup]="reset" (keyup.enter)="resetPassword()">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="New password"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            required
            (keyup)="validateAfterKeyUp($event)"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          <mat-error>
            <app-password-error
              [passwordErrorMinLength]="passwordErrorMinLength"
              [passwordErrorUpperCaseLetter]="passwordErrorUpperCaseLetter"
              [passwordErrorLowerCaseLetter]="passwordErrorLowerCaseLetter"
              [passwordErrorNumber]="passwordErrorNumber"
              [passwordErrorSpecialCharacter]="passwordErrorSpecialCharacter"
            ></app-password-error>
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div class="row" style="margin-top: 20px">
        <div class="fill-space"></div>
        <div>
          <button
            mat-raised-button
            [disabled]="!reset.valid"
            (click)="resetPassword()"
          >
            <span *ngIf="!resetSent">Reset</span>
            <span *ngIf="resetSent"
            ><mat-spinner diameter="30"></mat-spinner
            ></span>
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<app-version></app-version>
