import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {MediaMatcher} from '@angular/cdk/layout';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {environment} from 'src/environments/environment';
import * as _ from 'underscore';
import {AdminService} from 'src/app/services/admin.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NewChecklistitemComponent} from '../new-checklistitem/new-checklistitem.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ObjectStatus} from 'src/app/models/Status';

@Component({
  selector: 'app-checklistitems-list',
  templateUrl: './checklistitems-list.component.html',
  styleUrls: ['./checklistitems-list.component.css'],
})
export class ChecklistitemsListComponent implements OnInit {
  checklistitems: ChecklistItem[];
  isLoading = true;
  mobileQuery: MediaQueryList;
  statuses: ObjectStatus[];
  statusNames: string[];
  private ngUnsubscribe = new Subject();

  constructor(
    adminservice: AdminService,
    media: MediaMatcher,
    private dialogNewChecklistitem: MatDialog,
    private location: Location
  ) {
    adminservice
      .getObjectStatuses()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((statuses) => {
        if (statuses?.length > 0) {
          let tempStatuses = _.reject(statuses, _.isNull);
          tempStatuses = tempStatuses.filter(s => s.priority !== 1 && s.priority !== tempStatuses.length);
          tempStatuses = _.sortBy(tempStatuses, 'priority');
          tempStatuses.push({name: 'Uten statusnivå'});
          this.statuses = tempStatuses;
          this.statusNames = this.statuses.map(status => status.name);
        }
      });
    adminservice
      .getChecklistitems()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklistitems) => {
        this.checklistitems = _.reject(checklistitems, _.isNull);
        this.checklistitems.forEach((item) => {
          if (item.type && item.type.name) {
            item.typename = item.type.name;
          }
          item.path = this.location.path(true) + '/sp/' + item.id;
        });
        const itemsByName = _.sortBy(this.checklistitems, 'text');
        this.checklistitems = _.sortBy(itemsByName, 'typename');
        this.isLoading = false;
      });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  newChecklistitem(statusId?: string) {
    this.dialogNewChecklistitem.open(NewChecklistitemComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: { statusId },
    });
  }
}
