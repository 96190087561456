<div class="row">
  <app-headertext text="Admin - Endringslogg"></app-headertext>
  <div class="fill-space"></div>

  <button
    mat-button
    matTooltip="Tving oppdatering"
    (click)="reloadVersion()"
    class="header-row-button"
  >
    <mat-icon color="primary">cached</mat-icon
    ><span style="margin-left: 10px">Tving oppdatering</span>
  </button>

  <button
    mat-button
    matTooltip="Ny versjon"
    (click)="newVersion()"
    class="header-row-button"
    *ngIf="user?.superAdmin"
  >
    <mat-icon color="primary">add</mat-icon>
    Ny versjon
  </button>
</div>

<div style="margin: 0 15px 0 15px">
  <app-changelog-list></app-changelog-list>
</div>
