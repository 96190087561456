<div class="row center-on-screen noselect">
  <div class="fill-space"></div>
  <div>
    <h1>
      <mat-icon style="vertical-align: middle; margin-right: 5px"
        >insert_page_break</mat-icon
      >
      Side ikke funnet
    </h1>

    <h4>Vi finner ikke siden du prøver å nå.</h4>

    <button
      mat-raised-button
      cdkFocusInitial
      (click)="backClicked()"
      style="margin-bottom: 20px"
    >
      Tilbake
    </button>
  </div>
  <div class="fill-space"></div>
</div>
