import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppVersion } from '../models/Admin';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ChangelogService {
  constructor(
    private afs: AngularFirestore,
    private snackbarservice: SnackbarService
  ) {}

  newVersion(newVersion: AppVersion) {
    const version = newVersion;
    version.id = this.afs.createId();
    this.updateVersion(version);
  }

  updateVersion(version: AppVersion) {
    let message = 'Endring lagret: ' + version.version;
    if (version.beta) { message += ' (BETA)'; }
    if (version.id) {
      this.afs
        .doc('app/' + version.id)
        .set(version)
        .then(() => {
          this.snackbarservice.openSnackBar(
            message,
            '',
            1000
          );
        })
        .catch((err) => {
          console.error(err);
          this.snackbarservice.openSnackBar(err.message, '');
        });
    }
  }

  async deleteVersion(version: AppVersion) {
    if (version.id?.length > 0) {
      this.afs
        .doc('app/' + version.id)
        .delete()
        .then(() => {
          this.snackbarservice.openSnackBar(
            'Endring slettet: ' + version.version,
            '',
            1000
          );
        })
        .catch((err) => {
          console.error(err);
          this.snackbarservice.openSnackBar(err.message, '');
        });
    }
  }
}
