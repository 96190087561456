<app-headertext text="Kart"></app-headertext>

<div style="margin: 0 15px 0 15px">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>

<div *ngIf="project && objects && !isLoading">
  <div class="row">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="child-margin" style="width: 100%">
      <mat-card style="height: 65vh">
        <mat-card-content>
          <app-partial-map></app-partial-map>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>
