import {Component} from '@angular/core';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  selector: 'app-device-location-detection',
  templateUrl: './device-location-detection.component.html',
  styleUrls: ['./device-location-detection.component.css']
})
export class DeviceLocationDetectionComponent {

  locationEnabled: boolean;

  constructor(private sharedservice: SharedService) {
  }

  ngOnInit(): void {
    this.sharedservice.locationEnabled.subscribe(enabled => this.locationEnabled = enabled);
  }

}
