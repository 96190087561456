<app-headertext
  [hideback]="mobileQuery.matches"
  text="Prosjekt"
></app-headertext>

<div style="margin: 0 15px 0 15px">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>

<div *ngIf="project && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth">
      <h2>
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >info
        </mat-icon
        >
        Prosjektinfo
      </h2>
      <mat-card class="child-margin">
        <div class="row" *ngIf="project.no">
          Nummer:
          <div class="fill-space"></div>
          <div style="text-align: right">{{ project.no }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row" *ngIf="project.name">
          Navn:
          <div class="fill-space"></div>
          <div style="text-align: right">{{ project.name }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row" *ngIf="project.nameshort">
          Kort navn:
          <div class="fill-space"></div>
          <div style="text-align: right">{{ project.nameshort }}</div>
        </div>
        <mat-divider [inset]="true"
                     *ngIf="user?.role.name !== 'customer'"></mat-divider>
        <div class="row" *ngIf="project.name && user?.role.name !== 'customer'">
          Last ned objektdata:
          <div class="fill-space"></div>
          <div>
            <button [disabled]="!objects || !checklists" mat-raised-button (click)="export2json()">
              <i *ngIf="objects && checklists" class="pi pi-file" style="margin-right: 10px; margin-top: -5px;"></i>
              <i *ngIf="!objects || !checklists" class="pi pi-spin pi-spinner"
                 style="margin-right: 10px; margin-top: -5px;"></i>
              JSON
            </button>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row" *ngIf="project.name">
          Last ned kunde eksport:
          <div class="fill-space"></div>
          <div>
            <button [disabled]="!objects || !checklists || isLoadingCustomerExport" mat-raised-button
                    (click)="customerExport2Excel()">
              <i *ngIf="objects && checklists && !isLoadingCustomerExport" class="pi pi-file-excel"
                 style="margin-right: 10px; margin-top: -5px;"></i>
              <i *ngIf="!objects || !checklists || isLoadingCustomerExport" class="pi pi-spin pi-spinner"
                 style="margin-right: 10px; margin-top: -5px;"></i>
              Excel
            </button>
          </div>
        </div>
      </mat-card>
      <h2
        *ngIf="user?.role.name !== 'customer'">
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >settings-cog
        </mat-icon
        >
        Innstillinger
      </h2>
      <mat-card class="child-margin"
                *ngIf="user?.role.name !== 'customer'">
        <div class="row" *ngIf="project">
          Statusnivå for objekter:
          <div class="fill-space"></div>
          <div style="text-align: right" *ngIf="project">
            <p-inputSwitch [disabled]="!user.superAdmin" (onChange)="toggleStatusLevelSetting($event)"
                           [(ngModel)]="project.useStatusLevel"></p-inputSwitch>
          </div>
        </div>
        <div *ngIf="project && project.statusLevelUpdateInProgress === STATUS_LEVEL_UPDATE_STATE.STARTED"
             style="height: 20px;">
          <div class="pull-right">
            <i class="pi pi-spin pi-spinner"></i>
            {{ user.language?.id === 'en' ? 'Updating statuses for all objects... ' : 'Oppdaterer status for alle objekter... ' }}
            {{ project.statusLevelUpdateInProgressCount }} / {{ project.statusLevelUpdateInProgressTotal }}
          </div>
        </div>
      </mat-card>
      <h2
        *ngIf="user?.role.name !== 'customer'">
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >people_alt
        </mat-icon
        >
        Brukere
      </h2>
      <mat-card class="child-margin"
                *ngIf="user?.role.name !== 'customer'">
        <!-- <mat-divider [inset]="true"></mat-divider> -->
        <div class="row">
          Administratorer:
          <div class="fill-space"></div>
          <div *ngIf="adminUsers" style="text-align: right">
            <div
              *ngFor="let user of adminUsers"
              [style.opacity]="user.active ? '1' : '0.2'"
              [matTooltip]="user.active ? null : 'Bruker deaktivert'"
              matTooltipPosition="left"
            >
              {{ user.firstname }} {{ user.lastname }}
            </div>
          </div>
          <div *ngIf="!adminUsers">
            <span>
              <!-- Henter administratorer... -->
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Brukere:
          <div class="fill-space"></div>
          <div *ngIf="userUsers" style="text-align: right">
            <div
              *ngFor="let user of userUsers"
              [style.opacity]="user.active ? '1' : '0.2'"
              [matTooltip]="user.active ? null : 'Bruker deaktivert'"
              matTooltipPosition="left"
            >
              {{ user.firstname }} {{ user.lastname }}
            </div>
          </div>
          <div *ngIf="!userUsers">
            <span>
              <!-- Henter brukere... -->
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Kunder:
          <div class="fill-space"></div>
          <div *ngIf="customerUsers" style="text-align: right">
            <div
              *ngFor="let user of customerUsers"
              [style.opacity]="user.active ? '1' : '0.2'"
              [matTooltip]="user.active ? null : 'Bruker deaktivert'"
              matTooltipPosition="left"
            >
              {{ user.firstname }} {{ user.lastname }}
            </div>
          </div>
          <div *ngIf="!customerUsers">
            <span>
              <!-- Henter kunder... -->
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </div>
        </div>
        <!-- <mat-divider [inset]="true"></mat-divider>
        <div class="row" *ngIf="project.nameshort">
          Posisjon:
          <div class="fill-space"></div>
          <div>{{ project.map?.lat }}, {{ project.map?.lng }}</div>
        </div> -->
        <!-- <mat-divider [inset]="true"></mat-divider> -->
        <!-- <div class="row" *ngIf="project">
        Objekttyper:
        <div class="fill-space"></div>
        <app-select
            label="Versjon"
            (value)="setVersion($event)"
            [selected]="project.objecttypeversion"
            [items]="objecttypeversions"
          ></app-select>
      </div> -->
      </mat-card>
      <h2>
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >travel_explore
        </mat-icon
        >
        Oversikt
      </h2>
      <mat-card class="child-margin">
        <!-- <mat-divider [inset]="true"></mat-divider> -->
        <div class="row" *ngIf="project.nameshort">
          Objekter:
          <div class="fill-space"></div>
          <div style="text-align: right">
          <span *ngIf="project && project.statusLevelUpdateInProgress === STATUS_LEVEL_UPDATE_STATE.STARTED">
            <i class="pi pi-spin pi-spinner"></i>
            {{ user.language?.id === 'en' ? 'Updating statuses for all objects... ' : 'Oppdaterer status for alle objekter... ' }}
            {{ project.statusLevelUpdateInProgressCount }} /
          </span>
            {{ objects?.length }}
          </div>
          <div *ngIf="!objects">
            <span>
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </div>
        </div>
        <div *ngIf="objectsChartData && chartOptions && project.useStatusLevel">
          <p-chart type="bar" [data]="objectsChartData" [options]="chartOptions"></p-chart>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row" *ngIf="project.nameshort">
          Sjekklister:
          <div class="fill-space"></div>
          <div style="text-align: right">{{ checklists?.length }}</div>
          <div *ngIf="!checklists">
            <span>
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </div>
        </div>
        <div *ngIf="checklistsChartData && chartOptions">
          <p-chart type="bar" [data]="checklistsChartData" [options]="chartOptions"></p-chart>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row" *ngIf="project.nameshort">
          Mottak:
          <div class="fill-space"></div>
          <div style="text-align: right">{{ receiptforms?.length }}</div>
          <div *ngIf="!receiptforms">
            <span>
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </div>
        </div>
        <p></p>

        <mat-card-content>
        </mat-card-content>
      </mat-card>
      <app-documents
        *ngIf="!isLoading && user?.role.name !== 'customer'"
        [files]="project.files"
        [dbPath]="filesDbPath"
      ></app-documents>
      <p></p>
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>

<!--
<div *ngIf="project && !isLoading">
  <div class="row">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth child-margin">
      <mat-card>
        <mat-card-content>
          <app-partial-map style="height: 300px"></app-partial-map>
        </mat-card-content>
      </mat-card>
      <p></p>
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div> -->

<!-- <div *ngIf="types && !isLoading" style="margin: 0 15px 0 15px">
  <app-headertext [hideback]="true" text="Objekter"></app-headertext>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let type of types">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ object.type?.name }}
          </mat-panel-title>
          <mat-panel-description>
            {{ object.name }}
          </mat-panel-description>
        </mat-expansion-panel-header>
          <mat-expansion-panel *ngFor="let object of type.objects">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ object.type?.name }}
              </mat-panel-title>
              <mat-panel-description>
                {{ object.name }}
              </mat-panel-description>
            </mat-expansion-panel-header>
                  <p>Beskrivelse: {{ object.desc }}</p>
            <p>Sjekklister:</p>
            <p *ngFor="let checklist of object.checklists">{{ checklist.name }}</p>
          </mat-expansion-panel>
      </mat-expansion-panel>
    </mat-accordion>
  </div> -->
<!--
<app-headertext [hideback]="true" text="Objekter"></app-headertext>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <button mat-icon-button disabled></button>
      Sjekkliste: {{node.name}} - {{node.statusHuman}}
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </div>
      <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree> -->

<div *ngIf="objects && !isLoading && user?.role.name !== 'customer'">
  <!-- <app-headertext [hideback]="true" text="Objekter"></app-headertext> -->
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth">
      <h2>
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >traffic
        </mat-icon
        >
        Objekter i prosjektet
      </h2>
      <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        class="tree"
      >
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <div style="width: 100%; padding: 15px">
            <div class="row" style="padding: 5px">
              <!-- Objekt:
              <button
                style="margin: 5px; margin-left: 15px; margin-right: 15px"
                mat-raised-button
              > -->
              <div style="padding-top: 12px">
                <mat-icon
                  style="vertical-align: middle; margin-right: 5px; color: #ddd"
                >traffic
                </mat-icon
                >
                {{ node.name }} - {{ node.desc }}
                <!-- </button> -->
              </div>
              <div class="fill-space"></div>
              <div>
                <button
                  [routerLink]="node.url"
                  matTooltip="Åpne objekt"
                  matTooltipPosition="left"
                  style="margin: 5px 0 5px 10px;"
                  mat-raised-button
                >
                  <mat-icon style="margin-right: 5px">launch</mat-icon>
                  Åpne
                </button>
              </div>
            </div>
            <mat-divider *ngIf="node.checklists?.length > 0"></mat-divider>
            <div
              class="row"
              style="padding: 5px"
              *ngIf="node.checklists?.length > 0"
            >
              <div style="padding-top: 12px">
                <mat-icon
                  style="vertical-align: middle; margin-right: 5px; color: #ddd"
                >list_alt
                </mat-icon
                >
                Sjekkliste:
              </div>
              <div class="fill-space"></div>
              <div>
                <button
                  *ngFor="let checklist of node.checklists"
                  [routerLink]="checklist.url"
                  matTooltip="Åpne sjekkliste"
                  matTooltipPosition="left"
                  style="margin: 5px 0 5px 10px;"
                  mat-raised-button
                >
                  <mat-icon [style.color]="checklist.color">{{
                      checklist.icon
                    }}
                  </mat-icon>
                  {{ checklist.statusHuman }}
                </button>
                <!-- <mat-icon
                  style="
                    font-size: 18px;
                    vertical-align: middle;
                    padding-left: 5px;
                    margin-top: 4px;
                  "
                  class="mat-icon-rtl-mirror"
                  >launch</mat-icon> -->
              </div>
            </div>
            <mat-divider *ngIf="node.receiptforms?.length > 0"></mat-divider>
            <div
              class="row"
              style="padding: 5px"
              *ngIf="node.receiptforms?.length > 0"
            >
              <div style="padding-top: 12px">
                <mat-icon
                  style="vertical-align: middle; margin-right: 5px; color: #ddd"
                >cases
                </mat-icon
                >
                Mottakskontroll:
              </div>
              <div class="fill-space"></div>
              <div>
                <button
                  *ngFor="let receiptform of node.receiptforms"
                  [routerLink]="receiptform.url"
                  matTooltip="Åpne sjekkliste"
                  matTooltipPosition="left"
                  style="margin: 5px 0 5px 10px;"
                  mat-raised-button
                >
                  <mat-icon [style.color]="receiptform.color">{{
                      receiptform.icon
                    }}
                  </mat-icon>
                  {{ receiptform.statusHuman }}
                </button>
              </div>
            </div>
            <mat-divider *ngIf="node.pdfexports?.length > 0"></mat-divider>
            <div
              class="row"
              style="padding: 5px"
              *ngIf="node.pdfexports?.length > 0"
            >
              <div style="padding-top: 12px">
                <mat-icon
                  style="vertical-align: middle; margin-right: 5px; color: #ddd"
                >picture_as_pdf
                </mat-icon
                >
                Pdf: {{ node.pdfexports?.length }} stk
              </div>
            </div>
          </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <!-- <div style="height: 5px; background-color: #202020;"></div> -->
          <div class="mat-tree-node">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
            </button>
            {{ node.name }} ({{ node.children.length }})
          </div>
          <!-- <mat-divider></mat-divider> -->
          <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
          <div
            [class.tree-invisible]="!treeControl.isExpanded(node)"
            role="group"
          >
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
          <div style="height: 10px; background-color: #202020"></div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>

    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>
