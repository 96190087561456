import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  passwordMinLength = 10;

  inProgress = false;

  Form = new UntypedFormGroup({
    oldPass: new UntypedFormControl('', [
      Validators.minLength(this.passwordMinLength),
      Validators.required,
    ]),
    newPass: new UntypedFormControl('', [
      Validators.minLength(this.passwordMinLength),
      Validators.required,
    ]),
  });
  error: string;

  get oldPass() {
    return this.Form.get('oldPass');
  }
  get newPass() {
    return this.Form.get('newPass');
  }

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authservice: AuthService
  ) {}

  ngOnInit(): void {}

  changePassword(oldP: string, newP: string) {
    this.inProgress = true;
    this.authservice
      .updatePassword(oldP, newP)
      .then((code) => {
        if (code === 'wrong password') {
          document.getElementById('oldPass').focus();
        } else {
          this.Form.reset();
          this.Form.markAsPristine();
          this.Form.markAsUntouched();
          this.dialogRef.close();
        }
        this.inProgress = false;
      })
      .catch((err) => {
        console.log(err);
        this.inProgress = false;
      });
  }
}
