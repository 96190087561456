import {Injectable} from '@angular/core';
import {UserTimestamp} from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {
  }

  getLatestEditedByFromArray(editedByArray: UserTimestamp[]) {
    if (!editedByArray) {
      return [];
    }
    if (editedByArray.length > 1) {
      return [editedByArray.reduce((a, b) => a.timestamp > b.timestamp ? a : b)];
    }
    return editedByArray;
  }

  dateAsYYYYMMDDHHNNSS(timestamp): string {
    const date = new Date(this.normalizeTimestamp(timestamp).seconds * 1000);
    return (
      date.getFullYear() +
      '-' +
      this.leftpad(date.getMonth() + 1, 2) +
      '-' +
      this.leftpad(date.getDate(), 2) +
      ' ' +
      this.leftpad(date.getHours(), 2) +
      ':' +
      this.leftpad(date.getMinutes(), 2) +
      ':' +
      this.leftpad(date.getSeconds(), 2)
    );
  }

  dateAsYYYYMMDD(timestamp): string {
    const date = new Date(this.normalizeTimestamp(timestamp).seconds * 1000);
    return (
      date.getFullYear() +
      '-' +
      this.leftpad(date.getMonth() + 1, 2) +
      '-' +
      this.leftpad(date.getDate(), 2)
    );

  }

  dateAsDDMMYYY(timestamp): string {
    const date = new Date(this.normalizeTimestamp(timestamp).seconds * 1000);
    return (
      this.leftpad(date.getDate(), 2) +
      '.' +
      this.leftpad(date.getMonth() + 1, 2) +
      '.' +
      date.getFullYear()
    );
  }

  dateAsHHNNDDMMYYY(timestamp): string {
    const date = new Date(this.normalizeTimestamp(timestamp).seconds * 1000);
    return (
      this.leftpad(date.getHours(), 2) +
      ':' +
      this.leftpad(date.getMinutes(), 2) +
      ' ' +
      this.leftpad(date.getDate(), 2) +
      '.' +
      this.leftpad(date.getMonth() + 1, 2) +
      '.' +
      date.getFullYear()
    );
  }

  dateAsHHNNSSDDMMYYY(timestamp): string {
    const date = new Date(this.normalizeTimestamp(timestamp).seconds * 1000);
    return (
      this.leftpad(date.getHours(), 2) +
      ':' +
      this.leftpad(date.getMinutes(), 2) +
      ':' +
      this.leftpad(date.getSeconds(), 2) +
      ' ' +
      this.leftpad(date.getDate(), 2) +
      '.' +
      this.leftpad(date.getMonth() + 1, 2) +
      '.' +
      date.getFullYear()
    );
  }
  dateAsHHNNSS(timestamp): string {
    const date = new Date(this.normalizeTimestamp(timestamp).seconds * 1000);
    return (
      this.leftpad(date.getHours(), 2) +
      ':' +
      this.leftpad(date.getMinutes(), 2) +
      ':' +
      this.leftpad(date.getSeconds(), 2)
    );
  }


  private leftpad(val, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength) + String(val)).slice(
      String(val).length
    );
  }

  private normalizeTimestamp(timestamp) {
    const t: any = timestamp;
    if (t._seconds) {
      t.seconds = t._seconds;
      t._seconds = null;
    }
    if (t._nanoseconds) {
      t.nanoseconds = t._nanoseconds;
      t._nanoseconds = null;
    }
    return t;
  }
}
