<!-- <app-spinner
disable="true"
[text]="['Vennligst vent', 'Genererer PDF...']"
header="Signerer..."
*ngIf="isSigning && checklist.generatingPDFchecklist"
></app-spinner> -->
<app-spinner
  disable="true"
  [text]="['Vennligst vent', 'Sletter PDF']"
  header="Fjerner signatur..."
  *ngIf="
    isRemovingSignature &&
    checklist.pdfexports &&
    checklist.pdfexports.length >= 1
  "
></app-spinner>
<app-spinner
  disable="true"
  [text]="loadingText"
  [header]="loadingHeaderText"
  *ngIf="isLoading && !checklistNotFound"
></app-spinner>

<app-page-not-found *ngIf="checklistNotFound"></app-page-not-found>

<div class="row" *ngIf="checklist">
  <!-- <app-back-button class="header-row-button"></app-back-button> -->
  <app-headertext
    [text]="checklist.receiptform ? 'Mottaksskjema' : 'Sjekkliste'"
  ></app-headertext>
  <div class="fill-space"></div>

  <button
    *ngIf="isAdmin"
    mat-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
    routerLink="/p/{{ checklist.projectid }}/o/{{ checklist.objectid }}"
  >
    <mat-icon>traffic</mat-icon>
    <span>Gå til objekt</span>
  </button>
  <button
    *ngIf="isAdmin"
    mat-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span>Admin</span>
  </button>
  <mat-menu #menu="matMenu">
    <div class="row">
      <button
        mat-button
        (click)="editChecklistStatus()"
        class="menu-row-button"
        *ngIf="isAdmin && checklist && checklist.status === 'signed'"
      >
        <mat-icon color="accent">directions_off</mat-icon>
        Fjerne signering
      </button>
    </div>
    <div class="row">
      <button
        mat-button
        (click)="copyFileLocation()"
        class="menu-row-button"
        *ngIf="isAdmin && checklist"
      >
        <mat-icon color="accent">content_copy</mat-icon>
        Kopiér filplassering
      </button>
    </div>
    <div
      class="row"
      [matTooltip]="
        checklist.isSigned ? 'Kan ikke slette signert sjekkliste' : null
      "
      matTooltipPosition="left"
    >
      <button
        mat-button
        (click)="deleteChecklistConfirm()"
        class="menu-row-button"
        *ngIf="isAdmin"
        [disabled]="checklist.isSigned"
      >
        <mat-icon color="warn">delete</mat-icon>
        Slett {{ checklist.receiptform ? "mottaksskjema" : "sjekkliste" }}
      </button>
    </div>
  </mat-menu>

  <!-- <app-close-button class="header-row-button"></app-close-button> -->
</div>

<div class="row" *ngIf="!isLoading">
  <div class="fill-space"></div>
  <!-- <div class="maxwidth"> -->
  <div class="child-margin maxwidth">
    <!-- <div> -->
    <mat-card class="noselect" style="padding: 0; box-shadow: none">
      <mat-card-header style="background: #202020; padding: 15px">
        <div mat-card-avatar>
          <mat-icon style="font-size: 30px; padding: 4px">{{
              checklist.receiptform ? "cases" : "description"
            }}
          </mat-icon>
        </div>
        <mat-card-title>
          <div *ngIf="checklist">{{ checklist.name }}</div>
        </mat-card-title
        >

        <mat-card-subtitle>
          <p *ngIf="isAdmin && diffChecklistObject">
            <button
              [disabled]="checklist.isSigned"
              style="margin-left: -16px"
              mat-button
              (click)="updateChecklistFromObject()"
              matTooltip="Oppdatere?"
            >
              <mat-icon color="warn">info</mat-icon>
              <span *ngIf="!user.language || user.language.id === 'no'">Sjekklistedata er ulik objektdata</span>
              <span *ngIf="user.language?.id === 'en'">Checklistdata differs from objectdata</span>
            </button>
          </p>
          <p *ngIf="checklist?.process">
            <b>
              <span *ngIf="!user.language || user.language.id === 'no'">Prosess:</span>
              <span *ngIf="user.language?.id === 'en'">Process:</span>
            </b> {{ checklist.process }}
          </p>
          <p *ngIf="checklist">
            <b>Type: </b>
            {{ checklist.receiptform ? "Mottak" : checklist.type.name }}
          </p>
          <p *ngIf="checklist"><b>
            <span *ngIf="!user.language || user.language.id === 'no'">Beskrivelse:</span>
            <span *ngIf="user.language?.id === 'en'">Description:</span>
          </b> {{ checklist.desc }}</p>
          <p *ngIf="checklist?.profilenumber">
            <b>
              <span *ngIf="!user.language || user.language.id === 'no'">Profilnummer:</span>
              <span *ngIf="user.language?.id === 'en'">Profilenumber:</span></b> {{ checklist.profilenumber }}
          </p>
          <p *ngIf="checklist">
            <b>Status:</b>
            {{
              checklist.statusHuman ? checklist.statusHuman : checklist.status
            }}
          </p>
          <div *ngIf="checklist">
            <b>Tags:</b>
            <table style="font-size: small; text-align: left">
              <tr *ngFor="let t of checklist.tags">
                <td>{{ t.key }}</td>
                <td style="text-align: right">{{ t.value }}</td>
              </tr>
            </table>
          </div>
          <div *ngIf="checklist?.receiptform">
            <p><b>
              <span *ngIf="!user.language || user.language.id === 'no'">Objekter:</span>
              <span *ngIf="user.language?.id === 'en'">Objects:</span>
            </b></p>
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ checklist.objects.length }} objekter
                  </mat-panel-title>
                  <mat-panel-description>

                    <span *ngIf="!user.language || user.language.id === 'no'">Klikk for å se</span>
                    <span *ngIf="user.language?.id === 'en'">Click to see</span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- <p
                  *ngFor="let o of checklist.objects"
                  style="font-size: x-small; padding-left: 20px"
                >
                  {{ o.type?.name }} - {{ o.name }}
                </p> -->
                <table style="font-size: x-small; text-align: left">
                  <tr>
                    <th>Type</th>
                    <th>
                      <span *ngIf="!user.language || user.language.id === 'no'">Navn</span>
                      <span *ngIf="user.language?.id === 'en'">Name</span></th>
                  </tr>
                  <tr *ngFor="let o of checklist.objects">
                    <td>{{ o.type?.name }}</td>
                    <td>{{ o.name }}</td>
                  </tr>
                </table>
              </mat-expansion-panel>
            </mat-accordion>
            <!-- <table style="padding-top: 10px;">
                <tr *ngFor="let o of checklist.objects" style="font-size: x-small">
                  <td style=width:auto;>{{ o.type?.name }}</td>
                  <td>{{ o.name }}</td>
                </tr>
              </table> -->
          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <!-- <mat-divider></mat-divider> -->
    </mat-card>

    <div *ngFor="let status of statuses; let first = first; let last = last">
      <mat-divider
        *ngIf="!first"
        style="margin-top: 10px;margin-bottom: 10px;"
        [inset]="false"
      ></mat-divider>

      <div class="row" *ngIf="status.id !== 'receiptForm'">
        <app-headertext [text]="status.name" hideback="true"></app-headertext>
        <div class="fill-space"></div>
<!--        <i *ngIf="statusCount && statusCount[status.name]?.count > 0 && statusCount[status.name]?.checked >= statusCount[status.name]?.count" class="pi pi-check-circle" style="color: green; margin-top: 20px; font-size: 20px;"></i>-->
        <i *ngIf="object.status?.priority >= status?.priority" class="pi pi-check-circle" style="color: green; margin-top: 20px; font-size: 20px;"></i>
      </div>

      <div *ngFor="let checklistItem of checklistitems">
        <mat-card
          class="noselect"
          style="padding-top: 5px; margin-bottom: 5px"
          *ngIf="isReceiptform || (checklistItem.statusLevel?.name === status.name || (status.name === 'Uten statusnivå' && !statusNames.includes(checklistItem.statusLevel?.name)))"
        >
          <mat-card-content>
            <!-- <mat-card-content
          style="background-color: #343434; color: #cacaca;"
        > -->
            <app-checklist-item
              style="width: 100%"
              [checklistItemId]="checklistItem.id"
              [checklistItem]="checklistItem"
              [locked]="checklist.isSigned"
              [isReceiptform]="isReceiptform"
              [deviationLock]="checklist.pdfexports?.length > 1"
              [objectTypeId]="checklist.type?.id"
            ></app-checklist-item>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- <app-checklist-item
      style="width: 100%"
      *ngFor="let checklistItem of checklistitems"
      [checklistItem]="checklistItem"
      [locked]="checklist.isSigned"
      [datetime]="checklistItem.lastupdatedString"
      [files]="checklistItem.files"
    ></app-checklist-item> -->
    <p></p>
    <div *ngIf="checklist" style="padding: 10px">
      <div>
        <mat-icon
          style="
            color: #aaa;
            vertical-align: middle;
            margin-bottom: 5px;
            margin-right: 5px;
          "
        >image
        </mat-icon
        >
        <strong *ngIf="!user.language || user.language.id === 'no'">Bilde av ferdig objekt med merking</strong>
        <strong *ngIf="user.language?.id === 'en'">Picture of finished object with marking:</strong>
      </div>
      <div class="flex-container">
        <app-thumbnail
          class="flex-item"
          [file]="file"
          [files]="checklistfiles"
          [isLocked]="checklist.isSigned"
          *ngFor="let file of checklistfiles"
        ></app-thumbnail>
        <app-upload-button
          class="flex-item"
          *ngIf="checklist && !checklist.isSigned"
          [dbPath]="checklist.path"
          [button]="false"
        ></app-upload-button>
      </div>
      <div *ngIf="checklist.isSigned && checklist.comment">
        <div style="color: #aaa">
          <span *ngIf="!user.language || user.language.id === 'no'">Sjekklistekommentar:</span>
          <span *ngIf="user.language?.id === 'en'">Checklist comment:</span>
        </div>
        <div style="white-space: pre-wrap">
          {{ checklist.comment }}
        </div>
      </div>
      <form [formGroup]="FormComment" *ngIf="!checklist.isSigned">
        <mat-form-field>
          <textarea
            cdkTextareaAutosize
            style="padding: 0; min-height: 14px !important; padding-bottom: 3px"
            [value]="checklist.comment"
            id="comment"
            #comment
            matInput
            [placeholder]="user.language?.id === 'en' ? 'Checklist comment' : 'Sjekklistekommentar'"
            (keyup.enter)="updateComment(comment.value)"
            (blur)="updateComment(comment.value)"
          ></textarea>
        </mat-form-field>
      </form>
      <div class="row">
        <div class="fill-space"></div>
        <!-- <div style="padding-right: 10px">
              <app-upload-button
                *ngIf="checklist && !checklist.isSigned"
                [dbPath]="checklist.path"
                text="Bilde av objekt"
              ></app-upload-button>
            </div> -->
        <div>
          <button
            *ngIf="checklist && !checklist.isSigned"
            [disabled]="
              sanitycheckNoPicture ||
              sanitycheckNoData ||
              sanitycheckNoValue ||
              sanitycheckNoComment ||
              sanitycheckNoValueComment
            "
            mat-raised-button
            color="primary"
            class="align-right"
            (click)="signChecklistConfirm()"
          >
            Signer
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="fill-space"></div>
      <div style="padding: 10px; text-align: end">
        <div *ngIf="sanitycheckNoData" class="errortext">
          {{ checklist.numberOfNoData }}
          <span *ngIf="!user.language || user.language.id === 'no'">avkrysninger mangler</span>
          <span *ngIf="user.language?.id === 'en'">checks missing</span>
        </div>
        <div *ngIf="sanitycheckNoComment" class="errortext">
          {{ checklist.numberOfNoComment + checklist.numberOfNoValueComment }}
          <span *ngIf="!user.language || user.language.id === 'no'">kommentarer mangler</span>
          <span *ngIf="user.language?.id === 'en'">comments missing</span>
        </div>
        <!-- <div *ngIf="sanitycheckNoValueComment" class="errortext">
          {{checklist.numberOfNoData}}  Mangler sted på en eller flere sjekkpunkter
        </div> -->
        <div *ngIf="sanitycheckNoValue" class="errortext">
          {{ checklist.numberOfNoData }}
          <span *ngIf="!user.language || user.language.id === 'no'">verdier mangler</span>
          <span *ngIf="user.language?.id === 'en'">values missing</span>
        </div>
        <div *ngIf="sanitycheckDeviations" class="errortext">
          <span *ngIf="!user.language || user.language.id === 'no'">Sjekkliste inneholder avvik</span>
          <span *ngIf="user.language?.id === 'en'">Checklist contains deviations</span>
        </div>
        <div *ngIf="sanitycheckNoPicture" class="errortext">
          <span *ngIf="!user.language || user.language.id === 'no'">Mangler bilde av objektet</span>
          <span *ngIf="user.language?.id === 'en'">Photo of the object is missing</span>
        </div>
      </div>
    </div>
    <!-- </mat-card-content> -->
    <!-- <mat-card-actions style="padding: 20px" align="end">
      </mat-card-actions> -->
    <div *ngIf="checklist && checklist.isSigned && checklist.signedBy">
      <app-signed
        [user]="checklist.signedBy.user"
        [timestamp]="checklist.signedBy.timestamp"
      ></app-signed>
    </div>
    <div class="row">
      <div class="fill-space"></div>
      <div *ngIf="!checklist.pdfexports && checklist.isSigned">
        <button disabled style="margin: 10px" mat-button>
          <mat-spinner diameter="20"></mat-spinner>
          <span *ngIf="!mobileQuery.matches" class="button-icon-text"
          >Laster inn pdf</span
          >
        </button>
      </div>
      <div
        *ngIf="checklist.pdfexports || !checklist.generatingPDFchecklist"
        class="row"
      >
        <div *ngFor="let pdf of checklist.pdfexports; let isFirst = first">
          <button
            [disabled]="!isOnline"
            (click)="
              openDialogPdfPreview(
                pdf,
                checklist,
                isFirst && checklist.pdfexports?.length > 1
              )
            "
            style="margin: 10px 10px 0 10px"
            mat-raised-button
            [ngClass]="
              mobileQuery.matches ? 'mat-raised-button' : 'mat-raised-button'
            "
            [matTooltip]="isOnline ? 'Åpne PDF' : 'Ikke tilgjengelig når du er offline'"
            matTooltipPosition="left"
          >
            <mat-icon [color]="pdf.iconcolor ? pdf.iconcolor : 'primary'"
            >picture_as_pdf
            </mat-icon
            >
            <span *ngIf="!mobileQuery.matches || true" class="button-icon-text"
            >PDF
              <span *ngIf="pdf.sizestring" style="font-size: smaller"
              >({{ pdf.sizestring }})</span
              ></span
            >
          </button>
          <div style="font-size: x-small; text-align: center">
            <div>
              {{ pdf.timestampstring }}
            </div>
            <div>
              {{ pdf.iconcolor === "warn" ? "PDF med avvik" : "" }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- <button
          *ngIf="
            checklist.generatingPDFstatus && checklist.generatingPDFchecklist
          "
          [matTooltip]="checklist.generatingPDFstatus"
          matTooltipPosition="left"
          style="margin: 10px"
          mat-button
          disabled
        >
          <mat-icon
            [color]="
              checklist.generatingPDFstatus.substring(0, 5) === 'Error'
                ? 'warn'
                : null
            "
            >{{ checklist.generatingPDFstatusIcon }}</mat-icon
          >
          <span class="button-icon-text">{{
            checklist.generatingPDFstatusText
          }}</span>
        </button> -->
        <button
          [disabled]="!isOnline"
          *ngIf="
            checklist.isSigned &&
            ((!checklist.generatingPDFstatus &&
              checklist.pdfexports?.length === 0 &&
              checklist.isSigned) ||
              (checklist.numberOfDeviationsSigned ==
                checklist.numberOfDeviations &&
                checklist.numberOfDeviations > 0 &&
                checklist.pdfexports?.length < 2))
          "
          [matTooltip]="isOnline ? '' : 'Ikke mulig når du er offline'"
          style="margin: 10px"
          mat-raised-button
          (click)="generatePDF(checklist)"
        >
          <span class="row" *ngIf="checklist.generatingPDFchecklist">
            <span class="fill-space"></span>
            <span
              [style.padding-top]="!mobileQuery.matches ? '6px' : '0'"
              [style.padding-bottom]="!mobileQuery.matches ? '6px' : '0'"
            >
              <mat-spinner diameter="25"></mat-spinner>
            </span>
            <span class="button-icon-text">Genererer</span>
            <span class="fill-space"></span>
          </span>

          <mat-icon *ngIf="!checklist.generatingPDFchecklist" color="accent"
          >library_add
          </mat-icon
          >
          <span
            *ngIf="!checklist.generatingPDFchecklist"
            class="button-icon-text"
          >Opprett PDF</span
          >
        </button>
      </div>
    </div>
    <!-- </mat-card> -->
  </div>

  <div class="fill-space"></div>
</div>
