<h1 mat-dialog-title>
  <mat-icon style="vertical-align: middle; margin-right: 5px"
    >celebration</mat-icon
  >
  Ny versjon
</h1>
<h2 *ngIf="newVersion">
  <div class="row">
    <div>
      <!-- v.{{ currentVersion }} -->
      <mat-chip-list>
        <mat-chip>v.{{ currentVersion }}</mat-chip>
      </mat-chip-list>
    </div>
    <div class="fill-space"></div>
    <div>
      <mat-icon style="font-size: 26px; vertical-align: bottom"
        >arrow_forward</mat-icon
      >
    </div>
    <div class="fill-space"></div>
    <div>
      <!-- v.{{ newVersion }} -->
      <mat-chip-list>
        <mat-chip color="primary" selected>v.{{ newVersion }}</mat-chip>
      </mat-chip-list>
    </div>
  </div>
</h2>
<mat-dialog-content>
  <p>Oppdatering tilgjengelig</p>
  <p *ngIf="newVersion">Dersom oppdateringsknapp ikke fungerer - klikk Control+F5 (Cmd+Shift+R på Mac)</p>
  <p *ngIf="versionMessage">{{versionMessage}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close *ngIf="newVersion">
    <mat-icon>snooze</mat-icon
    ><span class="button-icon-text">Utsett 2 min</span>
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="primary"
    (click)="reloadVersion()"
  >
    <mat-icon>cached</mat-icon>
    <span class="button-icon-text"> Oppdater </span>
  </button>
</mat-dialog-actions>
