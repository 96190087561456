import {User} from './User';

export class EventLogItem {
  public type: EventLogItemType;
  public source: EventLogSource;
  public time?: string;
  public loginEmail?: string;
  public loginUrl?: string;
  public user?: User;
  public appVersion?: string;
  public errorMessage?: string;
}

export enum EventLogItemType {
  AUTH_ERROR = 'AUTH_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_FOUND_IN_FIRESTORE = 'USER_NOT_FOUND_IN_FIRESTORE',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR',
}

export enum EventLogSource {
  APG_AUTH = 'APG_AUTH',
  APG_MAIN = 'APG_MAIN',
  APG_PASS_RESET = 'APG_PASS_RESET',
}
