import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Checklist} from 'src/app/models/Checklist';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {DateService} from 'src/app/services/date.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';
import {IdService} from 'src/app/services/id.service';
import {ExcelService} from '../../../services/excel.service';

@Component({
  selector: 'app-deviations-list',
  templateUrl: './deviations-list.component.html',
  styleUrls: ['./deviations-list.component.css'],
})
export class DeviationsListComponent implements OnInit {

  user: User;
  projectId: string;
  checklists: Checklist[];
  checklistsFrontend: Checklist[];
  checklistItemsWithDeviation: ChecklistItem[];
  deviations: ChecklistItem[];
  deviationsReady = false;
  isEmpty = false;
  isLoading = true;
  files: AventiFile[];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Checklist>;
  pageIndex = 0;
  pageSize = 25;
  page = 1;
  pages: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  filter: string;
  sortactive = '';
  sortdirection: 'asc' | 'desc';
  FormFilter = new UntypedFormGroup({
    filter: new UntypedFormControl('', []),
  });
  disableRoute = false;
  mobileQuery: MediaQueryList;
  private ngUnsubscribe = new Subject();

  constructor(
    private sharedservice: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dateservice: DateService,
    media: MediaMatcher,
    private excelservice: ExcelService,
    private idservice: IdService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  get desc() {
    return this.FormFilter.get('filter');
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = this.checklistsFrontend;
    }
  }

  ngOnInit(): void {
    this.projectId = this.idservice.projectId();
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          if (user[0] && !this.user) {
            this.user = user[0];
            this.buildDeviations();
          }
        }
      });
    this.sharedservice.checklists
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklists) => {
        if (checklists !== null) {
          this.checklists = checklists;
          this.buildDeviations();
        }
      });
    this.sharedservice.checklistItemsWithDeviation
      // this.checklistservice.getAllChecklistItemsWithDeviation()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklistItemsWithDeviation) => {
        if (checklistItemsWithDeviation !== null) {
          this.checklistItemsWithDeviation = checklistItemsWithDeviation;
          this.buildDeviations();
        }
      });
    // this.sharedservice.checklistfiles
    // this.checklistservice.getChecklistFiles(this.projectId)
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((fs) => {
    //     if (fs !== null) {
    //       this.files = fs;
    //       this.buildDeviations();
    //     }
    //   });
  }

  buildDeviations() {
    if (this.user && this.checklistItemsWithDeviation && this.checklists) {
      // this.deviations = [];
      this.checklistsFrontend = [];
      this.checklists.forEach((checklist) => {
        checklist.files = [];
        this.files?.forEach((file) => {
          if (file.checklistid === checklist.id && !file.checklistitemid) {
            file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
              file.timestamp
            );
            checklist.files.push(file);
          }
        });
        checklist.files = _.sortBy(checklist.files, 'timestamporiginal');
        checklist.checklistitems = [];
        this.checklistItemsWithDeviation.forEach((checklistitem) => {
          if (
            checklistitem.status === 'deviation' &&
            !checklistitem.deviationSignedBy
          ) {
            if (checklist.id === checklistitem.checklistid) {
              if (checklistitem.lastupdated) {
                checklistitem.lastupdatedString =
                  this.dateservice.dateAsYYYYMMDDHHNNSS(
                    checklistitem.lastupdated
                  );
              }
              checklistitem.files = [];
              this.files?.forEach((file) => {
                if (file.checklistitemid === checklistitem.id) {
                  file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
                    file.timestamp
                  );
                  checklistitem.files.push(file);
                }
              });
              checklistitem.files = _.sortBy(
                checklistitem.files,
                'timestamporiginal'
              );
              // checklistitem.parentChecklist = checklist;
              checklist.checklistitems.push(checklistitem);
            }
            // if (checklistitem.parentChecklist) {
            //   this.deviations.push(checklistitem);
            // }
          }
        });
        if (checklist.checklistitems?.length > 0) {
          this.checklistsFrontend.push(checklist);
        }
      });
      // console.log(this.checklistsFrontend);
      // console.log(this.deviations)
      this.isLoading = false;
      if (!this.dataSource) {
        this.fillDataSource(this.checklistsFrontend);
      }
      this.dataSource.data = this.checklistsFrontend;
      if (this.checklistsFrontend.length === 0) {
        this.isEmpty = true;
        this.isLoading = false;
      }
    }
  }

  fillDataSource(deviations: ChecklistItem[]) {
    // console.log("Datasource starter");
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource(deviations);
    }
    // this.dataSource.data = deviations;
    const dataLength = deviations.length;
    this.dataSource.paginator = this.paginator;
    // if (this.mobileQuery.matches) {
    this.dataSource.filterPredicate = (
      data,
      filter: string
    ): boolean => {
      const filterLowerCase = filter.toLowerCase();
      return (
        data.process?.toLowerCase().includes(filterLowerCase) ||
        data.id?.toLowerCase().includes(filterLowerCase) ||
        data.name?.toLowerCase().includes(filterLowerCase) ||
        data.comment?.toLowerCase().includes(filterLowerCase) ||
        data.desc?.toLowerCase().includes(filterLowerCase) ||
        data.type?.name?.toLowerCase().includes(filterLowerCase) ||
        data.profilenumber?.toLowerCase().includes(filterLowerCase) ||
        data.numberOfDeviations?.toString().toLowerCase().includes(filterLowerCase) ||
        (data.numberOfDeviations &&
          data.numberOfDeviations > 0 &&
          'Avvik:'.toLowerCase().includes(filterLowerCase)) ||
        data.statusHuman?.toLowerCase().includes(filterLowerCase) ||
        data.lastupdatedString?.includes(filterLowerCase) ||
        JSON.stringify(data.pdfexports)?.includes(filterLowerCase)
      );
    };
    // }
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastupdated': {
          if (item.lastupdated) {
            return this.dateservice.dateAsYYYYMMDDHHNNSS(item.lastupdated);
          } else {
            return null;
          }
        }
        case 'pdfdate': {
          if (item.pdfexports[0]?.lastupdated) {
            return this.dateservice.dateAsYYYYMMDDHHNNSS(
              item.pdfexports[0].lastupdated
            );
          } else {
            return null;
          }
        }
        case 'type': {
          if (item.type) {
            return item.type.name;
          } else {
            return null;
          }
        }
        case 'deviations': {
          if (item.numberOfDeviations) {
            return 'Avvik:' + item.numberOfDeviations;
          } else {
            return null;
          }
        }
        case 'mobileName': {
          let searchstring = '';
          if (item.type.name) {
            searchstring += item.type.name;
          }
          if (item.name) {
            searchstring += item.name;
          }
          if (item.profilenumber) {
            searchstring += item.profilenumber;
          }
          return searchstring;
        }
        case 'mobileStatus': {
          let searchstring = '';
          if (item.status) {
            searchstring += item.status;
          }
          if (item.numberOfDeviations) {
            searchstring += 'Avvik:' + item.numberOfDeviations;
          }
          return searchstring;
        }
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    // console.log("Datasource ferdig");
    this.deviationsReady = true;

    if (dataLength === 0) {
      this.isEmpty = true;
      this.displayedColumns = [];
    } else {
      this.isEmpty = false;
      this.setDisplayedColumns(this.mobileQuery.matches);
    }
    this.getFilterOnLoad();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.sortactive = params.sortactive;
      this.sortdirection = params.sortdirection;
      if (!this.sortactive?.includes('pdf')) {
        this.setSortingOnLoad();
      }
    });
    this.getPagesOnLoad();
    this.pages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
    this.isLoading = false;
  }

  setSorting(sortBy) {
    console.log(sortBy);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        sortactive: sortBy.active,
        sortdirection: sortBy.direction,
      },
      queryParamsHandling: 'merge',
    });
  }

  setSortingOnLoad() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.sortactive = params.sortactive;
      this.sortdirection = params.sortdirection;
      if (
        this.sortactive?.length > 0 &&
        this.sort?.active !== this.sortactive &&
        this.sort?.direction !== this.sortdirection
      ) {
        this.sort.sort({
          id: this.sortactive,
          start: this.sortdirection,
          disableClear: false,
        });
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getFilterOnLoad() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.filter = params.filter;
      // document.getElementById('filter').nodeValue = filter;
      this.FormFilter.patchValue({
        filter: this.filter,
      });
      if (this.filter && this.filter.length > 0) {
        this.applyFilter(null, this.filter);
      }
    });
  }

  setFilter(filter: string) {
    if (this.checklistsFrontend) {
      this.applyFilter(null, filter);
    }
  }

  applyFilter(event?: Event, value?: string) {
    let filterValue: string;
    if (event) {
      filterValue = (event.target as HTMLInputElement).value;
    } else {
      filterValue = value;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.FormFilter.patchValue({
      filter: filterValue,
    });

    this.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
    this.page = 1;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {filter: filterValue, pageIndex: this.page - 1},
      queryParamsHandling: 'merge',
    });
  }

  pageChange(page) {
    console.log(page);
    this.page = this.pageIndex + 1;
    this.pages = Math.ceil(page.length / page.pageSize);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {pageIndex: page.pageIndex, pageSize: page.pageSize},
      queryParamsHandling: 'merge',
    });
  }

  getPagesOnLoad() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        // console.log(params);
        if (params.pageIndex) {
          this.pageIndex = parseInt(params.pageIndex, 10);
          if (this.pageIndex < 0) {
            this.pageIndex = 0;
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
              },
              queryParamsHandling: 'merge',
            });
          }
          this.page = this.pageIndex + 1;
          console.log(this.page, this.pageIndex);
        }
        if (params.pageSize) {
          this.pageSize = params.pageSize;
        }
      });
    this.pages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
    if (this.page > this.pages) {
      this.page = this.pages;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {pageIndex: this.page - 1, pageSize: this.pageSize},
        queryParamsHandling: 'merge',
      });
    }
  }

  setDisplayedColumns(matches) {
    if (matches) {
      if (this.user.role?.name === 'customer') {
        this.displayedColumns = ['mobileName', 'mobileStatus'];
      } else {
        this.displayedColumns = [
          // 'mobileName',
          // 'mobileStatus',
          'checklist',
          'checklistitem',
          // 'tags',
          // 'checklistpdfs',
          // 'pdfdeliveredToCustomer',
        ];
      }
    } else {
      this.displayedColumns = [
        'checklist',
        // 'files',
        'checklistitem',
        // 'text',
        // 'desc',
        // 'profilenumber',
        // 'tags',
        // 'comment',
        // 'statusHuman',
        // 'checklistpdfs',
        // 'pdfdeliveredToCustomer',
        // 'pdfdate',
        'lastupdated',
      ];
    }
  }

  downloadDeviationListToExcel() {
    console.log('checklistsFrontend', this.checklistsFrontend);
    const deviationsForExport = [];
    this.checklistsFrontend.forEach((checklist) => {
      this.checklistItemsWithDeviation.forEach((checklistitem) => {
        if (checklistitem.checklistid === checklist.id) {
          deviationsForExport.push({
            checklistName: checklist.name,
            checklistDesc: checklist.desc,
            checklistComment: checklist.comment,
            checklistNumberOfDeviations: checklist.numberOfDeviations,
            checklistitemType: checklistitem.type.name,
            checklistitemText: checklistitem.text,
            checklistitemComment: checklistitem.comment,
          });
        }
      });
    });
    console.log(deviationsForExport);
    if (deviationsForExport.length > 0) {
      this.excelservice.exportAsExcelFile(deviationsForExport, 'deviations');
    }
  }
}
