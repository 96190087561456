import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import * as _ from 'underscore';
import {AventiFile} from '../models/File';
import {Project} from '../models/Project';
import {ProjectAccess, User} from '../models/User';
import {DbpathService} from './dbpath.service';
import {IdService} from './id.service';
import {SharedService} from './shared.service';
import {UserService} from './user.service';
import {SortService} from './sort.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  projectCol: AngularFirestoreCollection<Project>;
  projectDoc: AngularFirestoreDocument<Project>;
  projectObs: Observable<Project>;
  projectsObs: Observable<Project[]>;

  project: Project;

  constructor(
    private afs: AngularFirestore,
    private idservice: IdService,
    private dbpathservice: DbpathService,
    private sharedservice: SharedService,
    private userservice: UserService,
    private sortservice: SortService
  ) {
    this.project = new Project();
    this.projectCol = this.afs.collection('projects', (ref) => {
      return ref.orderBy('no');
    }); // reference
  }

  getAllShortProjects(user?: User) {
    // return this.projectCol.valueChanges({ idField: 'id' });

    return this.projectCol.snapshotChanges().pipe(
      map((actions) => {
        const userProjectIds = user?.projectaccess?.map(projectAccess => projectAccess.id);
        const filteredActions = actions.filter(action => !user || userProjectIds?.includes(action.payload.doc.id));
        return filteredActions.map((action) => {
          const id = action.payload.doc.id;
          const project = action.payload.doc.data();
          const fromCache = action.payload.doc.metadata.fromCache;
          return {id, fromCache, name: project.name, no: project.no, useStatusLevel: project.useStatusLevel} as Project;
        });
      }));
  }

  getAllProjectIds() {
    return this.projectCol.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => {
          return action.payload.doc.id;
        });
      }));
  }

  getChecklistStatusForProject(projectId: string) {
    // return this.projectCol.valueChanges({ idField: 'id' });

    return this.afs.doc('projects/' + projectId).snapshotChanges().pipe(
      map((action) => {

        const project = action.payload.data() as Project;
        const id = action.payload.id;
        return {id, checklistStatus: project.checklistStatus};
      }));
  }

  getFullProjectById(projectId: string): Observable<Project> {
    const doc: AngularFirestoreDocument<Project> = this.afs.doc('projects/' + projectId);
    return doc.snapshotChanges().pipe(
      map((action) => {
        const project = action.payload.data() as Project;
        const id = action.payload.id;
        return {id, ...project};
      }));
    // // const objectsObs = this.objectservice.getObjects();
    // this.projectDoc = this.afs.doc('projects/' + projectId);
    // this.projectObs = this.projectDoc.valueChanges();
    // return (this.projectObs = this.projectDoc.snapshotChanges().pipe(
    //   map((actions) => {
    //     const data = actions.payload.data() as Project;
    //     const id = actions.payload.id;
    //     const fullurl = '/p/' + id + '/prosjekt';
    //     // return { id, fullurl, ...data };
    //     if (data) {
    //       data.id = id;
    //       data.fullurl = fullurl;
    //     }
    //     return data;
    //   })
    //   // flatMap((actions) => {
    //   //   const data = actions.payload.data() as Project;
    //   //   const id = actions.payload.id;
    //   //   const fullurl = '/p/' + id + '/prosjekt';
    //   //   // return { id, fullurl, ...data };
    //   //   data.id = id;
    //   //   data.fullurl = fullurl;
    //   //   return objectsObs.pipe(
    //   //     map((c) => {
    //   //       // data.forEach((object) => {
    //   //       // object.checklists = [];
    //   //       c.forEach((object) => {
    //   //         if (object) {
    //   //           // if (object.id === checklist.parentid) {
    //   //           data.objects.push(object);
    //   //           // }
    //   //         }
    //   //       });
    //   //       // });
    //   //       return data;
    //   //     })
    //   //   );
    //   // })
    // ));
    // // return this.projectObs;
  }

  getProjectFiles(projectid: string): Observable<AventiFile[]> {
    return this.afs
      .collection(
        this.dbpathservice.dbPathProjectFiles(),
        (ref) =>
          ref
            .where('projectid', '==', projectid)
            .orderBy('name')
      )
      .valueChanges({ idField: 'id' });
  }


  createProject(project: Project) {
    // this.userservice
    // .getUser()
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        // this.sharedservice.user.subscribe((u) => {
        const user = u[0];
        const timestamp = firebase.firestore.Timestamp.now();
        const usertimestamp = {
          user,
          timestamp,
        };
        if (!project.editedBy) {
          project.editedBy = [];
        }
        project.editedBy.push(usertimestamp);
        project.editedBy = this.sortservice.reduceArrayToMaxLength(project.editedBy, 'timestamp');

        project.useStatusLevel = true;
        console.log(project);
        this.afs
          .doc('projects/' + project.no)
          .set(project)
          .then(() => {
            const projectaccess: ProjectAccess = {
              id: project.no,
              name: project.name,
            };
            const userwithnewaccess = user;
            userwithnewaccess.projectaccess?.push(projectaccess);
            this.userservice.updateUserProjectAccess(userwithnewaccess);
          });

        return; // .add(project);
      }
    });
  }

  readProjects() {
    return this.afs
      .collection('projects')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as Project;
            const id = a.payload.doc.id;
            return { id, ...data } as Project;
          });
        })
      );
  }

  readProject() {
    return this.afs
      .doc(this.dbpathservice.dbPathProject())
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const project: Project = actions.payload.data();

          project.id = actions.payload.id;
          return project;
        })
      );
  }

  updateProject(project) {
    this.sharedservice.user.subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        const timestamp = firebase.firestore.Timestamp.now();
        const usertimestamp = {
          user,
          timestamp,
        };
        if (!project.editedBy) {
          project.editedBy = [];
        }
        project.editedBy.push(usertimestamp);
        project.editedBy = this.sortservice.reduceArrayToMaxLength(project.editedBy, 'timestamp');

        project = _.omit(project, 'id');
        this.afs.doc('projects/' + this.idservice.projectId()).update(project);
      }
    });
  }

  // deleteProject(projectid) {
  //   this.afs.doc('projects/' + projectid).delete();
  // }
}
