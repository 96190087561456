import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cached-indicator',
  templateUrl: './cached-indicator.component.html',
  styleUrls: ['./cached-indicator.component.css']
})
export class CachedIndicatorComponent {

  @Input() visible: boolean;
  @Input() pullRight: boolean;

}
