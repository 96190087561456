import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-password-error',
  templateUrl: './password-error.component.html',
  styleUrls: ['./password-error.component.css']
})
export class PasswordErrorComponent implements OnChanges {

  @Input() passwordErrorMinLength: boolean;
  @Input() passwordErrorUpperCaseLetter: boolean;
  @Input() passwordErrorLowerCaseLetter: boolean;
  @Input() passwordErrorNumber: boolean;
  @Input() passwordErrorSpecialCharacter: boolean;

  passwordMinLength = environment.passwordMinLength;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.passwordErrorMinLength !== undefined) {
      this.passwordErrorMinLength = changes.passwordErrorMinLength.currentValue;
    }
    if (changes.passwordErrorUpperCaseLetter !== undefined) {
      this.passwordErrorUpperCaseLetter = changes.passwordErrorUpperCaseLetter.currentValue;
    }
    if (changes.passwordErrorLowerCaseLetter !== undefined) {
      this.passwordErrorLowerCaseLetter = changes.passwordErrorLowerCaseLetter.currentValue;
    }
    if (changes.passwordErrorNumber !== undefined) {
      this.passwordErrorNumber = changes.passwordErrorNumber.currentValue;
    }
    if (changes.passwordErrorSpecialCharacter !== undefined) {
      this.passwordErrorSpecialCharacter = changes.passwordErrorSpecialCharacter.currentValue;
    }
  }

}
