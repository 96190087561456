import {Component} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-service-worker-detection',
  templateUrl: './service-worker-detection.component.html',
  styleUrls: ['./service-worker-detection.component.css']
})
export class ServiceWorkerDetectionComponent {

  swEnabled: boolean;

  constructor(private updates: SwUpdate) {}

  ngOnInit(): void {
    this.swEnabled = this.updates.isEnabled;
  }
}
