import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { DateService } from 'src/app/services/date.service';
import { SharedService } from 'src/app/services/shared.service';
import { SortService } from 'src/app/services/sort.service';
import { environment } from 'src/environments/environment';
import { NewUserComponent } from './new-user/new-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  users: User[];

  isLoading = true;
  isEmpty = false;

  FormFilter = new UntypedFormGroup({
    filter: new UntypedFormControl('', []),
  });

  filter: string;

  displayedColumns: string[] = [
    // 'id',
    'name',
    'role',
    'email',
    'phone',
    'active',
    'appversion',
    'superadmin',
  ];
  dataSource: MatTableDataSource<User>;

  pageIndex = 0;
  pageSize = 25;
  page = 1;
  pages: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  mobileQuery: MediaQueryList;

  constructor(
    private sharedservice: SharedService,
    private dialogNewObject: MatDialog,
    private sortservice: SortService,
    media: MediaMatcher,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dateservice: DateService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }


  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      // if (!this.isSort) {
      //   this.dataSource.sort = this.sort;
      //   this.isSort = true;
      // }
      // if (!this.isPaginator) {
      //   this.dataSource.paginator = this.paginator;
      //   this.isPaginator = true;
      // }
      this.dataSource.data = this.users;
    }
  }

  ngOnInit(): void {
    this.sharedservice.users
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users: User[]) => {
        if (users !== null) {
          this.users = users;
          this.users.sort(this.sortservice.dynamicSort('firstname'));
          this.users.forEach((user) => {
            if (user.lastseen) {
              user.lastseenString = this.dateservice.dateAsHHNNDDMMYYY(
                user.lastseen
              );
            }
            if (user.lastupdated) {
              user.lastupdatedString = this.dateservice.dateAsHHNNDDMMYYY(
                user.lastupdated
              );
            }
            if (user.role?.name) {
              switch (user.role?.name) {
                case 'user':
                  user.rolename = 'Bruker';
                  break;
                case 'admin':
                  user.rolename = 'Admin';
                  break;
                case 'customer':
                  user.rolename = 'Kunde';
                  break;
                default:
                  user.rolename = user.role.name;
                  break;
              }
            }
          });
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.data = this.users;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (
            data,
            filter: string
          ): boolean => {
            const filterLowerCase = filter.toLowerCase();
            return (
              data.id?.toLowerCase().includes(filterLowerCase) ||
              data.firstname?.toLowerCase().includes(filterLowerCase) ||
              data.lastname?.toLowerCase().includes(filterLowerCase) ||
              data.role?.name?.toLowerCase().includes(filterLowerCase) ||
              data.email?.toLowerCase().includes(filterLowerCase) ||
              data.phone?.locale?.toLowerCase().includes(filterLowerCase) ||
              data.phone?.number?.toLowerCase().includes(filterLowerCase) ||
              (data.active && 'aktiv'.toLowerCase().includes(filterLowerCase)) ||
              (!data.active && 'de'.toLowerCase().includes(filterLowerCase)) ||
              (data.superAdmin &&
                'superadmin'.toLowerCase().includes(filterLowerCase)) ||
              (data.firebaseUser && 'firebaseUser'.toLowerCase().includes(filterLowerCase)) ||
              (data.firebaseUser && 'firebaseAuth'.toLowerCase().includes(filterLowerCase)) ||
              (data.projectaccess &&
                JSON.stringify(data.projectaccess)
                  .toLowerCase()
                  .includes(filterLowerCase)) ||
              data.lastseenString?.toLowerCase().includes(filterLowerCase) ||
              data.lastupdatedString?.toLowerCase().includes(filterLowerCase) ||
              data.appVersion?.toLowerCase().includes(filterLowerCase)
            );
          };
          // 'name',
          // 'role',
          // 'email',
          // 'phone',
          // 'active',
          // 'appversion',
          // 'superadmin',
          // 'projectaccess',
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'name': {
                return item.firstname?.toLowerCase();
              }
              case 'role': {
                return item.role?.name;
              }
              case 'phone': {
                return item.phone?.locale + item.phone?.number;
              }
              case 'appversion': {
                return item.appVersion;
              }
              case 'info': {
                return item.appVersion;
              }
              case 'superadmin': {
                return item.superAdmin ? 'a' : 'b';
              }
              case 'firebaseAuth': {
                return item.firebaseUser ? 'a' : 'b';
              }
              case 'active': {
                return item.active ? 'a' : 'b';
              }
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.isLoading = false;
          this.setDisplayedColumns(this.mobileQuery.matches);
          this.getFilterOnLoad();
          this.getPagesOnLoad();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getFilterOnLoad() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.filter = params.filter;
      // document.getElementById('filter').nodeValue = filter;
      this.FormFilter.patchValue({
        filter: this.filter,
      });
      if (this.filter && this.filter.length > 0) {
        this.applyFilter(null, this.filter);
      }
    });
  }

  setFilter(filter: string) {
    if (this.users) {
      this.applyFilter(null, filter);
    }
  }
  applyFilter(event?: Event, value?: string) {
    let filterValue: string;
    if (event) {
      filterValue = (event.target as HTMLInputElement).value;
    } else {
      filterValue = value;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.FormFilter.patchValue({
      filter: filterValue,
    });

    this.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
    this.page = 1;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { filter: filterValue, pageIndex: this.page - 1 },
      queryParamsHandling: 'merge',
    });
  }

  pageChange(page) {
    this.page = this.pageIndex + 1;
    this.pages = Math.ceil(page.length / page.pageSize);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageIndex: page.pageIndex, pageSize: page.pageSize },
      queryParamsHandling: 'merge',
    });
  }

  getPagesOnLoad() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        if (params.pageIndex) {
          this.pageIndex = parseInt(params.pageIndex, 10);
          this.page = this.pageIndex + 1;
        }
        if (params.pageSize) {
          this.pageSize = params.pageSize;
        }
      });
    this.pages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
    if (this.page > this.pages) {
      this.page = this.pages;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { pageIndex: this.page - 1, pageSize: this.pageSize },
        queryParamsHandling: 'merge',
      });
    }
  }

  newUser() {
    this.dialogNewObject.open(NewUserComponent, {
      backdropClass: 'blur-dialog-backdrop',
    });
  }

  setDisplayedColumns(matches) {
    if (matches) {
      this.displayedColumns = [
        'mobilename',
        // 'mobilesetup',
        'active',
        // 'projectaccess',
        'firebaseAuth',
      ];
    } else {
      this.displayedColumns = [
        'name',
        'role',
        'email',
        'phone',
        'active',
        // 'appversion',
        'superadmin',
        'projectaccess',
        'firebaseAuth',
        'info',
      ];
    }
  }
}
