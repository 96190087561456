import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-domain-detection-icon',
  templateUrl: './domain-detection-icon.component.html',
  styleUrls: ['./domain-detection-icon.component.css'],
})
export class DomainDetectionIconComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  domainMessage: string;
  tooltip: string;
  visible: boolean;

  constructor(private sharedservice: SharedService) {}

  ngOnInit(): void {
    this.sharedservice.domainMessage
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dm) => {
        if (dm !== null) {
          this.domainMessage = dm[0];
          if (this.domainMessage) {
            this.tooltip = this.domainMessage;
            this.visible = true;
          } else {
            this.visible = false;
          }
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
