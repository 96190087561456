import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checklists',
  templateUrl: './checklists.component.html',
  styleUrls: ['./checklists.component.css'],
})
export class ChecklistsComponent implements OnInit {

  mobileQuery: MediaQueryList;

  constructor(
    media: MediaMatcher // private idservice: IdService, // private checklistservice: ChecklistService,
  ) // private location: Location,
  // private router: Router
  {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    // this.projectid = this.idservice.projectId();
    // this.page = this.location.path().substring(1).split('/')[3];
    // switch (this.page) {
    //   case 'utfylling': {
    //     this.checklistsTabIndex = 1;
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
    // this.checklistservice.getChecklists().pipe(takeUntil(this.ngUnsubscribe)).subscribe((checklists) => {
    //   checklists = _.reject(checklists, _.isNull);
    //   this.checklists = checklists;
    // });
  }

  // tabClicked() {
  //   // console.log(this.checklistsTabIndex);
  //   switch (this.checklistsTabIndex) {
  //     case 1: {
  //       this.router.navigate(['p/' + this.projectid + '/sl/utfylling']);
  //       break;
  //     }
  //     default: {
  //       this.router.navigate(['p/' + this.projectid + '/sl/oversikt']);
  //       break;
  //     }
  //   }
  // }
}
