import { MediaMatcher } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit {
  @Input() label: string;
  @Input() selected: any;
  @Input() items: any[];
  @Input() multiple: boolean;
  @Input() showid: boolean;
  @Input() showtype: boolean;
  @Output() value = new EventEmitter();
  mobileQuery: MediaQueryList;
  selecteditem = { id: '', name: '' };
  selecteditems: any[] = [];
  // selectedvalue: any;

  constructor(media: MediaMatcher) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    // console.log('select loaded');
    if (this.multiple && this.selected) {
      this.selecteditems = this.selected;
    }
  }

  onSelectionChange(event) {
    const value = event.value;
    // console.log(event);
    if (this.multiple) {
      // console.log(value);
      this.selecteditems = value;
    } else {
      this.items.forEach((i: { id: string; name: string }) => {
        if (i.id === value) {
          this.selecteditem.id = i.id;
          this.selecteditem.name = i.name;
        }
      });
      this.selected = this.selecteditem.id;
      this.value.emit(this.selecteditem);
    }
  }
  onOpenChange(event) {
    if (!event && this.multiple) {
      this.value.emit(this.selecteditems);
    }
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.id === o2.id : o2 === o2;
  }
}
