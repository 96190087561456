<div class="row">
  <app-headertext text="Admin - Sjekklistepunkter"></app-headertext>
  <div class="fill-space"></div>
  <div style="padding: 10px;">
    <!-- <app-select
      label="Versjon"
      selected=""
      (value)="onSelectionChange()"
      [items]="checklistitems"
    ></app-select> -->
  </div>
</div>
