import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {SnackbarService} from './snackbar.service';
import { Map } from '../models/Map';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private afs: AngularFirestore,
    private snackbarservice: SnackbarService
  ) {}

  updateMap(path: string, map: Map) {
    this.afs.doc(path).update({
      map,
    })
    .then(() => {
      this.snackbarservice.openSnackBar('Kart oppdatert', '');
    })
    .catch((err) => {
      console.log(err);
    });
  }
}
