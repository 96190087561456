import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invalid-project',
  templateUrl: './invalid-project.component.html',
  styleUrls: ['./invalid-project.component.css'],
})
export class InvalidProjectComponent implements OnInit {
  header = 'Ups!';
  text = 'Dette prosjektet eksisterer ikke';

  constructor(
    private location: Location,
    private router: Router,
    private dialogRef: MatDialogRef<InvalidProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { header: string; text: string }
  ) {}

  ngOnInit(): void {
    if (this.data?.header) {
      this.header = this.data.header;
    }
    if (this.data?.text) {
      this.text = this.data.text;
    }
  }

  backClicked() {
    this.dialogRef.close();
    this.location.back();
  }

  homeClicked() {
    this.dialogRef.close();
    this.router.navigateByUrl('');
  }
}
