import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';

import {firstValueFrom, Observable} from 'rxjs';
import {Checklist} from '../models/Checklist';
import {ChecklistItemsLibraryItem} from '../models/Checklistitem';
import {User, UserTimestamp} from '../models/User';
import {DbpathService} from './dbpath.service';
import {IdService} from './id.service';
import {SharedService} from './shared.service';
import {SnackbarService} from './snackbar.service';
import {SortService} from './sort.service';

@Injectable({
  providedIn: 'root',
})
export class ReceiptformService {
  user: User;
  projectid: string;

  constructor(
    private dialog: MatDialog,
    private sharedservice: SharedService,
    private idservice: IdService,
    private dbpathservice: DbpathService,
    private afs: AngularFirestore,
    private snackbarservice: SnackbarService,
    private router: Router,
    private sortservice: SortService
  ) {
  }

  dbDocReceiptform(receiptform?: Checklist, projectid?: string) {
    return this.afs.doc(
      this.dbpathservice.dbPathReceiptform(receiptform, projectid)
    );
  }

  // private getUser() {
  //   this.sharedservice.user.pipe(take(1)).subscribe((users) => {
  //     if (users !== null) {
  //       this.user = users[0];
  //     }
  //   });
  // }
  // private getProjectId() {
  //   this.projectid = this.idservice.projectId();
  // }

  getReceiptforms(projectid?: string) {
    return this.afs
      .collection(this.dbpathservice.dbPathReceiptforms(projectid), (ref) =>
        ref.orderBy('name')
      )
      .valueChanges({idField: 'id'});
  }

  getReceiptform(receiptform?: Checklist): Observable<Checklist> {
    return this.afs
      .doc(this.dbpathservice.dbPathReceiptform(receiptform))
      .valueChanges();
  }

  // openDialog() {
  //   this.getUser();
  //   this.getProjectId();
  //   this.popupservice.openDialogNewReceiptForm(this.user, this.projectid);
  // }

  async createReceiptform(receiptform: Checklist, redirect: boolean = false) {
    this.snackbarservice.openSnackBar(
      'Mottaksskjema opprettes...',
      'Vennligst vent'
    );
    // let dbPathReceiptforms = this.dbpathservice.dbPathReceiptforms(projectid);
    let user: User;

    receiptform.lastupdated = firebase.firestore.Timestamp.now();
    let editedBy: UserTimestamp[] = [];
    const u = await firstValueFrom(this.sharedservice.user);
    if (u !== null) {
      user = u[0];
      editedBy.push({
        user,
        timestamp: receiptform.lastupdated,
      });
    }
    editedBy = this.sortservice.reduceArrayToMaxLength(editedBy, 'timestamp');

    console.log('user', user);
    receiptform.editedBy = editedBy;

    receiptform.receiptform = true;
    const types = await firstValueFrom(this.sharedservice.checklistitemtypes);
    if (types !== null) {
      await this.localCreateReceiptform(receiptform, types, redirect);
    } else {
      const localTypes = await firstValueFrom(this.sharedservice.checklistitemtypes);
      if (localTypes !== null) {
        await this.localCreateReceiptform(receiptform, localTypes, redirect);
      }
    }

    // this.afs
    //   .collection(dbPathReceiptforms)
    //   .add(receiptform)
    //   .then((newreceiptform) => {
    //     receiptform.id = newreceiptform.id;
    //     this.updateReceiptformId(projectid,  receiptform);
    //     let message = 'Mottaksskjema opprettes...';
    //     this.snackbarservice.openSnackBar(message, 'Vennligst vent');
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  updateReceiptformId(projectid: string, receiptform?: Checklist) {
    this.dbDocReceiptform(receiptform, projectid).update({
      id: receiptform.id,
    });
  }

  private async localCreateReceiptform(
    receiptform: Checklist,
    types: ChecklistItemsLibraryItem[],
    redirect: boolean
  ) {
    let projectid = receiptform.projectid;
    if (!receiptform.projectid) {
      projectid = this.idservice.projectId();
    }
    const typeid = 'aA32zATR143hYG8Sx4Yh';
    const docRefReceiptform = this.afs.firestore
      .collection(this.dbpathservice.dbPathReceiptforms(projectid))
      .doc();
    receiptform.id = docRefReceiptform.id;
    console.log(receiptform, types, redirect);
    console.log('receiptform', receiptform);
    console.log('types', types);
    const online = await firstValueFrom(this.sharedservice.online);
    console.log('online', online);
    const batch = this.afs.firestore.batch();
    if (online) {
      batch.set(docRefReceiptform, receiptform);
    } else {
      await batch.commit();
    }
    types.map(async (t) => {
      if (t.parentid === typeid) {
        console.log('Found matching type', t);
        const docRefItem = this.afs.firestore
          .collection(`${docRefReceiptform.path}/checklistitems`)
          .doc();
        // type.checklistitemslibrary.forEach((item) => {
        console.log('docRefItem', docRefItem);
        const data = {
          id: docRefItem.id,
          checklistid: docRefReceiptform.id,
          // objectid: receiptform.objectid ? receiptform.objectid : null,
          projectid,
          comment: '',
          value: '',
          lastupdated: firebase.firestore.FieldValue.serverTimestamp(),
          type: t.type,
          text: t.text,
          // ...type,
        };
        console.log('data', data);
        if (online) {
          batch.set(docRefItem, data);
        } else {
          await docRefItem.set(data)
            .then(i => {
              console.log('item added', i);
            })
            .catch((err) => {
              console.error('Error while creating checklistItem: ', err);
              return err;
            });
        }
        // });
      }
    });

    if (online) {
      batch
        .commit()
        .then(() => {
          if (redirect) {
            this.router.navigateByUrl(
              'p/' + projectid + '/mt/' + docRefReceiptform.id
            );
            this.dialog.closeAll();
          }
        })
        .catch((err) => {
          console.log(err);
          this.snackbarservice.openSnackBar(
            'Det skjedde en feil under oppretting av mottaksskjema...',
            ''
          );
        });
    } else {
      const receiptForms = await firstValueFrom(this.sharedservice.receiptforms);
      if (receiptForms !== null) {
        receiptForms.push(receiptform);
        this.sharedservice.receiptforms.next(receiptForms);
        this.sharedservice.isLoading.next([false, '', ['']]);
        if (redirect) {
          await this.router.navigateByUrl(
            'p/' + projectid + '/mt/' + docRefReceiptform.id
          );
          this.dialog.closeAll();
        }
      }
    }
  }
}
