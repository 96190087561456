import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmComponent} from 'src/app/components/common/bottom-sheet/confirm/confirm.component';
import {AppVersion} from 'src/app/models/Admin';
import {AdminService} from 'src/app/services/admin.service';
import {ChangelogService} from 'src/app/services/changelog.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';

export interface DialogData {
  inputVersion: AppVersion;
}

@Component({
  selector: 'app-new-changelog-item',
  templateUrl: './new-changelog-item.component.html',
  styleUrls: ['./new-changelog-item.component.css'],
})
export class NewChangelogItemComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    version: new UntypedFormControl('', [Validators.required]),
    summary: new UntypedFormControl('', [Validators.required]),
  });
  error: string;

  message = 'Laster opp...';

  id = '';

  items: string[] = [''];
  itemsFrontend: string[] = [''];
  itemsTest: string[];

  get version() {
    return this.Form.get('version');
  }
  get summary() {
    return this.Form.get('summary');
  }

  inputVersion?: AppVersion;

  changelogitem: AppVersion;

  latestAppversion: AppVersion;

  beta = false;

  mobileQuery: MediaQueryList;

  constructor(
    private dialogRef: MatDialogRef<NewChangelogItemComponent>,
    private changelogservice: ChangelogService,
    media: MediaMatcher,
    private adminservice: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bottomSheet: MatBottomSheet
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    if (this.data?.inputVersion) {
      this.inputVersion = this.data.inputVersion;
      this.id = this.inputVersion.id;
      this.Form.patchValue({
        version: this.inputVersion.version,
        summary: this.inputVersion.summary,
      });
      this.items = this.inputVersion.changeItems;
      this.itemsTest = this.inputVersion.changeItems;
      this.inputVersion.changeItems.forEach((item, index) => {
        this.changeClicked(item, index);
      });
      this.beta = this.inputVersion.beta;
    } else {
      this.itemsTest = [''];
      this.adminservice
        .getAppVersions()
        .pipe(take(1))
        .subscribe((appVersions: AppVersion[]) => {
          new Promise((resolve) => {
            appVersions.forEach((version, index) => {
              const v = version.version.split('.');
              version.mainversion = parseInt(v[0], 10);
              version.subversion = parseInt(v[1], 10);
              version.subsubversion = parseInt(v[2], 10);
              if (index === appVersions.length - 1) {
                resolve(true);
              }
            });
          }).then(() => {
            let appvs = _.sortBy(appVersions, 'subsubversion');
            appvs = _.sortBy(appvs, 'subversion');
            appvs = _.sortBy(appvs, 'mainversion').reverse();
            this.latestAppversion = appvs[0];
            const latestAppversionArr =
              this.latestAppversion.version.split('.');
            const newVersionNumber =
              latestAppversionArr[0] +
              '.' +
              latestAppversionArr[1] +
              '.' +
              (parseInt(latestAppversionArr[2], 10) + 1);
            this.Form.patchValue({
              version: newVersionNumber,
            });
          });
        });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  betaChanged(beta: boolean) {
    this.beta = beta;
  }

  changeClicked(changestring: string, i: number) {
    this.itemsFrontend[i] = changestring;
  }

  create(version: string, summary: string) {
    if (
      version.length > 0 &&
      summary.length > 0 &&
      this.itemsFrontend.length > 0
    ) {
      this.changelogitem = {
        id: this.id,
        version,
        summary,
        changeItems: this.itemsFrontend,
        beta: this.beta,
      };
      if (this.inputVersion) {
        this.changelogservice.updateVersion(this.changelogitem);
      } else {
        this.changelogservice.newVersion(this.changelogitem);
      }
      this.Form.reset();
      this.Form.markAsPristine();
      this.Form.markAsUntouched();
      this.dialogRef.close();
    }
  }

  deleteVersionConfirm(): void {
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'Er du sikker?',
        text: 'Slette versjon: ' + this.inputVersion.version,
        yes: {
          name: 'Ja',
          icon: 'delete',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          this.changelogservice
            .deleteVersion(this.inputVersion)
            .then(() => {
              this.Form.reset();
              this.Form.markAsPristine();
              this.Form.markAsUntouched();
              this.dialogRef.close();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }
}
