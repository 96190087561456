import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {MatSnackBarDismiss} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  openSnackBar(message: string, action: string, duration = 5000, onActionType: string = null) {
    if (!action || action === '') {
      action = 'Ok';
    }
    const snackBar = this.snackbar.open(message, action, {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    if (onActionType) {
      snackBar.afterDismissed().subscribe((matSnackBarDismiss: MatSnackBarDismiss) => {
        console.log('afterDismissed', matSnackBarDismiss);
      });
      snackBar.onAction().subscribe(() => {
        switch (onActionType) {
          case 'reload':
            console.log('reloading');
            window.location.reload();
            break;
        }
        console.log('onAction');
        snackBar.dismiss();
      });
      snackBar.afterOpened().subscribe(() => {
        console.log('afterOpened');
      });
    }
  }
}
