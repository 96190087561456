<!-- <div *ngIf="!mobileQuery || !project">
  <app-spinner></app-spinner>
</div> -->

<app-spinner
  disable="false"
  [text]="['Vennligst vent...']"
  [header]="'Åpner ' + loaderScreenText"
  *ngIf="showingLoaderscreen"
></app-spinner>

<mat-sidenav-container
  id="sidenavcontainer"
  class="sidenav-container"
  *ngIf="mobileQuery && project"
>
  <mat-sidenav
    class="noselect"
    #sidenav
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [opened]="showSidenav || !mobileQuery.matches"
    (openedChange)="closeSidenav(sidenav.opened)"
    [ngStyle]="{
      width: opened ? '150px' : '40px'
    }"
  >
    <div class="header">
      <div class="sidebar-item">
        <mat-icon
          *ngIf="!mobileQuery.matches"
          class="sidebar-item-icon cursor"
          (click)="toggleOpened()"
          >{{ menuicon }}</mat-icon
        >
        <mat-icon
          (click)="closeSidenav()"
          class="sidebar-item-icon cursor"
          *ngIf="mobileQuery.matches"
          >menu</mat-icon
        >
        <app-sidenavitemtext
          [opened]="opened"
          text="Aventi"
        ></app-sidenavitemtext>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div
      class="sidebar-item cursor hover"
      routerLink="p/{{ project.id }}"
      routerLinkActive="active current"
      [routerLinkActiveOptions]="{ exact: true }"
      matTooltip="Hjem"
      matTooltipPosition="right"
      [matTooltipDisabled]="opened"
    >
      <mat-icon class="sidebar-item-icon"> other_houses </mat-icon>
      <app-sidenavitemtext
        *ngIf="user"
        [opened]="opened"
        text="Hjem"
      ></app-sidenavitemtext>
    </div>
    <div
      class="sidebar-item cursor hover"
      routerLink="p/{{ project.id }}/p"
      routerLinkActive="active current"
      [routerLinkActiveOptions]="{ exact: true }"
      matTooltip="Prosjekt"
      matTooltipPosition="right"
      [matTooltipDisabled]="opened"
    >
      <mat-icon class="sidebar-item-icon"> developer_board </mat-icon>
      <app-sidenavitemtext
        *ngIf="user"
        [opened]="opened"
        text="Prosjekt"
      ></app-sidenavitemtext>
    </div>
    <div
      *ngIf="user?.role.name !== 'customer'"
      class="sidebar-item cursor hover"
      routerLink="p/{{ project.id }}/o"
      routerLinkActive="active current"
      [routerLinkActiveOptions]="{ exact: false }"
      matTooltip="Objekter"
      matTooltipPosition="right"
      [matTooltipDisabled]="opened"
    >
      <mat-icon class="sidebar-item-icon"> traffic </mat-icon>
      <app-sidenavitemtext
        *ngIf="user"
        [opened]="opened"
        text="Objekter"
      ></app-sidenavitemtext>
    </div>
    <div
      class="sidebar-item cursor hover"
      routerLink="p/{{ project.id }}/sl"
      routerLinkActive="active current"
      [routerLinkActiveOptions]="{ exact: false }"
      matTooltip="Sjekklister"
      matTooltipPosition="right"
      [matTooltipDisabled]="opened"
    >
      <mat-icon class="sidebar-item-icon"> list_alt </mat-icon>
      <app-sidenavitemtext
        *ngIf="user"
        [opened]="opened"
        text="Sjekklister"
      ></app-sidenavitemtext>
    </div>
    <div
      *ngIf="user?.role.name !== 'customer'"
      class="sidebar-item cursor hover"
      routerLink="p/{{ project.id }}/av"
      routerLinkActive="active current"
      [routerLinkActiveOptions]="{ exact: false }"
      matTooltip="Avvik"
      matTooltipPosition="right"
      [matTooltipDisabled]="opened"
    >
      <mat-icon class="sidebar-item-icon"> assignment_late </mat-icon>
      <app-sidenavitemtext
        *ngIf="user"
        [opened]="opened"
        text="Avvik"
      ></app-sidenavitemtext>
    </div>
    <div
      *ngIf="user?.role.name !== 'customer'"
      class="sidebar-item cursor hover"
      routerLink="p/{{ project.id }}/mt"
      routerLinkActive="active current"
      [routerLinkActiveOptions]="{ exact: false }"
      matTooltip="Mottak"
      matTooltipPosition="right"
      [matTooltipDisabled]="opened"
    >
      <mat-icon class="sidebar-item-icon"> cases </mat-icon>
      <app-sidenavitemtext
        *ngIf="user"
        [opened]="opened"
        text="Mottak"
      ></app-sidenavitemtext>
    </div>
    <!-- <div *ngIf="items && project">
      <div *ngFor="let item of items">
        <app-sidenavitem
          [project]="project"
          [opened]="opened"
          [item]="item"
          (click)="showLoaderscreen(item)"
        ></app-sidenavitem>
      </div>
    </div> -->
    <div class="sidebar-footer">
      <div
        *ngIf="opened"
        class="noselect"
        style="
          color: #666;
          font-size: 10px;
          text-align: right;
          margin-right: 4px;
          margin-bottom: 2px;
          /* position: absolute;
          right: 10px;
          bottom: 10px;
          z-index: 2; */
        "
      >
        <app-version [positionRelative]="true"></app-version>
      </div>
      <mat-divider></mat-divider>
      <!-- <div
        *ngIf="isAdmin"
        class="sidebar-item cursor hover"
        routerLink="p/{{ project.id }}/a"
        routerLinkActive="active current"
        [routerLinkActiveOptions]="{ exact: false }"
        matTooltip="Admin"
        matTooltipPosition="right"
        [matTooltipDisabled]="opened"
      >
        <mat-icon class="sidebar-item-icon">
          admin_panel_settings
        </mat-icon>
        <app-sidenavitemtext
          *ngIf="user"
          [opened]="opened"
          text="Admin"
        ></app-sidenavitemtext>
      </div> -->
      <div
        class="sidebar-item cursor hover"
        routerLink=""
        matTooltip="Prosjektliste"
        matTooltipPosition="right"
        [matTooltipDisabled]="opened"
      >
        <mat-icon class="sidebar-item-icon"> view_list </mat-icon>
        <app-sidenavitemtext
          *ngIf="user"
          [opened]="opened"
          text="Prosjektliste"
        ></app-sidenavitemtext>
      </div>
      <div
        class="sidebar-item cursor hover"
        (click)="bugReport()"
        matTooltip="Rapporter feil"
        matTooltipPosition="right"
        [matTooltipDisabled]="opened"
      >
        <mat-icon class="sidebar-item-icon"> bug_report </mat-icon>
        <app-sidenavitemtext
          *ngIf="user"
          [opened]="opened"
          text="Meld feil"
        ></app-sidenavitemtext>
      </div>
      <!-- <div
        class="sidebar-item cursor hover"
        routerLink="p/{{ project.id }}/m"
        routerLinkActive="active current"
        [routerLinkActiveOptions]="{ exact: true }"
        matTooltip="Min side"
        matTooltipPosition="right"
        [matTooltipDisabled]="opened"
      >
        <mat-icon class="sidebar-item-icon"> account_circle </mat-icon>
        <app-sidenavitemtext
          *ngIf="user"
          [opened]="opened"
          [text]="user.firstname"
        ></app-sidenavitemtext>
      </div> -->
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    #scrollframe
    (scroll)="scrolled($event)"
    [ngStyle]="{
      'margin-left': mobileQuery.matches ? '0' : opened ? '150px' : '40px'
    }"
    [style.height]="mobileQuery.matches ? 'calc(100% - 60px)' : '100%'"
    [style.top]="mobileQuery.matches ? '0' : '0'"
    [style.margin-top]="mobileQuery.matches ? '0' : '0'"
  >
    <!-- <div
      *ngIf="mobileQuery.matches"
      style="float: left; margin-top: 7px; margin-left: 12px"
    >
      <mat-icon (click)="openSidenav()">menu</mat-icon>
    </div> -->
    <app-content [project]="project"></app-content>
  </mat-sidenav-content>
</mat-sidenav-container>

<div
  *ngIf="mobileQuery.matches && user"
  style="
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 900;
    background: #303030;
  "
>
  <mat-divider *ngIf="mobileQuery.matches"></mat-divider>
  <div class="row" *ngIf="project">
    <!-- <div class="fill-space"></div>
    <button
      mat-button
      routerLink="p/{{ project.id }}"
      [color]="!pathsplit[2] ? 'primary' : ''"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>other_houses</mat-icon>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Hjem
      </div>
    </button> -->
    <div class="fill-space"></div>
    <button
      mat-button
      routerLink="p/{{ project.id }}/p"
      [color]="pathsplit[2]?.substring(0, 1) === 'p' ? 'primary' : undefined"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>view_list</mat-icon>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Prosjekt
      </div>
    </button>
    <div class="fill-space" *ngIf="user.role.name !== 'customer'"></div>
    <button
      *ngIf="user.role.name !== 'customer'"
      mat-button
      routerLink="p/{{ project.id }}/o"
      [color]="
        pathsplit[2]?.substring(0, 1) === 'o' &&
        pathsplit[4]?.substring(0, 1) !== 's'
          ? 'primary'
          : undefined
      "
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>traffic</mat-icon>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Objekter
      </div>
    </button>
    <div class="fill-space"></div>
    <button
      mat-button
      routerLink="p/{{ project.id }}/sl"
      [color]="
        pathsplit[2]?.substring(0, 2) === 'sl' ||
        pathsplit[4]?.substring(0, 1) === 's'
          ? 'primary'
          : undefined
      "
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>list_alt</mat-icon>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Sjekklister
      </div>
    </button>
    <!-- <div class="fill-space" *ngIf="user.role.name !== 'customer'"></div>
    <button
      *ngIf="user.role.name !== 'customer'"
      mat-button
      routerLink="p/{{ project.id }}/mt"
      [color]="pathsplit[2]?.substring(0,2) === 'mt' ? 'primary' : ''"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>cases</mat-icon>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Mottak
      </div>
    </button> -->
    <div class="fill-space" *ngIf="user.role.name !== 'customer'"></div>
    <button
      *ngIf="user.role.name !== 'customer'"
      mat-button
      routerLink="p/{{ project.id }}/av"
      [color]="pathsplit[2]?.substring(0, 2) === 'av' ? 'primary' : undefined"
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
    >
      <mat-icon>assignment_late</mat-icon>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Avvik
      </div>
    </button>
    <!-- <div class="fill-space" *ngIf="user.role.name !== 'customer'"></div>
     <button
    mat-button
      *ngIf="user.role.name !== 'customer'"
    (click)="newReceiptform()"
    class="header-row-button"
    [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
    [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
    [disabled]="!user"
  >
    <mat-icon>cases</mat-icon>
    <span style="padding-left: 5px" *ngIf="!mobileQuery.matches">Mottak</span>
    <div
      style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"

    >
      Mottak
    </div>
  </button> -->
    <div class="fill-space"></div>

    <button
      mat-button
      [style.margin-top]="mobileQuery.matches ? '3px !important' : ''"
      [style.margin-bottom]="mobileQuery.matches ? '0 !important' : ''"
      [disabled]="!user"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>menu</mat-icon>
      <span style="padding-left: 5px" *ngIf="!mobileQuery.matches">Meny</span>
      <div
        style="font-size: 10px; padding: 0; margin-top: -15px; margin-bottom: 0"
      >
        Meny
      </div>
    </button>

    <div class="fill-space"></div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button
    *ngIf="project"
    mat-menu-item
    routerLink="p/{{ project.id }}"
    [disabled]="!user"
    [style.color]="!pathsplit[2] && pathsplit[0] === 'p' ? '#398E3C' : ''"
  >
    <mat-icon [color]="!pathsplit[2] && pathsplit[0] === 'p' ? 'primary' : undefined"
      >other_houses</mat-icon
    ><span style="padding-left: 5px">Hjem</span>
  </button>
  <button mat-menu-item routerLink="/m" [disabled]="!user" >
    <mat-icon>account_circle</mat-icon
    ><span style="padding-left: 5px">Min side</span>
  </button>
  <button
    mat-menu-item
    (click)="bugReport()"
    [disabled]="!user"
    *ngIf="user?.role.name !== 'customer'"
  >
    <mat-icon>bug_report</mat-icon
    ><span style="padding-left: 5px">Meld feil</span>
  </button>

  <button
    mat-menu-item
    *ngIf="user?.role.name !== 'customer' && isAdmin"
    routerLink="/a"
    [disabled]="!user"
  >
    <mat-icon>admin_panel_settings</mat-icon
    ><span style="padding-left: 5px">Admin</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/" [disabled]="!user">
    <mat-icon>view_list</mat-icon
    ><span style="padding-left: 5px">Prosjektliste</span>
  </button>
  <button
    mat-menu-item
    [disabled]="!user"
    *ngIf="project && user?.role.name !== 'customer'"
    routerLink="p/{{ project.id }}/mt"
  >
    <mat-icon>cases</mat-icon>
    <span style="padding-left: 5px">Mottak</span>
  </button>
  <button mat-menu-item (click)="logout()" [disabled]="!user">
    <mat-icon>exit_to_app</mat-icon
    ><span style="padding-left: 5px">Logg av</span>
  </button>
  <div class="row">
    <div class="fill-space"></div>
    <div>
      <span style="font-size: x-small; padding: 5px; color: #aaa">
        Appversjon: v.{{ appVersion }}</span
      >
    </div>
  </div>
</mat-menu>
