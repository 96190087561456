import {Component, OnInit} from '@angular/core';
import {AdminService} from 'src/app/services/admin.service';
import {MediaMatcher} from '@angular/cdk/layout';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'underscore';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {ObjectStatus} from 'src/app/models/Status';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NewObjectstatusComponent} from './new-objectstatus/new-objectstatus.component';

@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.css']
})
export class StatusesComponent implements OnInit {
  isLoading = true;
  mobileQuery: MediaQueryList;
  statuses: ObjectStatus[];
  private ngUnsubscribe = new Subject();

  constructor(
    private adminservice: AdminService,
    private dialogNewObjectStatus: MatDialog,
    media: MediaMatcher
  ) {
    adminservice
      .getObjectStatuses()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((statuses) => {
        let statusesTemp = _.reject(statuses, _.isNull);
        statusesTemp = _.sortBy(statusesTemp, 'priority');
        this.statuses = structuredClone(statusesTemp);
        this.isLoading = false;
      });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {

  }

  newObjectStatus() {
    const priorities = this.statuses.map(status => status.priority);
    const newPriority = Math.max.apply(null, priorities) + 1;
    this.dialogNewObjectStatus.open(NewObjectstatusComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {newPriority},
    });
  }

  async sortPriority(index: number, direction: string) {
    if (direction === 'up' || direction === 'down') {
      this.isLoading = true;
      const newPriority = direction === 'up' ? index : index + 2;
      const newStatuses = this.updatePriority(this.statuses, index, newPriority);
      console.log(newStatuses);
      this.adminservice.updateObjectStatuses(newStatuses);
      // newStatuses.forEach((status: ObjectStatus) => {
      //   this.adminservice.updateObjectStatus(status);
      // });
    }
  }

  updatePriority(arr, indexToUpdate, newPriority) {
    // Find the item to update
    const itemToUpdate = arr[indexToUpdate];

    if (!itemToUpdate) {
      console.log('Item not found');
      return arr;
    }

    // Save the current priority of the item to be updated
    const currentPriority = itemToUpdate.priority;

    if (newPriority < 1 || newPriority > arr.length || newPriority === currentPriority) {
      console.log('Invalid new priority or trying to set the same priority');
      return arr;
    }

    // Decrease the priority of items below the updated item
    arr.forEach(item => {
      if (item.priority > currentPriority && item.priority <= newPriority) {
        item.priority -= 1;
      }
    });

    // Increase the priority of items above the updated item
    arr.forEach(item => {
      if (item.priority < currentPriority && item.priority >= newPriority) {
        item.priority += 1;
      }
    });

    // Set the new priority for the updated item
    itemToUpdate.priority = newPriority;

    // Sort the array by priority
    arr.sort((a, b) => a.priority - b.priority);

    console.log('returning', arr);
    return arr;
  }

}
