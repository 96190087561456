import { Checklist } from './Checklist';
import { Objecttype } from './Objecttype';
import { UserTimestamp } from './User';
import { Timestamp } from '@firebase/firestore-types';
import { AventiFile } from './File';
import {ObjectStatus} from './Status';

export class AventiObject {
  public id?: string;
  public projectid?: string;
  public name?: string;
  public nameSearchString?: string;
  public statusSearchString?: string;
  public tagsSearchString?: string;
  public no?: string;
  public downloadurl?: string;
  public desc?: string;
  public comment?: string;
  public drawing?: string;
  public cabinet?: string;
  public process?: string;
  public profilenumber?: string;
  public type?: Objecttype;
  public typename?: string;
  public receiptforms?: Checklist[];
  public checklists?: Checklist[];
  public checklistPresent?: boolean;
  public status?: ObjectStatus;
  // tslint:disable-next-line:variable-name
  public z_checklistsstring?: string;
  public receivingReports?: string[];
  public reload?: boolean;
  public editedBy?: UserTimestamp[];
  public lastupdated?: Timestamp;
  public lastupdatedString?: string;
  public generatingChecklist?: boolean;
  public generatingReceiptform?: boolean;
  public files?: AventiFile[];
  public pos?: {
    lat: number;
    lng: number;
  };
  public tags?: Tag[];
  public isarchived?: boolean;
  public path?: string;
  public pdfLoaded?: boolean;
  public fromCache?: boolean;
}

export class Tag {
  key?: string;
  value?: string;
  edit?: boolean;
}
