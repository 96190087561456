<div class="center-on-screen noselect">
  <mat-card class="card">
    <mat-card-header>
      <div mat-card-avatar class="header-logo"></div>
      <mat-card-title>Aventi</mat-card-title>
      <mat-card-subtitle>KS system</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form class="form" [formGroup]="signin" (keyup.enter)="login()">
        <mat-form-field class="full-width"
                        [class]="wiggleUsername ? 'wiggle' : ''">
          <mat-label>Email</mat-label>
          <input
            autofocus
            id="email"
            matInput
            placeholder="Ex. ola.normann@aventi.no"
            formControlName="email"
            required
          />
          <mat-error
            *ngIf="
              emailInput.hasError('email') && !emailInput.hasError('required')
            "
          >
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailInput.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width"
                        [class]="wigglePassword ? 'wiggle' : ''">
          <input
            matInput
            placeholder="Password"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            required
            (keyup)="validateAfterKeyUp($event)"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          <mat-error>
            <app-password-error
              [passwordErrorMinLength]="passwordErrorMinLength"
              [passwordErrorUpperCaseLetter]="passwordErrorUpperCaseLetter"
              [passwordErrorLowerCaseLetter]="passwordErrorLowerCaseLetter"
              [passwordErrorNumber]="passwordErrorNumber"
              [passwordErrorSpecialCharacter]="passwordErrorSpecialCharacter"
            ></app-password-error>
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div class="row" style="margin-top: 20px">
        <div>
          <button mat-button (click)="forgotPassword()">
            Glemt passord?
          </button>
        </div>
        <div class="fill-space"></div>
        <div>
          <button
            mat-raised-button
            [disabled]="!signin.valid || loggingIn"
            (click)="login()"
          >
            <span *ngIf="!loggingIn">Login</span>
            <span *ngIf="loggingIn"
            >
              <mat-spinner diameter="30"></mat-spinner
            ></span>
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<app-version></app-version>
