import {Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {EventLogItem, EventLogItemType, EventLogSource} from 'src/app/models/EventLog';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {AdminService} from 'src/app/services/admin.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  @Input() oobCode: string;
  hide = true;
  resetSent = false;

  passwordErrorMinLength = true;
  passwordErrorUpperCaseLetter = false;
  passwordErrorLowerCaseLetter = false;
  passwordErrorNumber = false;
  passwordErrorSpecialCharacter = false;

  reset: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [
      Validators.required,
      this.validatePasswordInput
    ]),
  });

  error: string;
  private ngUnsubscribe = new Subject();

  constructor(
    private router: Router,
    private adminservice: AdminService,
    private snackbarservice: SnackbarService,
    private afAuth: AngularFireAuth
  ) {
  }

  get passwordInput() {
    return this.reset.get('password');
  }

  validatePasswordInput(c: UntypedFormControl) {
    return (
      environment.passwordRegExMinLength.test(c.value) &&
      environment.passwordRegExUpperCaseLetter.test(c.value) &&
      environment.passwordRegExLowerCaseLetter.test(c.value) &&
      environment.passwordRegExNumber.test(c.value) &&
      environment.passwordRegExSpecialCharacter.test(c.value)
    ) ? null : {
      validateInput: {
        valid: false
      }
    };
  }

  validateAfterKeyUp(event) {
    const value = event.target.value;
    this.passwordErrorMinLength = !environment.passwordRegExMinLength.test(value);
    this.passwordErrorUpperCaseLetter = !environment.passwordRegExUpperCaseLetter.test(value);
    this.passwordErrorLowerCaseLetter = !environment.passwordRegExLowerCaseLetter.test(value);
    this.passwordErrorNumber = !environment.passwordRegExNumber.test(value);
    this.passwordErrorSpecialCharacter = !environment.passwordRegExSpecialCharacter.test(value);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  resetPassword() {
    this.afAuth.confirmPasswordReset(this.oobCode, this.passwordInput.value)
      .then(() => this.router.navigateByUrl('/'))
      .catch(async err => {
        this.error = err.message;
        console.error(this.error);
        await this.buildEventLogItemAndAddToEventLog(EventLogItemType.PASSWORD_RESET_ERROR);
        this.snackbarservice.openSnackBar(this.error, 'dismiss', 10000);
      });
  }

  async buildEventLogItemAndAddToEventLog(type: EventLogItemType) {
    const newEventLogItem: EventLogItem = {
      type,
      loginEmail: '',
      loginUrl: window.location.href,
      appVersion: environment.appVersion,
      errorMessage: this.error,
      time: new Date().toJSON(),
      source: EventLogSource.APG_PASS_RESET
    };
    await this.adminservice.addToEventLog(newEventLogItem);
  }

}
