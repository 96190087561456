<div class="row">
  <app-headertext text="Filer"></app-headertext>
  <div class="fill-space"></div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="!files"></mat-progress-bar>

<div *ngIf="files && objects">
  <div class="row">
    <div class="fill-space"></div>
    <div style="width: 90%">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="example-header-image">
            <mat-icon
              *ngIf="canNavigateUp"
              class="pointer"
              (click)="navigateUp()"
              >arrow_back</mat-icon
            ><mat-icon *ngIf="!canNavigateUp">folder_open</mat-icon>
          </div>
          <mat-card-title>{{ path || "Objekter" }}</mat-card-title>
        </mat-card-header>
        <!-- <mat-divider></mat-divider> -->
        <p></p>
        <div *ngIf="!singleobject && !singlechecklist">
          <table>
            <tr *ngFor="let object of objects" (click)="openObject(object)">
              <td>
                <mat-icon color="primary" class="file-or-folder-icon pointer"
                  >folder</mat-icon
                >
              </td>
              <td>{{ object.name }}</td>
              <td *ngIf="!mobileQuery.matches">{{ object.type?.name }}</td>
              <td style="text-align: right;font-size: small;">
                <div *ngIf="mobileQuery.matches">{{ object.type?.name }}</div>
                <div>{{ object.files?.length || '0' }} {{ (object.files?.length  === 1) ? 'fil' : 'filer' }}</div>
                <div>{{ object.checklists?.length || '0' }} {{ (object.checklists?.length  === 1) ? 'sjekkliste' : 'sjekklister' }}</div>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="singleobject && !singlechecklist">
          <table>
            <tr
              *ngFor="let checklist of singleobject.checklists"
              (click)="openChecklist(checklist)"
            >
              <td>
                <mat-icon color="primary" class="file-or-folder-icon pointer"
                  >folder</mat-icon
                >
              </td>
              <td *ngIf="!mobileQuery.matches">{{ checklist.name }}</td>
              <td>Sjekkliste</td>
              <td style="text-align: right; font-size: small;">
                <div>{{ checklist.files?.length || '0' }} {{ (checklist.files?.length  === 1) ? 'fil' : 'filer' }}</div>
              </td>
            </tr>
            <tr
              *ngFor="let file of singleobject.files"
              (click)="openDialogImagePreview(file)"
            >
              <td>
                <!-- <mat-icon color="primary" class="file-or-folder-icon pointer"
                  >folder</mat-icon
                > -->
                <app-thumbnail [file]="file" [files]="singleobject.files" [isLocked]="true" [disableclick]="true"></app-thumbnail>
              </td>
              <td *ngIf="!mobileQuery.matches">{{ file.name }}</td>
              <td>{{ file.typestring }}</td>
              <!-- <td style="text-align: right">{{ object.files?.length }} filer</td> -->
            </tr>
          </table>
        </div>
        <div *ngIf="singlechecklist">
          <table>
            <tr
              *ngFor="let file of singlechecklist.files"
              (click)="openDialogImagePreview(file)"
            >
              <td>
                <app-thumbnail [file]="file" [files]="singlechecklist.files" [isLocked]="true" [disableclick]="true"></app-thumbnail>
              </td>
              <td *ngIf="!mobileQuery.matches">{{ file.name }}</td>
              <td>{{ file.typestring }}</td>
            </tr>
          </table>
        </div>
      </mat-card>

    </div>
    <div class="fill-space"></div>
  </div>
</div>
