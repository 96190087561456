<div class="row">
  <app-headertext text="Admin - Objekttyper - Objekttype - Sjekklistepunkt"></app-headertext>
  <div class="fill-space"></div>
  <button
    mat-button
    (click)="deleteChecklistitemConfirm()"
    class="header-row-button"
  >
    <mat-icon color="warn">delete</mat-icon>
    Slett sjekklistepunkt
  </button>
  <!-- <button mat-button (click)="newChecklistitems()" class="header-row-button">
    <mat-icon color="primary">add</mat-icon>
    Nytt sjekklistepunkt
  </button> -->
  <!-- <button mat-button (click)="editChecklistitems()" class="header-row-button">
    <mat-icon color="accent">edit</mat-icon>
    Rediger sjekklistepunkt
  </button> -->
</div>

<div style="margin: 0 15px 0 15px;">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>

<div *ngIf="checklistitem && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <mat-card class="maxwidth child-margin">
      <div class="row" *ngIf="checklistitem.text">
        Tekst:
        <div class="fill-space"></div>
        <div *ngIf="!edittext && checklistitem.text">
          {{ checklistitem.text }}
        </div>
        <div *ngIf="edittext">
          <form [formGroup]="FormText">
            <div class="row">
              <mat-form-field>
                <input
                  cdkFocusInitial
                  matInput
                  id="text"
                  type="text"
                  placeholder="Tekst"
                  formControlName="text"
                />
                <mat-error *ngIf="text.touched && text.invalid">
                  <div *ngIf="!text.errors.required">
                    Please enter a checklistitem text
                  </div>
                  <div *ngIf="text.errors.required">
                    Checklistitem text is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                mat-icon-button
                [disabled]="FormText.invalid"
                *ngIf="edittext"
                style="padding-left: 10px;"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="saveText(text.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px;"
            class="cursor"
            [matTooltip]="edittext ? 'Avbryt endring' : 'Endre tekst'"
            (click)="toggleEditText()"
          >{{ edittext ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>

      <mat-divider [inset]="true"></mat-divider>

      <div class="row" *ngIf="checklistitem.text">
        Engelsk tekst:
        <div class="fill-space"></div>
        <div *ngIf="!editEnText && checklistitem.enText">
          {{ checklistitem.enText }}
        </div>
        <div *ngIf="editEnText">
          <form [formGroup]="FormEnText">
            <div class="row">
              <mat-form-field>
                <input
                  cdkFocusInitial
                  matInput
                  id="enText"
                  type="text"
                  placeholder="Tekst"
                  formControlName="enText"
                />
                <mat-error *ngIf="enText.touched && enText.invalid">
                  <div *ngIf="!enText.errors.required">
                    Please enter a checklistitem english text
                  </div>
                  <div *ngIf="enText.errors.required">
                    Checklistitem english text is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                mat-icon-button
                [disabled]="FormEnText.invalid"
                *ngIf="editEnText"
                style="padding-left: 10px;"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="saveEnText(enText.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px;"
            class="cursor"
            [matTooltip]="editEnText ? 'Avbryt endring' : 'Endre engelsk tekst'"
            (click)="toggleEditEnText()"
          >{{ edittext ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>

      <mat-divider [inset]="true"></mat-divider>

      <div class="row" *ngIf="checklistitem.type">
        Type:
        <div class="fill-space"></div>
        <div *ngIf="!edittype">
          {{ checklistitem.type.name }}
        </div>
        <div *ngIf="edittype && checklistitemtypes">
          <app-select
            label="Type"
            [selected]="checklistitem.type.id"
            (value)="onTypeSelectionChange($event)"
            [items]="checklistitemtypes"
          ></app-select>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px;"
            class="cursor"
            [matTooltip]="edittype ? 'Avbryt endring' : 'Endre type'"
            (click)="toggleEditType()"
          >{{ edittype ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>

      <mat-divider [inset]="true"></mat-divider>

      <div class="row">
        Statusnivå:
        <div class="fill-space"></div>
        <div *ngIf="!editStatusLevel">
          {{ checklistitem.statusLevel?.name }}
        </div>
        <div *ngIf="editStatusLevel && objectStatusLevels">
          <app-select
            label="Statusnivå"
            [selected]="checklistitem.statusLevel?.id"
            (value)="onStatusLevelSelectionChange($event)"
            [items]="objectStatusLevels"
          ></app-select>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px;"
            class="cursor"
            [matTooltip]="editStatusLevel ? 'Avbryt endring' : 'Endre statusnivå'"
            (click)="toggleEditStatusLevel()"
          >{{ editStatusLevel ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>

      <mat-divider [inset]="true"></mat-divider>

      <div class="row">
        Enhet:
        <div class="fill-space"></div>
        <div *ngIf="!editunit && checklistitem.unit">
          {{ checklistitem.unit }}
        </div>
        <div *ngIf="editunit">
          <form [formGroup]="FormUnit">
            <div class="row">
              <mat-form-field>
                <input
                  cdkFocusInitial
                  matInput
                  id="unit"
                  type="unit"
                  placeholder="Tekst"
                  formControlName="unit"
                />
                <mat-error *ngIf="unit.touched && unit.invalid">
                  <div *ngIf="!unit.errors.required">
                    Please enter a checklistitem unit
                  </div>
                  <div *ngIf="unit.errors.required">
                    Checklistitem unit is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
              <button
                mat-icon-button
                [disabled]="FormUnit.invalid"
                *ngIf="editunit"
                style="margin-left: 10px;"
                class="cursor"
                matTooltip="Lagre endring"
                (click)="saveUnit(unit.value)"
              >
                <mat-icon> save</mat-icon>
              </button>
            </div>
          </form>
        </div>
        <div>
          <mat-icon
            style="margin-left: 10px;"
            class="cursor"
            [matTooltip]="editunit ? 'Avbryt endring' : 'Endre enhet'"
            (click)="toggleEditUnit()"
          >{{ editunit ? "cancel" : "edit" }}
          </mat-icon>
        </div>
      </div>
      <!-- <div class="row">
        Tekst:
        <div class="fill-space"></div>
        <div>{{ checklistitem.text }}</div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        Type:
        <div class="fill-space"></div>
        <div>{{ checklistitem.type }}</div>
      </div> -->
    </mat-card>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>
