<app-headertext
  text="Sammenlign Excel filer"
  [hideback]="true"
></app-headertext>

<div class="row">
  <div class="fill-space"></div>
  <div class="maxwidth">
    <div class="row child-margin">
      <!-- <div class="fill-space"></div> -->
      <div class="maxwidth">
        <button
          mat-raised-button
          (click)="uploadExcelFile1()"
          style="width: 100%"
          class="header-row-button"
        >
          <mat-icon>calculate</mat-icon>
          <span style="margin-left: 10px">Fil 1</span>
        </button>
        <div *ngIf="file1name && file1dataoriginallength">{{ file1name }}</div>
        <div *ngIf="file1name && file1dataoriginallength">
          {{ file1dataoriginallength }} rader
        </div>
        <div *ngIf="!file1name && !file1dataoriginallength && filespinner === 1">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </div>
      <div class="maxwidth">
        <button
          mat-raised-button
          (click)="uploadExcelFile2()"
          style="width: 100%"
          class="header-row-button"
        >
          <mat-icon>calculate</mat-icon>
          <span style="margin-left: 10px">Fil 2</span>
        </button>
        <div *ngIf="file2name && file2dataoriginallength">{{ file2name }}</div>
        <div *ngIf="file2name && file2dataoriginallength">
          {{ file2dataoriginallength }} rader
        </div>
        <div *ngIf="!file2name && !file2dataoriginallength && filespinner === 2">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </div>
      <!-- <div class="fill-space"></div> -->
    </div>
    <div class="row child-margin">
      <!-- <div class="fill-space"></div> -->
      <div class="maxwidth">
        <mat-card>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon style="font-size: 40px; opacity: 0.7">info</mat-icon>
            </div>
            <mat-card-subtitle style="font-size: small"
              >Sammenligning vil merke rader som ikke finnes i det andre arket
              som enten fjernet fra fil 1 eller lagt til i fil 2
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
        <button
          mat-raised-button
          *ngIf="
            file1name &&
            file1dataoriginallength &&
            file2name &&
            file2dataoriginallength
          "
          (click)="compare()"
          style="width: 100%"
          class="header-row-button"
        >
          <mat-icon>compare_arrows</mat-icon>
          <span style="margin-left: 10px">Sammenlign</span>
        </button>
        <div>
          <span *ngIf="diff1notin2.length > 0">
            {{ diff1notin2.length }} fjernet
          </span>
          <span *ngIf="diff2notin1.length > 0 && diff1notin2.length > 0">
            /
          </span>
          <span *ngIf="diff2notin1.length > 0">
            {{ diff2notin1.length }} lagt til
          </span>
        </div>
      </div>
      <!-- <div class="fill-space"></div> -->
    </div>
  </div>
  <div class="fill-space"></div>
</div>

<div *ngIf="comparefiles">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<div class="row" *ngIf="tableready">
  <div class="fill-space"></div>
  <!-- <div class="maxwidth"> -->
  <div class="child-margin">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Forskjeller" class="child-margin">
        <div class="row">
          <div class="fill-space"></div>
          <div>
            <button
              [disabled]="diff1and2.length === 0"
              mat-raised-button
              (click)="downloadTable('tablediff')"
              style="width: 100%"
              *ngIf="tableready"
              class="header-row-button"
            >
              <mat-icon>download</mat-icon>
              <span style="margin-left: 10px">Last ned</span>
            </button>
          </div>
        </div>
        <div *ngIf="diff1and2.length === 0">Ingen endringer</div>
        <table
          *ngIf="diff1and2.length > 0"
          style="width: 100%; font-size: x-small"
          #tablediff
        >
          <tr style="background-color: #424242; height: 30px">
            <th
              style="padding: 5px"
              *ngFor="let head of diff1and2[0] | keys"
              (click)="sortTable(head, tablediff)"
            >
              {{ head }}
            </th>
          </tr>
          <tr *ngFor="let item of diff1and2; let islast = last">
            <td
            style="padding: 5px"
              *ngFor="let list of item | keys"
              [style.background-color]="
                item.status === 'removed'
                  ? 'rgba(167, 76, 46, 0.46)'
                  : 'darkslategray'
              "
              [style.border-bottom]="islast ? '' : '1px solid #666'"
            >
              {{ item[list] }}
            </td>
          </tr>
        </table>
      </mat-tab>
      <mat-tab label="Fil 1" class="child-margin">
        <div class="row">
          <div class="fill-space"></div>
          <div>
            <button
              mat-raised-button
              (click)="downloadTable('table1')"
              style="width: 100%"
              *ngIf="tableready"
              class="header-row-button"
            >
              <mat-icon>download</mat-icon>
              <span style="margin-left: 10px">Last ned</span>
            </button>
          </div>
        </div>
        <table style="width: 100%; font-size: x-small" #table1>
          <tr style="background-color: #424242; height: 30px">
            <th
              style="padding: 5px"
              *ngFor="let head of tabledata1[0] | keys"
              (click)="sortTable(head, table1)"
            >
              {{ head }}
            </th>
          </tr>
          <tr
            *ngFor="let item of tabledata1; let islast = last"
            [style.background-color]="
              item.status === 'removed' ? 'rgba(167, 76, 46, 0.46)' : '#303030'
            "
          >
            <td
            style="padding: 5px"
              *ngFor="let list of item | keys"
              [style.border-bottom]="islast ? '' : '1px solid #666'"
            >
              {{ item[list] }}
            </td>
          </tr>
        </table>
      </mat-tab>
      <mat-tab label="Fil 2" class="child-margin">
        <div class="row">
          <div class="fill-space"></div>
          <div>
            <button
              mat-raised-button
              (click)="downloadTable('table2')"
              style="width: 100%"
              *ngIf="tableready"
              class="header-row-button"
            >
              <mat-icon>download</mat-icon>
              <span style="margin-left: 10px">Last ned</span>
            </button>
          </div>
        </div>
        <table style="width: 100%; font-size: x-small" #table2>
          <tr style="background-color: #424242; height: 30px">
            <th
              style="padding: 5px"
              *ngFor="let head of tabledata2[0] | keys"
              (click)="sortTable(head, table2)"
            >
              {{ head }}
            </th>
          </tr>
          <tr
            *ngFor="let item of tabledata2; let islast = last"
            [style.background-color]="
              item.status === 'added' ? 'darkslategray' : '#303030'
            "
          >
            <td
            style="padding: 5px"
              *ngFor="let list of item | keys"
              [style.border-bottom]="islast ? '' : '1px solid #666'"
            >
              {{ item[list] }}
            </td>
          </tr>
        </table>
      </mat-tab>
      <mat-tab label="Full" class="child-margin">
        <div class="row">
          <div class="fill-space"></div>
          <div>
            <button
              mat-raised-button
              (click)="downloadTable('tablefull')"
              style="width: 100%"
              *ngIf="tableready"
              class="header-row-button"
            >
              <mat-icon>download</mat-icon>
              <span style="margin-left: 10px">Last ned</span>
            </button>
          </div>
        </div>
        <table style="width: 100%; font-size: x-small" #table2>
          <tr style="background-color: #424242; height: 30px">
            <th
              style="padding: 5px"
              *ngFor="let head of tabledatafull[0] | keys"
              (click)="sortTable(head, table2)"
            >
              {{ head }}
            </th>
          </tr>
          <tr
            *ngFor="let item of tabledatafull; let islast = last"
            [style.background-color]="
              item.status === 'added' ? 'darkslategray' : '#303030'
            "
            [style.background-color]="
              item.status === 'removed'
                ? 'rgba(167, 76, 46, 0.46)'
                : item.status === 'added'
                ? 'darkslategray'
                : '#303030'
            "
          >
            <td
            style="padding: 5px"
              *ngFor="let list of item | keys"
              [style.border-bottom]="islast ? '' : '1px solid #666'"
            >
              {{ item[list] }}
            </td>
          </tr>
        </table>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="fill-space"></div>
</div>

<input
  hidden
  type="file"
  accept=".xlsx"
  id="excelinput"
  appReadexcel
  #fileNameInput
  (change)="setFilename($event.target.files, fileNameInput)"
  (eventEmitter)="dataFromExcelEventEmitter($event)"
/>
