<app-headertext text="Bytt passord" hideback="true"></app-headertext>
<form
  [formGroup]="Form"
  (ngSubmit)="changePassword(oldPass.value, newPass.value)"
>
  <div class="form-group">
    <!-- <p>
      <mat-form-field>
        <input
          matInput
          id="id"
          type="number"
          placeholder="ID"
          formControlName="id"
        />
        <mat-error *ngIf="id.touched && id.invalid">
          <div *ngIf="!id.errors.required">
            Please enter an ID
          </div>
          <div *ngIf="id.errors.required">
            Objecttype ID is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p> -->
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="oldPass"
          type="password"
          placeholder="Gammelt passord"
          formControlName="oldPass"
        />
        <mat-error *ngIf="oldPass.touched && oldPass.invalid">
          <!-- <div
            *ngIf="!oldPass.errors.required && !oldPass.hasError('minLength')"
          >
            Please enter a password
          </div> -->
          <div *ngIf="oldPass.errors.required">
            Old password is <strong>required</strong>
          </div>
          <div
            *ngIf="!oldPass.hasError('minLength') && !oldPass.errors.required"
          >
            Password must be at least {{ passwordMinLength }} characters long
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          cdkFocusInitial
          matInput
          id="newPass"
          type="password"
          placeholder="Nytt passord"
          formControlName="newPass"
        />
        <mat-error *ngIf="newPass.touched && newPass.invalid">
          <!-- <div
            *ngIf="!newPass.errors.required && !newPass.hasError('minLength')"
          >
            Please enter a password
          </div> -->
          <div *ngIf="newPass.errors.required">
            New password is <strong>required</strong>
          </div>
          <div
            *ngIf="!newPass.hasError('minLength') && !newPass.errors.required"
          >
            Password must be at least {{ passwordMinLength }} characters long
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
        <span *ngIf="!inProgress">Endre</span>
        <span *ngIf="inProgress"
          ><mat-spinner diameter="30"></mat-spinner
        ></span>
      </button>
    </p>
    <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
  </div>
</form>
