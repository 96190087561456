<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div *ngIf="!isLoading">
  <h3>Din appversjon: v.{{ userVersionstring }}</h3>
  <div *ngFor="let subversion of changesSorted; first as isFirstMain">

    <mat-expansion-panel
      [expanded]="isFirstMain ? true : false"
      style="background-color: #272727"
    >
      <mat-expansion-panel-header
        id="mainheader"
        style="background-color: #272727"
      >
        <mat-panel-title>
          <h2>v.{{ subversion.mainversion }}.{{ subversion.subversion }}</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        <mat-expansion-panel
          *ngFor="let change of subversion.subversions; first as isFirstSub"
          [expanded]="(change.version === userVersionstring || isFirstSub && isFirstMain) ? true : expanded"
        >
          <mat-expansion-panel-header
            [style.background-color]="change.beta ? '#443400' : ''"
          >
            <mat-panel-title>
              <div>
                <div
                  [style.font-weight]="
                    change.version === userVersionstring ? 'bold' : null
                  "
                >
                  v.{{ change.version }}
                </div>
                <div
                  *ngIf="change.version === userVersionstring"
                  style="font-size: smaller"
                >
                  Gjeldende
                </div>
              </div>
              <!-- <span style="color: darkgray; margin-left: 5px; font-size: smaller;" *ngIf="user.superAdmin"> ({{ change.id }})</span> -->
            </mat-panel-title>
            <mat-panel-description>
              {{ change.summary }}
              <mat-chip-list style="margin-left: 10px">
                <mat-chip color="accent" selected *ngIf="change.beta"
                  >BETA</mat-chip
                >
              </mat-chip-list>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <p *ngFor="let item of change.changeItems">- {{ item }}</p>
          <div class="row">
            <div class="fill-space"></div>
            <button
              mat-icon-button
              *ngIf="user.superAdmin && change.id"
              (click)="editVersion(change)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </p>
    </mat-expansion-panel>
  </div>
</div>
