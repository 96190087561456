import {DatePipe, NgOptimizedImage} from '@angular/common';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFirePerformanceModule, PerformanceMonitoringService} from '@angular/fire/compat/performance';

import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {environment} from 'src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule} from '@angular/material/legacy-chips';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {AppComponent} from './app.component';
import {AdminComponent} from './components/admin/admin.component';
import {ChangelogComponent} from './components/admin/changelog/changelog.component';
import {ChecklistitemComponent} from './components/admin/checklistitems/checklistitem/checklistitem.component';
import {ChecklistitemsListComponent} from './components/admin/checklistitems/checklistitems-list/checklistitems-list.component';
import {ChecklistitemsComponent} from './components/admin/checklistitems/checklistitems.component';
import {NewChecklistitemComponent} from './components/admin/checklistitems/new-checklistitem/new-checklistitem.component';
import {NewObjecttypeComponent} from './components/admin/objecttypes/new-objecttype/new-objecttype.component';
import {ObjecttypeComponent} from './components/admin/objecttypes/objecttype/objecttype.component';
import {ObjecttypesComponent} from './components/admin/objecttypes/objecttypes.component';
import {ProjectComponent} from './components/projects/project/project.component';
import {NewUserComponent} from './components/admin/users/new-user/new-user.component';
import {UserComponent} from './components/admin/users/user/user.component';
import {UsersComponent} from './components/admin/users/users.component';
import {ChecklistItemComponent} from './components/checklists/checklist-item/checklist-item.component';
import {ChecklistListItemComponent} from './components/checklists/checklist-list-item/checklist-list-item.component';
import {ChecklistListComponent} from './components/checklists/checklist-list/checklist-list.component';
import {ChecklistOverviewComponent} from './components/checklists/checklist-overview/checklist-overview.component';
import {ChecklistComponent} from './components/checklists/checklist/checklist.component';
import {ChecklistsComponent} from './components/checklists/checklists.component';
import {AddToHomescreenComponent} from './components/common/add-to-homescreen/add-to-homescreen.component';
import {AventibackgroundComponent} from './components/common/aventibackground/aventibackground.component';
import {AventilogoComponent} from './components/common/aventilogo/aventilogo.component';
import {ConfirmComponent} from './components/common/bottom-sheet/confirm/confirm.component';
import {BackButtonComponent} from './components/common/buttons/back-button/back-button.component';
import {CloseButtonComponent} from './components/common/buttons/close-button/close-button.component';
import {NewChecklistButtonComponent} from './components/common/buttons/new-checklist-button/new-checklist-button.component';
import {DomainDetectionIconComponent} from './components/common/domain-detection-icon/domain-detection-icon.component';
import {EmptyListComponent} from './components/common/empty-list/empty-list.component';
import {PreviewComponent} from './components/common/file/preview/preview.component';
import {ThumbnailComponent} from './components/common/file/thumbnail/thumbnail.component';
import {UploadButtonComponent} from './components/common/file/upload-button/upload-button.component';
import {HeadertextComponent} from './components/common/headertext/headertext.component';
import {OfflineDetectionComponent} from './components/common/offline-detection/offline-detection.component';
import {SelectComponent} from './components/common/select/select.component';
import {SignedInitialsComponent} from './components/common/signed-initials/signed-initials.component';
import {SignedComponent} from './components/common/signed/signed.component';
import {SpinnerComponent} from './components/common/spinner/spinner.component';
import {VersionDetectionIconComponent} from './components/common/version-detection-icon/version-detection-icon.component';
import {VersionComponent} from './components/common/version/version.component';
import {MainComponent} from './components/main/main.component';
import {ChangePasswordComponent} from './components/mypage/change-password/change-password.component';
import {MypageComponent} from './components/mypage/mypage.component';
import {AuthenticationComponent} from './components/navigation/authentication/authentication.component';
import {ContentComponent} from './components/navigation/content/content.component';
import {HeaderComponent} from './components/navigation/header/header.component';
import {PageNotFoundComponent} from './components/navigation/page-not-found/page-not-found.component';
import {RefreshComponent} from './components/navigation/refresh/refresh.component';
import {SidenavComponent} from './components/navigation/sidenav/sidenav.component';
import {SidenavitemComponent} from './components/navigation/sidenavitem/sidenavitem.component';
import {SidenavitemtextComponent} from './components/navigation/sidenavitem/sidenavitemtext/sidenavitemtext.component';
import {NewObjectComponent} from './components/objects/new-object/new-object.component';
import {ObjectListComponent} from './components/objects/object-list/object-list.component';
import {ObjectComponent} from './components/objects/object/object.component';
import {ObjectsComponent} from './components/objects/objects.component';
import {ReceiptformComponent} from './components/objects/receiptform/receiptform.component';
import {InvalidProjectComponent} from './components/popups/invalid-project/invalid-project.component';
import {ProjectListComponent} from './components/projects/project-list/project-list.component';
import {ProjectsComponent} from './components/projects/projects.component';
// pipes
import {DownloadUrlPipe} from './pipes/download-url.pipe';
import {RemoveExtension, ReturnExtension} from './pipes/file-extension.pipe';
import {FileSizePipe} from './pipes/file-size.pipe';
import {FirestorePipe} from './pipes/firestore.pipe';
import {ReceiptformsComponent} from './components/receiptforms/receiptforms.component';
import {NewProjectComponent} from './components/projects/new-project/new-project.component';
import {LogoutComponent} from './components/popups/logout/logout.component';
import {ChangelogListComponent} from './components/admin/changelog/changelog-list/changelog-list.component';
import {ChangelogPopupComponent} from './components/popups/changelog-popup/changelog-popup.component';
import {NewChangelogItemComponent} from './components/admin/changelog/new-changelog-item/new-changelog-item.component';
import {ChangelogItemComponent} from './components/admin/changelog/changelog-item/changelog-item.component';
import {NewReceiptformPopupComponent} from './components/popups/new-receiptform-popup/new-receiptform-popup.component';
import {NotificationsComponent} from './components/admin/notifications/notifications.component';
import {FilesComponent} from './components/files/files.component';
import {PositionComponent} from './components/popups/consent/position/position.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {LandingpageComponent} from './components/projects/landingpage/landingpage.component';

import {MatGridListModule} from '@angular/material/grid-list';
import {PartialMapComponent} from './components/map/partial-map/partial-map.component';
import {MapComponent} from './components/map/map.component';
import {UpdateComponent} from './components/popups/update/update.component';
import {DocumentsComponent} from './components/documents/documents.component';
import {ReadexcelDirective} from './directives/readexcel.directive';
import {NewObjectsMultipleComponent} from './components/objects/new-objects-multiple/new-objects-multiple.component';
import {ObjectService} from './services/object.service';
import {ObjectchecklistsComponent} from './components/checklists/objectchecklists/objectchecklists.component';
import {DeviationsComponent} from './components/deviations/deviations.component';
import {DeviationsListComponent} from './components/deviations/deviations-list/deviations-list.component';
import {DeviationComponent} from './components/deviations/deviation/deviation.component';
import {DeviationSignComponent} from './components/deviations/deviation-sign/deviation-sign.component';
import {CompareExcelComponent} from './components/common/compare/compare-excel/compare-excel.component';
import {CompareComponent} from './components/common/compare/compare.component';
import {KeysPipe} from './pipes/keys.pipe';
import {ServiceWorkerDetectionComponent} from './components/common/service-worker-detection/service-worker-detection.component';
import {NotAvailableWhenOfflineComponent} from './components/common/not-available-when-offline/not-available-when-offline.component';
import {DeviceLocationDetectionComponent} from './components/common/device-location-detection/device-location-detection.component';
import {CachedIndicatorComponent} from './components/common/cached-indicator/cached-indicator.component';
import { ResetPasswordComponent } from './components/navigation/authentication/reset-password/reset-password.component';
import { PasswordErrorComponent } from './components/navigation/authentication/password-error/password-error.component';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {TagModule} from 'primeng/tag';
import {CheckboxModule} from 'primeng/checkbox';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {BadgeModule} from 'primeng/badge';
import {RippleModule} from 'primeng/ripple';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {AccordionModule} from 'primeng/accordion';
import {DataViewModule} from 'primeng/dataview';
import { PdfButtonComponent } from './components/common/buttons/pdf-button/pdf-button.component';
import {SkeletonModule} from 'primeng/skeleton';
import { StatusesComponent } from './components/admin/statuses/statuses.component';
import { NewObjectstatusComponent } from './components/admin/statuses/new-objectstatus/new-objectstatus.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ChartModule} from 'primeng/chart';
import {ChipModule} from 'primeng/chip';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import {TreeTableModule} from 'primeng/treetable';

@NgModule({
  declarations: [
    AppComponent,
    ChecklistComponent,
    ChecklistItemComponent,
    SidenavComponent,
    SidenavitemComponent,
    ContentComponent,
    MainComponent,
    ProjectComponent,
    MypageComponent,
    HeaderComponent,
    PageNotFoundComponent,
    InvalidProjectComponent,
    ChecklistsComponent,
    ChecklistOverviewComponent,
    ChecklistListComponent,
    ChecklistListItemComponent,
    ObjectsComponent,
    AuthenticationComponent,
    RefreshComponent,
    ObjectListComponent,
    NewObjectComponent,
    SpinnerComponent,
    ObjectComponent,
    ObjecttypesComponent,
    SelectComponent,
    AdminComponent,
    ChecklistitemsComponent,
    NewObjecttypeComponent,
    ObjecttypeComponent,
    SidenavitemtextComponent,
    BackButtonComponent,
    CloseButtonComponent,
    NewChecklistButtonComponent,
    ConfirmComponent,
    ChecklistitemsListComponent,
    ChecklistitemComponent,
    NewChecklistitemComponent,
    ChangePasswordComponent,
    EmptyListComponent,
    HeadertextComponent,
    OfflineDetectionComponent,
    AddToHomescreenComponent,
    SignedComponent,
    ProjectsComponent,
    ProjectListComponent,
    AventilogoComponent,
    AventibackgroundComponent,
    VersionComponent,
    SignedInitialsComponent,
    UploadButtonComponent,
    PreviewComponent,
    ThumbnailComponent,
    FileSizePipe,
    ReturnExtension,
    RemoveExtension,
    DownloadUrlPipe,
    ChangelogComponent,
    VersionDetectionIconComponent,
    DomainDetectionIconComponent,
    UsersComponent,
    UserComponent,
    NewUserComponent,
    FirestorePipe,
    ReceiptformComponent,
    ReceiptformsComponent,
    NewProjectComponent,
    LogoutComponent,
    ChangelogListComponent,
    ChangelogPopupComponent,
    NewChangelogItemComponent,
    ChangelogItemComponent,
    NewReceiptformPopupComponent,
    NotificationsComponent,
    FilesComponent,
    PositionComponent,
    MapComponent,
    LandingpageComponent,
    PartialMapComponent,
    UpdateComponent,
    DocumentsComponent,
    ReadexcelDirective,
    NewObjectsMultipleComponent,
    ObjectchecklistsComponent,
    DeviationsComponent,
    DeviationsListComponent,
    DeviationComponent,
    DeviationSignComponent,
    CompareExcelComponent,
    CompareComponent,
    KeysPipe,
    ServiceWorkerDetectionComponent,
    NotAvailableWhenOfflineComponent,
    DeviceLocationDetectionComponent,
    CachedIndicatorComponent,
    ResetPasswordComponent,
    PasswordErrorComponent,
    PdfButtonComponent,
    StatusesComponent,
    NewObjectstatusComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule,
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence({
      experimentalForceOwningTab: true,
      synchronizeTabs: false
    }),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatListModule,
    MatToolbarModule,
    MatExpansionModule,
    MatTreeModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSlideToggleModule,
    GoogleMapsModule,
    MatGridListModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    NgxExtendedPdfViewerModule,
    NgOptimizedImage,
    TableModule,
    ButtonModule,
    TagModule,
    CheckboxModule,
    AutoCompleteModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    BadgeModule,
    RippleModule,
    OverlayPanelModule,
    AccordionModule,
    DataViewModule,
    SkeletonModule,
    InputSwitchModule,
    ChartModule,
    ChipModule,
    MatChipsModule,
    MatLegacyChipsModule,
    MatBadgeModule,
    TreeTableModule,
  ],
  providers: [
    DatePipe,
    PerformanceMonitoringService,
    ObjectService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

