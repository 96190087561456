<!-- <div
  *ngIf="(isLoadingUser || isLoadingObjects || isLoadingChecklists || isLoadingChecklistItems || isLoadingChecklistPDFs || isLoadingChecklistFiles) && LoadingText && LoadingHeader"
> -->
<!-- <div
  *ngIf="(isLoadingUser || isLoadingObjects || isLoadingChecklists) && LoadingText && LoadingHeader"
>
  <app-spinner [text]="LoadingText" [header]="LoadingHeader"></app-spinner>

</div> -->
<div
  *ngIf="isLoadingUser && LoadingText && LoadingHeader"
>
  <app-spinner [text]="LoadingText" [header]="LoadingHeader"></app-spinner>

</div>

<!-- <div
  *ngIf="!isLoadingUser && !isLoadingObjects && !isLoadingChecklists && !isLoadingChecklistItems && !isLoadingChecklistPDFs && !isLoadingChecklistFiles"> -->
  <!-- <div
  *ngIf="!isLoadingUser && !isLoadingObjects && !isLoadingChecklists"> -->
  <div
    *ngIf="!isLoadingUser">
  <app-header [project]="project" *ngIf="!mobileQuery.matches"></app-header>
  <router-outlet></router-outlet>
</div>

<!--
  <app-header [project]="project"></app-header>
  <router-outlet></router-outlet> -->
