<app-spinner
  disable="false"
  [text]="['Laster inn objekt...']"
  header="Vennligst vent..."
  *ngIf="isLoading"
></app-spinner>

<div class="row">
  <app-headertext text="Objekt"></app-headertext>
  <div class="fill-space"></div>
  <button
    *ngIf="isAdmin"
    mat-button
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span>Admin</span>
  </button>
  <mat-menu #menu="matMenu">
    <div class="row">
      <button
        mat-button
        (click)="copyFileLocation()"
        class="menu-row-button"
        *ngIf="isAdmin && object"
      >
        <mat-icon color="accent">content_copy</mat-icon>
        Kopiér filplassering
      </button>
    </div>
    <div
      class="row"
      [matTooltip]="
        checklistsSigned
          ? 'Kan ikke slette objekt med signert sjekkliste'
          : null
      "
      matTooltipPosition="left"
    >
      <button
        *ngIf="isAdmin && object && !isLoading"
        mat-button
        (click)="deleteObjectConfirm()"
        class="menu-row-button"
        [disabled]="checklistsSigned"
      >
        <mat-icon color="warn">delete</mat-icon>
        Slett objekt
      </button>
    </div>
  </mat-menu>
</div>

<div style="margin: 0 15px 0 15px">
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
</div>
<div *ngIf="object && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth">
      <div class="row">
        <h2>{{ object.name }}</h2>
        <div class="fill-space"></div>
        <div class="child-margin">
          <p-tag
            [style]="{'margin-top': '20px'}"
            [hidden]="!object.status?.name || !project.useStatusLevel" [value]="user.language?.id === 'en' ? object.status?.enName : object.status?.name"
            [severity]="object.status?.name === 'Signert' ? 'success' : object.status?.priority === '1' ? 'warning' : 'info'"
          ></p-tag>
        </div>
      </div>
      <mat-card class="child-margin">
        <div class="row">
          Navn:
          <div class="fill-space"></div>
          <div *ngIf="!editname && object.name">
            {{ object.name }}
          </div>
          <div *ngIf="editname">
            <form [formGroup]="Form">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="name"
                    type="name"
                    placeholder="Navn"
                    formControlName="name"
                    (keyup.enter)="saveName(name.value)"
                  />
                  <mat-error *ngIf="name.touched && name.invalid">
                    <div *ngIf="!name.errors.required">
                      Please enter a object name
                    </div>
                    <div *ngIf="name.errors.required">
                      Object name is <strong>required</strong>
                    </div>
                  </mat-error>
                </mat-form-field>
                <button
                  type="button"
                  mat-icon-button
                  [disabled]="Form.invalid"
                  *ngIf="editname"
                  style="padding-left: 10px; margin-top: -10px"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveName(name.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              mat-icon-button
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="editname ? 'Avbryt endring' : 'Endre tekst'"
              (click)="toggleEditName()"
            >
              {{ editname ? "cancel" : "edit" }}
            </mat-icon
            >
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <!-- <div class="row">
            Navn:
            <div class="fill-space"></div>
            <div>{{ object.name }}</div>
          </div>
          <mat-divider [inset]="true"></mat-divider> -->
        <div class="row">
          Type:
          <div class="fill-space"></div>
          <div *ngIf="!editObjecttype && object.type">
            {{ object.type.name }}
          </div>
          <div *ngIf="editObjecttype && isAdmin">
            <app-select
              label="Objekttype"
              [selected]="object.type ? object.type.id : ''"
              (value)="onSelectionChange($event)"
              [items]="objecttypes"
            ></app-select>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="
                editObjecttype ? 'Avbryt endring' : 'Endre objekttype'
              "
              (click)="toggleEditObjecttype()"
            >{{ editObjecttype ? "cancel" : "edit" }}
            </mat-icon>
          </div>
        </div>
        <!-- <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              Nummer:
              <div class="fill-space"></div>
              <div>{{ object.no }}</div>
            </div> -->
        <!-- <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              Skap:
              <div class="fill-space"></div>
              <div>{{ object.cabinet }}</div>
            </div> -->
        <!-- <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              Prosess:
              <div class="fill-space"></div>
              <div>{{ object.process }}</div>
            </div> -->
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Beskrivelse:
          <div class="fill-space"></div>
          <div *ngIf="!editdesc && object.desc" style="text-align: end">
            {{ object.desc }}
          </div>
          <div *ngIf="editdesc">
            <form [formGroup]="FormDesc">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="desc"
                    type="desc"
                    placeholder="Beskrivelse"
                    formControlName="desc"
                    (keyup.enter)="saveDesc(desc.value)"
                  />
                  <mat-error *ngIf="desc.touched && desc.invalid">
                    <div *ngIf="!desc.errors.required">
                      Please enter an object description
                    </div>
                    <!-- <div *ngIf="name.errors.required">
                    Object description is <strong>required</strong>
                  </div> -->
                  </mat-error>
                </mat-form-field>
                <button
                  type="button"
                  mat-icon-button
                  [disabled]="FormDesc.invalid"
                  *ngIf="editdesc"
                  style="padding-left: 10px; margin-top: -10px"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveDesc(desc.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              mat-icon-button
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="editdesc ? 'Avbryt endring' : 'Endre beskrivelse'"
              (click)="toggleEditDesc()"
            >
              {{ editdesc ? "cancel" : "edit" }}
            </mat-icon
            >
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Profilnummer:
          <div class="fill-space"></div>
          <div *ngIf="!editprofilenumber && object.profilenumber">
            {{ object.profilenumber }}
          </div>
          <div *ngIf="editprofilenumber">
            <form [formGroup]="FormProfilenumber">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="profilenumber"
                    type="profilenumber"
                    placeholder="Profilnummer"
                    formControlName="profilenumber"
                    (keyup.enter)="saveProfilenumber(profilenumber.value)"
                  />
                  <mat-error
                    *ngIf="profilenumber.touched && profilenumber.invalid"
                  >
                    <div *ngIf="!profilenumber.errors.required">
                      Please enter an object profilenumber
                    </div>
                    <!-- <div *ngIf="name.errors.required">
                    Object profilenumberription is <strong>required</strong>
                  </div> -->
                  </mat-error>
                </mat-form-field>
                <button
                  type="button"
                  mat-icon-button
                  [disabled]="FormProfilenumber.invalid"
                  *ngIf="editprofilenumber"
                  style="padding-left: 10px; margin-top: -10px"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveProfilenumber(profilenumber.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              mat-icon-button
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="
                editprofilenumber ? 'Avbryt endring' : 'Endre profilnummer'
              "
              (click)="toggleEditProfilenumber()"
            >
              {{ editprofilenumber ? "cancel" : "edit" }}
            </mat-icon
            >
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Prosessnummer:
          <div class="fill-space"></div>
          <div *ngIf="!editprocess && object.process">
            {{ object.process }}
          </div>
          <div *ngIf="editprocess">
            <form [formGroup]="FormProcess">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="process"
                    type="process"
                    placeholder="Prosessnummer"
                    formControlName="process"
                    (keyup.enter)="saveProcess(process.value)"
                  />
                  <mat-error *ngIf="process.touched && process.invalid">
                    <div *ngIf="!process.errors.required">
                      Please enter an object process
                    </div>
                    <!-- <div *ngIf="name.errors.required">
                    Object processription is <strong>required</strong>
                  </div> -->
                  </mat-error>
                </mat-form-field>
                <button
                  type="button"
                  mat-icon-button
                  [disabled]="FormProcess.invalid"
                  *ngIf="editprocess"
                  style="padding-left: 10px; margin-top: -10px"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveProcess(process.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              mat-icon-button
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="
                editprocess ? 'Avbryt endring' : 'Endre Prosessnummer'
              "
              (click)="toggleEditProcess()"
            >
              {{ editprocess ? "cancel" : "edit" }}
            </mat-icon
            >
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Tegning:
          <div class="fill-space"></div>
          <div *ngIf="!editdrawing && object.drawing">
            {{ object.drawing }}
          </div>
          <div *ngIf="editdrawing">
            <form [formGroup]="FormDrawing">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="drawing"
                    type="drawing"
                    placeholder="Tegningsreferanse"
                    formControlName="drawing"
                    (keyup.enter)="saveDrawing(drawing.value)"
                  />
                  <mat-error *ngIf="drawing.touched && drawing.invalid">
                    <div *ngIf="!drawing.errors.required">
                      Please enter an object drawing
                    </div>
                    <!-- <div *ngIf="name.errors.required">
                    Object drawingription is <strong>required</strong>
                  </div> -->
                  </mat-error>
                </mat-form-field>
                <button
                  type="button"
                  mat-icon-button
                  [disabled]="FormDrawing.invalid"
                  *ngIf="editdrawing"
                  style="padding-left: 10px; margin-top: -10px"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveDrawing(drawing.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              mat-icon-button
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="
                editdrawing ? 'Avbryt endring' : 'Endre Tegning'
              "
              (click)="toggleEditDrawing()"
            >
              {{ editdrawing ? "cancel" : "edit" }}
            </mat-icon
            >
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          Kommentar:
          <div class="fill-space"></div>
          <div *ngIf="!editcomment && object.comment">
            {{ object.comment }}
          </div>
          <div *ngIf="editcomment">
            <form [formGroup]="FormComment">
              <div class="row">
                <mat-form-field>
                  <input
                    cdkFocusInitial
                    matInput
                    id="comment"
                    type="comment"
                    placeholder="Kommentar"
                    formControlName="comment"
                    (keyup.enter)="saveComment(comment.value)"
                  />
                  <mat-error *ngIf="comment.touched && comment.invalid">
                    <div *ngIf="!comment.errors.required">
                      Please enter an object comment
                    </div>
                    <!-- <div *ngIf="name.errors.required">
                    Object commentription is <strong>required</strong>
                  </div> -->
                  </mat-error>
                </mat-form-field>
                <button
                  type="button"
                  mat-icon-button
                  [disabled]="FormComment.invalid"
                  *ngIf="editcomment"
                  style="padding-left: 10px; margin-top: -10px"
                  class="cursor"
                  matTooltip="Lagre endring"
                  (click)="saveComment(comment.value)"
                >
                  <mat-icon> save</mat-icon>
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="isAdmin">
            <mat-icon
              mat-icon-button
              style="padding-left: 10px"
              class="cursor"
              [matTooltip]="
                editcomment ? 'Avbryt endring' : 'Endre Kommentar'
              "
              (click)="toggleEditComment()"
            >
              {{ editcomment ? "cancel" : "edit" }}
            </mat-icon
            >
          </div>
        </div>
      </mat-card>
      <div class="row">
        <div>
          <h2>
            <mat-icon
              style="
                vertical-align: bottom;
                margin-bottom: 3px;
                margin-right: 10px;
              "
            >sell
            </mat-icon
            >
            Tags
          </h2>
        </div>
        <div class="fill-space"></div>
        <div style="position: relative">
          <button
            type="button"
            mat-button
            style="margin: 5px; position: absolute; bottom: 0; right: 0"
            class="cursor"
            matTooltip="Ny tag"
            matTooltipPosition="left"
            (click)="newTag()"
            a
          >
            <mat-icon color="primary">add</mat-icon>
            Ny tag
          </button>
        </div>
      </div>
      <mat-card class="child-margin">
        <div class="row">
          <div class="fill-space"></div>
          <div>
            <!-- <mat-spinner
              diameter="50"
              style="margin: 32px"
              *ngIf="!object.tags"
            ></mat-spinner> -->
            <app-empty-list
              message="Ingen tags"
              *ngIf="!object.tags || object.tags?.length === 0"
            ></app-empty-list>
          </div>
          <div class="fill-space"></div>
        </div>
        <div *ngFor="let tag of object.tags; let i = index; let islast = last">
          <div class="row">
            <div *ngIf="!edittags[i]">{{ tag.key }}:</div>
            <div class="fill-space" *ngIf="!edittags[i]"></div>
            <div *ngIf="object.tags">
              <div *ngIf="!edittags[i]">
                {{ tag.value }}
              </div>
              <!-- <table>
              <tr *ngFor="let tag of object.tags">
                <td>{{ tag.key }}</td>
                <td style="text-align: right">{{ tag.value }}</td>
              </tr>
            </table> -->
              <div *ngIf="edittags[i]">
                <div [class]="mobileQuery.matches ? 'row' : ''">
                  <div class="fill-space"></div>
                  <form [formGroup]="FormTags">
                    <div
                      [class]="mobileQuery.matches ? '' : 'row child-margin'"
                    >
                      <div class="fill-space"></div>
                      <div>
                        <mat-form-field>
                          <input
                            cdkFocusInitial
                            matInput
                            id="key"
                            type="key"
                            placeholder="Key"
                            formControlName="key"
                            (keyup.enter)="saveTags(key.value, value.value, i)"
                            style="margin-right: 10px"
                          />
                        </mat-form-field>
                      </div>
                      <div class="fill-space"></div>
                      <div>
                        <mat-form-field>
                          <input
                            cdkFocusInitial
                            matInput
                            id="value"
                            type="value"
                            placeholder="Value"
                            formControlName="value"
                            (keyup.enter)="saveTags(key.value, value.value, i)"
                          />
                          <!-- <mat-error *ngIf="tags.touched && tags.invalid">
                    <div *ngIf="!tags.errors.required">
                      Please enter object tags
                    </div>
                  </mat-error> -->
                        </mat-form-field>
                      </div>
                      <div
                        [class]="
                          mobileQuery.matches ? 'row child-margin' : 'row'
                        "
                      >
                        <div>
                          <mat-icon
                            mat-icon-button
                            style="padding-left: 10px"
                            class="cursor"
                            [matTooltip]="
                              edittags[i] ? 'Avbryt endring' : 'Endre Tags'
                            "
                            (click)="toggleEditTags(i)"
                          >
                            {{ edittags[i] ? "cancel" : "edit" }}
                          </mat-icon
                          >
                        </div>
                        <div>
                          <button
                            type="button"
                            mat-icon-button
                            [disabled]="FormTags.invalid"
                            *ngIf="edittags"
                            style="padding-left: 10px; margin-top: -10px"
                            class="cursor"
                            [matTooltip]="
                              key.value === '' && value.value === ''
                                ? 'Slett tag'
                                : 'Lagre endring'
                            "
                            (click)="saveTags(key.value, value.value, i)"
                          >
                            <mat-icon>{{
                                key.value === "" && value.value === ""
                                  ? "delete"
                                  : "save"
                              }}
                            </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div *ngIf="isAdmin && !edittags[i]">
              <mat-icon
                mat-icon-button
                style="padding-left: 10px"
                class="cursor"
                [matTooltip]="edittags[i] ? 'Avbryt endring' : 'Endre Tags'"
                (click)="toggleEditTags(i)"
              >
                {{ edittags[i] ? "cancel" : "edit" }}
              </mat-icon
              >
            </div>
          </div>
          <mat-divider *ngIf="!islast" [inset]="true"></mat-divider>
        </div>
      </mat-card>

      <app-objectchecklists *ngIf="!isLoading" [object]="object"></app-objectchecklists>

      <div class="row">
        <div>
          <h2>
            <mat-icon
              style="
                vertical-align: bottom;
                margin-bottom: 3px;
                margin-right: 10px;
              "
            >cases
            </mat-icon
            >
            Mottak
          </h2>
        </div>
        <div class="fill-space"></div>
        <div style="position: relative">
          <button
            type="button"
            mat-button
            style="margin: 5px; position: absolute; bottom: 0; right: 0"
            class="cursor"
            matTooltip="Nytt mottak"
            matTooltipPosition="left"
            (click)="newReceiptformClick()"
          >
            <mat-icon color="primary">add</mat-icon>
            Nytt mottak
          </button>
        </div>
      </div>
      <mat-card class="child-margin">
        <div>
          <div class="row">
            <div class="fill-space"></div>
            <div>
              <mat-spinner
                diameter="50"
                style="margin: 32px"
                *ngIf="!receiptforms"
              ></mat-spinner>
              <app-empty-list
                message="Ingen mottak"
                *ngIf="receiptforms?.length === 0"
              ></app-empty-list>
            </div>

            <div class="fill-space"></div>
          </div>
          <div *ngIf="receiptforms && receiptforms.length > 0">
            <div *ngFor="let receiptform of receiptforms">
              <div class="row">
                <div>
                  <button
                    mat-raised-button
                    routerLink="/p/{{ projectid }}/mt/{{ receiptform.id }}"
                    [matTooltip]="receiptform.objectsstring"
                    [style.min-width]="mobileQuery.matches ? '123px' : '123px'"
                    style="margin-bottom: 5px; margin-top: 5px"
                  >
                    <mat-icon [style.color]="receiptform.color">cases</mat-icon>
                    <span class="button-icon-text">{{ receiptform.name }}</span>
                  </button>
                </div>
              </div>
              <div style="height: 3px" *ngIf="receiptforms.length > 0"></div>
            </div>
          </div>
          <!-- <div
              *ngIf="!object.generatingReceiptform"
            >
              <app-new-checklist-button
                [object]="object"
                type="receipt"
                [loadingscreen]="true"
                [redirect]="true"
              ></app-new-checklist-button>
            </div>
            <div *ngIf="object.generatingReceiptform">
              <button
              matTooltip="Oppretter mottaksskjema"
              mat-mini-fab
              disabled
              >
                <mat-spinner diameter="25"></mat-spinner>
              </button>
            </div> -->
        </div>
        <!-- </div> -->
      </mat-card>

      <app-documents
        *ngIf="!isLoading"
        [files]="files"
        [dbPath]="object.path"
      ></app-documents>
      <!-- <h2>
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
          >folder_open</mat-icon
        >Filer
      </h2>
      <mat-card class="child-margin">
        <mat-progress-bar
          *ngIf="!filesLoaded"
          mode="indeterminate"
        ></mat-progress-bar>
        <div class="row">
          <div class="flex-container">
            <app-thumbnail
              class="flex-item"
              [file]="file"
              [isLocked]="object.isarchived"
              *ngFor="let file of object.files"
            ></app-thumbnail>
          </div>
        </div>
        <div class="row">
          <div class="fill-space"></div>
          <div style="padding-right: 10px">
            <app-upload-button
              [dbPath]="object.path"
              text="Last opp fil"
              [loading]="uploadSpinner"
              (click)="uploadButtonClicked()"
            ></app-upload-button>
          </div>
        </div>
      </mat-card> -->
      <h2>
        <mat-icon
          style="vertical-align: bottom; margin-bottom: 3px; margin-right: 10px"
        >inventory
        </mat-icon
        >
        Arkiv
      </h2>
      <mat-card class="child-margin">
        <div class="row">
          Arkivert:
          <div class="fill-space"></div>
          <div>
            <button
              mat-icon-button
              (click)="toggleArchived()"
              style="margin-top: -10px; margin-bottom: -10px"
            >
              <mat-icon>{{
                  object.isarchived ? "check_box" : "check_box_outline_blank"
                }}
              </mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
      <div class="row">
        <div class="fill-space"></div>
        <div>
          <app-signed-initials
            *ngIf="object.lastupdated && datetime"
            [datetime]="datetime"
            prefix="Sist endret:"
          ></app-signed-initials>
        </div>
        <!-- <div *ngIf="isAdmin">
          <mat-icon
            mat-icon-button
            style="padding-left: 10px;"
            class="cursor"
            [matTooltip]="
              editprocess ? 'Avbryt endring' : 'Endre Prosessnummer'
            "
            (click)="toggleEditProcess()"
          >
            {{ editprocess ? "cancel" : "edit" }}</mat-icon
          >
        </div> -->
      </div>
      <!-- <div class="row">
        Beskrivelse:
        <div class="fill-space"></div>
        <div>{{ object.desc }}</div>
      </div> -->
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div>

<!-- <div *ngIf="object && !isLoading">
  <div class="row child-margin">
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
    <div class="maxwidth">
      <mat-card>
        <mat-card-content>
          <app-partial-map [object]="object" style="height: 300px;"></app-partial-map>
        </mat-card-content>
      </mat-card>
      <p></p>
    </div>
    <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  </div>
</div> -->
