<!--<div class="row">-->
<!--  <app-headertext [hideback]="mobileQuery.matches" text="Sjekklister"></app-headertext>-->
<!--  <div class="fill-space"></div>-->
<!--</div>-->

<!-- <mat-tab-group
  *ngIf="projectid"
  (click)="tabClicked()"
  [(selectedIndex)]="checklistsTabIndex"
>
  <mat-tab label="Oversikt"></mat-tab>
  <mat-tab label="Utfylling"></mat-tab>
</mat-tab-group> -->

<!-- <div *ngIf="checklistsTabIndex === 0 && checklists"> -->
<!-- <app-checklist-overview></app-checklist-overview> -->
<!-- </div>
<div *ngIf="checklistsTabIndex === 1 && checklists"> -->
<app-checklist-list></app-checklist-list>
<!-- </div> -->
