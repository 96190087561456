import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import { AppVersion } from 'src/app/models/Admin';

export interface DialogData {
  inputVersion: AppVersion;
  newVersion: string;
  versionMessage: string;
}

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {
  // private ngUnsubscribe = new Subject();

  versionMessage: string;
  newVersion: string;
  currentVersion: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private globalservice: GlobalService
    ) { }

  ngOnInit(): void {
    this.currentVersion = environment.appVersion;
    this.newVersion = this.data.newVersion;
    this.versionMessage = this.data.versionMessage;
    // this.sharedservice.versionMessage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(vm => {
    //   this.versionMessage = vm[0];
    // })
  }

  // ngOnDestroy() {
  //   this.ngUnsubscribe.next(null);
  //   this.ngUnsubscribe.complete();
  // }

  reloadVersion() {
    // clearCache();
    this.globalservice.clearCacheAndRefresh();
  }


}
