import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/User';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdService } from 'src/app/services/id.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.css'],
})
export class SignedComponent implements OnInit {
  @Input() user: User;
  @Input() timestamp: any;
  date: string;
  isAdmin = false;
  filename: string;
  running = false;
  projectid: string;
  objectid: string;
  checklistid: string;

  constructor(
    private http: HttpClient,
    private idservice: IdService,
    private dateservice: DateService
  ) {}

  ngOnInit(): void {
    this.date = this.dateservice.dateAsDDMMYYY(this.timestamp);

    if (this.user.role?.name === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.projectid = this.idservice.projectId();
    this.objectid = this.idservice.objectId();
    this.checklistid = this.idservice.checklistId();
  }

  generatePdf() {
    this.running = true;
    const data = {
      projectid: this.projectid,
      objectid: this.objectid,
      checklistid: this.checklistid,
    };
    const config = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    this.http
      .post(
        'https://europe-west1-aventiapg.cloudfunctions.net/createPdf',
        data,
        // {
        //   responseType: 'text',
        // },
        config
      )
      .subscribe((res) => {
        console.log(res);
        this.filename = res.toString();
        this.running = false;
      });
  }
}
