import { Breadcrumbitem } from './BreadcrumbItem';

export class Currentpath {
  public event: any;
  public mode: string;
  public path: string[];
  public length: number;
  public breadcrumb: Breadcrumbitem[];
  public print?: boolean;
}
