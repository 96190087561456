import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {Project} from 'src/app/models/Project';
import {AuthService} from 'src/app/services/auth.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() project: Project;
  mobileQuery: MediaQueryList;

  constructor(
    private authservice: AuthService,
    media: MediaMatcher,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {}

  logout() {
    this.authservice.logoutConfirm();
  }
}
