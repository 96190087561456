import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IdService {
  constructor(private location: Location) {
  }

  public getPath() {
    return this.location.path(true).substring(1).split('/');
  }

  public projectId(): string {
    const path = this.getPath();
    if (path[0] === 'p') {
      return path[1];
    } else {
      // console.log('Path "' + path[0] + '" does not match project path: "p"');
      return null;
    }
  }
  public objectId(): string {
    const path = this.getPath();
    if (path[2] === 'o') {
      return path[3];
    } else {
      console.log('Path "' + path[2] + '" does not match object path: "o"');
      return null;
    }
  }
  public userId(): string {
    const path = this.getPath();
    if (path[1] === 'brukere') {
      return path[2];
    } else {
      console.log(
        'Path "' + path[1] + '" does not match object path: "brukere"'
      );
      return null;
    }
  }
  public checklistId(): string {
    const path = this.getPath();
    if (path[4] === 's') {
      return path[5];
    } else {
      console.log('Path "' + path[4] + '" does not match checklist path: "s"');
      return null;
    }
  }
  public receiptformId(): string {
    const path = this.getPath();
    if (path[2] === 'mt') {
      return path[3];
    } else {
      console.log('Path "' + path[2] + '" does not match checklist path: "mt"');
      return null;
    }
  }
  public isReceiptformPath(): boolean {
    const path = this.getPath();
    return path[2] === 'mt';
  }
  public objecttypeId(): string {
    const path = this.getPath();
    if (path[1] === 'objekttyper') {
      return path[2];
    } else {
      console.log(
        'Path "' + path[1] + '" does not match objekttyper path: "objekttyper"'
      );
      return null;
    }
  }
  public checklistitemId(): string {
    const path = this.getPath();
    // console.log(path);
    if (path[3] === 'sp') {
      return path[4];
    } else if (path[4] === 's') {
      return path[6];
    } else {
      console.log(
        'Path "' + path[5] + '" does not match checklistitem path: "sp"'
      );
      return null;
    }
  }
}
