import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import { SharedService } from 'src/app/services/shared.service';

import { environment } from 'src/environments/environment';
import { AppVersion } from 'src/app/models/Admin';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css'],
})
export class VersionComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  @Input() positionRelative: boolean;

  appVersion: string;
  newVersion: string;
  latestVersion = '0.0.0';

  versionMessage: string;
  domainMessage: string;
  // reloadUrl: string;

  constructor(
    private sharedservice: SharedService,
    private adminservice: AdminService,
    private globalservice: GlobalService
  ) {}

  ngOnInit(): void {
    // ----------- Check current version against latest --------------
    this.adminservice
      .getAppVersions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((appversions) => {
        this.sharedservice.appVersions.next(appversions);
        // console.log(appversions);
      });

    this.appVersion = environment.appVersion;

    // this.reloadUrl = window.location.href;

    const hostname = window.location.hostname;
    if (hostname === 'apg.aventi.no') {
      this.domainMessage = null;
    } else {
      this.domainMessage =
        'Du bruker appen fra feil domene. Noen funksjoner vil ikke fungere som de skal. Bytt til apg.aventi.no';
    }
    this.sharedservice.domainMessage.next([this.domainMessage]);

    this.sharedservice.newAppVersion
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newAppVersion) => {
        if (newAppVersion !== null) {
          this.newVersion = newAppVersion[0];
          if (this.latestVersion !== '0.0.0') {
            if (this.newVersion) {
              const message = `Ny versjon tilgjengelig (v.${this.latestVersion})`;
              console.log(message);
              this.sharedservice.versionMessage.next([message]);
            } else {
              // console.log(
              //   'Du kjører siste versjon av appen',
              //   this.latestVersion
              // );
              this.sharedservice.versionMessage.next([null]);
              // this.sharedservice.versionMessage.next(['Test av popup']);
            }
          }
        }
      });

    this.sharedservice.appVersions
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((appversions) => {
        if (appversions !== null) {
          this.latestVersion = '0.0.0';
          // console.log(appversions);
          // let vSplit: string[] = [];
          // let vSplitLatest: string[] = [];
          // let vSplitCurrent: string[] = environment.appVersion.split('.');
          appversions.forEach((appversion: AppVersion) => {
            if (!appversion.beta) {
              if (!gteVersion(this.latestVersion, appversion.version)) {
                this.latestVersion = appversion.version;
              }
            }

            // vSplit = appversion.version.split('.');
            // if (parseInt(vSplit[0]) > parseInt(vSplitCurrent[0])) {
            //   vSplitLatest = vSplit;
            //   // latestAppVersion = appversion;
            // } else if (parseInt(vSplit[1]) > parseInt(vSplitCurrent[1])) {
            //   vSplitLatest = vSplit;
            //   // latestAppVersion = appversion;
            // } else if (parseInt(vSplit[2]) > parseInt(vSplitCurrent[2])) {
            //   vSplitLatest = vSplit;
            //   // latestAppVersion = appversion;
            // }
            // // console.log(vSplitLatest);
          });
        }

        this.sharedservice.latestAppVersion.next([this.latestVersion]);
        if (this.latestVersion !== '0.0.0') {
          if (gteVersion(this.appVersion, this.latestVersion)) {
            // No new version

            this.sharedservice.newAppVersion.next([null]);
            // this.sharedservice.newAppVersion.next(['0.27.14']);
          } else {
            // New version available
            this.sharedservice.newAppVersion.next([this.latestVersion]);
          }
        }

        // if (
        //   parseInt(vSplitLatest[0]) > parseInt(vSplitCurrent[0]) ||
        //   (parseInt(vSplitLatest[0]) === parseInt(vSplitCurrent[0]) &&
        //     parseInt(vSplitLatest[1]) > parseInt(vSplitCurrent[1])) ||
        //   (parseInt(vSplitLatest[0]) === parseInt(vSplitCurrent[0]) &&
        //     parseInt(vSplitLatest[1]) === parseInt(vSplitCurrent[1]) &&
        //     parseInt(vSplitLatest[2]) > parseInt(vSplitCurrent[2]))
        // ) {
        //   this.newVersion = vSplitLatest.join('.');
        //   console.log('Ny versjon tilgjengelig', vSplitLatest.join('.'));
        // } else {
        //   this.newVersion = null;
        //   console.log(
        //     'Du kjører siste versjon av appen',
        //     vSplitLatest.join('.')
        //   );
        // }
        // this.sharedservice.latestAppVersion.next([this.newVersion]);
      });

    this.sharedservice.versionMessage
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((vm) => {
        if (vm !== null) {
          this.versionMessage = vm[0];
          // const dia = this.dialog.getDialogById('update-popup');
          if (this.versionMessage) {
            // this.versionMessage = this.versionMessage;
            // if (!dia) {
            //   this.openUpdateDialog();
            // }
          } else {
            this.versionMessage = null;
            // if (dia) {
            //   dia.close();
            // }
          }
        }
      });
  }

  // openUpdateDialog() {
  //   this.dialog
  //     .open(UpdateComponent, {
  //       id: 'update-popup',
  //       closeOnNavigation: true,
  //       disableClose: true,
  //       // width: '100%',
  //       backdropClass: 'blur-dialog-backdrop',
  //       data: {
  //         appVersion: this.appVersion,
  //         versionMessage: this.versionMessage,
  //       },
  //     })
  //     .afterClosed()
  //     .subscribe(async (result) => {
  //       console.log(result);
  //       if (!result) {
  //         await new Promise((r) => setTimeout(r, 120000));
  //         this.openUpdateDialog();
  //       }
  //     });
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  reloadVersion() {
    // clearCache();
    this.globalservice.clearCacheAndRefresh();
  }
}

function cmpVersion(a: string | any[], b: string | any[]) {
  let i: number;
  let cmp: number;
  let len: number;
  a = (a + '').split('.');
  b = (b + '').split('.');
  len = Math.max(a.length, b.length);
  for (i = 0; i < len; i++) {
    if (a[i] === undefined) {
      a[i] = '0';
    }
    if (b[i] === undefined) {
      b[i] = '0';
    }
    cmp = parseInt(a[i], 10) - parseInt(b[i], 10);
    if (cmp !== 0) {
      return cmp < 0 ? -1 : 1;
    }
  }
  return 0;
}

function gteVersion(a: string, b: string) {
  return cmpVersion(a, b) >= 0;
}
// function ltVersion(a: any, b: any) {
//   return cmpVersion(a, b) < 0;
// }
