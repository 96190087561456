import { MediaMatcher } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Currentpath } from 'src/app/models/Currentpath';
import { Project } from 'src/app/models/Project';
import { Sidenavitem } from 'src/app/models/Sidenavitem';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { CurrentpathService } from 'src/app/services/currentpath.service';
import { EmailService } from 'src/app/services/email.service';
import { IdService } from 'src/app/services/id.service';
import { PopupService } from 'src/app/services/popup.service';
import { SharedService } from 'src/app/services/shared.service';
import { SortService } from 'src/app/services/sort.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  project: Project;
  projectid: string;

  user: User;
  isAdmin = false;

  showSidenav: boolean;
  showingLoaderscreen: boolean;
  loaderScreenText: string;

  mobileQuery: MediaQueryList;
  // private readonly mobileQueryListener: () => void;

  currentpath: Currentpath;
  pathsplit: any[] = [''];
  page = '';

  isLoading = true;
  opened = true;
  menuicon = 'menu_open';

  itemsObs: Observable<any>;
  items: any[];

  appVersion: string;

  @ViewChild('scrollframe', { read: ElementRef })
  public scrollframe: ElementRef<any>;
  scrollTop: number;
  scrolled(event: any): void {
    // this.isNearBottom = this.isUserNearBottom();
    // console.log(event.target.scrollTop);
    this.scrollTop = event.target.scrollTop;
  }

  constructor(
    private afs: AngularFirestore,
    media: MediaMatcher,
    // private projectservice: ProjectService,
    router: Router,
    private sortservice: SortService,
    private sharedservice: SharedService,
    private emailservice: EmailService,
    private idservice: IdService,
    private currentpathservice: CurrentpathService,
    private authservice: AuthService,
    private popupservice: PopupService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    // this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // this.mobileQuery.addEventListener('change', (ev) => {
    //   this.showSidenav = !ev.matches;
    //   // console.log('mobile event:', this.showSidenav);
    // });
    // if (this.mobileQuery.matches) {
    //   this.showSidenav = false;
    // } else {
    //   this.showSidenav = true;
    // }
    this.showSidenav = !this.mobileQuery.matches;

    // router.events
    //   .pipe(filter((e) => e instanceof NavigationStart))
    //   .subscribe((event) => {
    // });
    this.currentpath = this.currentpathservice.getCurrentpath();
    this.pathsplit = this.currentpath.path;
    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        if (this.mobileQuery.matches) {
          this.closeSidenav(false);
        }
        this.showingLoaderscreen = false;
        this.currentpath = this.currentpathservice.getCurrentpath();
        this.pathsplit = this.currentpath.path;
        // console.log(this.pathsplit[2]?.substring(0, 2))
      });

    // this.itemsObs = this.itemDoc.valueChanges();
  }
  // ngAfterViewInit() {
  // this.currentpath = this.currentpathservice.getCurrentpath();
  //   if(this.currentpath.path[4] === 's'){
  //     console.log('setting scrolltop = ', this.scrollTop);
  //   } else {
  //     this.scrollTop = 0;
  //     console.log('setting scrolltop to zero',);
  //   }
  //   this.scrollframe.nativeElement.scrollTop = this.scrollTop;
  // }
  ngOnInit(): void {
    this.appVersion = environment.appVersion;
    this.projectid = this.idservice.projectId();
    this.sharedservice.project
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((project) => {
        if (project !== null) {
          // projects.forEach((project) => {
          //   if (project.id === this.projectid) {
          //     this.project = project;
          //   }
          // });
          this.project = project;
        }
      });
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user !== null) {
          this.user = user[0];
          if (this.user) {
            if (this.user.role?.name === 'admin') {
              this.isAdmin = true;
            } else {
              this.isAdmin = false;
            }
            this.afs
              .collection<any>('lists/navigation/sidenav', (ref) =>
                ref.orderBy('id')
              )
              // .snapshotChanges()
              .valueChanges()
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((items) => {
                if (this.user.role?.name === 'customer') {
                  this.items = [];
                  items.forEach((item) => {
                    if (item.customerFriendly) {
                      this.items.push(item);
                    }
                  });
                } else {
                  this.items = items;
                }
                this.items.sort(this.sortservice.dynamicSort('id'));
              });
          }
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  toggleOpened() {
    this.opened = !this.opened;
    // console.log('toggle sidenav');
    // console.log('opened', this.opened);
    // console.log('showSidenav', this.showSidenav);
    // console.log('mobileQuery', this.mobileQuery.matches);
    if (this.opened) {
      this.menuicon = 'menu_open';
    } else {
      this.menuicon = 'menu';
    }
  }
  openSidenav() {
    // console.log('open sidenav');
    // console.log('opened', this.opened);
    // console.log('showSidenav', this.showSidenav);
    // console.log('mobileQuery', this.mobileQuery.matches);
    this.showSidenav = true;
    this.opened = this.showSidenav;
  }
  closeSidenav(opened?: boolean) {
    // console.log('close sidenav');
    // console.log('opened', this.opened);
    // console.log('showSidenav', this.showSidenav);
    // console.log('mobileQuery', this.mobileQuery.matches);
    if (!opened) {
      this.showSidenav = false;
    }
  }

  logout() {
    this.authservice.logoutConfirm();
  }

  newReceiptform() {
    // this.receiptformservice.openDialog();
    this.popupservice.openDialogNewReceiptForm(this.user, this.projectid);
  }

  bugReport() {
    this.emailservice.bugReport(this.user);
  }
  showLoaderscreen(item: Sidenavitem) {
    // this.showingLoaderscreen = true;
    this.loaderScreenText = item.name;
  }
}
