import {Component, OnInit} from '@angular/core';
import {ProjectService} from 'src/app/services/project.service';
import {Project, STATUS_LEVEL_UPDATE_STATE} from 'src/app/models/Project';
import {MediaMatcher} from '@angular/cdk/layout';
import {environment} from 'src/environments/environment';
import {firstValueFrom, Subject, takeUntil} from 'rxjs';
import {SharedService} from 'src/app/services/shared.service';
import {AventiObject} from 'src/app/models/AventiObject';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {IdService} from 'src/app/services/id.service';
import {Checklist} from 'src/app/models/Checklist';
import {User} from 'src/app/models/User';
import {AventiFile} from 'src/app/models/File';
import {DbpathService} from 'src/app/services/dbpath.service';
import {AdminService} from 'src/app/services/admin.service';
import {ObjectStatus} from 'src/app/models/Status';
import _ from 'underscore';
import {ExcelService} from 'src/app/services/excel.service';
import {ChecklistService} from 'src/app/services/checklist.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {

  constructor(
    private projectservice: ProjectService,
    private sharedservice: SharedService,
    media: MediaMatcher,
    private idservice: IdService,
    private dbpathservice: DbpathService,
    private excelService: ExcelService,
    private checklistservice: ChecklistService,
    private adminservice: AdminService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    adminservice
      .getObjectStatuses()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((statuses) => {
        this.statuses = _.reject(statuses, _.isNull);
        this.statuses = _.sortBy(this.statuses, 'priority');
        this.statusNames = this.statuses.map(status => status.name);
        this.setChartData();
      });
  }
  project: Project;
  statuses: ObjectStatus[];
  statusNames: string[];
  objects: AventiObject[];
  checklists: Checklist[];
  receiptforms: Checklist[];
  isLoading = true;
  isLoadingCustomerExport = false;
  projectid: string;
  user: User;
  userUsers: User[];
  adminUsers: User[];
  customerUsers: User[];
  files: AventiFile[];
  filesDbPath: string;
  mobileQuery: MediaQueryList;
  treeControl = new NestedTreeControl<any>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  objectsChartData: any;
  checklistsChartData: any;
  chartOptions: any;
  private ngUnsubscribe = new Subject();

  protected readonly STATUS_LEVEL_UPDATE_STATE = STATUS_LEVEL_UPDATE_STATE;

  hasChild = (num: number, node: any) => !!node.children && node.children.length > 0 && (num || !num);

  ngOnInit(): void {
    this.projectid = this.idservice.projectId();

    this.projectservice.getFullProjectById(this.projectid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (project) => {
        if (project !== null) {
          this.project = project;
          this.isLoading = false;
        }
      });

    this.filesDbPath = this.dbpathservice.dbPathProject();

    this.sharedservice.checklists
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checklists: Checklist[]) => {
        if (checklists !== null) {
          this.checklists = checklists;
          this.setChartData();
        }
      });
    this.sharedservice.receiptforms
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((receiptforms: Checklist[]) => {
        if (receiptforms !== null) {
          this.receiptforms = receiptforms;
          this.setChartData();
        }
      });
    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects: AventiObject[]) => {
        if (objects !== null) {
          this.objects = objects;
          this.setChartData();
          const types = [];

          const result = objects.reduce((r, a) => {
            r[a.type.name] = r[a.type.name] || [];
            r[a.type.name].push(a);
            return r;
          }, Object.create(null));

          for (const [key, value] of Object.entries(result)) {
            types.push({
              name: key,
              children: value,
            });
          }
          types.forEach((type) => {
            type.children.forEach((object) => {
              object.url = '/p/' + this.projectid + '/o/' + object.id;
              object.checklists?.forEach((checklist) => {
                checklist.url = object.url + '/s/' + checklist.id;
              });
              object.receiptforms?.forEach((receiptform) => {
                receiptform.url = object.url + '/s/' + receiptform.id;
              });
            });
          });
          this.dataSource.data = types;
        }
      });

    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
        }
      });

    this.sharedservice.users
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users: User[]) => {
        if (users !== null) {
          this.userUsers = [];
          this.adminUsers = [];
          this.customerUsers = [];
          users.forEach((user) => {
            user.projectaccess?.forEach((pa) => {
              if (pa.id === this.projectid) {
                if (user.role?.name === 'user') {
                  this.userUsers.push(user);
                }
                if (user.role?.name === 'admin') {
                  this.adminUsers.push(user);
                }
                if (user.role?.name === 'customer') {
                  this.customerUsers.push(user);
                }
              }
            });
          });
        }

      });

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  setChartData() {
    if (this.statuses && this.objects && this.checklists) {
      const withoutStatusName = 'Uten statusnivå';
      const statusCount: Record<string, { count: number, priority: number }> = {};
      statusCount[withoutStatusName] = {count: 0, priority: -1};
      const statusWithoutStatusName: ObjectStatus = {name: withoutStatusName};
      const objectStatuses = [...this.statuses, statusWithoutStatusName];
      for (const status of objectStatuses) {
        const count = this.objects.filter(object => object.status?.id === status.id).length;
        statusCount[status.name] = {count, priority: status.priority};
      }
      const statusCounts = [];
      const statusNames = [];
      for (const [key, value] of Object.entries(statusCount)) {
        statusCounts.push(value.count);
        statusNames.push(key + ' - ' + value.count);
      }
      let updateObjectsChartData = false;
      for (let i = 0; i < this.objectsChartData?.datasets[0].data.length; i++) {
        if (this.objectsChartData?.datasets[0].data[i] !== statusCounts[i]) {
          updateObjectsChartData = true;
        }
      }
      if (!this.objectsChartData || updateObjectsChartData) {
        this.objectsChartData = {
          labels: statusNames,
          datasets: [
            {
              label: 'Objekt status',
              data: statusCounts,
              backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
              borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
              borderWidth: 1
            }
          ]
        };
      }

      const checklistStatuses = ['creating', 'started', 'finished', 'signed'];
      const statusCountChecklists: Record<string, { count: number }> = {};
      statusCountChecklists[withoutStatusName] = {count: 0};
      for (const status of checklistStatuses) {
        const count = this.checklists.filter(checklist => checklist.status === status).length;
        statusCountChecklists[status] = {count};
      }
      const statusCountsChecklists = [];
      const statusNamesChecklists = [];
      for (const [key, value] of Object.entries(statusCountChecklists)) {
        statusCountsChecklists.push(value.count);
        const humanKey = this.sharedservice.humanizeStatus(key)[0];
        statusNamesChecklists.push(humanKey + ' - ' + value.count);
      }
      let updateChecklistsChartData = false;
      for (let i = 0; i < this.checklistsChartData?.datasets[0].data.length; i++) {
        if (this.checklistsChartData?.datasets[0].data[i] !== statusCountsChecklists[i]) {
          updateChecklistsChartData = true;
        }
      }
      if (!this.checklistsChartData || updateChecklistsChartData) {
        this.checklistsChartData = {
          labels: statusNamesChecklists,
          datasets: [
            {
              label: 'Sjekkliste status',
              data: statusCountsChecklists,
              backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
              borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
              borderWidth: 1
            }
          ]
        };
      }

      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
      if (!this.chartOptions) {
        this.chartOptions = {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        };
      }
    }
  }

  export2json() {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(
      new Blob([JSON.stringify(this.objects, null, 2)], {
        type: 'application/json',
      })
    );
    a.setAttribute('download', this.project.id + ' - export.json');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async customerExport2Excel() {
    this.isLoadingCustomerExport = true;
    const customerExport = [];
    const allStatuses = await firstValueFrom(this.adminservice.getObjectStatuses());
    const allChecklistitems = await firstValueFrom(this.adminservice.getAllChecklistitemsObs());
    for (const object of this.objects) {

      const {
        object: updatedObject,
        sortedAllStatuses
      } = await this.checklistservice.groupChecklistItems(
        object, object.checklists[0], false, this.project.useStatusLevel, allStatuses, allChecklistitems
      );
      const result = {
        Objektnavn: updatedObject.name ?? '',
        Arbeidspakke: updatedObject.comment ?? '',
        Beskrivelse: updatedObject.desc ?? '',
        Type: updatedObject.type.name ?? '',
        Prosess: updatedObject.process ?? '',
        Pnummer: updatedObject.profilenumber ?? '',
        Tags: updatedObject.tags ? updatedObject.tags.map(tag => `${tag.key}: ${tag.value}`).join() : '',
        'Antall Sjekklister': updatedObject.checklists ? updatedObject.checklists.length : 0,
        Status: updatedObject.status?.name ?? ''
      };
      sortedAllStatuses.forEach((status) => {
        result[status.name] = status.priority <= object.status.priority ? 'X' : '';
      });
      customerExport.push(result);
    }
    this.excelService.exportAsExcelFile(customerExport, `${this.project.id} ${this.project.name} - customer`);
    this.isLoadingCustomerExport = false;
  }

  toggleStatusLevelSetting(event) {
    this.project.useStatusLevel = event.checked;
    this.project.statusLevelUpdateInProgress = event.checked ? STATUS_LEVEL_UPDATE_STATE.REQUESTED : STATUS_LEVEL_UPDATE_STATE.DISABLED;
    this.project.statusLevelUpdateInProgressCount = 0;
    this.projectservice.updateProject(this.project);
  }
}
