import { Component, OnInit } from '@angular/core';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-compare-excel',
  templateUrl: './compare-excel.component.html',
  styleUrls: ['./compare-excel.component.css'],
})
export class CompareExcelComponent implements OnInit {
  file1name: string;
  file2name: string;

  file1: File;
  file2: File;
  file1data: any[];
  file2data: any[];
  fulldata: any[];
  file1dataoriginal: any[];
  file2dataoriginal: any[];
  file1dataoriginallength: any[];
  file2dataoriginallength: any[];
  file1concat: string[] = [];
  file2concat: string[] = [];
  tabledata1: any[];
  tabledata2: any[];
  tabledatafull: any[];
  tabledatadiff: any[];
  diff1notin2: any[] = [];
  diff2notin1: any[] = [];
  diff1and2: any[] = [];

  comparefiles: boolean;
  filespinner = 0;

  fileid = 0;
  keys = [];

  tableready: boolean;

  constructor(private excelservice: ExcelService) {}

  ngOnInit(): void {}

  uploadExcelFile1() {
    this.fileid = 1;
    document.getElementById('excelinput').click();
  }
  uploadExcelFile2() {
    this.fileid = 2;
    document.getElementById('excelinput').click();
  }

  dataFromExcelEventEmitter(data) {
    // console.log(this.fileid, data);
    if (this.fileid === 1) {
      // console.log(this.fileid, data);
      this.file1dataoriginal = data;
      this.file1dataoriginallength = data.length;
      // this.file1data = this.file1dataoriginal;
    } else if (this.fileid === 2) {
      // console.log(this.fileid, data);
      this.file2dataoriginal = data;
      this.file2dataoriginallength = data.length;
      // this.file2data = this.file2dataoriginal;
    }
    this.fileid = 0;
    this.filespinner = this.fileid;
  }
  compare() {
    this.comparefiles = true;
    this.diff1and2 = [];
    this.diff1notin2 = [];
    this.diff2notin1 = [];
    this.tableready = false;
    this.tabledata1 = [];
    this.tabledata2 = [];
    this.file1concat = [];
    this.file2concat = [];
    this.tabledatafull = [];
    this.fulldata = [];
    this.file1data = [];
    this.file2data = [];
    this.keys = [];
    this.fileid = 0;
    this.file1dataoriginal.forEach((row) => {
      this.file1data.push(row);
    });
    this.file2dataoriginal.forEach((row) => {
      this.file2data.push(row);
    });
    // console.log(this.file1dataoriginal.length, this.file1dataoriginal.length);

    this.file1data.forEach((row) => {
      row.status = '';
      Object.keys(row).forEach((key) => {
        if (!this.keys.includes(key)) {
          this.keys.push(key);
        }
      });
    });
    this.file2data.forEach((row) => {
      row.status = '';
      Object.keys(row).forEach((key) => {
        if (!this.keys.includes(key)) {
          this.keys.push(key);
        }
      });
    });
    this.file1data.forEach((row) => {
      this.keys.forEach((key) => {
        if (!row[key]) {
          row[key] = '';
        }
      });
      this.file1concat.push(JSON.stringify(row));
    });
    this.file2data.forEach((row) => {
      this.keys.forEach((key) => {
        if (!row[key]) {
          row[key] = '';
        }
      });
      this.file2concat.push(JSON.stringify(row));
    });
    if (!this.keys.includes('status')) {
      this.keys.push('status');
    }
    this.fulldata = this.file1data;

    // console.log(this.diff1notin2.length, this.diff2notin1.length);
    // console.log(this.file1concat.length, this.file2concat.length);
    this.file1data.forEach((row) => {
      if (!row.status) {
        row.status = '';
      }
      const rowString = JSON.stringify(row);
      if (!this.file2concat.includes(rowString)) {
        row.status = 'removed';
        this.diff1notin2.push(row);
        this.diff1and2.push(row);
      } else {
        row.status = '';
        const index = this.file2concat.indexOf(rowString);
        if (index > -1) {
          this.file2concat.splice(index, 1);
        }
      }
      this.fulldata.push(row);
    });
    this.file2data.forEach((row) => {
      if (!row.status) {
        row.status = '';
      }
      const rowString = JSON.stringify(row);
      if (!this.file1concat.includes(rowString)) {
        row.status = 'added';
        this.diff2notin1.push(row);
        this.diff1and2.push(row);
        this.fulldata.push(row);
      } else {
        row.status = '';
        const index = this.file1concat.indexOf(rowString);
        if (index > -1) {
          this.file1concat.splice(index, 1);
        }
      }
    });
    // console.log(this.file1dataoriginal.length, this.file1dataoriginal.length);
    // console.log(this.diff1notin2.length, this.diff2notin1.length);
    // console.log(this.file1concat.length, this.file2concat.length);
    this.tabledata1 = this.file1data;
    this.tabledata2 = this.file2data;
    this.tabledatafull = this.fulldata;

    // console.log(this.keys);
    this.tableready = true;
    this.comparefiles = false;
  }

  arraysEqual(a1, a2) {
    return (
      a1.length === a2.length &&
      a1.every((o, idx) => this.objectsEqual(o, a2[idx]))
    );
  }

  objectsEqual(o1, o2) {
    return (
      Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p])
    );
  }

  sortTable(key, table) {
    const n = this.keys.indexOf(key);
    let rows;
    let switching;
    let i;
    let x;
    let y;
    let shouldSwitch;
    let dir;
    let switchCount = 0;
    // table = document.getElementById("tablediff");
    switching = true;
    // Set the sorting direction to ascending:
    dir = 'asc';
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < rows.length - 1; i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName('td')[n];
        y = rows[i + 1].getElementsByTagName('td')[n];
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        if (dir === 'asc') {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir === 'desc') {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchCount++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchCount === 0 && dir === 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  }

  downloadTable(id) {
    let data = [];
    if (id === 'table1') {
      data = this.file1data;
    }
    if (id === 'table2') {
      data = this.file2data;
    }
    if (id === 'tablediff') {
      data = this.diff1and2;
    }
    this.excelservice.exportAsExcelFile(data, id + '_changecheck');
  }

  setFilename(eventFileList: FileList, fileNameInput) {
    // The File objects
    const files = Array.from(eventFileList);
    // console.log(files);

    this.diff1and2 = [];
    this.diff1notin2 = [];
    this.diff2notin1 = [];
    this.tableready = false;
    this.tabledata1 = [];
    this.tabledata2 = [];
    this.file1concat = [];
    this.file2concat = [];
    this.tabledatafull = [];
    this.fulldata = [];
    this.keys = [];

    this.filespinner = this.fileid;

    if (this.fileid === 1) {
      this.file1 = files[0];
      this.file1name = this.file1.name.substring(
        this.file1.name.lastIndexOf('/') + 1
      );
    }
    if (this.fileid === 2) {
      this.file2 = files[0];
      this.file2name = this.file2.name.substring(
        this.file2.name.lastIndexOf('/') + 1
      );
    }

    // Clear the input
    fileNameInput.value = null;
  }
}
