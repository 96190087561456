<div
  *ngIf="
    !userNotFound &&
    !showingChangelogPopup &&
    isLoading &&
    (isAuthenticated === '' || isAuthenticated === 'true')
  "
>
  <app-aventibackground [showversion]="false"></app-aventibackground>
  <app-spinner></app-spinner>
</div>
<div
  *ngIf="alert && !ismaintenance"
  style="
    background-color: #d32f2f;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-weight: 400;
  "
>
  <mat-icon style="vertical-align: text-top; padding-right: 2px"
    >report_problem</mat-icon
  >
  {{ alert }}
</div>
<div
  [ngStyle]="{
    position: 'absolute',
    top: alert ? '44px' : '0',
    bottom: '0',
    left: '0',
    right: '0'
  }"
>
  <app-sidenav
    *ngIf="
      !userNotFound &&
      !showingChangelogPopup &&
      isAuthenticated === 'true' &&
      !isPasswordReset &&
      !isLoading &&
      project &&
      !ismaintenance &&
      (googlemapsApiLoaded | async)
    "
  ></app-sidenav>

  <app-projects
    *ngIf="
      !userNotFound &&
      !showingChangelogPopup &&
      isAuthenticated === 'true' &&
      !isPasswordReset &&
      !isLoading &&
      !project &&
      !ismaintenance
    "
  ></app-projects>

  <div
    class="row"
    *ngIf="
      !userNotFound &&
      showingChangelogPopup &&
      isAuthenticated === 'true' &&
      !isPasswordReset &&
      !isLoading &&
      !ismaintenance
    "
  >
    <app-aventibackground [showversion]="false"></app-aventibackground>
    <div class="fill-space"></div>
    <div style="max-width: 600px; width: 90%">
      <h1>
        <mat-icon style="vertical-align: middle; margin-right: 5px"
          >published_with_changes</mat-icon
        >
        Ny versjon lastet inn!
      </h1>

      <h4 *ngIf="user.role?.name === 'customer'">v.{{ appVersion }}</h4>
      <div *ngIf="user.role?.name !== 'customer'">
        <h4>Endringer fra v.{{ user.appVersion }} til v.{{ appVersion }}</h4>
        <p>
          <app-changelog-list
            [newVersion]="appVersion"
            [oldVersion]="user.appVersion"
            [expanded]="true"
          ></app-changelog-list>
        </p>
      </div>

      <button
        mat-raised-button
        cdkFocusInitial
        (click)="approveChangelog()"
        style="margin-bottom: 20px"
      >
        OK, start APG
      </button>
    </div>
    <div class="fill-space"></div>
  </div>

  <div
    *ngIf="userNotFound && !isLoading && !ismaintenance"
    style="padding-top: 150px; text-align: center"
  >
    <app-aventibackground [showversion]="false"></app-aventibackground>
    <!-- <div class="center-on-screen noselect">
      <div style="max-width: 600px; width: 90%"> -->
    <h1>
      <mat-icon style="vertical-align: middle; margin-right: 5px"
        >no_accounts</mat-icon
      >
      Fant ikke bruker
    </h1>

    <h4>Send en mail til APG admin for å melde feilen.</h4>

    <button
      mat-raised-button
      cdkFocusInitial
      (click)="sendEmail()"
      style="margin-bottom: 20px"
    >
      Send mail til APG admin
    </button>
    <!-- </div>
    </div> -->
  </div>

  <!-- <mat-tab-group
    *ngIf="
      isAuthenticated === 'true' && !isLoading && !project && !ismaintenance
    "
  >
    <mat-tab label="Prosjekt">
    </mat-tab>
    <mat-tab label="Admin" [disabled]="isAdmin">
      <app-admin></app-admin>
    </mat-tab>
  </mat-tab-group> -->
  <app-authentication
    *ngIf="!isLoading && isAuthenticated === 'false' && !ismaintenance && !isPasswordReset"
  ></app-authentication>
  <app-reset-password *ngIf="isPasswordReset" [oobCode]="oobCode"></app-reset-password>
</div>

<div
  *ngIf="isLoadingShared && isLoadingShared[0] === true"
>
  <app-spinner [text]="isLoadingShared[2]" [header]="isLoadingShared[1].toString()"></app-spinner>

</div>

<div
  *ngIf="ismaintenance"
  style="padding-top: 150px; text-align: center; z-index: 2000"
>
  <div class="noselect">
    <app-aventibackground [showversion]="false"></app-aventibackground>
    <h1>
      <mat-icon style="font-size: 40px; vertical-align: sub"
        >engineering</mat-icon
      >
      <span style="margin-left: 30px">{{ maintenanceheader }}</span>
    </h1>
    <div>
      <p>{{ maintenancetext }}</p>
      <p>&mdash; APG Team</p>
    </div>
  </div>
  <div *ngIf="user?.superAdmin">
    <button mat-raised-button (click)="allowsuperadminDuringMaintenance()">
      Tillat superAdmin
    </button>
  </div>
</div>

<div
  style="z-index: 900; position: fixed; top: 0; left: 50%; margin-left: -20px"
>
  <div class="row">
    <app-offline-detection></app-offline-detection>
    <app-domain-detection-icon></app-domain-detection-icon>
    <app-service-worker-detection *ngIf="user?.debug"></app-service-worker-detection>
    <app-device-location-detection *ngIf="user?.debug"></app-device-location-detection>
  </div>

</div>
