import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Objecttype} from 'src/app/models/Objecttype';
import {IdService} from 'src/app/services/id.service';
import {ObjectService} from 'src/app/services/object.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';

export interface DialogData {
  projectid: string;
  hideOpenAfterCreated: boolean;
}

@Component({
  selector: 'app-new-object',
  templateUrl: './new-object.component.html',
  styleUrls: ['./new-object.component.css'],
  providers: [ObjectService],
})
export class NewObjectComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    // no: new FormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    desc: new UntypedFormControl('', []),
    type: new UntypedFormControl('', [Validators.required]),
    process: new UntypedFormControl('', []),
    profilenumber: new UntypedFormControl('', []),
  });
  error: string;

  message = 'Laster opp...';
  showMessage = false;

  get no() {
    return this.Form.get('no');
  }
  get name() {
    return this.Form.get('name');
  }
  get desc() {
    return this.Form.get('desc');
  }
  get type() {
    return this.Form.get('type');
  }
  get process() {
    return this.Form.get('process');
  }
  get profilenumber() {
    return this.Form.get('profilenumber');
  }

  objectDoc: AngularFirestoreDocument<AventiObject>;
  objectData: AventiObject;

  objecttypes: Objecttype[];

  mobileQuery: MediaQueryList;

  openAfterCreate = true;

  hideOpenAfterCreated = false;

  constructor(
    private afs: AngularFirestore,
    private dialogRef: MatDialogRef<NewObjectComponent>,
    private objectservice: ObjectService,
    private sharedservice: SharedService,
    // private adminservice: AdminService,
    media: MediaMatcher,
    private idservice: IdService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    // projectservice.readProject().pipe(takeUntil(this.ngUnsubscribe)).subscribe((project) => {
    //   this.projectversion = project.objecttypeversion;
    // adminservice.getObjecttypes()
    // });
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    if (this.data?.hideOpenAfterCreated) {
      this.hideOpenAfterCreated = this.data.hideOpenAfterCreated;
    }
    if (this.hideOpenAfterCreated) {
      this.openAfterCreate = false;
    }
    this.sharedservice.objecttypes.pipe(take(1)).subscribe((types) => {
      if (types !== null) {
        // console.log(types);
        this.objecttypes = types.filter(type => type.category?.id !== 'loJgvvKxjzx1jk771Wuf'); // Filter out Mottak category
      } else {
        // this.adminservice
        //   .getObjecttypes()
        this.sharedservice.objecttypes
          .pipe(take(1))
          .subscribe((returnedTypes) => {
            if (returnedTypes !== null) {
              // console.log(types);
              this.objecttypes = returnedTypes.filter(type => type.category?.id !== 'loJgvvKxjzx1jk771Wuf'); // Filter out Mottak category
            }
          });
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  create(
    name: string,
    typeid: string,
    desc?: string,
    process?: string,
    profilenumber?: string
  ) {
    let objecttypename: string;
    let projectid: string;
    if (this.data?.projectid) {
      projectid = this.data.projectid;
    } else {
      projectid = this.idservice.projectId();
    }
    this.objecttypes.forEach((type) => {
      if (type.id === typeid) {
        objecttypename = type.name;
      }
    });
    this.objectData = {
      name,
      desc,
      process,
      profilenumber,
      type: {
        id: typeid,
        name: objecttypename,
      },
      projectid,
    };
    if (this.openAfterCreate) {
      this.sharedservice.isLoading.next([
        true,
        'Oppretter objekt',
        [this.objectData.name, this.objectData.type?.name],
      ]);
    }
    this.objectData.id = this.afs.createId();
    this.objectservice.set(this.objectData, this.openAfterCreate, true);
    this.Form.reset();
    this.Form.markAsPristine();
    this.Form.markAsUntouched();
    this.dialogRef.close({ data: this.objectData });
  }

  toggleOpenAfterCreate() {
    this.openAfterCreate = !this.openAfterCreate;
  }

  // fileChange(event): void {
  //   const fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     const file = fileList[0];
  //     this.showMessage = true;
  //     this.xlservice.uploadFile(file).then(() => {
  //       this.message = 'Ferdig lastet opp';
  //     });
  //   }
  // }
}
