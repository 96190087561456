import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from 'src/app/models/User';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Language} from '../../models/Admin';
import firebase from 'firebase/compat/app';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.css'],
})
export class MypageComponent implements OnInit {
  isLoading: false;
  mobileQuery: MediaQueryList;
  // private userDoc: AngularFirestoreDocument<any>;
  user: User;
  // user$: Observable<User>;
  FormLanguage = new UntypedFormGroup({
    language: new UntypedFormControl('', [Validators.required]),
  });
  editlanguage = false;
  languages: Language[] = [];
  private ngUnsubscribe = new Subject();

  constructor(
    media: MediaMatcher,
    private dialogChangePassword: MatDialog,
    private sharedservice: SharedService,
    private userservice: UserService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    // this.userDoc = afs.doc<any>('users/christers');
    // this.user$ = this.userDoc.valueChanges();
  }

  get language() {
    return this.FormLanguage.get('language');
  }

  ngOnInit(): void {
    this.languages = this.sharedservice.getLanguages();
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User[]) => {
        if (user[0] !== null) {
          if (!user[0].language) {
            user[0].language = this.languages.find(language => language.id === 'no');
          }
          this.user = user[0];
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  changePassword() {
    this.dialogChangePassword.open(ChangePasswordComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {user: this.user},
    });
  }

  toggleeditlanguage() {
    this.editlanguage = !this.editlanguage;
    this.FormLanguage.patchValue({
      language: this.language,
    });
  }

  savelanguage(languageInput: Language) {
    this.user.language = this.languages.find(language => language.id === languageInput.id);
    this.updateUser();
    this.editlanguage = false;
  }

  updateUser() {
    const user = this.user;
    user.lastupdated = firebase.firestore.Timestamp.now();
    this.userservice.updateUser(user);
  }

}
