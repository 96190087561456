
<a
  style="margin: 3px; background-color: #666"
  class="button card card-1"
  (click)="openDialogImagePreview()"
  *ngIf="downloadurl && !newItem"
  @animateDestroy
  [style.height]="size"
  [style.width]="size"
  [style.opacity]="isOnline ? '1' : '0.1'"
  [matTooltip]="isOnline ? '' : 'Ikke tilgjengelig når du er offline'"
  matTooltipPosition="below"
>
  <div
    *ngIf="isOnline && isimage && format === 'image' && warninglevel > 0"
    style="
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: x-small;
      z-index: 100;
    "
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <!-- <mat-spinner diameter="10"></mat-spinner> -->
    Komprimerer...
<!--    <span style="opacity: 0.5;">{{ warninglevel }}</span>-->
    <!-- <mat-icon [color]="warninglevel === 1 ? 'accent' : warninglevel === 2 ? 'error' : ''">error</mat-icon> -->
  </div>
  <div
    *ngIf="isOnline && file.comment"
    style="
      width: 0;
      height: 0;
      border-bottom: 45px solid #20202060;
      border-left: 45px solid transparent;
      bottom: 0;
      right: 0;
      position: absolute;
      color: green;
      z-index: 99;
    "
  ></div>
  <button
    mat-icon-button
    [matTooltip]="file.comment"
    matTooltipPosition="above"
    *ngIf="isOnline && file.comment"
    style="
      position: absolute;
      bottom: -5px;
      right: -5px;
      font-size: 18px;
      z-index: 100;
    "
  >
    <mat-icon>notes</mat-icon>
  </button>
  <div
    class="center-cropped"
    [style.height]="size"
    [style.width]="size"
    style="text-align: center;"
    *ngIf="isOnline && isimage && format === 'image' && downloadurl === 'no_image'"
  >
    <span>
      <mat-icon>image_not_supported</mat-icon>
      image not found
    </span>
  </div>
  <div class="center-cropped"
       [style.height]="size"
       [style.width]="size"
       [style.opacity]="isOnline ? '1' : '0.2'">
    <span class="fileextension">
        {{ file.name | replaceFileExtension }}
    </span>
  </div>
  <img
    *ngIf="isOnline && isimage && format === 'image' && downloadurl !== 'no_image'"
    #thumbnail
    class="center-cropped"
    [style.height]="size"
    [style.width]="size"
    [src]="downloadurl"
    (error)="srcChange(thumbnail)"
    [alt]="file.name"
    [style.opacity]="isimage && warninglevel > 0 ? 0.2 : 1"
  />
  <video
    *ngIf="isOnline && format === 'video'"
    id="video"
    class="center-cropped"
    [style.height]="size"
    [style.width]="size"
    muted
    oncanplay="this.muted=true"
  >
    <!-- <video
    *ngIf="format === 'video'"
    id="video"
    class="center-cropped"
    autoplay
    loop
    muted
    oncanplay="this.muted=true"
  > -->
    <source [src]="downloadurl" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <!-- <div *ngIf="file.deviation" class="center-cropped">
    <mat-icon>error_outline</mat-icon>
  </div> -->
  <!-- <div
    *ngIf="file.deviation"
    class="center-cropped"
    [class.deviation-border]="file.deviation === 'true'"
  ></div> -->
</a>
<!-- <div *ngIf="file?.timestamporiginal">
  {{ file.timestamporiginal }}
</div> -->
