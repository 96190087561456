<div class="header noselect">
  <div class="row">
    <div class="sidebar-item">
      <!-- <mat-icon class="sidebar-item-icon cursor">domain</mat-icon> -->
      <span class="sidebar-item-text header"
        >{{ project.no }} -
        {{ mobileQuery.matches ? project.nameshort : project.name }}</span
      >
    </div>
    <div class="fill-space"></div>
    <!-- <app-offline-detection></app-offline-detection>
    <app-version-detection-icon style="cursor:pointer;"></app-version-detection-icon>
    <app-domain-detection-icon style="cursor:pointer;"></app-domain-detection-icon> -->
    <!-- <div>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="">
          <mat-icon>view_list</mat-icon>Prosjektliste
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>Logg av
        </button>
      </mat-menu>
    </div> -->
    <div routerLink="" class="header-icon" style="cursor:pointer;">
      <mat-icon>view_list</mat-icon>
      <span class="top-header-icon-text" *ngIf="!mobileQuery.matches">Prosjektliste</span>
    </div>
    <mat-divider></mat-divider>
    <div (click)="logout()" class="header-icon" style="cursor:pointer;">
      <mat-icon>exit_to_app</mat-icon>
      <span class="top-header-icon-text" *ngIf="!mobileQuery.matches">Logg av</span>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
