import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {IdService} from 'src/app/services/id.service';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-objects',
    templateUrl: './objects.component.html',
    styleUrls: ['./objects.component.css'],
})
export class ObjectsComponent implements OnInit {
    editMode = true;
    editModeButtonName: string;
    projectid: string;
    mobileQuery: MediaQueryList;
    private ngUnsubscribe = new Subject();

    constructor(
        private idservice: IdService,
        media: MediaMatcher,
    ) {
        this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    }

    ngOnInit(): void {
        this.projectid = this.idservice.projectId();
        // this.toggleEditMode();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }

    //
    // toggleEditMode() {
    //   this.editMode = !this.editMode;
    //   if (this.editMode) {
    //     this.editModeButtonName = 'Vis liste';
    //   } else {
    //     this.editModeButtonName = 'Endre liste';
    //   }
    // }

}
