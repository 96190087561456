import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import * as XLSX from 'xlsx';
import { SharedService } from '../services/shared.service';

@Directive({
  selector: '[appReadexcel]',
  exportAs: 'readexcel',
})
export class ReadexcelDirective {
  excelObservable: Observable<any>;
  @Output() eventEmitter = new EventEmitter();

  constructor(private sharedservice: SharedService) {}

  @HostListener('change', ['$event.target'])
  onChange(target: HTMLInputElement) {
    const file = target.files[0];
    // target.value = '';
    console.log(file.name);
    this.sharedservice.isLoading.next([
      true,
      'Åpner Excel fil...',
      [file.name],
    ]);

    this.excelObservable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    this.excelObservable.subscribe((data) => {
      // console.log('data', data);
      this.sharedservice.isLoading.next([false, '', ['']]);
      this.eventEmitter.emit(data);
      target.value = '';
    });
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bufferArray, { type: 'buffer' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      subscriber.next(data);
      subscriber.complete();
    };
  }
}
