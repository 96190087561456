import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { GlobalService } from 'src/app/services/global.service';
import { PopupService } from 'src/app/services/popup.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css'],
})
export class ChangelogComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  user: User;

  constructor(
    private sharedservice: SharedService,
    private globalservice: GlobalService,
    private popupservice: PopupService,
  ) {}

  ngOnInit(): void {
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users) => {
        if (users !== null) {
          this.user = users[0];
        }
      });
  }

  newVersion() {
    this.popupservice.ChangelogItem();
  }

  reloadVersion() {
    // clearCache();
    this.globalservice.clearCacheAndRefresh();
  }
}
