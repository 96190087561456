import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-signed-initials',
  templateUrl: './signed-initials.component.html',
  styleUrls: ['./signed-initials.component.css'],
})
export class SignedInitialsComponent implements OnInit {
  @Input() user: User;
  // @Input() timestamp: Timestamp;
  @Input() showInitials: boolean;
  // @Input() showDate = 'false';
  // @Input() showTime = 'false';
  // @Input() showDateTime = 'false';
  @Input() prefix = '';
  // @Input() date: string = null;
  // @Input() time: string = null;
  @Input() datetime: string;
  // usertimestamp: UserTimestamp;

  constructor() {}

  ngOnInit(): void {
    // this.date = this.timestamp.toDate().toLocaleDateString();
    // this.time = this.timestamp.toDate().toLocaleTimeString();
    // this.datetime = this.dateservice.dateAsYYYYMMDDHHNNSS(
    //   this.timestamp.toDate()
    // );
  }
}
