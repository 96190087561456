<!-- <div *ngIf="!projects">
  <app-spinner></app-spinner>
</div> -->
<!-- <app-spinner
disable="false"
[text]="['']"
header="Henter prosjekter..."
*ngIf="
  !projects
"
></app-spinner> -->
<app-spinner
  disable="true"
  [text]="['Åpner prosjekt...']"
  [header]="selectedProject.no + ' - ' + selectedProject.name"
  *ngIf="selectedProject"
></app-spinner>
<div class="row" *ngIf="!projects" style="margin-top: 50px">
  <div class="fill-space"></div>
  <div>
    <div class="row">
      <div class="fill-space"></div>
      <mat-spinner diameter="55"></mat-spinner>
      <div class="fill-space"></div>
    </div>
    <h3>Henter prosjekter...</h3>
  </div>
  <div class="fill-space"></div>
</div>
<div class="row child-margin" *ngIf="projects">
  <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  <div class="maxwidth child-margin" *ngIf="projects">
    <!-- <h2 style="text-align: center" *ngIf="projects">
      <mat-icon style="vertical-align: text-top">announcement</mat-icon>
      Ingen prosjekter tilgjengelig
    </h2> -->
    <div
      style="overflow-y: auto"
      [style.max-height]="
        mobileQuery.matches ? 'calc(100vh - 155px)' : 'calc(100vh - 165px)'
      "
      [style.padding-bottom]="mobileQuery.matches ? '50px' : '0'"
    >
      <div class="project-card" *ngFor="let p of projects">
        <button
          mat-raised-button
          class="hover"
          style="
            width: 100%;
            text-align: left;
            white-space: pre-wrap !important;
          "
          [routerLink]="p.url"
          (click)="projectClicked(p)"
        >
          <app-cached-indicator [pullRight]="true" [visible]="p.fromCache"></app-cached-indicator>
          <div style="text-indent: -53px; padding-left: 53px">
            {{ p.id }} - {{ p.name }}<span *ngIf="p.id !== p.no"> (avvik i prosjektinfo)</span>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
</div>
