import { ChecklistItem } from './Checklistitem';
import { UserTimestamp } from './User';

export class Objecttype {
  public id?: string;
  public name?: string;
  public enName?: string;
  public category?: ObjecttypeCategory;
  public checklistitems?: ChecklistItem[];
  public editedBy?: UserTimestamp[];
  public missingEn?: boolean;
  public numberOfItemsMissingEn?: number;
  // public category?: string;
}
export class ObjecttypeCategory {
  public id?: string;
  public name?: string;
  public editedBy?: UserTimestamp[];
}
