<div class="row">
  <app-headertext text="Admin - Statuser"></app-headertext>
  <div class="fill-space"></div>
  <button mat-button (click)="newObjectStatus()" class="header-row-button">
    <mat-icon color="primary">add</mat-icon>
    Ny objektstatus
  </button>
  <!-- <button mat-button (click)="editChecklistitems()" class="header-row-button">
    <mat-icon color="accent">edit</mat-icon>
    Rediger sjekklistepunkt
  </button> -->
</div>

<div style="margin: 0 15px 0 15px;">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>
<div class="row child-margin">
  <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
  <p-table
    [value]="statuses"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-striped p-datatable-sm"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width:120px">Priority</th>
        <th>Navn</th>
        <th>Name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-status let-i="rowIndex">
      <tr>
        <td>
          <p-button (click)="sortPriority(i, 'up')" pRipple [disabled]="i === 0" [text]="true" label="" icon="pi pi-angle-up"></p-button>
          <p-tag [value]="status.priority"></p-tag>
          <p-button (click)="sortPriority(i, 'down')" pRipple [disabled]="i === statuses.length - 1" [text]="true" label="" icon="pi pi-angle-down"></p-button>
        </td>
        <td>{{ status.name }}</td>
        <td>{{ status.enName }}</td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="!mobileQuery.matches" class="fill-space"></div>
</div>
