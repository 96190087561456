import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {Project} from 'src/app/models/Project';
import {ObjectService} from 'src/app/services/object.service';
import {ReceiptformService} from 'src/app/services/receiptform.service';
import {SharedService} from 'src/app/services/shared.service';
import _ from 'underscore';
import {NewObjectComponent} from '../../objects/new-object/new-object.component';
import {AppVersion} from 'src/app/models/Admin';
import {TreeNode} from 'primeng/api';
import {MediaMatcher} from '@angular/cdk/layout';
import {environment} from '../../../../environments/environment';

export interface DialogData {
  version: AppVersion;
  projects: Project[];
  currentprojectid: string;
  inputObject: AventiObject;
}

@Component({
  selector: 'app-new-receiptform-popup',
  templateUrl: './new-receiptform-popup.component.html',
  styleUrls: ['./new-receiptform-popup.component.css'],
  // providers: [ObjectService],
})
export class NewReceiptformPopupComponent implements OnInit {
  objects: AventiObject[];
  // private ngUnsubscribeObjects = new Subject();
  objectsTemp: AventiObject[];
  selectedObjects: AventiObject[] = [];
  receiptForms: Checklist[];
  projectid: string;
  objectid: string;
  isLoadingObjects: boolean;
  receiptform: Checklist = {};
  types: TreeNode[];
  mobileQuery: MediaQueryList;
  private ngUnsubscribe = new Subject();

  constructor(
    media: MediaMatcher,
    public dialogRef: MatDialogRef<NewReceiptformPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private objectservice: ObjectService,
    private sharedservice: SharedService,
    private dialogNewObject: MatDialog,
    private receiptformservice: ReceiptformService
  ) {
  this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  async ngOnInit(): Promise<void> {
    if (this.data.inputObject) {
      this.selectedObjects.push(this.data.inputObject);
    }
    this.objects = null;
    if (this.data.currentprojectid) {
      this.projectid = this.data.currentprojectid;
      await this.loadObjects();
    }
    this.objectid = null;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async projectselect(event) {
    this.projectid = event.value;
    this.objects = null;
    this.selectedObjects = [];
    await this.loadObjects();
  }

  async loadObjects() {
    // this.isLoadingObjects = true;
    setTimeout(() => (this.isLoadingObjects = true));

    // this.ngUnsubscribeObjects.next(null);
    // this.ngUnsubscribeObjects.complete();

    let objectsObs: Observable<AventiObject[]>;
    let receiptFormsObs: Observable<Checklist[]>;
    if (this.data.currentprojectid) {
      objectsObs = this.sharedservice.objects;
      receiptFormsObs = this.sharedservice.receiptforms;
    } else {
      objectsObs = this.objectservice.getObjectsByProjectId(this.projectid);
      receiptFormsObs = this.receiptformservice.getReceiptforms(this.projectid);
      // objectsObs = this.sharedservice.objectsallprojects;
    }

    this.receiptForms = await firstValueFrom(receiptFormsObs);

    objectsObs
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects: AventiObject[]) => {
        if (objects !== null) {
          this.objectsTemp = []; // objects;
          objects.forEach((item) => {
            if (this.projectid === item.projectid) {
              item.typename = item.type.name;
              this.objectsTemp.push(item);
            }
          });
          this.objectsTemp = _.sortBy(this.objectsTemp, 'typename');
          this.objects = this.objectsTemp;
          // extract all types from this.objects
          const typeNames: string[] = [];
          const types = {};
          this.objects.forEach((o) => {
              const objectIdsCounted: string[] = [];
              if (typeNames.indexOf(o.type.name) === -1) {
                typeNames.push(o.type.name);
                types[o.type.name] = {
                  name: o.type.name,
                  total: 1,
                  count: 0,
                  receiptForms: [],
                };
              } else if (objectIdsCounted.indexOf(o.id) === -1) {
                objectIdsCounted.push(o.id);
                types[o.type.name].total++;
              }
              const receiptFormIdsCounted: string[] = [];
              this.receiptForms.forEach((rf) => {
                  rf.objects.forEach((rfo) => {
                      if (rfo.id === o.id) {
                        if (!o.receiptforms) {
                          o.receiptforms = [];
                        }
                        o.receiptforms.push(rf);
                        if (receiptFormIdsCounted.indexOf(rfo.id) === -1) {
                          receiptFormIdsCounted.push(rfo.id);
                          types[o.type.name].count++;
                          types[o.type.name].receiptForms.push(rf);
                        }
                      }
                    }
                  );
                }
              );
            }
          );
          console.log(this.receiptForms);
          // check if receiptForms.objects contains items in this.objects and include them in this.objects as receiptforms


          console.log(this.objects);
          const typesTree: TreeNode[] = [];
          typeNames.forEach((t) => {
              const type = {
                data: {
                  name: types[t].name,
                  object: null,
                  total: types[t].total,
                  count: types[t].count,
                  complete: types[t].count >= types[t].total || (types[t].total === -1 && types[t].receiptForms?.length > 0)
                },
                expandedIcon: 'pi pi-folder-open',
                collapsedIcon: 'pi pi-folder',
                children: [],
              };
              this.objects.forEach((o) => {
                if (o.type.name === t) {
                  type.children.push({
                    name: o.name,
                    data: {
                      name: o.name,
                      object: o,
                      total: null,
                      count: null,
                      complete: o.receiptforms?.length > 0
                    }
                  });
                }
              });
              typesTree.push(type);
            }
          );
          this.types = typesTree;
          console.log(this.types);

          setTimeout(() => (this.isLoadingObjects = false));
          // this.isLoadingObjects = false;
        }
      });
  }

  newObject() {
    this.dialogNewObject.open(NewObjectComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {projectid: this.projectid, hideOpenAfterCreated: true},
    }).afterClosed().subscribe(res => {
      // this.loadObjects();
      // console.log(res);
      if (res) {
        const o = res.data;
        const newo: AventiObject = {
          id: o.id,
          name: o.name,
          desc: o.desc,
          type: o.type,
        };
        this.selectedObjects.push(newo);
        // console.log(this.selectedObjects);
      }
    });
  }

  selectObjects(objects: AventiObject[]) {
    const newObjects: AventiObject[] = [];
    objects.forEach((o) => {
      const newo: AventiObject = {
        id: o.id,
        name: o.name,
        desc: o.desc,
        type: o.type,
      };
      newObjects.push(newo);
    });
    this.selectedObjects = newObjects;
    console.log(this.selectedObjects);
  }

  async createReceiptform() {
    const selectedObjectsFiltered = this.selectedObjects.filter((o: any) => !o.children);
    const onlyObjects: AventiObject[] = selectedObjectsFiltered.map((o: any) => o.data.object);
    onlyObjects.forEach((o: any) => delete o.checklists);
    console.log(onlyObjects);
    this.receiptform = {
      name: 'Mottak ' + new Date().toISOString(),
      desc: '',
      objects: onlyObjects,
      receiptform: true,
      projectid: this.projectid,
      status: 'not_started',
    };
    await this.receiptformservice
      .createReceiptform(this.receiptform, true)
      .then(() => {
        this.dialogRef.close();
      })
      .catch((err) => {
        console.error(err.message);
      });
  }
}
