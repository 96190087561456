<!-- <mat-tab-group>
  <mat-tab label="Ett objekt"> -->
    <app-headertext text="Nytt prosjekt" hideback="true"></app-headertext>
    <form *ngIf="projectIds"
      [formGroup]="Form"
      (ngSubmit)="create(no.value, name.value, nameshort.value)"
    >
      <div class="form-group">
        <!-- <p>
          <mat-form-field>
            <input
              matInput
              id="no"
              type="number"
              placeholder="Nummer"
              formControlName="no"
            />
            <mat-error *ngIf="no.touched && no.invalid">
              <div *ngIf="!no.errors.required">Please enter a valid number</div>
              <div *ngIf="no.errors.required">
                Object number is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p> -->
        <p>
          <!-- <mat-icon>person_outline</mat-icon> -->
          <mat-form-field>
            <input
              matInput
              id="no"
              type="text"
              placeholder="Nummer"
              formControlName="no"
            />
            <mat-error *ngIf="no.touched && no.invalid">
              <div *ngIf="!no.errors.required && ! no.errors?.projectNoExists">
                Please enter a project no
              </div>
              <div *ngIf="no.errors.required">
                Project no is <strong>required</strong>
              </div>
              <div *ngIf="no.errors?.projectNoExists">
                Project number exists.
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <!-- <mat-icon>person_outline</mat-icon> -->
          <mat-form-field>
            <input
              matInput
              id="name"
              type="text"
              placeholder="Navn"
              formControlName="name"
            />
            <mat-error *ngIf="name.touched && name.invalid">
              <div *ngIf="!name.errors.required">
                Please enter a project name
              </div>
              <div *ngIf="name.errors.required">
                Project name is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <!-- <mat-icon>person_outline</mat-icon> -->
          <mat-form-field>
            <input
              matInput
              id="nameshort"
              type="text"
              placeholder="Kort navn"
              formControlName="nameshort"
            />
            <mat-error *ngIf="nameshort.touched && nameshort.invalid">
              <div *ngIf="!nameshort.errors.required">
                Please enter a nameshort
              </div>
              <div *ngIf="nameshort.errors.required">
                Project nameshort is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid">
            Lagre
          </button>
        </p>
        <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
      </div>
    </form>
    <!-- </mat-tab>
      <mat-tab label="Flere objekter">
        <input type="file" (change)="fileChange($event)" name="file" />

        <h4 *ngIf="showMessage">{{ message }}</h4>
      </mat-tab>
    </mat-tab-group> -->
