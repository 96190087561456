import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AventiFile} from 'src/app/models/File';
import {User} from 'src/app/models/User';
import {FileService} from 'src/app/services/file.service';
import {SharedService} from 'src/app/services/shared.service';
import {environment} from 'src/environments/environment';
import FileSaver from 'file-saver';
import {PopupService} from 'src/app/services/popup.service';
import {DateService} from '../../services/date.service';
import {SortService} from '../../services/sort.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
})
export class DocumentsComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  @Input() files: AventiFile[];
  @Input() dbPath: string;

  isLoading = true;
  user: User;
  mobileQuery: MediaQueryList;
  isAdmin: boolean;
  nofiles: boolean;

  isOnline: boolean;

  constructor(
    media: MediaMatcher,
    private fileservice: FileService,
    private popupservice: PopupService,
    private dateservice: DateService,
    private sortservice: SortService,
    private sharedservice: SharedService,
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    sharedservice.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      if (user !== null) {
        this.user = user[0];
        if (this.user.role?.name === 'admin') {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      }
    });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.setupFiles();
  //   // You can also use categoryId.previousValue and
  //   // categoryId.firstChange for comparing old and new values
  // }

  ngOnInit(): void {
    this.sharedservice.online.subscribe(online => {
      this.isOnline = online;
    });
    this.setupFiles();
  }

  setupFiles() {
    this.files?.forEach((file) => {
      file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
        file.timestamp
      );
      if (file.lastupdated) {
        file.lastupdatedString = this.dateservice.dateAsHHNNDDMMYYY(
          file.lastupdated
        );
      }
    });
    if (this.files?.length > 0) {
      this.files = this.files.sort(
        this.sortservice.dynamicSort('timestampstring')
      );
    }
    if (!this.files) {
      this.nofiles = true;
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  openDialogImagePreview(file) {
    if (this.isOnline) {
      let indexTemp = -1;
      let index = 0;
      this.files.forEach((f) => {
        indexTemp++;
        if (f.id === file.id) {
          index = indexTemp;
        }
      });
      this.popupservice.openDialogFilePreview(this.files, index, !this.isAdmin);
    }
  }

  delete(file) {
    this.fileservice.deleteFile(file);
  }

  filedownloadDirect(file) {
    FileSaver.saveAs(file.downloadurl, file.name);
  }
}
