import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AventiObject } from 'src/app/models/AventiObject';
import { Map, MapPolyline } from 'src/app/models/Map';
import { Project } from 'src/app/models/Project';
import { User } from 'src/app/models/User';
import { DbpathService } from 'src/app/services/dbpath.service';
import { MapService } from 'src/app/services/map.service';
import { ObjectService } from 'src/app/services/object.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

export interface MapConfiguration extends google.maps.MapOptions {
  /*
   * `mapId` not yet available in the google.maps.MapOptions type definition
   * See https://developers.google.com/maps/documentation/javascript/using-typescript
   */
  readonly mapId?: string;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

@Component({
  selector: 'app-partial-map',
  templateUrl: './partial-map.component.html',
  styleUrls: ['./partial-map.component.css'],
  providers: [GoogleMap],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-30px)', opacity: 0 }),
        animate(
          '100ms ease-out',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(
          '100ms ease-in',
          style({ transform: 'translateY(-30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class PartialMapComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  private ngUnsubscribe = new Subject();

  // @Input() mapDraggable: boolean = false;
  // @Input() markersDraggable: boolean = false;
  // @Input() zoom: number = 14;
  // @Input() center: google.maps.LatLngLiteral;
  // @Input() polyline: google.maps.LatLngLiteral[];
  // @Input() cabinets: {
  //   pos: google.maps.LatLngLiteral;
  //   name: string;
  // }[];

  googlemap: google.maps.Map;
  center: google.maps.LatLngLiteral;
  zoom: number;
  options: MapConfiguration;

  map: Map;
  @Input() object?: AventiObject;
  objects?: AventiObject[];

  project: Project;

  objectIconSmall = '../../../../assets/icons/traffic_white_24dp.svg';
  objectIconMedium = '../../../../assets/icons/traffic_white_36dp.svg';
  objectIconLarge = '../../../../assets/icons/traffic_white_48dp.svg';
  lineIcon = '../../../../assets/icons/gesture_white_48dp.svg';
  lineColor = '#FED949'; // '#278426';

  isLoading = true;

  showMapPill = false;
  pillHeader = 'Insert header here';
  pillText = 'Insert text here';
  pillIcon: string = this.lineIcon;

  // Options: MapConfiguration;
  // markerOptions: google.maps.MarkerOptions;
  // markerOptionsCabinets: google.maps.MarkerOptions;
  // markerPositions: google.maps.LatLngLiteral[] = [];
  // markerPositionsCabinets: {
  //   pos: google.maps.LatLngLiteral;
  //   markerOptions: google.maps.MarkerOptions;
  // }[] = [];
  // width: string;

  markers: google.maps.Marker[] = [];

  routeSource: google.maps.LatLngLiteral;
  routeDestination: google.maps.LatLngLiteral;

  routeDs: google.maps.DirectionsService = new google.maps.DirectionsService();
  routeDr: google.maps.DirectionsRenderer = new google.maps.DirectionsRenderer({
    map: null,
    suppressMarkers: true,
  });

  user: User;

  editCabinet: boolean;
  editCabinetColor = '';
  editLine: boolean;
  editLineColor = '';
  line: google.maps.LatLngLiteral[] = [];

  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  deviceInfo;

  constructor(
    private sharedservice: SharedService,
    private mapservice: MapService,
    private dbpathservice: DbpathService,
    private deviceService: DeviceDetectorService,
    private objectservice: ObjectService
  ) {}

  ngOnInit(): void {
    this.sharedservice.project
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((project) => {
        if (project !== null) {
          this.project = project;
          this.initMap();
        }
      });
    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects) => {
        if (objects !== null) {
          this.objects = objects;
          this.initMap();
        }
      });
    this.sharedservice.user
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => {
        if (u !== null) {
          this.user = u[0];
          this.initMap();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  initMap() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log(this.isMobile); // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(this.isTablet); // returns if the device us a tablet (iPad etc)
    console.log(this.isDesktopDevice); // returns if the app is running on a Desktop browser.
    if (this.project && this.user && this.objects) {
      if (!this.map) {
        this.map = this.project.map;
        this.center = this.map?.center
          ? this.map.center
          : { lat: 59.927784, lng: 10.806302 };
        this.zoom = this.map?.zoom ? this.map.zoom : 8;

        this.options = {
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          // scrollwheel: false,
          disableDefaultUI: true,
          // disableDoubleClickZoom: true,
          zoom: this.zoom,
          mapId: environment.mapIdDarkmode,
          // draggable: false,
          fullscreenControl: this.isDesktopDevice ? false : true,
          scaleControl: true,
        };
        this.googlemap = new google.maps.Map(
          document.getElementById('map-canvas'),
          {
            ...this.options,
            center: this.center,
          }
        );
        // if (this.isDesktopDevice) {
        //   this.initFullscreenControl(this.googlemap);
        // }

        this.googlemap.addListener('zoom_changed', () => {
          console.log();
        });
        this.googlemap.addListener('click', (mapsMouseEvent) => {
          console.log(this.editCabinet);
          this.showMapPill = false;
          if (this.editCabinet) {
            this.object.pos = mapsMouseEvent.latLng.toJSON();
            this.objectservice.updateObject(this.object);
            this.addMarker(this.object, true);
          }
          if (this.editLine) {
            if (!this.routeSource) {
              this.routeSource = mapsMouseEvent.latLng.toJSON();
            } else if (!this.routeDestination) {
              this.routeDestination = mapsMouseEvent.latLng.toJSON();
            }
            if (this.routeSource && this.routeDestination) {
              this.setRoutePolyline();
            }
          }
        });
        this.map?.polylines?.forEach((line) => {
          this.addPolyline(line);
        });
      }
      this.deleteMarkers();
      this.objects?.forEach((object) => {
        if (object.id === this.object?.id) {
          this.addMarker(object, true);
        } else {
          this.addMarker(object);
        }
      });

      this.isLoading = false;
      // });
      // this.markerOptions = { draggable: this.map.markersDraggable };}
    }
  }

  addPolyline(line: MapPolyline) {
    const polyline = new google.maps.Polyline({
      path: line.pos,
      strokeColor: this.lineColor,
      map: this.googlemap,
    });
    polyline.addListener('click', (event: any) => {
      console.log(event);
      this.pillHeader = 'Strekning: ' + line.name;
      this.pillText = '';
      this.pillIcon = this.lineIcon;
      this.showMapPill = true;
      // this.onCenterMap();
    });
  }

  addMarker(object: AventiObject, isSignificant: boolean = false) {
    console.log(isSignificant);
    const marker = new google.maps.Marker({
      position: object.pos,
      draggable: false,
      title: object.name,
      animation: isSignificant ? google.maps.Animation.BOUNCE : null,
      icon: isSignificant ? this.objectIconLarge : this.objectIconMedium,
      map: this.googlemap,
    });
    marker.addListener('click', (event: any) => {
      console.log(event);
      this.pillHeader = object.type?.name + ': ' + object.name;
      this.pillText = '';
      this.pillIcon = this.objectIconLarge;
      this.showMapPill = true;
      // this.onCenterMap();
    });
    this.markers.push(marker);
    // this.mapservice.updateMap(this.googlemap)
  }

  // Sets the map on all markers in the array.
  setMapOnAll(map: google.maps.Map | null) {
    for (const item of this.markers) {
      item.setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  hideMarkers(): void {
    this.setMapOnAll(null);
  }

  // Shows any markers currently in the array.
  showMarkers(): void {
    this.setMapOnAll(this.googlemap);
  }

  // Deletes all markers in the array by removing references to them.
  deleteMarkers(): void {
    this.hideMarkers();
    this.markers = [];
  }

  mapClicked(event: google.maps.MapMouseEvent) {
    console.log(event.latLng.toJSON());
    // this.markerPositions.push(event.latLng.toJSON());
  }

  setRoutePolyline() {
    const request = {
      origin: this.routeSource,
      destination: this.routeDestination,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    this.routeDs.route(request, (response, status) => {
      this.routeDr.setOptions({
        suppressPolylines: false,
        map: this.googlemap,
      });

      if (status === google.maps.DirectionsStatus.OK) {
        console.log(response);
        this.routeDr.setDirections(response);
        const newline: google.maps.LatLngLiteral[] = [];
        response.routes[0].overview_path.forEach((point) => {
          newline.push(point.toJSON());
          this.routeSource = null;
          this.routeDestination = null;
        });
        if (!this.map.polylines) {
          this.map.polylines = [];
        }
        this.map.polylines.push({ pos: newline });
        this.mapservice.updateMap(this.dbpathservice.dbPathProject(), this.map);
      }
    });
  }

  openInfoWindow(marker: MapMarker) {
    console.log(marker.getTitle());
    this.infoWindow.options = {
      content:
        '<h3>' +
        marker.getTitle() +
        '</h3><button (click)="cabinetClicked()">hello click me!</button>',
    };
    this.infoWindow.open(marker);
  }

  cabinetClicked() {
    console.log('Success! Cabinet clicked button');
  }

  editCabinetClicked() {
    this.editCabinet = !this.editCabinet;
    if (this.editCabinet) {
      this.editCabinetColor = 'accent';
      this.editLineColor = '';
    } else {
      this.editCabinetColor = '';
    }
  }

  editLineClicked() {
    this.editLine = !this.editLine;
    if (this.editLine) {
      this.editLineColor = 'accent';
      this.editCabinetColor = '';
    } else {
      this.editLineColor = '';
    }
  }

  zoomChange(event) {
    console.log(event);
    console.log(this.map.zoom);
    console.log(this.googlemap.getZoom());
  }

  zoomIn() {
    this.googlemap.setZoom(this.googlemap.getZoom() + 1);
  }
  zoomOut() {
    this.googlemap.setZoom(this.googlemap.getZoom() - 1);
  }

  centerChange(event) {
    console.log(event);
    console.log(this.map.center);
  }
  updateZoomClicked() {
    // this.map.zoom =
    // this.mapservice.updateMap()
  }
  // fullScreen() {
  //   this.googlemap.controls.
  // }

  // initMap(): void {
  //   this.googlemap = new google.maps.Map(
  //     document.querySelector('#map') as HTMLElement,
  //     {
  //       center: { lat: -34.397, lng: 150.644 },
  //       zoom: 8,
  //       disableDefaultUI: true,
  //     }
  //   );

  //   this.initZoomControl(this.googlemap);
  //   this.initMapTypeControl(this.googlemap);
  //   this.initFullscreenControl(this.googlemap);
  // }

  // initZoomControl(map: google.maps.Map) {
  //   (document.querySelector('.zoom-control-in') as HTMLElement).onclick =
  //     function () {
  //       map.setZoom(map.getZoom()! + 1);
  //     };

  //   (document.querySelector('.zoom-control-out') as HTMLElement).onclick =
  //     function () {
  //       map.setZoom(map.getZoom()! - 1);
  //     };

  //   map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
  //     document.querySelector('.zoom-control') as HTMLElement
  //   );
  // }

  // initMapTypeControl(map: google.maps.Map) {
  //   const mapTypeControlDiv = document.querySelector(
  //     '.maptype-control'
  //   ) as HTMLElement;

  //   (document.querySelector('.maptype-control-map') as HTMLElement).onclick =
  //     function () {
  //       mapTypeControlDiv.classList.add('maptype-control-is-map');
  //       mapTypeControlDiv.classList.remove('maptype-control-is-satellite');
  //       map.setMapTypeId('roadmap');
  //     };

  //   (
  //     document.querySelector('.maptype-control-satellite') as HTMLElement
  //   ).onclick = function () {
  //     mapTypeControlDiv.classList.remove('maptype-control-is-map');
  //     mapTypeControlDiv.classList.add('maptype-control-is-satellite');
  //     map.setMapTypeId('hybrid');
  //   };

  //   map.controls[google.maps.ControlPosition.LEFT_TOP].push(mapTypeControlDiv);
  // }

  initFullscreenControl(map: google.maps.Map) {
    const elementToSendFullscreen = map.getDiv().firstChild as HTMLElement;
    const fullscreenControl = document.querySelector(
      '.fullscreen-control'
    ) as HTMLElement;

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      fullscreenControl
    );

    const fullscreencontrolicon = document.getElementById(
      'fullscreen-control-icon'
    );

    fullscreenControl.addEventListener('click', (mapsMouseEvent) => {
      console.log(mapsMouseEvent);
      if (this.isFullscreen(elementToSendFullscreen)) {
        fullscreencontrolicon.innerText = 'fullscreen';
        this.exitFullscreen();
      } else {
        fullscreencontrolicon.innerText = 'fullscreen_exit';
        this.requestFullscreen(elementToSendFullscreen);
      }
    });

    document.onwebkitfullscreenchange =
      document.onmsfullscreenchange =
      document.onmozfullscreenchange =
        document.addEventListener('onfullscreenchange', () => {
          if (this.isFullscreen(elementToSendFullscreen)) {
            fullscreenControl.classList.add('is-fullscreen');
          } else {
            fullscreenControl.classList.remove('is-fullscreen');
          }
        });
  }

  isFullscreen(element: HTMLElement) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }

  requestFullscreen(element: HTMLElement) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
