<app-spinner
  disable="false"
  [text]="[
    'Laster inn ' +
      (type === 'receiptform' ? 'mottakskontroller' : 'sjekklister') +
      '...'
  ]"
  header="Vennligst vent..."
  *ngIf="isLoading && !tableFiltersReady"
></app-spinner>

<app-spinner
  id="app_spinner"
  disable="false"
  [text]="class.progressstring"
  header="Vennligst vent..."
  *ngIf="class.progressstring.length !== 0"
></app-spinner>

<div class="row" *ngIf="type === 'receiptform'">
  <app-headertext [hideback]="mobileQuery.matches" text="Mottakskontroll"></app-headertext>
  <div class="fill-space"></div>
  <button
    mat-button
    matTooltip="Nytt mottak"
    (click)="newReceiptform()"
    class="header-row-button"
  >
    <mat-icon color="primary">add</mat-icon>
    Nytt mottak
  </button>
</div>

<div class="row" *ngIf="type === 'checklist'">
  <app-headertext [hideback]="mobileQuery.matches" text="Sjekklister"></app-headertext>
  <div class="fill-space"></div>

  <!--  <p-button-->
  <!--    icon="pi pi-shield"-->
  <!--    [label]="!mobileQuery.matches ? 'Admin' : ''"-->
  <!--    [rounded]="mobileQuery.matches"-->
  <!--    [matMenuTriggerFor]="menu"-->
  <!--    *ngIf="user.role.name === 'admin'"-->
  <!--    severity="warning"-->
  <!--  >-->
  <!--  </p-button>-->
  <button
    *ngIf="user.role.name === 'admin'"
    mat-button
    [ngClass]="mobileQuery.matches ? 'mat-icon-button' : 'mat-button'"
    [matMenuTriggerFor]="menu"
    class="header-row-button"
  >
    <mat-icon color="accent">admin_panel_settings</mat-icon>
    <span *ngIf="!mobileQuery.matches">Admin</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="downloadAllPdfsNotSent()" *ngIf="user.superAdmin">
      <mat-icon>download</mat-icon>
      Last ned ikke sendte PDF
    </button>
  </mat-menu>
</div>


<div style="margin: 0 15px 0 15px">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoading || !tableFiltersReady"
  ></mat-progress-bar>


  <p-table
    [hidden]="isLoading"
    #dataTable
    [value]="checklistsFrontend"
    [tableStyle]="{ 'min-height': '400px','max-width': '100vw', 'font-size': '11px', 'font-weight': '100' }"
    [paginator]="true"
    [alwaysShowPaginator]="true"
    paginatorDropdownAppendTo="body"
    [rows]="25"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} / {totalRecords}"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    styleClass="p-datatable-striped p-datatable-sm"
    [globalFilterFields]="filterFields"
    [selectionMode]="isOnline && !mobileQuery.matches ? 'multiple' : 'single'"
    [(selection)]="selectedRows"
    dataKey="id"
    [scrollable]="true"
    [scrollHeight]="mobileQuery.matches ? 'calc(100dvh - 310px)' : 'calc(100dvh - 240px)'"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnSelect($event)"
    (onFilter)="onFilter($event)"
    [filters]="tableFilters"
    (onSort)="onSort($event)"
    sortMode="multiple"
    [multiSortMeta]="tableSort"
  >

    <ng-template pTemplate="caption">
      <div class="row"
           [hidden]="!tableFiltersReady">
        <div>
          <p-skeleton width="85px" height="34px"
                      [hidden]="tableFiltersReady"></p-skeleton>
          <p-button icon="pi pi-filter-slash"
                    label="Clear"
                    [style]="{'margin-right': '5px'}"
                    [outlined]="true"
                    [hidden]="!tableFiltersReady"
                    *ngIf="tableFiltersReady"
                    (click)="clearTable(dataTable)"
          ></p-button>
        </div>
        <div class="fill-space"></div>

        <div *ngIf="!mobileQuery.matches" class="child-margin" style="margin: 0 15px 0 15px">
          <p-tag *ngFor="let status of statuses"
                 [value]="status.count + ' - ' + status.label"
                 [severity]="tableFilters['status']?.value?.includes(status.value) ? 'success' : ''"
                 [style]="tableFilters['status']?.value?.includes(status.value) ? {} : { 'background': '#777' }"
                 class="cursor"
                 (click)="toggleStatusFilter(status.value)"
          ></p-tag>
        </div>

        <div>
          <p-skeleton width="165px" height="34px"
                      [hidden]="tableFiltersReady"></p-skeleton>
          <span class="p-input-icon-left ml-auto"
                *ngIf="tableFiltersReady">
                    <i class="pi pi-search" *ngIf="tableFiltersReady"></i>
                    <input pInputText type="text" [value]="searchString" style="width: 100%"
                           (input)="dataTable.filterGlobal($any($event.target)?.value, 'contains')" placeholder="Søk"
                           [hidden]="!tableFiltersReady"
                    />
              </span>
        </div>
      </div>
      <div class="row"
           *ngIf="selectedRows.length > 1 && isOnline && user.role?.name === 'admin'">
        <p-button icon="pi pi-trash"
                  label="Slett valgte sjekklister"
                  [style]="{'margin-right': '5px', 'margin-top': '5px'}"
                  [outlined]="true"
                  *ngIf="selectedRows.length > 1 && isOnline"
                  (click)="deleteMultipleChecklists()"
        ></p-button>
      </div>
      <div *ngIf="mobileQuery.matches || !mobileQuery.matches" style="padding-top: 5px;">
        <p-overlayPanel #opFilter>
          <p-columnFilter field="process" matchMode="in" [showMenu]="false" [hidden]="type === 'receiptform'">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="processes"
                             placeholder="Søk i Prosess"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
                             optionLabel="label"
                             optionValue="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
          <p-columnFilter field="typename" matchMode="in" [showMenu]="false" [hidden]="type === 'receiptform'">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="types"
                             placeholder="Søk i Type"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
                             optionLabel="label"
                             optionValue="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
          <p-columnFilter type="text" field="nameSearchString" matchMode="contains" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text"
                     pInputText
                     [ngModel]="value"
                     (ngModelChange)="filter($event)"
                     class="p-inputtext"
                     style="min-width: 220px;"
                     placeholder="Søk i navn, beskrivelse, kommentar">
            </ng-template>
          </p-columnFilter>
          <p-columnFilter type="text" field="objectsSearchString" matchMode="contains" [showMenu]="false"
                          [hidden]="type !== 'receiptform'">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text"
                     pInputText
                     [ngModel]="value"
                     (ngModelChange)="filter($event)"
                     class="p-inputtext"
                     placeholder="Søk i tilhørende objekt">
            </ng-template>
          </p-columnFilter>
          <p-columnFilter field="profilenumber" matchMode="in" [showMenu]="false" [hidden]="type === 'receiptform'">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="profilenumbers"
                             placeholder="Søk i Profilnummer"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
                             optionLabel="label"
                             optionValue="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
          <p-columnFilter field="status" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="statuses"
                             placeholder="Søk i Status"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
          <p-columnFilter type="text" field="tagsSearchString" matchMode="contains" [showMenu]="false"
                          [hidden]="type === 'receiptform'">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text"
                     pInputText
                     [ngModel]="value"
                     (ngModelChange)="filter($event)"
                     class="p-inputtext"
                     placeholder="Søk i Tags">
            </ng-template>
          </p-columnFilter>
          <div class="row">
            <span style="font-size: 11px; font-weight: 200; margin-top: 9px; margin-right: 15px; margin-left: 10px;">Levert til kunde</span>
            <div class="fill-space"></div>
            <p-columnFilter type="boolean" field="PDFDeliveredToCustomerBoolean"></p-columnFilter>
          </div>
          <div class="row">
            <span
              style="font-size: 11px; font-weight: 200; margin-top: 9px; margin-right: 15px; margin-left: 10px;">PDF</span>
            <div class="fill-space"></div>
            <p-columnFilter type="boolean" field="pdfexportsBoolean"></p-columnFilter>
          </div>
        </p-overlayPanel>
        <p-overlayPanel #opSort>
          <div class="row">
            <div class="fill-space"></div>
            <div>
              <p-button icon="pi pi-sort-alt-slash"
                        [rounded]="true"
                        [text]="true"
                        label="Clear"
                        (click)="resetTableSort();"
              ></p-button>
            </div>
          </div>
          <p *ngFor="let button of sortButtons">
            <p-button
              [icon]="'pi ' + ((button.sort.order === 1) ? 'pi-sort-amount-up-alt' : (button.sort.order === -1) ? 'pi-sort-amount-down-alt' : 'pi-sort-alt')"
              (click)="sortFromButton(button)"
              [outlined]="button.sort.order === 0"
            ></p-button>
            <span style="padding-left: 15px; font-size: 13px">{{ button.name }}</span>
            <p-badge [style]="{ 'margin-left': '15px', 'background-color': '#444', 'color': '#ccc' }"
                     [value]="button.index.toString()" *ngIf="button.index && button.sort.order !== 0"></p-badge>
          </p>
        </p-overlayPanel>
        <div class="row" *ngIf="mobileQuery.matches">
          <div>
            <p-skeleton width="105px" height="34px"
                        [hidden]="tableFiltersReady"></p-skeleton>
            <p-button (click)="opSort.toggle($event)" icon="pi pi-sort-alt" label="Sortering"
                      [style]="{'margin-right': '5px'}"
                      [outlined]="!numberOfTableSorts || numberOfTableSorts === 0"
                      [hidden]="!tableFiltersReady"
            ></p-button>
          </div>
          <div class="fill-space"></div>
          <div>
            <p-skeleton width="105px" height="34px"
                        [hidden]="tableFiltersReady"></p-skeleton>
            <p-button (click)="opFilter.toggle($event)"
                      icon="pi pi-filter"
                      label="Filter"
                      [outlined]="!numberOfTableFilters || numberOfTableFilters === 0"
                      [hidden]="!tableFiltersReady"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>


    <ng-template pTemplate="header">

      <tr [hidden]="mobileQuery.matches">
        <!--          DESKTOP         -->
        <th [hidden]="mobileQuery.matches || type === 'receiptform'" pSortableColumn="process">Prosess
          <p-sortIcon field="process"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches || type === 'receiptform'" pSortableColumn="typename" style="width:20%">Type
          <p-sortIcon field="typename"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches" pSortableColumn="nameSearchString"
            style="width:20%">{{ type === 'receiptform' ? 'Navn' : 'Objekt' }}
          <p-sortIcon field="nameSearchString"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches || type !== 'receiptform'" pSortableColumn="objectsSearchString"
            style="width:20%">Tilhørende objekter
          <p-sortIcon field="objectsSearchString"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches || type === 'receiptform'" pSortableColumn="profilenumber">Pnummer
          <p-sortIcon field="profilenumber"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches || type === 'receiptform'" pSortableColumn="tagsSearchString">Tags
          <p-sortIcon field="tagsSearchString"></p-sortIcon>
        </th>
        <!--          <th pSortableColumn="comment">Kommentarer-->
        <!--            <p-sortIcon field="comment"></p-sortIcon>-->
        <!--          </th>-->
        <th [hidden]="mobileQuery.matches" pSortableColumn="status" [style]="{ 'width': '150px' }">Status
          <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches" pSortableColumn="PDFDeliveredToCustomerBoolean"
            [style]="{ 'width': '50px' }">Levert kunde
          <p-sortIcon field="PDFDeliveredToCustomerBoolean"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches" pSortableColumn="numberOfDeviations" [style]="{ 'width': '50px' }">Avvik
          <p-sortIcon field="numberOfDeviations"></p-sortIcon>
        </th>
        <th [hidden]="mobileQuery.matches" [style]="{ 'width': '50px' }">PDF
          <!--            <p-sortIcon field="pdfexportsBoolean"></p-sortIcon>-->
        </th>
      </tr>

      <tr *ngIf="!tableFiltersReady && !mobileQuery.matches" style="margin: 10px;">
        <!--          DESKTOP        -->
        <td [hidden]="type === 'receiptform'">
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px', 'margin-right': '8px' }" height="30px"
                      width="125px"></p-skeleton>
        </td>
        <td [hidden]="type === 'receiptform'">
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px', 'margin-right': '8px' }" height="30px"
                      width="110px"></p-skeleton>
        </td>
        <td>
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px', 'margin-right': '8px' }" height="30px"
                      width="220px"></p-skeleton>
        </td>
        <td [hidden]="type === 'receiptform'">
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
        </td>
        <td>
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
        </td>
        <td>
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="30px" width="60%"></p-skeleton>
        </td>
        <td>
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="20px" width="20px"></p-skeleton>
        </td>
        <td style="width: 20px;"></td>
        <td>
          <p-skeleton [style]="{ 'margin-top': '8px', 'margin-left': '8px' }" height="20px" width="20px"></p-skeleton>
        </td>
      </tr>


      <tr [hidden]="mobileQuery.matches || !tableFiltersReady">
        <!--          DESKTOP         -->
        <th [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-columnFilter field="process" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="processes"
                             placeholder="Søk i Prosess"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
                             optionLabel="label"
                             optionValue="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-columnFilter field="typename" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="types"
                             placeholder="Søk i Type"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
                             optionLabel="label"
                             optionValue="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches">
          <p-columnFilter type="text" field="nameSearchString" matchMode="contains" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text"
                     pInputText
                     [ngModel]="value"
                     (ngModelChange)="filter($event)"
                     class="p-inputtext"
                     placeholder="Søk i {{ type === 'receiptform' ? 'navn, beskrivelse, kommentar' : 'navn, beskrivelse, kommentar'}}"
                     style="min-width: 210px;"
              >
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches || type !== 'receiptform'">
          <p-columnFilter type="text" field="objectsSearchString" matchMode="contains" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text"
                     pInputText
                     [ngModel]="value"
                     (ngModelChange)="filter($event)"
                     class="p-inputtext"
                     placeholder="Søk i tilhørende objekt"
                     style="min-width: 185px;"
              >
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-columnFilter field="profilenumber" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="profilenumbers"
                             placeholder="Søk i Profilnummer"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
                             optionLabel="label"
                             optionValue="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-columnFilter type="text" field="tagsSearchString" matchMode="contains" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text"
                     pInputText
                     [ngModel]="value"
                     (ngModelChange)="filter($event)"
                     class="p-inputtext"
                     placeholder="Søk i Tags"
                     style="min-width: 75px;"
              >
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches" [style]="{ 'width': '150px' }">
          <p-columnFilter field="status" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [options]="statuses"
                             placeholder="Søk i Status"
                             (onChange)="filter($event.value)"
                             [ngModel]="value"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches" [style]="{ 'width': '50px' }">
          <p-columnFilter type="boolean" field="PDFDeliveredToCustomerBoolean"></p-columnFilter>
        </th>
        <th [hidden]="mobileQuery.matches" [style]="{ 'width': '50px' }">
        </th>
        <th [hidden]="mobileQuery.matches" [style]="{ 'width': '50px' }">
          <p-columnFilter type="boolean" field="pdfexportsBoolean"></p-columnFilter>
        </th>
      </tr>

    </ng-template>

    <ng-template pTemplate="body" let-checklist>

      <tr *ngIf="!tableFiltersReady">
        <!--          MOBILE         -->
        <td [hidden]="!mobileQuery.matches" style="width: 80%">
          <p-skeleton [style]="{ 'margin-bottom': '20px', 'margin-top': '20px' }" width="80%"
                      height="30px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="40%" height="20px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="70%" height="10px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="60%" height="10px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="80%" height="10px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="60%" height="10px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="70%" height="10px"></p-skeleton>
        </td>
        <td [hidden]="!mobileQuery.matches" style="text-align: center;align-content: center; vertical-align: top;">
          <p-skeleton [style]="{ 'margin-bottom': '10px' }" width="40px" height="20px"></p-skeleton>
          <p-skeleton [style]="{ 'margin-left': '3px' }" shape="circle" size="34px"></p-skeleton>
        </td>
        <!--          DESKTOP        -->
        <td [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches || type !== 'receiptform'">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches || type === 'receiptform'">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches">
          <p-skeleton height="30px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches">
          <p-skeleton height="20px" width="20px"></p-skeleton>
        </td>
        <td [hidden]="mobileQuery.matches" style="width: 20px;"></td>
        <td [hidden]="mobileQuery.matches">
          <p-skeleton shape="circle" size="34px"></p-skeleton>
        </td>
      </tr>

      <tr [pSelectableRow]="checklist" [hidden]="!tableFiltersReady">
        <!--          MOBILE         -->
        <td [hidden]="!mobileQuery.matches" class="p-column-title"
            style="vertical-align: top">
          <p style="font-size: 16px; font-weight: 600;">{{ checklist.name }}</p>
          <p>
            <p-tag [value]="checklist.typename" [hidden]="type === 'receiptform'"></p-tag>
            <p-tag *ngIf="checklist.numberOfDeviations > 0" icon="pi pi-exclamation-triangle"
                   [value]="checklist.numberOfDeviations"
                   [style]="{ 'margin-left': '10px' }"
                   severity="danger"></p-tag>
          </p>
          <!--            <p>-->
          <!--              <p-tag *ngIf="checklist.desc" [style]="{ 'background': 'none'}" icon="pi pi-book" [value]="checklist.desc" severity=""></p-tag>-->
          <!--            </p>-->
          <!--            <p>-->
          <!--              <p-tag *ngIf="checklist.comment" icon="pi pi-comment" [value]="checklist.comment"-->
          <!--                     severity="info"></p-tag>-->
          <!--            </p>-->
          <p>
            <span class="dimmed-50 margin-right-10">Beskrivelse:</span>
            {{ checklist.desc }}
          </p>
          <p>
            <span class="dimmed-50 margin-right-10">Kommentar:</span>
            {{ checklist.comment }}
          </p>
          <p [hidden]="type === 'receiptform'">
            <span class="dimmed-50 margin-right-10">Prosess:</span>
            {{ checklist.process }}
          </p>
          <p [hidden]="type === 'receiptform'">
            <span class="dimmed-50 margin-right-10">Profilnummer:</span>
            {{ checklist.profilenumber }}
          </p>
          <p [hidden]="type !== 'receiptform'">
            <span class="dimmed-50 margin-right-10">Tilhørende objekter:</span>
          </p>
          <ul [hidden]="type !== 'receiptform'">
            <li *ngFor="let object of checklist.objects">
              {{ object.name }}
            </li>
          </ul>
          <div class="row" *ngIf="type !== 'receiptform'">
            <div>
              <span class="dimmed-50 margin-right-10">Tags:</span>
            </div>
            <div>
              <table>
                <tr *ngFor="let t of checklist.tags">
                  <td style="font-size: smaller; text-align: left">
                    <b>{{ t.key }}:</b>
                  </td>
                  <td style="font-size: smaller; text-align: right">{{ t.value }}</td>
                </tr>
              </table>
            </div>
          </div>
        </td>
        <td [hidden]="!mobileQuery.matches" class=""
            style="text-align: center;align-content: center;vertical-align: top">
          <p class="align-top">
            <p-tag [value]="checklist.statusHuman" [severity]="getSeverity(checklist.status)"></p-tag>
          </p>
          <p class="align-bottom">
            <app-pdf-button [checklist]="checklist" (click)="toggleDisableRoute()"></app-pdf-button>
          </p>
          <p class="align-top" *ngIf="checklist.PDFDeliveredToCustomer?.status">
            <p-tag icon="pi pi-send" value="Levert" [style]="{ 'background': 'none', 'color': '#ccc' }"
                   severity="info"></p-tag>
          </p>
        </td>
        <!--          DESKTOP         -->
        <td [hidden]="mobileQuery.matches || type === 'receiptform'" class="p-column-title">
          {{ checklist.process }}
        </td>
        <td [hidden]="mobileQuery.matches || type === 'receiptform'" class="p-column-title">{{ checklist.typename }}
        </td>
        <td [hidden]="mobileQuery.matches" class="p-column-title">
          <p style="font-size: 13px; font-weight: 600;">{{ checklist.name }}</p>
          <p>
            <p-tag *ngIf="checklist.desc" icon="pi pi-book" [value]="checklist.desc" severity=""></p-tag>
          </p>
          <p>
            <p-tag *ngIf="checklist.comment" icon="pi pi-comment" [value]="checklist.comment" severity="info"></p-tag>
          </p>
        </td>
        <td [hidden]="mobileQuery.matches || type !== 'receiptform'" class="p-column-title">
          <li *ngFor="let object of checklist.objects">
            {{ object.name }}
          </li>
        </td>
        <td [hidden]="mobileQuery.matches || type === 'receiptform'" class="p-column-title">
          {{ checklist.profilenumber }}
        </td>
        <td [hidden]="mobileQuery.matches || type === 'receiptform'" class="p-column-title">
          <div *ngIf="checklist.tags?.length > 0">
            <table style="font-size: smaller; text-align: left">
              <tr *ngFor="let t of checklist.tags">
                <td>
                  <b>{{ t.key }}:</b>
                </td>
                <td style="text-align: right">{{ t.value }}</td>
              </tr>
            </table>
          </div>
        </td>
        <td [hidden]="mobileQuery.matches" class="p-column-title">
          <p-tag [value]="checklist.statusHuman" [severity]="getSeverity(checklist.status)"></p-tag>
        </td>
        <td [hidden]="mobileQuery.matches" class="p-column-title">
          <p-checkbox [binary]="true"
                      [ngModel]="checklist.PDFDeliveredToCustomerBoolean"
                      (click)="setDeliveredToCustomer(checklist); toggleDisableRoute()"
                      *ngIf="checklist.PDFDeliveredToCustomer && checklist.pdfexports?.length > 0"
                      [value]="checklist.PDFDeliveredToCustomerBoolean"
          ></p-checkbox>
        </td>
        <td [hidden]="mobileQuery.matches" class="p-column-title">
          <p-tag *ngIf="checklist.numberOfDeviations > 0" [value]="checklist.numberOfDeviations"
                 severity="danger"></p-tag>
        </td>
        <td [hidden]="mobileQuery.matches" class="p-column-title">
          <app-pdf-button [checklist]="checklist" (click)="toggleDisableRoute()"></app-pdf-button>
        </td>
      </tr>
    </ng-template>


    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="displayedColumns.length + 1"
            style="font-size: 24px; font-weight: 100; text-align: center; align-content: center">
          <span *ngIf="tableFiltersReady">Ingen {{ type === 'checklist' ? 'sjekklister' : 'mottakskjema' }}funnet</span>
          <div class="row" *ngIf="!tableFiltersReady">
            <div class="fill-space"></div>
            <div>
              <mat-spinner></mat-spinner>
            </div>
            <div class="fill-space"></div>
          </div>
        </td>
      </tr>
    </ng-template>
    <!--        <ng-template pTemplate="paginatorleft">-->
    <!--          <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>-->
    <!--        </ng-template>-->
    <!--        <ng-template pTemplate="paginatorright">-->
    <!--          <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>-->
    <!--        </ng-template>-->
    <!--      <ng-template pTemplate="footer">-->
    <!--        <tr [style]="{'height': '200px'}">-->
    <!--          <td [colSpan]="displayedColumns.length + 1" style="text-align: center" class="dimmed-50">-->
    <!--            <i class="pi pi-database" style="font-size: 70px"></i>-->
    <!--          </td>-->
    <!--        </tr>-->
    <!--      </ng-template>-->
  </p-table>
</div>
