import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'APG';

  constructor(
    // afs: AngularFirestore,
  ) {
    // afs.firestore.enablePersistence({
    //   experimentalForceOwningTab: true,
    //   synchronizeTabs: false
    // })
    //   .then(() => console.log(`Offline persistence initiated`))
    //   .catch(err => console.error(`Offline persistence failed`, err));
  }
  // addToHomeScreen() {
  //   // hide our user interface that shows our A2HS button
  //   this.showButton = false;
  //   // Show the prompt
  //   this.deferredPrompt.prompt();
  //   // Wait for the user to respond to the prompt
  //   this.deferredPrompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //       console.log('User accepted the A2HS prompt');
  //     } else {
  //       console.log('User dismissed the A2HS prompt');
  //     }
  //     this.deferredPrompt = null;
  //   });
  // }
}
