<div class="flex-container">
  <div *ngIf="!uploadDone && !button" class="flex-container">
    <div *ngFor="let prog of progress" class="flex-item">
      <div *ngIf="prog.snapshot | async as snap">
        <div
          *ngIf="snap.bytesTransferred !== snap.totalBytes"
          class="button card card-1 flex-item"
          [style.height]="size"
          [style.width]="size"
          style="
            margin: 3px;
            background-color: #66666630;
            color: #aaa;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
          "
          (click)="uploadButtonClicked()"
        >
          <mat-icon>image</mat-icon>
          <div
            style="
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              font-size: x-small;
              text-align: center;
            "
          >
            <!-- <div *ngIf="prog.snapshot | async as snap"> -->
            <div>
              {{ snap.bytesTransferred | fileSize }} of
              {{ snap.totalBytes | fileSize }}
            </div>
            <div *ngIf="prog.percentage | async as pct">
              <mat-progress-bar
                mode="determinate"
                [value]="pct"
                max="100"
              ></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div
    class="button card card-1 flex-item"
    [style.height]="size"
    [style.width]="size"
    *ngIf="button && !uploadDone"
    style="
      margin: 3px;
      background-color: #66666630;
      color: #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
    "
  ></div> -->
  <div
    class="button card card-1 flex-item"
    *ngIf="isOnline && !button && uploadDone"
    [style.height]="size"
    [style.width]="size"
    [style.opacity]="uploadDone ? '1' : '0.1'"
    style="
      margin: 3px;
      background-color: #66666630;
      color: #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
    "
    (click)="fileNameInput.click()"
  >
    <mat-icon>add</mat-icon>
  </div>
  <div
    class="button card card-1 flex-item"
    *ngIf="!isOnline && !button && uploadDone"
    matTooltip="Ikke mulig å laste opp når du er offline"
    matTooltipPosition="below"
    [style.height]="size"
    [style.width]="size"
    style="
      opacity: 0.4;
      margin: 3px;
      background-color: #66666630;
      color: #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
    "
  >
    <mat-icon>add</mat-icon>
  </div>
  <div
    class="button card card-1 flex-item"
    *ngIf="!button && !uploadDone"
    [style.height]="size"
    [style.width]="size"
    [style.opacity]="uploadDone ? '1' : '0.5'"
    style="
      margin: 3px;
      background-color: #66666630;
      color: #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: not-allowed;
      position: relative;
    "
  >
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</div>

<!-- <div style="font-size: x-small">
  Files:
  <div *ngFor="let f of file">
    <div>{{ f }}</div>
  </div>
</div> -->

<input
  type="file"
  #fileNameInput
  hidden
  multiple
  [accept]  = "'image/*, .heic, .heif, .pdf, .xls, .xlsx'"
  (change)="startUpload($event.target.files, fileNameInput)"
/>

<div class="fileUpload" *ngIf="isOnline && button">
  <div class="file">
    <label class="file-label">
      <!-- <input
        type="file"
        #fileNameInput
        hidden
        multiple
        (change)="startUpload($event.target.files, fileNameInput)"
      /> -->
      <a
        mat-button
        [ngClass]="raised ? 'mat-raised-button' : 'mat-button'"
        (click)="fileNameInput.click()"
        class="cursor"
      >
        <mat-icon [color]="color" *ngIf="!loading">{{ icon }}</mat-icon>
        <mat-spinner
          *ngIf="loading"
          diameter="25"
          style="margin-top: 5px; margin-bottom: 5px"
        ></mat-spinner>
        <span *ngIf="text && !loading">{{ text }}</span>
      </a>
    </label>
  </div>

  <div *ngIf="!uploadDone">
    <div *ngFor="let prog of progress">
      <div *ngIf="prog.snapshot | async as snap">
        {{ snap.bytesTransferred | fileSize }} of
        {{ snap.totalBytes | fileSize }}
      </div>
      <div *ngIf="prog.percentage | async as pct">
        <mat-progress-bar
          mode="determinate"
          [value]="pct"
          max="100"
        ></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
