import {MediaMatcher} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ChecklistItem} from 'src/app/models/Checklistitem';
import {AventiFile} from 'src/app/models/File';
import {ChecklistService} from 'src/app/services/checklist.service';
import {DateService} from 'src/app/services/date.service';
import {environment} from 'src/environments/environment';
import _ from 'underscore';
import {ConfirmComponent} from '../../common/bottom-sheet/confirm/confirm.component';
import {IdService} from 'src/app/services/id.service';

@Component({
  selector: 'app-deviation-sign',
  templateUrl: './deviation-sign.component.html',
  styleUrls: ['./deviation-sign.component.css'],
})
export class DeviationSignComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  @Input() checklistitem: ChecklistItem;
  @Input() objectTypeId: string;

  deviationfiles: AventiFile[];

  projectId: string;

  sanitycheckNoDeviationPicture = true;
  sanitycheckNoDeviationComment = true;

  mobileQuery: MediaQueryList;

  Form = new UntypedFormGroup({
    deviationcomment: new UntypedFormControl('', [Validators.required]),
  });
  get deviationcomment() {
    return this.Form.get('deviationcomment');
  }

  constructor(
    media: MediaMatcher,
    private bottomSheet: MatBottomSheet,
    private checklistservice: ChecklistService,
    // private sharedservice: SharedService,
    private dateservice: DateService,
    private idservice: IdService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
  }

  ngOnInit(): void {
    this.projectId = this.idservice.projectId();
    // this.sharedservice.checklistfiles
    this.checklistservice.getChecklistFilesForChecklistItem(this.checklistitem.checklistid, this.checklistitem.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((fs) => {
        if (fs !== null) {
          // const files: AventiFile[] = [];
          const deviationfiles: AventiFile[] = [];
          fs.forEach((file) => {
            if (file.checklistitemid === this.checklistitem.id) {
              file.timestampstring = this.dateservice.dateAsYYYYMMDDHHNNSS(
                file.timestamp
              );
              if (file.isdeviationsign) {
                deviationfiles.push(file);
                // } else {
                //   files.push(file);
              }
            }
          });
          // this.files = _.sortBy(files, 'timestamporiginal');
          this.deviationfiles = _.sortBy(deviationfiles, 'timestamporiginal');
          if (this.deviationfiles.length > 0) {
            this.sanitycheckNoDeviationPicture = false;
          } else {
            this.sanitycheckNoDeviationPicture = true;
            if (this.checklistitem.deviationSignedBy) {
              this.checklistservice.removeDeviationSignature(
                this.checklistitem
              );
            }
          }
          // this.checkAll();
        }
      });
    this.checklistitem.deviationcomment = this.checklistitem.deviationcomment
      ? this.checklistitem.deviationcomment
      : '';
    if (this.checklistitem.deviationcomment.length > 0) {
      this.sanitycheckNoDeviationComment = false;
    } else {
      this.sanitycheckNoDeviationComment = true;
      if (this.checklistitem.deviationSignedBy) {
        this.checklistservice.removeDeviationSignature(this.checklistitem);
      }
    }
  }

  signDeviationConfirm(): void {
    const text = 'Signére utsjekk av avvik: ';
    const title = 'Signere?';

    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title,
        text,
        yes: {
          name: 'Ja',
          icon: 'edit',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          this.checklistservice.updateDeviationSignature(this.checklistitem);
        }
      });
  }

  updateDeviationComment(deviationcomment) {
    console.log(deviationcomment);
    if (deviationcomment !== this.checklistitem.deviationcomment) {
      this.checklistservice.updateChecklistitemDeviationComment(
        this.checklistitem.id,
        deviationcomment
        // this.isReceiptform
      );
      this.sanitycheckNoDeviationComment = deviationcomment.length <= 0;
    }
    if (deviationcomment.length === 0) {
      this.checklistservice.removeDeviationSignature(this.checklistitem);
    }
  }
}
