<!--<app-spinner-->
<!--disable="true"-->
<!--[header]="spinnerHeader"-->
<!--[text]="['Vennligst vent', '']"-->
<!--*ngIf="isGenerating || object.generatingChecklist"-->
<!--&gt;</app-spinner>-->

<button
  *ngIf="object && !object.generatingChecklist"
  [matTooltip]="tooltip"
  matTooltipPosition="left"
  style="margin: 5px; height: 37px; width: 37px"
  mat-mini-fab
  (click)="newClick()"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
<button
  *ngIf="object && object.generatingChecklist"
  matTooltip="Genererer..."
  matTooltipPosition="left"
  style="margin: 5px; height: 37px; width: 37px"
  mat-mini-fab
  disabled
>
  <mat-spinner diameter="20"></mat-spinner>
</button>
