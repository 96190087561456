<div class="row">
  <app-headertext *ngIf="inputVersion" [text]="'Endre versjon ' + inputVersion.version" hideback="true"></app-headertext>
  <app-headertext *ngIf="!inputVersion" text="Ny versjon" hideback="true"></app-headertext>
  <div class="fill-space"></div>
  <button
    *ngIf="inputVersion?.id"
    mat-button
    (click)="deleteVersionConfirm()"
    class="header-row-button"
  >
    <mat-icon color="warn">delete</mat-icon>
    Slett versjon
  </button>
</div>
<!-- <mat-tab-group>
  <mat-tab label="Ett objekt"> -->

<mat-progress-bar
  mode="indeterminate"
  *ngIf="!latestAppversion && !inputVersion"
></mat-progress-bar>

<form
  [formGroup]="Form"
  (ngSubmit)="create(version.value, summary.value)"
  *ngIf="latestAppversion || inputVersion"
>
  <div class="form-group">
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          matInput
          id="version"
          type="text"
          placeholder="Versjon"
          formControlName="version"
        />
        <mat-error *ngIf="version.touched && version.invalid">
          <div *ngIf="!version.errors.required">Please enter a version</div>
          <div *ngIf="version.errors.required">
            Version no is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <mat-form-field>
        <input
          matInput
          id="summary"
          type="text"
          placeholder="Sammendrag"
          formControlName="summary"
        />
        <mat-error *ngIf="summary.touched && summary.invalid">
          <div *ngIf="!summary.errors.required">Please enter a summary</div>
          <div *ngIf="summary.errors.required">
            Summary is <strong>required</strong>
          </div>
        </mat-error>
      </mat-form-field>
    </p>
    <div *ngIf="itemsTest">
      <mat-form-field *ngFor="let item of itemsTest; let i = index">
        <input
          matInput
          type="text"
          placeholder="Endring"
          [value]="item"
          (keyup)="changeClicked($event.target.value, i)"
        />
      </mat-form-field>
      <!-- <mat-icon>person_outline</mat-icon> -->
      <!-- <mat-form-field>
            <input
              matInput
              id="changeItems"
              type="text"
              placeholder="Endringer"
              formControlName="changeItems"
            />
            <mat-error *ngIf="changeItems.touched && changeItems.invalid">
              <div *ngIf="!changeItems.errors.required">
                Please enter changeItems
              </div>
              <div *ngIf="changeItems.errors.required">
                ChangeItems is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field> -->
    </div>
    <a mat-button (click)="itemsTest.push('')">
      <mat-icon>add</mat-icon>Legg til endring
    </a>
    <p>
      <mat-checkbox
        class="example-margin"
        [checked]="beta"
        (change)="betaChanged($event.checked)"
        >Beta</mat-checkbox
      >
    </p>
    <p>
      <button mat-raised-button id="btnSubmit" [disabled]="Form.invalid" cdkFocusInitial>
        Lagre
      </button>
    </p>
    <mat-error color="warn" *ngIf="error">{{ error }}</mat-error>
  </div>
</form>
