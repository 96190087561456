<mat-icon
  color="accent"
  class="header-icon header-icon-small noselect"
  matTooltip="Service worker not working"
  *ngIf="!swEnabled"
  [matMenuTriggerFor]="Menu"
>settings_input_antenna</mat-icon
>
<mat-icon
  color="primary"
  class="header-icon header-icon-small noselect"
  matTooltip="Service worker OK"
  *ngIf="swEnabled"
>settings_input_antenna</mat-icon
>

<mat-menu #Menu="matMenu">
  <div style="padding: 5px 20px 20px 20px">
    <div>
      <mat-icon color="accent" style="padding: 0 0 10px 0">settings_input_antenna</mat-icon
      >Service worker not working
    </div>
    Offline mode will not work
  </div>
  <!-- <mat-divider></mat-divider> -->
  <!-- <a mat-menu-item color="primary" (click)="reloadVersion()" style="margin-top:5px;">
    <mat-icon color="primary">cached</mat-icon>Oppdater
  </a> -->
</mat-menu>
