
Password must be at least <span
  [style.color]="passwordErrorMinLength ? '#E02020' : '#20A020'">{{ passwordMinLength }} characters long</span> and a combination of at least <br/>
<ul style="margin-top: 0; margin-left: -20px;">
  <li
    [style.color]="passwordErrorUpperCaseLetter ? '#E02020' : '#20A020'">
    one upper-case letter
  </li>
  <li
    [style.color]="passwordErrorLowerCaseLetter ? '#E02020' : '#20A020'">
    one lower-case letter
  </li>
  <li
    [style.color]="passwordErrorNumber ? '#E02020' : '#20A020'">
    one number
  </li>
  <li
  [style.color]="passwordErrorSpecialCharacter ? '#E02020' : '#20A020'">
    one special character
  </li>
</ul>
